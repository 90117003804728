import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { PanTo } from "react-google-map-components";
import FormTextField from "../form/FormTextField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import { connect } from "react-redux";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import LeafletBrandMaker from "../maps/osm/BrandMaker";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";

const enhancer = compose(
  useSheet({
    map: { height: "256px", marginTop: "12px", width: "100%" },
    actions: { marginTop: "12px" },
  }),
  connect(state => ({
    mapProvider: getMapProvider(state),
  })),
  reduxForm({
    form: "OrderAddressForm",
    enableReinitialize: true,
    validate: values => ({
      details: !values.details && "Enter Dropoff Address Details",
      location:
        !isValidCoordinates(values.location) && "Select Dropoff Locations",
    }),
  }),
  formValues({ location: "location" }),
);

OrderAddressForm.propTypes = {
  classes: PropTypes.object,

  reset: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  location: PropTypes.object,
  mapProvider: PropTypes.string,
};

function OrderAddressForm(props) {
  const { classes } = props;

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <FlexBox
      gutter={8}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox>
        {isGoogleMapProvider ? (
          <GoogleMapWrapper
            className={classes.map}
            onClick={event =>
              props.change("location", {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              })
            }
          >
            {isValidCoordinates(props.location) && (
              <div>
                <PanTo latLng={props.location} />
                <BrandMarker position={props.location} />
              </div>
            )}
          </GoogleMapWrapper>
        ) : (
          <LeafletMapWrapper
            className={classes.map}
            onClick={event =>
              props.change("location", {
                lat: event.latlng.lat,
                lng: event.latlng.lng,
              })
            }
          >
            {isValidCoordinates(props.location) && (
              <div>
                <LeafletPanTo latLng={props.location} />
                <LeafletBrandMaker position={props.location} />
              </div>
            )}
          </LeafletMapWrapper>
        )}
      </FlexBox>

      <FlexBox>
        <FormGeoAutoComplete
          name="location"
          countryCode={fp.get(props, "location.country") || "AE"}
          label="Location"
          fullWidth={true}
        />
      </FlexBox>

      <FlexBox>
        <FormTextField name="addressCity" fullWidth={true} label="City" />
      </FlexBox>

      <FlexBox>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormTextField
              name="details"
              fullWidth={true}
              label="Office/Apartment #"
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField name="postcode" fullWidth={true} label="Postcode" />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end" className={classes.actions}>
        <Button onClick={props.reset}>Reset</Button>
        <Button type="submit">Submit</Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderAddressForm);
