import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { FitBounds } from "react-google-map-components";
import BrandMarker from "../maps/BrandMarker";
import BrandMarkerCar from "../maps/BrandMarkerCar";
import PolylineWrapper from "../maps/PolylineWrapper";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import {
  getPublicOrder,
  getPublicOrderDriver,
  getPublicOrderDriverLocation,
} from "../../api/shared/PublicOrderApi";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import { connect } from "react-redux";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import LeafletBrandMarkerCar from "../maps/osm/BrandMarkerCar";
import { FitBounds as LeafletFitBounds } from "react-leflet-map-components";

const enhancer = compose(
  connect(state => ({
    mapProvider: getMapProvider(state),
  })),
  useSheet({
    root: {
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      top: 0,
      display: "flex",
      position: "fixed",
    },
    map: { flex: "1 1 0%", zIndex: 1, position: "relative" },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const orderStream = propsStream
          .distinctUntilKeyChanged("orderId")
          .switchMap(props =>
            getPublicOrder(props.orderId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.get("data")),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(orderStream, (props, response) => ({
          ...props,
          response,
        }));
      },
      propsStream => {
        const driverLocationStream = propsStream
          .distinctUntilKeyChanged("response", isEqualData)
          .filter(props => props.response.getIn(["payload", "id"]) > 0)
          .switchMap(
            props =>
              getPublicOrderDriver(props.orderId)
                .takeLast(1)
                .catch(error => Observable.of({ error })),
            (props, driver) => ({ ...props, driver }),
          )
          .switchMap(
            props =>
              props.driver.error
                ? Observable.of(null)
                : getPublicOrderDriverLocation(props.orderId)
                    .takeLast(1)
                    .map(fp.flow(fp.get("payload.data"), fromJS)),
            // .retryWhen(observer => observer.delay(5 * 1000))
            // .repeatWhen(observer => observer.delay(60 * 1000)),
          )
          .startWith(null)
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(driverLocationStream, (props, driverLocation) => ({
            ...props,
            driverLocation,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

OrderTrackMap.propTypes = {
  classes: PropTypes.object,
  orderId: PropTypes.string.isRequired,
  response: PropTypes.instanceOf(Map),
  driverLocation: PropTypes.instanceOf(Map),
  mapProvider: PropTypes.string,
};

function OrderTrackMap(props) {
  const { classes, response } = props;

  const isGoogleMapWrapper = props.mapProvider === GOOGLE_MAP_PROVIDER;

  if (response.get("pending", false)) {
    return <PageLoading isLoading={true} />;
  }

  const pickupMarkerPosition = {
    lat: response.getIn(["payload", "locations", 0, "lat"]),
    lng: response.getIn(["payload", "locations", 0, "lon"]),
  };

  const dropoffMarkerPosition = {
    lat: response.getIn(["payload", "locations", 1, "lat"]),
    lng: response.getIn(["payload", "locations", 1, "lon"]),
  };

  const driverMarkerPosition = !props.driverLocation
    ? null
    : {
        lat: props.driverLocation.get("lat"),
        lng: props.driverLocation.get("lon"),
      };

  return (
    <div className={classes.root}>
      {isGoogleMapWrapper ? (
        <GoogleMapWrapper className={classes.map}>
          <BrandMarker position={pickupMarkerPosition} />
          <BrandMarker position={dropoffMarkerPosition} accent={false} />

          <PolylineWrapper
            origin={pickupMarkerPosition}
            destination={dropoffMarkerPosition}
          />

          {isValidCoordinates(driverMarkerPosition) ? (
            <div>
              <BrandMarkerCar position={driverMarkerPosition} />

              <FitBounds
                latLngBounds={[
                  driverMarkerPosition,
                  pickupMarkerPosition,
                  dropoffMarkerPosition,
                ]}
              />
            </div>
          ) : (
            <FitBounds
              latLngBounds={[pickupMarkerPosition, dropoffMarkerPosition]}
            />
          )}
        </GoogleMapWrapper>
      ) : (
        <LeafletMapWrapper className={classes.map}>
          <LeafletBrandMarker position={pickupMarkerPosition} />
          <LeafletBrandMarker position={dropoffMarkerPosition} accent={false} />
          {isValidCoordinates(driverMarkerPosition) ? (
            <div>
              <LeafletBrandMarkerCar position={driverMarkerPosition} />

              <LeafletFitBounds
                latLngBounds={[
                  driverMarkerPosition,
                  pickupMarkerPosition,
                  dropoffMarkerPosition,
                ]}
              />
            </div>
          ) : (
            <LeafletFitBounds
              latLngBounds={[pickupMarkerPosition, dropoffMarkerPosition]}
            />
          )}
        </LeafletMapWrapper>
      )}
    </div>
  );
}

export default enhancer(OrderTrackMap);
