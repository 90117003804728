import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import spring from "react-motion/lib/spring";
import presets from "react-motion/lib/presets";
import TransitionMotion from "react-motion/lib/TransitionMotion";

const enhancer = compose(
  useSheet({
    root: { margin: "0 8px", borderRadius: "4px", backgroundColor: "#fff" },
  }),
);

OrderCreateWizardStepper.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

const willEnter = () => ({ height: 0 });
const willLeave = () => ({
  height: spring(0, { damping: 40, stiffness: 300 }),
});

const prepareStyles = style => ({
  height: `${style.height}px`,
  overflow: style.height < 56 ? "hidden" : "visible",
});

function OrderCreateWizardStepper(props) {
  const styles = [];
  const defaultStyles = [];

  React.Children.forEach(props.children, (child, index) => {
    if (React.isValidElement(child)) {
      const key = String(index);

      defaultStyles.push({ key, data: child, style: { height: 0 } });
      styles.push({
        key,
        data: child,
        style: { height: spring(56, presets.wobbly) },
      });
    }
  });

  return (
    <TransitionMotion
      willEnter={willEnter}
      willLeave={willLeave}
      styles={styles}
      defaultStyles={defaultStyles}
    >
      {interpolatedStyles => (
        <Paper className={props.classes.root}>
          {interpolatedStyles.map(config => (
            <div key={config.key} style={prepareStyles(config.style)}>
              {config.data}
            </div>
          ))}
        </Paper>
      )}
    </TransitionMotion>
  );
}

export default enhancer(OrderCreateWizardStepper);
