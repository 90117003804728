import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { pure, compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { getIsRTL } from "../../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    root: {
      marginTop: 5,
      marginBottom: 10,
      display: "flex",
      flexWrap: "nowrap",
      userSelect: "none",
      justifyContent: "space-between",
      alignItems: "center",
    },
    img: {
      maxHeight: 150,
      maxWidth: 256,
      marginTop: "10px",
    },
    title: {
      marginTop: 0,
      textAlign: "center",
    },
  }),
  pure,
  mapPropsStream(propsStream => {
    const {
      stream: onLeftClickStream,
      handler: onLeftClick,
    } = createEventHandler();
    const {
      stream: onRightClickStream,
      handler: onRightClick,
    } = createEventHandler();

    Observable.merge(onLeftClickStream.mapTo(-1), onRightClickStream.mapTo(1))
      .startWith(0)
      .scan(fp.add)
      .combineLatest(propsStream, (index, props) => ({ index, ...props }))
      .distinctUntilChanged(null, ({ index }) => index)
      .subscribe(({ index, sampleItems, onClickArrow }) => {
        const safeOutIndex = index % sampleItems.size;
        const item = sampleItems.get(safeOutIndex);
        onClickArrow(item);
      });

    return propsStream.combineLatest(props => ({
      onLeftClick,
      onRightClick,
      ...props,
    }));
  }),
);

OrderCreateWizardStepDetails.propTypes = {
  classes: PropTypes.object,
  sampleItem: PropTypes.instanceOf(Map),
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  isRTL: PropTypes.bool,
};

function OrderCreateWizardStepDetails(props) {
  const { classes, sampleItem, isRTL } = props;
  const image = sampleItem && sampleItem.get("image");
  const name = sampleItem && sampleItem.get("name");

  return (
    <div className={classes.root}>
      <IconButton onClick={props.onLeftClick}>
        {isRTL ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      {name && (
        <div className={classes.title}>
          {name}
          {image && (
            <img className={classes.img} alt="SampleItems" src={image} />
          )}
        </div>
      )}
      <IconButton onClick={props.onRightClick}>
        {isRTL ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
}

export default enhancer(OrderCreateWizardStepDetails);
