import React, { useEffect, useRef } from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { isEqualData } from "../../helpers/DataUtils";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import { trackPageView } from "../../helpers/EventTracker";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceId,
  getMarketplaceName,
  getMarketplaceFavicon,
  isMarketplaceSubscriptionStatusActive,
  getMarketplace,
} from "../../reducers/MarketplaceReducer";
import {
  onHidePopup,
  showFreeTrialPopup,
} from "../../reducers/SubscriptionReducer";
import { getAppName } from "../../../shared/reducers/AppReducer";

import { ROLE_MARKETPLACE_ADMIN } from "../../../shared/constants/Authorities";
import favicon from "../../assets/favicon.ico";
import { Map } from "immutable";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import AppTitle from "../ui-core/AppTitle";
import Typography from "@material-ui/core/Typography";
import CustomerAppLayoutBar from "./CustomerAppLayoutBar";
import { getCustomer } from "../../reducers/CustomerReducer";

const getTitle = fp.flow(
  fp.over([fp.get("marketplaceName"), fp.get("title")]),
  cleanupStringArray,
  fp.join(" | "),
);
const enhancer = compose(
  withRouter,
  connect(
    state => {
      const userRoles = getUser(state).get("authorities");

      return {
        marketplaceName: getMarketplaceName(state),
        isMarketplaceAdmin:
          userRoles && userRoles.includes(ROLE_MARKETPLACE_ADMIN),
        isStatusActive: isMarketplaceSubscriptionStatusActive(state),
        marketplaceFavicon: getMarketplaceFavicon(state),
        marketplaceId: getMarketplaceId(state),
        marketplace: getMarketplace(state),
        appName: getAppName(state),
        showPopup: showFreeTrialPopup(state),
        customer: getCustomer(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    { onHidePopup },
  ),
  withState("state", "setState", {
    isOpenTrialPopup: true,
  }),
  getContext({
    setLocation: PropTypes.func,
  }),
  useSheet({
    root: {
      height: "100%",
    },
    content: {
      flex: "0.998",
      height: "100%",
      padding: "10px 10px 10px 0",
      overflowY: "auto",
      background: "#F9F9F9",
    },
    sidebarRoot: {
      important: false,
      padding: 10,
      minWidth: "72px",
      maxWidth: "72px",
      width: "4.50vw",
      transition: "0.3s",
      background: "#F9F9F9",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",

      "& .sidebar": {
        paddingTop: "16px",
      },

      "& .MuiAccordionSummary-content": {
        position: "relative",
      },
      "& .overlay": {
        width: "100%",
        height: "48px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: 0,
        zIndex: 10,
        display: "none",
      },
      "& .overlay._second": {
        width: "65%",
        "&.overed": {
          width: "100%",
        },
      },
      "& .MuiTypography-root:not(.MuiTypography-displayBlock)": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "50px",
        transition: "0.3s",
      },
      "&.open": {
        minWidth: "250px",
        maxWidth: "250px",
        "& .MuiListItemIcon-root": {
          maxWidth: "50px",
        },
      },
      "&:not(.open)": {
        "& .MuiTypography-root:not(.MuiTypography-displayBlock)": {
          opacity: "0",
          pointerEvents: "none",
          visibility: "hidden",
        },
        "& .overlay": {
          display: "block",
        },
        "& .MuiPaper-root": {
          position: "relative",
        },
        "& .MuiCollapse-root": {
          position: "absolute",
          zIndex: 3000,
          background: "#fff",
          top: 0,
          left: "50px",
          minWidth: 200,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        },
        "& .MuiAccordionDetails-root .MuiButtonBase-root": {
          paddingLeft: "16px !important",
        },
        "& .MuiAccordionSummary-root": {},
      },
    },
    trialContainer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "red",
      color: "white",
      zIndex: "9999",
      textAlign: "center",
      lineHeight: "2",
    },
    container: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      border: "1.5px solid var(--slate-200, #E2E8F0)",
      borderRadius: 12,
      padding: 2,
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
      },
    },
    sidebarFooter: {
      borderTop: "1px solid #dedede",
    },
    sidebarFooterButton: {
      width: "100%",
      padding: "12px 0",
      cursor: "pointer",
      minWidth: 230,
      "& span": {
        transition: "0.3s",
        color: "#000000de",
      },
      "&:hover": {
        background: "#f3f3f3",
      },
      "& .MuiSvgIcon-root": {
        width: "50px",
      },
      "&:not(.open) span": {
        opacity: "0",
        visibility: "hidden",
      },
    },
    drawer: { "& > div": { top: "64px", bottom: 0, height: "auto" } },
    warningTitle: {
      color: "red",
    },
    daysLeft: {
      padding: "5px 10px",
      borderRadius: "4px",
      backgroundColor: "#ff4f54",
      fontWeight: "bold",
      color: "#fff",
    },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestChange,
      stream: onRequestChangeStream,
    } = createEventHandler();

    const openStream = propsStream
      .pluck("location", "key")
      .distinctUntilChanged()
      // .mapTo(false)
      .merge(onRequestChangeStream);

    const dynamicOpenStream = openStream.withLatestFrom(
      propsStream,
      (open, props) => {
        if (fp.isBoolean(open)) {
          return open;
        } else if (fp.isString(open)) {
          const sideBarOpenString = sessionStorage.getItem("sideBarOpen");
          const sideBarOpenBoolean = sideBarOpenString === "true";
          return sessionStorage.getItem("sideBarOpen") != null
            ? sideBarOpenBoolean
            : // : "";
              JSON.parse(props.sidebarStatus);
        } else if (fp.isUndefined(open)) {
          return false;
        }
        return false;
      },
    );

    const locationStream = propsStream
      .filter(
        fp.flow(
          fp.pick(["location", "marketplaceName", "app", "title"]),
          fp.every(Boolean),
        ),
      )
      .distinctUntilChanged(
        (x, y) =>
          x.location.pathname === y.location.pathname && x.title === y.title,
      )
      .do(props => {
        trackPageView(props.location, getTitle(props), fp.startCase(props.app));
      })
      .startWith({});

    return propsStream
      .combineLatest(
        dynamicOpenStream,
        locationStream,
        (props, open, settings) => ({
          ...props,
          open,
          settings,
          onRequestChange,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AppLayout.propTypes = {
  sheet: PropTypes.object,
  open: PropTypes.bool,
  onRequestChange: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  sidebar: PropTypes.node.isRequired,
  appBarRightAction: PropTypes.node,
  appTitleRightAction: PropTypes.node,
  className: PropTypes.string,
  marketplaceFavicon: PropTypes.string,
  appBarClassName: PropTypes.string,
  customer: PropTypes.instanceOf(Map),
  onSetSidebarStatus: PropTypes.func,
  setLocation: PropTypes.func,
};

AppLayout.defaultProps = {
  hideCrumbs: false,
};

function AppLayout(props) {
  const {
    sheet: { classes },
    open,
  } = props;

  const sidebarRef = useRef(null);
  const mFavicon = props.marketplaceFavicon || favicon;

  useEffect(() => {
    props.onSetSidebarStatus(open);
  }, [open]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{getTitle(props)}</title>
        <link rel="icon" type="image/png" href={mFavicon} sizes="16x16" />
      </Helmet>

      <FlexBox style={{ height: "100%", width: "100%" }}>
        <div
          ref={sidebarRef}
          className={`${classes.sidebarRoot} ${props.open && "open"}`}
        >
          <CustomerAppLayoutBar
            title={props.title}
            rightAction={props.appBarRightAction}
            open={props.open}
            className={props.appBarClassName}
            onToggle={() => props.onRequestChange(!props.open)}
            onRequestChangeFalse={() => props.onRequestChange(!props.open)}
          />

          {props.sidebar}
        </div>
        <div className={classes.content}>
          <div className={cx(classes.container, props.className)}>
            <AppTitle
              setLocation={props.setLocation}
              customer={props.customer}
              crumbs={
                <Typography
                  style={{
                    fontWeight: 900,
                    color: "rgba(0, 0, 0, 0.9)",
                    fontSize: 18,
                  }}
                >
                  {props.title}
                </Typography>
              }
            >
              {props.appTitleRightAction}
            </AppTitle>
            {props.children}
          </div>
        </div>
      </FlexBox>
    </div>
  );
}

export default enhancer(AppLayout);
