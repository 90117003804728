import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CustomerOrderFilterForm from "../../components/orders-core/CustomerOrderFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState("state", "setState", {
    toggleContainer: null,
    filterNameContainer: null,
  }),
  useSheet({
    paper: {
      "@media (max-width: 998px)": {
        minWidth: "300px",
      },
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

CustomerOrderFilterWrapper.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
  setFilter: PropTypes.func,
  showLogistic: PropTypes.bool,
  setSelectedItems: PropTypes.func,
  closeDialog: PropTypes.func,
};

CustomerOrderFilterWrapper.defaultProps = {
  showCashier: false,
  showFinanceMarketplace: false,
  showSearch: false,
  showLogistic: false,
};

function CustomerOrderFilterWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      paperClassName={props.classes.paper}
      onRequestClose={props.onRequestClose}
      title={props.getLocalisationMessage("order_filter")}
    >
      <CustomerOrderFilterForm
        setFilter={props.setFilter}
        isCustomerFilter={true}
        setSelectedItems={props.setSelectedItems}
        showLogistic={props.showLogistic}
        filterNameContainer={props.state.filterNameContainer}
        showCompany={false}
        closeDialog={props.closeDialog}
        showSearch={false}
        showMerchant={false}
        showPromiseDate={false}
        filter={props.filter}
        getCachedServiceType={getCachedServiceType}
        getServiceTypePredictions={getServiceTypePredictions}
        onDismiss={props.onRequestClose}
        onFilterChange={props.onFilterChange}
        toggleContainer={props.state.toggleContainer}
        getCachedCity={getCachedCity}
        getCityPredictions={getCityPredictions}
        getCachedCountry={getCachedCountry}
        getCountryPredictions={getCountryPredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictions={getPostcodePredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(CustomerOrderFilterWrapper);
