import React, { useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../../ui-core/FlexBox";
import { marketplaceDefaultOrderCreationEnabled } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import CustomButton, { TEXT } from "../../ui-core/CustomButton";
import { getPDF } from "../../../helpers/FormUtils";
import { IS_DOWNLOADING, IS_PRINTING } from "../../orders-core/AdminPostForm";
import { CircularProgress } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import { showErrorMessage } from "../../../reducers/NotificationsReducer";
import { ZEBRA_URL } from "../../../containers/customer/CustomerOrderListNew";

export const BRIEF_TAB = "brief";
export const HISTORY_TAB = "history";
export const MAP_TAB = "map";
export const SIGNATURE_TAB = "signature";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
    }),
    { showErrorMessage },
  ),
  withTheme,
  useSheet({
    root: { display: "flex", padding: "0 12px 18px" },

    tab: {
      padding: 0,
      fontSize: "13px",
      marginLeft: "12px",
      marginRight: "12px",
      color: props => props.theme.palette.activeTextColor,
      border: "none",
      outline: "none",
      cursor: "pointer",
      background: "none",
      fontFamily: "Blogger Sans",
      textTransform: "uppercase",
      transition: `color 250ms ease`,
      "&:hover": {
        color: props => props.theme.active.tab,
        textDecoration: "none",
      },
    },

    customTab: {
      padding: 0,
      fontSize: "13px",
      paddingLeft: "12px",
      paddingRight: "12px",
      color: props => props.theme.tabs.textColor,
      border: "none",
      outline: "none",
      cursor: "pointer",
      background: props => props.theme.palette.newButtonColor1,
      fontFamily: "Blogger Sans",
      textTransform: "uppercase",
      transition: `color 250ms ease`,
      "&:hover": {
        background: props => props.theme.palette.primary1Color,
        color: props => props.theme.tabs.textColor,
        textDecoration: "none",
      },
    },

    tabActive: { color: props => props.theme.active.tab },
  }),
);

CustomerOrderDetailsDialogTabs.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  onTabChange: PropTypes.func.isRequired,

  tab: PropTypes.oneOf([BRIEF_TAB, HISTORY_TAB, SIGNATURE_TAB, MAP_TAB]),
  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  isDefaultOrderCreation: PropTypes.bool,
};

function CustomerOrderDetailsDialogTabs(props) {
  const { classes, getLocalisationMessage, isDefaultOrderCreation } = props;

  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });

  return (
    <FlexBox className={classes.root}>
      <FlexBox flex={true}>
        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(BRIEF_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === BRIEF_TAB,
          })}
        >
          {getLocalisationMessage("brief", "Brief")}
        </button>

        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(HISTORY_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === HISTORY_TAB,
          })}
        >
          {getLocalisationMessage("history", "History")}
        </button>

        {!isDefaultOrderCreation && (
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(MAP_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === MAP_TAB,
            })}
          >
            {getLocalisationMessage("map", "Map")}
          </button>
        )}

        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(SIGNATURE_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === SIGNATURE_TAB,
          })}
        >
          {getLocalisationMessage("signatures", "Signatures")}
        </button>
      </FlexBox>

      <FlexBox>
        <CustomButton
          variant={TEXT}
          onClick={() =>
            getPDF(
              zebra,
              setZebra,
              IS_PRINTING,
              props.order.get("id"),
              props.showErrorMessage,
            )
          }
          endIcon={
            zebra[IS_PRINTING] ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              <Print />
            )
          }
        >
          {getLocalisationMessage("address_label")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerOrderDetailsDialogTabs);
