import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPasswordRequest } from "../../actions/AuthActions";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { CHANGE_PASSWORD_URL } from "../../constants/DashboardPathConstants";
import CustomerRecoverPasswordForm from "../../components/auth/CustomerRecoverPasswordForm";
import { formatPhone2 } from "../../helpers/FormatUtils";

export const redirectUrl = window.location.origin + CHANGE_PASSWORD_URL;
const enhancer = connect(
  state => ({
    i18n: getMessages(state),
  }),
  {
    showErrorMessage,
    showSuccessMessage,
    resetPasswordRequest,
  },
);

CustomerRecoverPasswordContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  resetPasswordRequest: PropTypes.func,
  onSubmitFail: PropTypes.func,
  i18n: PropTypes.func,
};

function CustomerRecoverPasswordContainer({
  i18n,
  onSubmitFail,
  resetPasswordRequest: resetPasswordRequest1,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);

  return (
    <CustomerRecoverPasswordForm
      step={step}
      isLoading={isLoading}
      onSubmit={values => {
        setIsLoading(true);
        resetPasswordRequest1(formatPhone2(values.email), redirectUrl)
          .then(res => {
            if (res.value.status === "success") {
              setStep(2);
              setIsLoading(false);
              showSuccessMessage1(i18n.get("successfully_submitted"));
            } else {
              setIsLoading(false);
              showErrorMessage1(res.message || i18n.get("failed"));
            }
          })
          .catch(error => {
            setIsLoading(false);
            if (onSubmitFail) {
              onSubmitFail(error);
            } else {
              showErrorMessage1(error);
            }
          });
      }}
      // onSubmitSuccess={() =>
      //   props.showSuccessMessage(
      //     i18n.get(
      //       "please_check_your_email_to_further_proceed_resetting_your_password",
      //       "Please check your email to further proceed resetting your password.",
      //     ),
      //   )
      // }
      onSubmitFail={showErrorMessage1}
    />
  );
}

export default enhancer(CustomerRecoverPasswordContainer);
