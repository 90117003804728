import React from "react";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import {
  isEqualDataIn,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { orderDeliveryConfirmPublicOrder } from "../../api/shared/PublicOrderApi";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderConfirmForm from "../../components/order-track/OrderConfirmForm";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import LogoWing from "../../assets/logo_wing_amazon.png";

const enhancer = compose(
  useSheet({
    card: {
      minWidth: "640px",
      maxWidth: "640px",
      marginBottom: "8px",

      [responsive("$xs")]: { minWidth: "100%" },
    },
    logo: { padding: "12px" },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream.distinctUntilChanged(
      isEqualDataIn(["params", "code"]),
    );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        code: initialValues.params.code,
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

CustomerOrderRescheduleConfirmContainer.propTypes = {
  classes: PropTypes.object,
  code: PropTypes.string,
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  location: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerOrderRescheduleConfirmContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox flex={true} align="center" container={8} direction="column">
      <Card className={classes.card}>
        <FlexBox justify="center">
          <img src={LogoWing} alt="Wing.ae" className={classes.logo} />{" "}
        </FlexBox>

        {props.location.query.confirmed ? (
          <div
            style={{
              width: "100%",
              fontWeight: "bold",
              textAlign: "center",
              padding: "10px",
            }}
          >
            Thanks for letting us know.
          </div>
        ) : (
          <div>
            <CardHeader
              textStyle={{ paddingRight: 0 }}
              title={
                <FlexBox justify="center">
                  <span>
                    <a
                      target="_blank"
                      href="https://www.souq.com/"
                      rel="noopener noreferrer"
                    >
                      Souq.com
                    </a>{" "}
                    {getLocalisationMessage(
                      "dubay_national_day",
                      "shipment delivery confirmation: we would like to know if you are planning to be at your chosen business address during the 2nd and 3rd December National Holidays in UAE?",
                    )}
                  </span>
                </FlexBox>
              }
            />

            <CardContent>
              <OrderConfirmForm
                publicId={props.code}
                onConfirm={values =>
                  Promise.resolve(
                    orderDeliveryConfirmPublicOrder(values).catch(
                      ResponseError.throw,
                    ),
                  )
                    .then(() =>
                      props.setLocation(
                        updateQuery(props.location, {
                          confirmed: true,
                        }),
                      ),
                    )
                    .catch(error =>
                      props.showErrorMessage(
                        error.errors.responseCode || error.errors._error,
                      ),
                    )
                }
              />
            </CardContent>
          </div>
        )}
      </Card>
    </FlexBox>
  );
}

export default enhancer(CustomerOrderRescheduleConfirmContainer);
