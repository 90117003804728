import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import OrderCreateWizardStepCourierItem from "./OrderCreateWizardStepCourierItem";
import OrderCreateNoNSAArea from "../internal/OrderCreateNoNSAArea";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardNew");

const enhancer = compose(
  withTheme,
  useSheet({
    progress: { display: "flex", paddingTop: "24px", justifyContent: "center" },
    vehicleIcon: {
      width: "18px",
      height: "18px",
      color: props => props.theme.palette.primary1Color,
      marginLeft: "6px",
      verticalAlign: "sub",
    },
  }),
  reduxForm({
    form: "OrderCreateWizardNew",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "pickupCountry",
      "dropoffCountry",
      "initialCourierId",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream =>
    propsStream.combineLatest(
      propsStream
        .filter(
          props =>
            props.values.initialCourierId > 0 && !props.courierPrice.isEmpty(),
        )
        .take(1)
        .do(props => {
          const item = props.courierPrice.find(
            v => v.getIn(["supplier", "id"]) === props.values.initialCourierId,
          );

          props.change("initialCourierId", null);

          if (item) {
            props.change("courier", item);
            props.handleSubmit();
          }
        })
        .startWith(null),
      propsStream
        .filter(
          props =>
            !props.courierPrice.isEmpty() && props.courierPrice.size === 1,
        )
        .take(1)
        .do(props => {
          const item = props.courierPrice.get(0);

          if (item && !item.get("has_supplier")) {
            props.change("courier", item);
            props.handleSubmit();
          }
        })
        .startWith(null),
      fp.identity,
    ),
  ),
);

OrderCreateWizardStepHowForm.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  showContent: PropTypes.bool,

  values: PropTypes.object,
  onSubmit: PropTypes.func,
  courierPriceFetching: PropTypes.bool.isRequired,
  courierPrice: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepHowForm(props) {
  return (
    <OrderCreateWizardStepCard
      title={props.getLocalisationMessage(
        "who_do_you_want_it_to_be_delivered_with",
        "Who do you want it to be delivered with?",
      )}
      showContent={props.showContent}
    >
      {props.courierPriceFetching ? (
        <div className={props.classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.courierPrice.size > 0 ? (
            props.courierPrice
              .map(item => (
                <OrderCreateWizardStepCourierItem
                  item={item}
                  key={item.getIn(["price", "id"])}
                  pickupCountry={props.values.pickupCountry}
                  dropoffCountry={props.values.dropoffCountry}
                  onClick={() => {
                    props.change("courier", item);
                    props.handleSubmit();
                  }}
                />
              ))
              .toArray()
          ) : (
            <OrderCreateNoNSAArea />
          )}
        </div>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepHowForm);
