import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper, IconButton, Button } from "@material-ui/core";
import { Collapse } from "react-collapse";
import { Clear, Check } from "@material-ui/icons";
import spring from "react-motion/lib/spring";
import Motion from "react-motion/lib/Motion";
import presets from "react-motion/lib/presets";
import { grey } from "@material-ui/core/colors";
import { success1 } from "../../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet({
    root: { borderRadius: "4px" },
    form: { width: "100%", position: "relative" },
    title: {
      width: "100%",
      padding: "12px",
      fontSize: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    dismissButton: {
      marginTop: "-12px",
      marginBottom: "-12px",
      marginRight: "-6px",
    },
    content: { padding: "0 12px 24px", borderTop: `1px solid ${grey[300]}` },
    submitButton: {
      position: "relative",
      "& > div": { position: "absolute", right: "12px", bottom: "-28px" },
    },
    subContent: { position: "absolute", left: 0, right: 0, top: "100%" },
  }),
  mapPropsStream(propsStream => {
    const canShowButtonStream = propsStream
      .map(fp.flow(fp.get("showContent"), Boolean))
      .distinctUntilChanged()
      .switchMap(showContent =>
        showContent
          ? Observable.timer(1500)
              .mapTo(true)
              .startWith(false)
          : Observable.of(false),
      )
      .distinctUntilChanged();

    return propsStream.combineLatest(
      canShowButtonStream,
      (props, canShowButton) => ({ ...props, canShowButton }),
    );
  }),
);

OrderCreateWizardStepCard.propTypes = {
  classes: PropTypes.object,
  canShowButton: PropTypes.bool,

  title: PropTypes.node,
  onTitleClick: PropTypes.func,
  disableTitleClick: PropTypes.bool,
  onSubmit: PropTypes.func,
  showSubmit: PropTypes.bool,
  onDismiss: PropTypes.func,
  children: PropTypes.node,
  showContent: PropTypes.bool,
  subContent: PropTypes.node,
};

OrderCreateWizardStepCard.defaultProps = {
  showSubmit: true,
  showContent: true,
};

function OrderCreateWizardStepCard(props) {
  const { classes } = props;

  return (
    <Paper zDepth={3} className={classes.root}>
      <form className={classes.form} onSubmit={props.onSubmit}>
        <Button
          containerElement="div"
          className={classes.title}
          onClick={props.onTitleClick}
          disabled={!props.onTitleClick || props.disableTitleClick}
        >
          {props.title}

          {Boolean(props.showContent && props.onDismiss) && (
            <IconButton
              className={classes.dismissButton}
              onClick={props.onDismiss}
            >
              <Clear />
            </IconButton>
          )}
        </Button>

        <Collapse
          isOpened={props.showContent}
          theme={{ content: classes.content }}
          springConfig={props.showContent ? presets.wobbly : presets.noWobble}
        >
          {props.children}
        </Collapse>

        <Motion
          style={{
            scale:
              props.showContent &&
              props.canShowButton &&
              props.showSubmit &&
              props.onSubmit
                ? spring(1, presets.wobbly)
                : spring(0, { stiffness: 300, damping: 40 }),
          }}
        >
          {styles => (
            <div className={classes.submitButton}>
              <Button
                type="submit"
                backgroundColor="#fff"
                style={{
                  transition: "none",
                  transform: `scale3d(${styles.scale}, ${styles.scale}, 1)`,
                }}
              >
                <Check style={{ fill: success1 }} />
              </Button>
            </div>
          )}
        </Motion>

        {Boolean(props.subContent) && (
          <div className={classes.subContent}>{props.subContent}</div>
        )}
      </form>
    </Paper>
  );
}

export default enhancer(OrderCreateWizardStepCard);
