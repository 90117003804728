import React from "react";
import sprintf from "sprintf";
import { differenceInCalendarDays } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import { red, green, orange } from "@material-ui/core/colors";
import Text, { PRIMARY, SUCCESS } from "../ui-core/Text";
import Audio from "../ui-core/Audio";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatText } from "../../helpers/FormatUtils";
import { createOrderSortingBinCreator } from "../../helpers/OrderSortingHelper";
import { RESIDENTIAL } from "../../constants/AddressType";
import BeepSound from "../../assets/voices/beep.wav";
import AlarmSound from "../../assets/voices/alarm.ogg";
import ErrorSound from "../../assets/voices/error.mp3";
import MultiBoxSound from "../../assets/voices/multi_box.mp3";

const NA = "N/A";

const formatMultiBox = (pieceCount, scannedPieceCount) =>
  pieceCount > 1 ? (
    pieceCount > scannedPieceCount ? (
      <span style={{ color: orange[700] }}>MultiBox Pending</span>
    ) : (
      <span style={{ color: green[700] }}>MultiBox Complete</span>
    )
  ) : (
    <span style={{ color: green[700] }}>Single</span>
  );

const enhancer = compose(
  useSheet({
    orderStatus: {
      fontSize: "16px",
      lineHeight: "24px",
      "& > strong": {
        fontSize: "24px",
        display: "block",
      },
      "& > span": {
        fontWeight: "bold",
        color: red[400],
        textTransform: "uppercase",
      },
    },
    orderDetails: {
      display: "none",
    },
    orderDetailsMobile: {
      fontSize: "20px",
      lineHeight: "initial",
      "& > div": {
        marginBottom: "15px",
      },
      "& h3": {
        fontSize: "16px",
      },
      "& h4": {
        fontSize: "14px",
        display: "block",
        lineHeight: "20px",
      },
    },
    "@media (min-width: 997px)": {
      orderStatus: {
        fontSize: "34px",
        lineHeight: "initial",
        "& > strong": {
          fontSize: "34px",
          display: "initial",
        },
        "& > span": {
          fontWeight: "bold",
          color: red[400],
          textTransform: "uppercase",
        },
      },
      orderDetails: { display: "flex" },
      orderDetailsMobile: { display: "none" },
    },
    "@media (max-width: 998px)": {
      orderBins: {
        fontSize: "20px",
        lineHeight: "initial",
        "& > strong": {
          fontSize: "34px",
          display: "block",
        },
      },
    },
  }),
  mapPropsStream((propsStream) => {
    const stateStream = propsStream
      .map(fp.pick(["orderNumber", "binRules", "allOrders", "queueOrders"]))
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const queueOrder = props.queueOrders.get(props.orderNumber);

        const state = {};

        if (queueOrder) {
          state.bin = queueOrder.get("bin");
          state.order = queueOrder.get("info");
          state.failed = queueOrder.get("failed");
          state.scannedAsBox = queueOrder.get("scanned_as_box");
        }

        if (!state.failed && !state.order) {
          state.order = props.allOrders.get(props.orderNumber);
        }

        if (state.order) {
          const promiseDate = safeParseDate(state.order.get("promise_date"));

          state.addressType = state.order.get("address_type");

          state.multiBox = formatMultiBox(
            state.order.get("piece_count"),
            state.order.get("scanned_piece_count"),
          );

          state.isMultiBox =
            state.order.get("piece_count") > 1
              ? state.order.get("piece_count") >
                state.order.get("scanned_piece_count")
                ? 1
                : 0
              : -1;

          state.multiBoxButShipmentScanned =
            !state.scannedAsBox && state.isMultiBox > -1;

          if (promiseDate) {
            const diffDate = differenceInCalendarDays(promiseDate, new Date());

            if (diffDate <= 1) {
              state.alarmPromiseDate = true;
            }

            if (diffDate <= 2) {
              state.showPromiseDate =
                diffDate === -1
                  ? "Yesterday"
                  : diffDate === 1
                  ? "Tomorrow"
                  : diffDate === 0
                  ? "Today"
                  : diffDate;
            }
          }
        }

        if (state.order && !state.bin) {
          const createBin = createOrderSortingBinCreator(props.binRules);

          state.bin = createBin(state.order);
        }

        return state;
      });

    return propsStream.combineLatest(stateStream, (props, state) => ({
      ...props,
      ...state,
    }));
  }),
);

SimpleOrderSortingCard.propTypes = {
  classes: PropTypes.object,

  bin: PropTypes.string,
  failed: PropTypes.bool,
  multiBoxButShipmentScanned: PropTypes.bool,
  addressType: PropTypes.string,
  multiBox: PropTypes.node,
  isMultiBox: PropTypes.number,
  alarmPromiseDate: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  showPromiseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onReloadClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,

  onSizeChange: PropTypes.func.isRequired,

  orderNumber: PropTypes.string.isRequired,
  binRules: PropTypes.instanceOf(Map).isRequired,
  allOrders: PropTypes.instanceOf(Map).isRequired,
  queueOrders: PropTypes.instanceOf(Map).isRequired,
};

function SimpleOrderSortingCard(props) {
  const { order, classes } = props;

  let orderKeyNumber = props.orderNumber;

  if (props.isMultiBox === 1 && order) {
    orderKeyNumber = sprintf(
      "%s-%s-%s",
      props.orderNumber,
      order.get("scanned_piece_count", 0),
      order.get("piece_count", 0),
    );
  }

  return (
    <FlexBox container={8} direction="column">
      {Boolean(order || props.failed) && (
        <Audio
          play={true}
          key={orderKeyNumber}
          src={
            props.failed
              ? ErrorSound
              : props.isMultiBox === 1 || props.multiBoxButShipmentScanned
              ? MultiBoxSound
              : props.alarmPromiseDate
              ? AlarmSound
              : BeepSound
          }
        />
      )}

      <br />

      {props.failed ? (
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <strong>{props.orderNumber}</strong> loading failed. (
          <LinkButton onClick={props.onReloadClick}>Reload</LinkButton> /{" "}
          <LinkButton onClick={props.onRemoveClick}>Remove</LinkButton>)
        </Text>
      ) : !props.order ? (
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <strong>{props.orderNumber}</strong> loading...
        </Text>
      ) : props.multiBoxButShipmentScanned ? (
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <span>Warning:</span> <strong>{props.orderNumber}</strong> is{" "}
          <span>MultiBox</span>.
        </Text>
      ) : (
        <FlexBox flex={true} direction="column">
          <Text element="h2" type="align-center" className={classes.orderBins}>
            <strong>{order.getIn(["package", "to_city"]) || NA} </strong>
            {props.addressType && (
              <Text
                type={props.addressType === RESIDENTIAL ? SUCCESS : PRIMARY}
              >
                {formatText(props.addressType)}
              </Text>
            )}{" "}
            {props.isMultiBox > -1 && (
              <Text>
                {props.multiBox}{" "}
                {`${order.get("scanned_piece_count")}/${order.get(
                  "piece_count",
                )}`}
              </Text>
            )}
          </Text>

          <br />

          <Divider />

          <br />

          <FlexBox gutter={8} flex={true} className={classes.orderDetails}>
            <FlexBox flex={true}>
              <FlexBox flex={true} direction="column">
                <h4>Rule Name:</h4>
                <h4>
                  <strong>{order.get("rule_name", NA)}</strong>
                </h4>
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <h4>Rule Description:</h4>
                <h4>
                  <strong>{order.get("rule_description", NA)}</strong>
                </h4>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(SimpleOrderSortingCard);
