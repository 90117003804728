import React from "react";
import { Map, List } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTimeSlot from "./FormTimeSlot";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("RescheduleTimeslotForm");

const enhancer = compose(
  useSheet({
    container: { borderTop: "1px solid #eee", marginTop: "12px" },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    timeslotDate: valueSelector(state, "timeslotDate"),
  })),
  reduxForm({
    form: "RescheduleTimeslotForm",
    validate: (values, props) => ({
      timeSlot:
        !Map.isMap(values.timeSlot) &&
        props.getLocalisationMessage("select_timeslot", "Select timeslot"),
    }),
  }),
  mapPropsStream(propsStream => {
    const sideEffectStream = propsStream
      .distinctUntilKeyChanged("timeslotDate")
      .do(props => props.change("timeSlot", null))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(sideEffectStream, props => props)
      .distinctUntilChanged(isEqualData);
  }),
);

RescheduleTimeslotForm.propTypes = {
  classes: PropTypes.object,
  timeslotList: PropTypes.instanceOf(List),
  timeSlotsFetching: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function RescheduleTimeslotForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox
      className={classes.container}
      gutter={8}
      element={<form onSubmit={props.handleSubmit} />}
      flex={true}
      direction="column"
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} direction="column">
        <FormTimeSlot
          minDate={new Date()}
          timeSlotField="timeSlot"
          timeSlotDateField="timeslotDate"
          timeSlots={props.timeslotList}
          timeSlotsFetching={props.timeSlotsFetching}
        />
      </FlexBox>

      <FlexBox justify="flex-end">
        {props.onDismiss && (
          <Button onClick={props.onDismiss}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        )}

        <Button
          disabled={props.timeSlotsFetching || props.invalid}
          type="submit"
        >
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(RescheduleTimeslotForm);
