import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSupplierName, getUserName } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { SIGN_OUT_URL } from "../../constants/DashboardPathConstants";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { SUPPLIER } from "../../../server/constants/AppTypes";
import { List } from "@material-ui/core";
import SignOutIcon from "../icons/SignOutIcon";
import CustomerAppSidebarLink from "./CustomerAppSidebarLink";

const enhancer = compose(
  useSheet({
    root: {
      marginTop: "auto",
    },
  }),
  connect(state => {
    const appName = getAppName(state);
    const userName =
      appName && appName === SUPPLIER
        ? getSupplierName(state)
        : getUserName(state);

    return {
      userName,
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    };
  }),
);

CustomerAppSidebarProfileBlock.propTypes = {
  classes: PropTypes.object,
  userName: PropTypes.string,
  sidebarStatus: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function CustomerAppSidebarProfileBlock(props) {
  const { classes, getLocalisationMessage, sidebarStatus } = props;

  return (
    <List className={classes.root}>
      <CustomerAppSidebarLink
        to={SIGN_OUT_URL}
        leftIcon={<SignOutIcon />}
        sidebarStatus={sidebarStatus}
      >
        {getLocalisationMessage("sign_out", "Sign Out")}
      </CustomerAppSidebarLink>
    </List>
  );
}

export default enhancer(CustomerAppSidebarProfileBlock);
