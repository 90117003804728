import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { updateBank, getBankItem } from "../../api/customer/CustomerFinanceApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CustomerBankForm from "../../components/bank-core/CustomerBankForm";

const enhancer = compose(
  renderIf(props => props.bankId > 0),
  useSheet({
    paper: { minWidth: "600px", maxWidth: "600px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const bankResponseStream = propsStream
      .distinctUntilKeyChanged("bankId")
      .switchMap(props =>
        getBankItem(props.bankId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(bankResponseStream, (props, bankResponse) => ({
        ...props,
        onRequestRefresh,
        bank: bankResponse.get("payload"),
        isLoading: bankResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

CustomerBankEditDialogWrapper.propTypes = {
  bank: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  bankId: PropTypes.number.isRequired,
  classes: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerBankEditDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("bank", "Bank")} #${props.bankId}`}
    >
      <CustomerBankForm
        onDismiss={props.onRequestClose}
        initialValues={toCamelCase(props.bank)}
        onSubmit={values => {
          const addressLine2 = values.address2 || "";
          return updateBank({
            ...toSnakeCase(_.omit(values, ["address2"])),
            id: props.bankId,
            address2: addressLine2,
          }).catch(ResponseError.throw);
        }}
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully Updated",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(CustomerBankEditDialogWrapper);
