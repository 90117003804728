import { Observable } from "rxjs";
import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Chip, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { ContactPhone } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardAvatars from "../internal/OrderCreateWizardAvatars";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormGeoAutoComplete from "../../form/FormGeoAutoComplete";
import FormCountryAutoComplete from "../../form/FormCountryAutoComplete";
import FlexBox from "../../ui-core/FlexBox";
import FormMapPinDialog from "../../deprecated/FormMapPinDialog";
import CustomerAddressBookSelectDialog from "../../address-book-core/CustomerAddressBookSelectDialog";
import { getValue } from "../../../helpers/DataUtils";
import { parsePhone } from "../../../helpers/FormatUtils";
import { getCustomerId } from "../../../reducers/CustomerReducer";
import { marketplaceMandatoryFieldsDisabled } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizard");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    title: getMessage(
      state,
      getMessage(
        state,
        "who_are_we_picking_it_from",
        "Who are we picking it from?",
      ),
      getMessage(state, "pickup_location", "Pickup Location"),
    ),
    customerId: getCustomerId(state),
    values: valueSelector(
      state,
      "pickupCountry",
      "pickupLocation",
      "initialPickupLocation",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
  })),
  withState("state", "setState", {
    showMapDialog: false,
    showAddressDialog: false,
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream.take(1).do(props => {
        if (props.values.initialPickupLocation) {
          props.change("initialPickupLocation", null);
          props.change("pickupLocation", props.values.initialPickupLocation);

          props.touch(
            "pickupLocation",
            "pickupDetails",
            "pickupContactPhone",
            "pickupContactName",
          );

          props.onShowForm();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("showForm")
        .skip(1)
        .do(props => {
          if (props.valid && props.showForm) {
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream.combineLatest(sideEffectsStream, fp.identity);
  }),
);

OrderCreateWizardStepFromForm.propTypes = {
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  showForm: PropTypes.bool,
  onShowForm: PropTypes.func,

  showContent: PropTypes.bool,
  title: PropTypes.string,
  customerId: PropTypes.number,

  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  disabledOrderMandatoryFields: PropTypes.bool,
};

function OrderCreateWizardStepFromForm(props) {
  const {
    state,
    title,
    getLocalisationMessage,
    disabledOrderMandatoryFields,
  } = props;

  const countryCode = getValue(props.values.pickupCountry, "code");
  const showForm = props.showForm && props.showContent;

  return (
    <OrderCreateWizardStepCard
      title={title}
      showContent={showForm}
      disableTitleClick={showForm}
      onSubmit={props.handleSubmit}
      showSubmit={showForm && props.valid}
      onTitleClick={props.onShowForm}
      subContent={
        Boolean(props.showContent && !props.showForm) && (
          <OrderCreateWizardAvatars
            onAddNew={props.onShowForm}
            onAddressBookClick={() =>
              props.setState(fp.set("showAddressDialog", true))
            }
            suggestions={props.suggestions}
            onSuggestionClick={item => {
              props.change("pickupLocation", {
                lat: item.get("lat"),
                lng: item.get("lon"),
                country: countryCode,
                address: item.get("address"),
              });

              props.change("pickupDetails", item.get("details"));
              props.change("pickupContactPhone", item.get("phone"));
              props.change("pickupContactName", item.get("contact_name"));

              props.touch(
                "pickupLocation",
                "pickupDetails",
                "pickupContactPhone",
                "pickupContactName",
              );

              props.onShowForm();
            }}
          />
        )
      }
    >
      <CustomerAddressBookSelectDialog
        open={state.showAddressDialog}
        onRequestClose={() =>
          props.setState(fp.set("showAddressDialog", false))
        }
        onSubmit={values => {
          props.setState(fp.set("showAddressDialog", false));

          props.change("pickupLocation", {
            country: countryCode,
            lat: values.address.lat,
            lng: values.address.lon,
            address: values.address.address,
          });

          props.change("pickupDetails", values.address.details);
          props.change("pickupContactPhone", values.address.phone);
          props.change("pickupContactName", values.address.contact_name);

          props.touch(
            "pickupLocation",
            "pickupDetails",
            "pickupContactPhone",
            "pickupContactName",
          );

          if (!props.showForm) {
            props.onShowForm();
          }
        }}
      />

      {props.customerId === 516650676 && (
        <div>
          <FlexBox flex={true}>
            <FlexBox flex={8} justify="flex-end">
              <FormTextField
                name="external_user_name"
                fullWidth={true}
                label={getLocalisationMessage(
                  "your_amazon_id",
                  "Your Amazon ID *",
                )}
              />
            </FlexBox>
            <FlexBox flex={4} justify="flex-end">
              <FlexBox direction="column" justify="flex-end">
                <Chip style={{ marginBottom: "10px" }}>@amazon.com</Chip>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <FormTextField
              name="reference_id"
              fullWidth={true}
              label={getLocalisationMessage(
                "souq_reference_number",
                "Souq Reference Number *",
              )}
            />
          </FlexBox>
        </div>
      )}

      <FlexBox>
        <FlexBox
          align="flex-end"
          justify="center"
          style={{
            paddingBottom: "10px",
            paddingRight: "8px",
            "align-self": "flex-end",
          }}
        >
          <FlagIcon code={countryCode} />
        </FlexBox>

        <FlexBox flex={true}>
          <FormCountryAutoComplete
            readOnly={true}
            fullWidth={true}
            openOnFocus={false}
            name="pickupCountry"
            label={getLocalisationMessage("country", "Country")}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type to search ...",
            )}
          />
        </FlexBox>
      </FlexBox>

      {countryCode && (
        <FlexBox gutter={8}>
          <FormMapPinDialog
            countryCode={countryCode}
            open={state.showMapDialog}
            initialValues={{ location: props.values.pickupLocation }}
            onRequestClose={() =>
              props.setState(fp.set("showMapDialog", false))
            }
            onSubmit={values => {
              props.setState(fp.set("showMapDialog", false));
              props.change("pickupLocation", values.location);
            }}
          />

          <FlexBox flex={true}>
            <FormGeoAutoComplete
              fullWidth={true}
              name="pickupLocation"
              countryCode={countryCode}
              label={`${getLocalisationMessage("address", "Address")} *`}
              popoverProps={{ style: { minWidth: "352px" } }}
              onOpenMap={() => props.setState(fp.set("showMapDialog", true))}
              hintText={getLocalisationMessage(
                "type_to_search_3_letters",
                "Type to Search (3 letters minimum)",
              )}
            />
          </FlexBox>

          <FlexBox flex={3}>
            <FormTextField
              name="pickupDetails"
              fullWidth={true}
              label={`${getLocalisationMessage("suite_#", "Suite #")} ${
                disabledOrderMandatoryFields ? "" : " * "
              }`}
            />
          </FlexBox>
        </FlexBox>
      )}

      <FlexBox>
        <FlexBox flex={true} direction="column">
          <FormTextField
            name="pickupContactName"
            fullWidth={true}
            label={`${getLocalisationMessage("sender_name", "Sender Name")} *`}
          />
        </FlexBox>

        <FlexBox align="center">
          <IconButton
            onClick={() => props.setState(fp.set("showAddressDialog", true))}
          >
            <ContactPhone />
          </IconButton>
        </FlexBox>
      </FlexBox>

      <FormTextField
        parse={parsePhone}
        name="pickupContactPhone"
        fullWidth={true}
        label={`${getLocalisationMessage("sender_phone", "Sender Phone")} ${
          disabledOrderMandatoryFields ? "" : " *"
        }`}
      />

      <FormTextField
        name="pickupContactEmail"
        fullWidth={true}
        label={getLocalisationMessage("sender_email", "Sender Email")}
      />

      <FlexBox flex={true}>
        <FormCheckbox
          name="pickupSaveAddressBook"
          label={getLocalisationMessage(
            "save_to_address_book",
            "Save to Address Book",
          )}
        />
      </FlexBox>
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepFromForm);
