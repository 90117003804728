import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getDraftOrder,
  getDraftOrderList,
  getOrderUpload,
  getOrderUploadList,
  getOrderUploadPublishProgress,
  publishOrderUpload,
  reRunOrderUpload,
  updateDraftOrder,
  uploadDomesticOrders,
} from "../../api/customer/CustomerOrderUploadApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import OrderUploadList from "../../components/order-upload/OrderUploadList";
import OrderUploadDialog from "../../components/order-upload/OrderUploadDialog";
import OrderUploadDetails from "../../components/order-upload/OrderUploadDetails";
import OrderUploadEditDialog from "../../components/order-upload/OrderUploadEditDialog";
import OrderUploadPublishDialog from "../../components/order-upload/OrderUploadPublishDialog";

const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
);

CustomerUploadOrdersContainer.propTypes = {
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
};

function CustomerUploadOrdersContainer(props) {
  const { location } = props;

  const batchId = fp.toFinite(location.query.id);
  const draftId = fp.toFinite(location.query.draft_id);
  const openUploadDialog =
    !batchId && !draftId && Boolean(location.query.upload);
  const openPublishDialog = batchId > 0 && Boolean(location.query.publish);

  return (
    <CustomerAppLayout
      slug="upload_orders"
      title={props.getLocalisationMessage("upload_orders", "Upload Orders")}
    >
      <OrderUploadDialog
        open={openUploadDialog}
        onRequestClose={() => props.setLocationQuery(fp.unset("upload"))}
        onSubmit={fp.flow(
          (values) => uploadDomesticOrders(values.file),
          (request) => request.toPromise().catch(ResponseError.throw),
        )}
        onSubmitSuccess={fp.flow(fp.get("payload.data"), (id) =>
          props.setLocationQuery(fp.set("id", id)),
        )}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderUploadEditDialog
        draftId={draftId}
        getDraftOrder={getDraftOrder}
        onRequestClose={() => props.setLocationQuery(fp.unset("draft_id"))}
        onSubmit={(draft) => updateDraftOrder(draft).catch(ResponseError.throw)}
        onSubmitSuccess={() => props.setLocationQuery(fp.unset("draft_id"))}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderUploadPublishDialog
        batchId={batchId}
        open={openPublishDialog}
        getOrderUploadPublishProgress={getOrderUploadPublishProgress}
        onRequestClose={() => props.setLocationQuery(fp.unset("publish"))}
        onRerunButtonClick={() => reRunOrderUpload(batchId)}
        onSubmit={(values) =>
          publishOrderUpload(batchId, values.keepOrder).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "your_orders_placed_successfully",
              "Congratulations! Your Orders Placed Successfully",
            ),
          );
          props.onRequestRefresh();
        }}
        onSubmitFail={props.showErrorMessage}
      />

      {!batchId && (
        <OrderUploadList
          getOrderUploadList={getOrderUploadList}
          onOrderUploadClick={(id) => props.setLocationQuery(fp.set("id", id))}
          onOrderCreateUploadClick={() =>
            props.setLocationQuery(fp.set("upload", true))
          }
        />
      )}

      {Boolean(batchId > 0 && !draftId) && (
        <OrderUploadDetails
          create={false}
          id={batchId}
          getOrdersUpload={getOrderUpload}
          getDraftOrderList={getDraftOrderList}
          onPublishClick={() => props.setLocationQuery(fp.set("publish", true))}
          onBackClick={() => props.setLocationQuery({})}
          onDraftSelect={(id) => props.setLocationQuery(fp.set("draft_id", id))}
        />
      )}
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerUploadOrdersContainer);
