import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { makeStyles, Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import cx from "classnames";

const enhancer = compose(
  withTheme,
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
);

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    display: "flex",
    fontSize: 24,
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
    },
    "& .MuiSwitch-switchBase": {
      color: "#1976d2",
      fontSize: 24,
      "&$checked": {
        color: "#FF9800",
        "& + $track": {
          opacity: 1,
          backgroundColor: "rgb(255, 152, 0, 0.5)",
          borderColor: "rgb(255, 152, 0, 0.5)",
        },
      },
    },
    "& .Mui-checked": {
      color: "#FF9800",
      "& + .MuiSwitch-track": {
        opacity: 0.5,
        backgroundColor: "#FF9800",
        borderColor: "#FF9800",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 0.5,
      backgroundColor: "#1976d2",
      borderColor: "#1976d2",
    },
  },
  label: {
    "& .disabled": {
      pointerEvents: "none",
      color: "grey",
      opacity: "0.7",
    },
  },
}));

CustomToggleComponent.propTypes = {
  input: PropTypes.object,
  trueValue: PropTypes.any,
  disabled: PropTypes.bool,
  falseValue: PropTypes.any,
  valueComparator: PropTypes.func,
  name: PropTypes.string.isRequired,
  rightLabel: PropTypes.any,
  leftLabel: PropTypes.any,
};

function CustomToggleComponent({
  input,
  trueValue,
  falseValue,
  valueComparator,
  name,
  rightLabel,
  leftLabel,
  disabled,
}) {
  const classes = useStyles();

  return (
    <Typography component="div" className={classes.root}>
      <Grid
        component="label"
        container={true}
        alignItems="center"
        spacing={1}
        className={classes.label}
      >
        <Grid
          item={true}
          className={cx({
            disabled: input.value,
          })}
        >
          {leftLabel}
        </Grid>
        <Grid item={true}>
          <Switch
            disabled={disabled}
            name={name}
            checked={valueComparator(input.value, trueValue)}
            onChange={(e, v) => input.onChange(v ? trueValue : falseValue)}
          />
        </Grid>
        <Grid
          item={true}
          className={cx({
            disabled: !input.value,
          })}
        >
          {rightLabel}
        </Grid>
      </Grid>
    </Typography>
  );
}

FormCustomToggle.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.string,
  rightLabel: PropTypes.any,
  leftLabel: PropTypes.any,
  name: PropTypes.string.isRequired,
  trueValue: PropTypes.any,
  falseValue: PropTypes.any,
  valueComparator: PropTypes.func,
  labelStyle: PropTypes.object,
  withLabel: PropTypes.bool,
};

FormCustomToggle.defaultProps = {
  trueValue: true,
  falseValue: false,
  withLabel: true,
  disabled: false,
  valueComparator: fp.eq,
};

function FormCustomToggle(props) {
  return <Field {...props} component={CustomToggleComponent} />;
}

export default enhancer(FormCustomToggle);
