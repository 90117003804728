import { Observable } from "rxjs";
import React from "react";
import { isToday, isTomorrow, isYesterday } from "date-fns";
import { fromJS, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { AddCircleOutline, Timelapse } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import UserAvatar from "../../avatars/UserAvatar";
import FlexBox from "../../ui-core/FlexBox";
import { isEqualData } from "../../../helpers/DataUtils";
import { safeParseDate } from "../../../helpers/DateUtils";
import {
  DATE_SHORT_FORMAT,
  formatDate,
  formatTime,
} from "../../../helpers/FormatUtils";
import {
  isEnableDriverPhoneNumber,
  isReverseLogisticEnabled,
} from "../../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import { getTimeSlotAvailability } from "../../../api/admin/AdminTimeSlotAvailabilityApi";
import { getStatusLocalisation } from "../../../reducers/localisation/OrderStatusLocalisationReducer";
import DateTimeCell from "../../data-list/DateTimeCell";
import { formatOrderStatusCodeForLocalisation } from "../../../helpers/OrderHelper";

const humanizeDate = (date, getLocalisationMessage) => {
  const value = safeParseDate(date);

  if (isTomorrow(value)) {
    return (
      (getLocalisationMessage &&
        getLocalisationMessage("tomorrow", "Tomorrow")) ||
      `Tomorrow`
    );
  }

  if (isToday(value)) {
    return (
      (getLocalisationMessage && getLocalisationMessage("today", "Today")) ||
      `Today`
    );
  }

  if (isYesterday(value)) {
    return (
      (getLocalisationMessage &&
        getLocalisationMessage("yesterday", "Yesterday")) ||
      `Yesterday`
    );
  }

  return formatDate(value, DATE_SHORT_FORMAT);
};

const humanizeDateTime = (date, getLocalisationMessage) =>
  `${humanizeDate(date, getLocalisationMessage)} ${formatTime(date)}`;

const mapResponseDataMap = fp.flow(
  fp.update("pending", Boolean),
  fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
  fromJS,
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    statusMessages: getStatusLocalisation(state),
    enableDriverPhoneNumber: isEnableDriverPhoneNumber(state),
    isRTL: getIsRTL(state),
    reverseLogisticEnabled: isReverseLogisticEnabled(state),
  })),
  withTheme,
  useSheet({
    root: {
      flex: "1 1 0%",
      height: "128px",
      padding: "12px",
      display: "flex",
      flexDirection: "column",

      color: props => props.theme.palette.activeTextColor,

      "& a": {
        color: props => props.theme.palette.activeTextColor,
      },
    },

    panel: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: "1 1 0%",
        fontFamily: "Blogger Sans",
      },
    },

    rootIdLine: {
      marginRight: 30,
    },

    idLine: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      position: "relative",
      maxWidth: "225px",
      "&:hover": {
        overflow: "visible",
        "& span": {
          backgroundColor: props => props.theme.palette.primary1Color,
          boxShadow: props =>
            `0 0 0px 2px ${props.theme.palette.primary1Color}`,
          padding: "5px 0",
        },
      },
    },

    createdDate: {
      width: "32px",
      height: "32px",
      margin: "4px",
      fill: "rgba(0, 0, 0, 0.54)",
    },

    informerTextBlock: {
      marginTop: 5,
      lineHeight: 1.2,
      marginLeft: props => (!props.isRTL ? "8px" : "0"),
      marginRight: props => (props.isRTL ? "8px" : "0"),
      flexDirection: "column",
      justifyContent: "center",
      textTransform: "uppercase",
    },

    informerSecondaryText: { fontSize: "12px" },

    shipment: { textAlign: "right" },
    customer: { display: "flex", flexDirection: "row" },
    customerTextBlock: {
      marginLeft: props => (!props.isRTL ? "12px" : "0"),
      marginRight: props => (props.isRTL ? "12px" : "0"),
      lineHeight: "15px",
      paddingTop: "4px",
      textTransform: "uppercase",
    },
    customerTitle: {
      color: props => props.theme.palette.activeTextColor,
      fontSize: "10px",
    },

    courier: {
      display: "flex",
      marginTop: "20px",
      textAlign: props => (props.isRTL ? "left" : "right"),
      flexDirection: "row-reverse",
    },

    courierTextBlock: {
      paddingTop: "4px",
      lineHeight: "15px",
      marginLeft: props => (!props.isRTL ? "0" : "12px"),
      marginRight: props => (props.isRTL ? "0" : "12px"),
      textTransform: "uppercase",
    },
    courierTitle: {
      color: props => props.theme.palette.activeTextColor,
      fontSize: "10px",
    },
    courierLastSync: {
      fontSize: "12px",
      color: props => props.theme.palette.activeTextColor,
    },
    toCourier: { textAlign: "center", marginTop: "20px", lineHeight: "18px" },
    toCourierTime: { fontSize: "12px" },
    statusWrapper: {
      "&>div": {
        display: "flex",
        "&>div": {
          margin: "0 .5rem",
        },
      },
    },
  }),
  mapPropsStream(propsStream => {
    const pickupTimeSlotAvailabilityResponseStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .filter(
        props =>
          props.order && props.order.get("pickup_timeslot_availability_id"),
      )
      .switchMap(props =>
        getTimeSlotAvailability(
          props.order.get("pickup_timeslot_availability_id"),
        ).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    const dropoffTimeSlotAvailabilityResponseStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .filter(
        props =>
          props.order && props.order.get("drop_off_timeslot_availability_id"),
      )
      .switchMap(props =>
        getTimeSlotAvailability(
          props.order.get("drop_off_timeslot_availability_id"),
        ).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        pickupTimeSlotAvailabilityResponseStream,
        dropoffTimeSlotAvailabilityResponseStream,
        (
          props,
          pickupTimeSlotAvailabilityResponse,
          dropoffTimeSlotAvailabilityResponse,
        ) => ({
          ...props,
          pickupTimeSlotItem: pickupTimeSlotAvailabilityResponse.get("payload"),
          pickupTimeSlotLoading: pickupTimeSlotAvailabilityResponse.get(
            "pending",
          ),
          dropoffTimeSlotItem: dropoffTimeSlotAvailabilityResponse.get(
            "payload",
          ),
          dropoffTimeSlotLoading: dropoffTimeSlotAvailabilityResponse.get(
            "pending",
          ),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

CustomerOrderDetailsDialogHeader.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func,
  getReverseUrl: PropTypes.func,
  enableDriverPhoneNumber: PropTypes.bool,
  reverseLogisticEnabled: PropTypes.bool,
};

function CustomerOrderDetailsDialogHeader(props) {
  const { classes, order, getLocalisationMessage } = props;
  const NA =
    (getLocalisationMessage && getLocalisationMessage("na", "N/A")) || "N/A";

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <div className={classes.rootIdLine}>
          <div className={classes.idLine}>
            <span>
              {getLocalisationMessage("order_upper_case", "ORDER")}:{" "}
              {order.get("order_number") || NA}
            </span>
          </div>

          {order.get("reference_id") && (
            <div className={classes.idLine}>
              <span>
                {getLocalisationMessage("ref", "Ref")}#:{" "}
                {order.get("reference_id") || NA}
              </span>
            </div>
          )}
        </div>

        <FlexBox justify="flex-end">
          <Avatar
            size={40}
            style={{
              backgroundColor: "#fff",
            }}
          >
            <AddCircleOutline className={classes.createdDate} />
          </Avatar>

          <div className={classes.informerTextBlock}>
            <div>{getLocalisationMessage("created_date", "Created Date")}:</div>
            <div className={classes.informerSecondaryText}>
              {humanizeDateTime(
                order.get("created_date"),
                getLocalisationMessage,
              )}
            </div>
          </div>
        </FlexBox>

        {false && (
          <FlexBox
            style={{
              visibility: order.get("merchant_promise_date")
                ? "visible"
                : "hidden",
            }}
          >
            <Avatar
              size={40}
              style={{
                backgroundColor: "#fff",
              }}
            >
              <Timelapse className={classes.createdDate} />
            </Avatar>

            <div className={classes.informerTextBlock}>
              <div>
                {getLocalisationMessage("promise_date", "Promise Date")}:
              </div>
              <div className={classes.informerSecondaryText}>
                {humanizeDateTime(
                  order.get("merchant_promise_date"),
                  getLocalisationMessage,
                )}
              </div>
            </div>
          </FlexBox>
        )}
      </div>

      <div className={classes.panel}>
        <div>
          <div className={classes.customer}>
            <UserAvatar size={40} name={order.getIn(["customer", "name"])} />

            <div className={classes.customerTextBlock}>
              <span className={classes.customerTitle}>
                {getLocalisationMessage("customer", "Customer")}
              </span>
              <br />
              {order.getIn(["customer", "name"])}
            </div>
          </div>
        </div>

        <div style={{ marginLeft: "130px", color: "black" }}>
          <div className={classes.toCourier}>
            <FlexBox className={classes.statusWrapper}>
              <span style={{ textTransform: "uppercase" }}>
                {formatOrderStatusCodeForLocalisation(
                  order.get("status"),
                  getLocalisationMessage,
                )}
              </span>
              {order.get("last_status_date") ? (
                <DateTimeCell date={order.get("last_status_date")} />
              ) : (
                NA
              )}
            </FlexBox>
          </div>
        </div>

        <div>
          {/* {Boolean(order.get("driver") || order.get("supplier")) && ( */}
          {false && (
            <div className={classes.courier}>
              <UserAvatar
                size={40}
                src={order.getIn(["driver", "description"])}
                name={
                  order.getIn(["driver", "name"]) ||
                  order.getIn(["supplier", "name"])
                }
              />
              <div className={classes.courierTextBlock}>
                <div className={classes.courierTitle}>
                  {getLocalisationMessage("postman")}
                </div>

                <div>
                  {Boolean(
                    props.enableDriverPhoneNumber && order.get("driver"),
                  ) && <span>{order.getIn(["driver", "name"])}</span>}

                  {Boolean(
                    props.enableDriverPhoneNumber && order.get("driver"),
                  ) && (
                    <div className={classes.courierLastSync}>
                      {getLocalisationMessage("driver_phone", "Driver Phone")}:{" "}
                      <span>{order.getIn(["driver", "phone"])}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default enhancer(CustomerOrderDetailsDialogHeader);
