import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Place, PinDrop } from "@material-ui/icons";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import {
  placeDetails,
  getPlacePredictions,
} from "../../helpers/GoogleMapsHelper";

const getCountryFromGeocodeResult = fp.flow(
  fp.get("address_components"),
  fp.find(fp.flow(fp.get("types"), fp.includes("country"))),
  fp.get("short_name"),
);

export const GeoAutoCompleteComponent = componentFromStream(propsStream => {
  const valueStream = propsStream
    .map(fp.get("input.value"))
    .filter(Boolean)
    .distinctUntilChanged(fp.isEqual);

  const dataSourceStream = valueStream
    /**
     * Load By { address }
     */
    .filter(({ address, lat, lng }) => Boolean(address && !lat && !lng))
    .combineLatest(propsStream, (value, props) => ({
      input: value.address,
      language: props.language,
      componentRestrictions: { country: props.countryCode },
    }))
    .distinctUntilChanged(fp.isEqual)
    .debounceTime(200)
    .filter(fp.flow(fp.get("input"), fp.size, fp.lte(3)))
    .switchMap(query =>
      Observable.defer(() => getPlacePredictions(query)).catch(() =>
        Observable.of([]),
      ),
    )
    .map(fp.map(fp.pick(["place_id", "description"])))
    .startWith([])
    .distinctUntilChanged(fp.isEqual)
    .map(
      fp.map(item => ({ address: item.description, placeId: item.place_id })),
    );

  const sideEffectsStream = Observable.merge(
    Observable.combineLatest(
      propsStream.pluck("countryCode").distinctUntilChanged(),
      Observable.merge(
        /**
         *  Load by { placeId }
         */
        valueStream
          .filter(({ placeId, lat, lng }) => Boolean(placeId && !lat && !lng))
          .map(fp.pick(["placeId", "address"])),
        /**
         *  Load by { lat, lng }
         */
        valueStream
          .filter(({ address, lat, lng }) => Boolean(!address && lat && lng))
          .map(({ lat, lng }) => ({ location: { lat, lng } })),
      ),
      (region, query) => ({ ...query, region }),
    )
      .switchMap(({ address, ...query }) =>
        Observable.defer(() => placeDetails(query))
          .catch(() => Observable.of(null))
          .map(
            fp.flow(
              // fp.first,
              result =>
                fp.isEmpty(result)
                  ? null
                  : {
                      lat: result.geometry.location.lat,
                      lng: result.geometry.location.lng,
                      country: getCountryFromGeocodeResult(result),
                      address: address || result.formatted_address,
                    },
            ),
          ),
      )
      .filter(Boolean)
      .withLatestFrom(propsStream)
      .do(([value, props]) => props.input.onChange(value)),
  )
    .mapTo(null)
    .startWith(null)
    .distinctUntilChanged();

  return propsStream
    .map(fp.omit(["language", "countryCode"]))
    .distinctUntilChanged(isEqualData)
    .combineLatest(
      dataSourceStream,
      sideEffectsStream,
      ({ onOpenMap, ...props }, options) => (
        <AutoCompleteComponent
          {...props}
          options={
            onOpenMap
              ? [
                  {
                    pinDrop: true,
                    onClick: onOpenMap,
                    address: "Locate On Map",
                  },
                  ...options,
                ]
              : options
          }
          input={{
            ...props.input,
            onChange: item =>
              props.input.onChange(
                item && item.pinDrop ? { ...props.input.value } : item,
              ),
          }}
          filter={fp.stubTrue}
          formatOption={fp.get("address")}
          parseInput={address => ({ address })}
          renderOptionLeftIcon={item =>
            item.pinDrop ? <PinDrop /> : <Place />
          }
        />
      ),
    );
});

GeoAutoCompleteComponent.propTypes = {
  language: PropTypes.string,
  countryCode: PropTypes.string,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,

  onOpenMap: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

GeoAutoCompleteComponent.defaultProps = {
  language: "en",
  hintText: "Type to Search (3 letters minimum)",
};

FormGeoAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  underlineShow: PropTypes.bool,

  onOpenMap: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

export default function FormGeoAutoComplete(props) {
  return <Field {...props} component={GeoAutoCompleteComponent} />;
}
