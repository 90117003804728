import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createBank } from "../../api/customer/CustomerFinanceApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CustomerBankForm from "../../components/bank-core/CustomerBankForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { minWidth: "600px", maxWidth: "600px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

CustomerBankCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerBankCreateDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <ModalPaper
      title={getLocalisationMessage("create_bank", "Create Bank")}
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <FlexBox container={8} direction="column">
        <CustomerBankForm
          onDismiss={props.onRequestClose}
          onSubmit={values => {
            const addressLine2 = values.address2 || "";
            return createBank({
              ...toSnakeCase(_.omit(values, ["address2"])),
              address2: addressLine2,
            }).catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(CustomerBankCreateDialogWrapper);
