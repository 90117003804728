import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate, formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { toTransactionsFilter } from "../../helpers/TransactionsFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getEndingBalance,
  getTransactionItem,
  getTransactionList,
} from "../../api/customer/CustomerFinanceApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CustomerTransactions from "../../components/finance-core/CustomerTransactions";
import CustomerTransactionsFilter from "../../components/finance-core/CustomerTransactionsFilter";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const idStream = propsStream
          .map(fp.flow(fp.get("location.query.id"), fp.toFinite))
          .distinctUntilChanged();

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toTransactionsFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(idStream, filterStream, (props, id, filter) => ({
            ...props,
            id,
            filter,
          }));
      },
      propsStream => {
        const endingBalanceStream = getEndingBalance()
          .catch(() => Observable.of({}))
          .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, fromJS))
          .distinctUntilChanged(isEqualData);

        const filterValuesStream = propsStream
          .distinctUntilKeyChanged("filter", isEqualData)
          .map(props => ({
            fromDate: safeParseDate(props.filter.getValue("from_date")),
            toDate: safeParseDate(props.filter.getValue("to_date")),
          }))
          .distinctUntilChanged(isEqualData);

        const itemResponseStream = propsStream
          .distinctUntilKeyChanged("id", isEqualData)
          .switchMap(
            props =>
              props.id > 0
                ? getTransactionItem(props.id).catch(error =>
                    Observable.of({ error }),
                  )
                : Observable.of({}),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            getTransactionList(props.filter).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            itemResponseStream,
            listResponseStream,
            endingBalanceStream,
            filterValuesStream,
            (
              props,
              itemResponse,
              listResponse,
              endingBalance,
              filterValues,
            ) => ({
              ...props,
              endingBalance,
              filterValues,
              item: itemResponse.get("payload"),
              itemFetching: itemResponse.get("pending"),
              list: listResponse.get("payload"),
              listFetching: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

CustomerTransactionsContainer.propTypes = {
  setLocationQuery: PropTypes.func,
  id: PropTypes.number,
  filterValues: PropTypes.object,
  itemFetching: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  listFetching: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  endingBalance: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerTransactionsContainer(props) {
  return (
    <CustomerAppLayout
      slug="transactions"
      title={props.getLocalisationMessage("transactions", "Transactions")}
    >
      <CustomerTransactionsFilter
        initialValues={props.filterValues}
        onChange={(values, dispatch, formProps) => {
          if (formProps.dirty) {
            props.setLocationQuery(
              fp.flow(
                fp.set("from_date", formatDateToUrl(values.fromDate)),
                fp.set("to_date", formatDateToUrl(values.toDate)),
              ),
            );
          }
        }}
      />

      <br />

      <CustomerTransactions
        selectedId={props.id}
        onItemSelect={id => props.setLocationQuery(fp.set("id", id))}
        item={props.item}
        itemFetching={props.itemFetching}
        list={props.list}
        listFetching={props.listFetching}
        endingBalance={props.endingBalance}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerTransactionsContainer);
