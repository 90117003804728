import React, { useEffect, useRef } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";

const enhancer = compose(
  useSheet({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      overflowX: "hidden",
      maxWidth: "230px",
      width: "100%",
      "&>a:hover": {
        textDecoration: "none",
      },
      "&::-webkit-scrollbar": {
        width: "3px",
        background: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#a9a9a9",
        borderRadius: "10px",
      },
    },
  }),
);

CustomerAppSidebar.propTypes = {
  sheet: PropTypes.object,
  children: PropTypes.node.isRequired,
  scrollTop: PropTypes.number,
};

function CustomerAppSidebar(props) {
  const {
    sheet: { classes },
  } = props;
  const sidebarRef = useRef();

  useEffect(() => {
    if (sidebarRef && sidebarRef.current) {
      const currentEl = sidebarRef.current;
      currentEl.scrollTop = props.scrollTop;
    }
  }, [sidebarRef, props.scrollTop]);

  return (
    <div ref={sidebarRef} className={`${classes.root} sidebar`}>
      {props.children}
    </div>
  );
}

export default enhancer(CustomerAppSidebar);
