import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { accent1 } from "../../../shared/theme/main-theme";
import { responsive } from "../../../shared/theme/jss-responsive";

const enhancer = compose(
  withTheme,
  useSheet(
    {
      root: {
        margin: 0,
        padding: 0,
        width: "100%",
        display: "table",
        overflow: "hidden",
        tableLayout: "fixed",
        [responsive("$xs or $sm")]: { display: "none" },
      },
      item: {
        color: props => props.theme.palette.appBarTextColor,
        borderColor: props => props.theme.palette.primary.main,
        backgroundColor: props => props.theme.palette.primary.main,
        height: "48px",
        fontSize: "12px",
        lineHeight: "14px",
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "3em",
        display: "table-cell",
        position: "relative",
        textAlign: "center",
        verticalAlign: "middle",
        "&:last-of-type": { paddingRight: "1em" },
        "&:first-of-type": { paddingLeft: "1em" },
        "&:before, &:after": {
          content: '""',
          zIndex: 1,
          top: "50%",
          left: "100%",
          width: "0",
          height: "0",
          position: "absolute",
          borderStyle: "solid",
          transform: "translateY(-50%)",
          borderWidth: "5.5em 0 5.5em 1.85em",
          borderColor: `transparent ${accent1}`,
        },
        "&:after": {
          borderLeftColor: "inherit",
          borderWidth: "4.5em 0 4.5em 1.5em",
        },
        "&:last-of-type:before, &:last-of-type:after": { content: "none" },
      },
      itemActive: {
        color: props => props.theme.palette.activeTextColor,
        borderColor: props => props.theme.palette.secondary.main,
        backgroundColor: props => props.theme.palette.secondary.main,
      },
    },
    { important: false },
  ),
);

Breadcrumb.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  items: PropTypes.array,
};

function Breadcrumb(props) {
  const { classes } = props;

  return (
    <ul className={cx(props.className, classes.root)}>
      {_.map(
        props.items,
        ({ label, active, className, ...itemProps }, index) => (
          <li
            {...itemProps}
            key={index}
            className={cx(classes.item, className, {
              [classes.itemActive]: active,
            })}
          >
            {label}
          </li>
        ),
      )}
    </ul>
  );
}

export default enhancer(Breadcrumb);
