import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderCreateWizardStepReverseItem from "./OrderCreateWizardStepReverseItem";
import { getMessage } from "../../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepReverseDetails.propTypes = {
  onClick: PropTypes.func.isRequired,
  logistic_type: PropTypes.any,
};

function OrderCreateWizardStepReverseDetails(props) {
  return (
    <OrderCreateWizardStepReverseItem
      logistic_type={props.logistic_type}
      onClick={props.onClick}
    />
  );
}

export default enhancer(OrderCreateWizardStepReverseDetails);
