import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import OrderCreateWizardStepHowItem from "./OrderCreateWizardStepHowItem";
import OrderCreateNoNSAArea from "../internal/OrderCreateNoNSAArea";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardDefault");

const enhancer = compose(
  withTheme,
  useSheet({
    progress: { display: "flex", paddingTop: "24px", justifyContent: "center" },
    vehicleIcon: {
      width: "18px",
      height: "18px",
      color: props => props.theme.palette.primary1Color,
      marginLeft: "6px",
      verticalAlign: "sub",
    },
  }),
  reduxForm({
    form: "OrderCreateWizardDefault",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "pickupCountry",
      "dropoffCountry",
      "initialPackageId",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream =>
    propsStream.combineLatest(
      propsStream
        .filter(
          props =>
            props.values.initialPackageId > 0 && !props.packagePrices.isEmpty(),
        )
        .take(1)
        .do(props => {
          const item = props.packagePrices.find(
            v => v.getIn(["price", "id"]) === props.values.initialPackageId,
          );

          props.change("initialPackageId", null);

          if (item) {
            props.change("package", item);
            props.handleSubmit();
          }
        })
        .startWith(null),
      propsStream
        .filter(
          props =>
            !props.packagePrices.isEmpty() && props.packagePrices.size === 1,
        )
        .take(1)
        .do(props => {
          const item = props.packagePrices.get(0);

          if (item) {
            props.change("package", item);
            props.handleSubmit();
          }
        })
        .startWith(null),
      fp.identity,
    ),
  ),
);

OrderCreateWizardStepHowForm.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  showContent: PropTypes.bool,

  values: PropTypes.object,
  onSubmit: PropTypes.func,
  packagePricesFetching: PropTypes.bool.isRequired,
  packagePrices: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepHowForm(props) {
  return (
    <OrderCreateWizardStepCard
      title={props.getLocalisationMessage(
        "how_do_you_want_us_to_deliver",
        "How do you want us to deliver?",
      )}
      showContent={props.showContent}
    >
      {props.packagePricesFetching ? (
        <div className={props.classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.packagePrices.size > 0 ? (
            props.packagePrices
              .map(item => (
                <OrderCreateWizardStepHowItem
                  item={item}
                  key={item.get("id")}
                  pickupCountry={props.values.pickupCountry}
                  dropoffCountry={props.values.dropoffCountry}
                  onClick={() => {
                    props.change("package", item);
                    props.handleSubmit();
                  }}
                />
              ))
              .toArray()
          ) : (
            <OrderCreateNoNSAArea />
          )}
        </div>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepHowForm);
