import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  reduxForm({ enableReinitialize: true }),
  useSheet({
    row: { alignItems: "center" },
    paidColumn: { width: "120px" },
    submitColumn: { width: "150px" },
    typeColumn: { width: "150px" },
  }),
);

OrderChargeEditDialogForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function OrderChargeEditDialogForm(props) {
  const { initialValues, classes, i18n } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox gutter={8} flex={true} align="center">
        <FlexBox className={classes.typeColumn}>
          {i18n.get(initialValues.type) || formatText(initialValues.type)}
        </FlexBox>

        <FlexBox flex={true}>
          <FormTextField name="charge" />
        </FlexBox>

        <FlexBox className={classes.paidColumn}>
          <FormCheckbox name="paid" label={i18n.get("paid", "Paid")} />
        </FlexBox>

        <FlexBox>
          <Button type="submit">{i18n.get("update", "Update")}</Button>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(OrderChargeEditDialogForm);
