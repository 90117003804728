import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import UserAvatar from "../../avatars/UserAvatar";
import PriceWrapper from "../../ui-core/PriceWrapper";
import { getMarketplace } from "../../../reducers/MarketplaceReducer";
import { getIsRTL } from "../../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    priceLtr: {
      position: "absolute",
      fontSize: 16,
      right: 10,
      top: 16,
    },
    priceRtl: {
      position: "absolute",
      fontSize: 16,
      left: 10,
      top: 16,
    },
  }),
  connect(state => ({
    isRTL: getIsRTL(state),
    marketplace: getMarketplace(state),
  })),
);

OrderCreateWizardStepHowItem.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node,
  isRTL: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  marketplace: PropTypes.instanceOf(Map),
};

function OrderCreateWizardStepHowItem(props) {
  const { item } = props;

  if (!item) {
    return null;
  }

  const name = item.getIn(["supplier", "name"]);
  const logo = item.getIn(["supplier", "logo"]);
  const currencyCode = item.getIn(["price", "currency", "code"]);

  return (
    <OrderCreateWizardStepDetails
      label={props.label}
      onClick={props.onClick}
      avatar={<UserAvatar name={name} src={logo} />}
      primaryText={
        <div>{item.get("supplier") ? name : props.marketplace.get("name")}</div>
      }
      rightPrimaryText={
        <div
          className={
            props.isRTL ? props.classes.priceRtl : props.classes.priceLtr
          }
        >
          <PriceWrapper
            price={item.getIn(["price", "total"])}
            code={currencyCode}
          />
        </div>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepHowItem);
