import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import {
  formValues,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
} from "redux-form";
import { IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { ArrowBack, ContactPhone, Place } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardStepFromLocationForm from "./OrderCreateWizardStepFromLocationForm";
import OrderCreateWizardAvatars from "../internal/OrderCreateWizardAvatars";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCardNew";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormSelectField from "../../form/FormSelectField";
import FormGeoAutoComplete from "../../form/FormGeoAutoComplete";
import FormCountryAutoComplete from "../../form/FormCountryV2AutoComplete";
import FormYandexGeoAutoComplete from "../../form/FormYandexGeoAutoComplete";
import FormPhoneCodeWithoutCountryField from "../../form/FormPhoneCodeWithoutCountryField";
import { getCurrentLocation } from "../../maps/Geolocation";
import FlexBox from "../../ui-core/FlexBox";
import OrderCityField from "../../orders-core/OrderCityField";
import OrderNeighborhoodField from "../../orders-core/OrderNeighborhoodField";
import CustomerAddressBookSelectV2Dialog from "../../address-book-core/CustomerAddressBookSelectV2Dialog";
import {
  getProperGeoAddressObj,
  getProperGeoAddressString,
} from "../../../helpers/GeoUtils";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import { formatText, parsePhone } from "../../../helpers/FormatUtils";
import { isValidCoordinates } from "../../../helpers/ValidateUtils";
import { getMessage as getMessaegeTitle } from "../../../reducers/MessageReducer";
import { marketplaceMandatoryFieldsDisabled } from "../../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import AddressTypes from "../../../constants/AddressType";
import { phoneCountyCodeList } from "../../../constants/PhoneCountryCodes";
import { getMapProvider } from "../../../../shared/reducers/AppReducer";
import { YANDEX_MAP_PROVIDER } from "../../../../shared/constants/MapsControllerConstants";

const valueSelector = formValueSelector("OrderCreateWizardNew");
const getFormErrors = getFormSyncErrors("OrderCreateWizardNew");

const enhancer = compose(
  useSheet({
    container: { marginBottom: 0 },
    details: { marginTop: "10px", padding: "7px 15px", borderRadius: "2px" },
    search: {
      "& > input + div": { display: "none" },
    },
    checkBoxAddressBook: { paddingBottom: "10px", paddingTop: "10px" },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
  }),
  reduxForm({
    form: "OrderCreateWizardNew",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  formValues({
    pickupContactPhone: "pickupContactPhone",
    pickupContactPhoneCode: "pickupContactPhoneCode",
    pickupAddressType: "pickupAddressType",
    pickupBuilding: "pickupBuilding",
    pickupApartment: "pickupApartment",
    pickupNeighborhood: "pickupNeighborhood",
    pickupCity: "pickupCity",
    pickupLocation: "pickupLocation",
    pickupTempLocation: "pickupTempLocation",
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "pickupCountry",
      "pickupCity",
      "pickupNeighborhood",
      "pickupLocation",
      "initialPickupLocation",
      "pickupAddressType",
      "pickupBuilding",
      "pickupApartment",
      "pickupContactPhone",
    ),
    pickupCountry: valueSelector(state, "pickupCountry"),
    formSyncErrors: getFormErrors(state, "pickupLocation"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    title: getMessaegeTitle(
      state,
      getMessage(
        state,
        "who_are_we_picking_it_from",
        "Who are we picking it from?",
      ),
      getMessage(
        state,
        "who_are_we_picking_it_from",
        "Who are we picking it from?",
      ),
    ),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
    mapProvider: getMapProvider(state),
    isRTL: getIsRTL(state),
  })),
  withState("state", "setState", {
    showMapDialog: false,
    showAddressDialog: false,
    showDetailedFields: false,
    focusLocation: false,
  }),
  mapPropsStream(propsStream => {
    propsStream
      .filter(props =>
        Boolean(props.values.pickupLocation && props.pickUpLocationDetails),
      )
      .skip(1)
      .map(fp.get("pickUpLocationDetails"))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .subscribe(([, props]) => {
        if (props.pickUpLocationDetails.get("city")) {
          if (props.pickUpLocationDetails.getIn(["city", "id"])) {
            props.change(
              "pickupCity",
              props.pickUpLocationDetails.get("city").toJS(),
            );
          } else
            props.change(
              "pickupCity",
              props.pickUpLocationDetails.getIn(["city", "name"]) || "",
            );
        } else if (_.isObject(props.pickupCity))
          props.change("pickupCity", null);

        if (props.pickUpLocationDetails.get("neighborhood")) {
          if (props.pickUpLocationDetails.getIn(["neighborhood", "id"])) {
            props.change(
              "pickupNeighborhood",
              props.pickUpLocationDetails.get("neighborhood").toJS(),
            );
          } else
            props.change(
              "pickupNeighborhood",
              props.pickUpLocationDetails.getIn(["neighborhood", "name"]) || "",
            );
        } else if (_.isObject(props.pickupNeighborhood))
          props.change("pickupNeighborhood", null);
      });

    const sideEffectsStream = Observable.merge(
      propsStream
        .distinctUntilKeyChanged("state", isEqualData)
        .delay(200)
        .do(props => {
          if (props.state.focusLocation) {
            props.setState(fp.set("focusLocation", false));
          }
        }),
      propsStream
        .distinctUntilKeyChanged("pickupCountry", isEqualData)
        .delay(200)
        .do(props => {
          if (fp.get("name", props.pickupCountry)) {
            const countryPhoneCode = _.find(phoneCountyCodeList, {
              country: props.pickupCountry.name,
            });
            props.change("pickupContactPhoneCode", countryPhoneCode);
          }
        }),
      propsStream.take(1).do(props => {
        if (props.values.initialPickupLocation) {
          props.change("pickupLocation", props.values.initialPickupLocation);
          props.touch("pickupLocation");

          props.change("initialPickupLocation", null);

          props.onShowLocationForm();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("showLocationForm")
        .skip(1)
        .do(props => {
          if (
            props.valid &&
            props.showLocationForm &&
            isValidCoordinates(props.pickupLocation)
          ) {
            props.touch("pickupLocation");
            props.onShowFormDetails();
          }
        }),
      propsStream
        .distinctUntilKeyChanged("showFormDetails")
        .skip(1)
        .do(props => {
          if (
            props.valid &&
            props.showLocationForm &&
            props.showFormDetails &&
            props.showContent
          ) {
            props.touch(
              "pickupLocation",
              "pickupTempLocation",
              "pickupContactPhoneCode",
              "pickupContactPhone",
              "pickupContactName",
              "pickupCity",
              "pickupNeighborhood",
              "pickupBuilding",
              "pickupApartment",
              "pickupAddressType",
            );

            props.onShowForm();
          }
        }),
      propsStream
        .distinctUntilKeyChanged("showForm")
        .skip(1)
        .do(props => {
          if (
            props.valid &&
            props.showForm &&
            props.showFormDetails &&
            props.showLocationForm
          ) {
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream.combineLatest(sideEffectsStream, fp.identity);
  }),
);

OrderCreateWizardStepFromForm.propTypes = {
  classes: PropTypes.object,
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  title: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,
  showLocationForm: PropTypes.bool,
  onShowLocationForm: PropTypes.func,
  showFormDetails: PropTypes.bool,
  onShowFormDetails: PropTypes.func,
  pickupLocation: PropTypes.object,
  pickUpLocationDetails: PropTypes.object,
  showContent: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  suggestions: PropTypes.instanceOf(List).isRequired,
  pickupContactPhoneCode: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  disabledOrderMandatoryFields: PropTypes.bool,
  mapProvider: PropTypes.string,
  isRTL: PropTypes.bool,
};

function OrderCreateWizardStepFromForm(props) {
  const {
    state,
    classes,
    getLocalisationMessage,
    disabledOrderMandatoryFields,
    isRTL,
  } = props;

  const countryCode = getValue(props.values.pickupCountry, "description");
  const countryId = getValue(props.values.pickupCountry, "id");
  const showForm = props.showLocationForm && props.showContent;
  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;

  if (props.showLocationForm && !props.showFormDetails)
    return (
      <OrderCreateWizardStepFromLocationForm
        onBackButtonClick={props.onBackButtonClick}
        onSubmit={values => {
          props.change("pickupLocation", values.location);
          props.onShowFormDetails();
        }}
      />
    );

  return (
    <OrderCreateWizardStepCard
      title={props.title}
      showContent={showForm}
      onSubmit={props.handleSubmit}
      showNextButton={
        props.showLocationForm && isValidCoordinates(props.pickupLocation)
      }
      showSubmit={showForm && props.valid}
      disableTitleClick={showForm && false}
      onTitleClick={props.onShowLocationForm}
      subContent={
        Boolean(props.showContent && !props.showLocationForm) && (
          <OrderCreateWizardAvatars
            onAddNew={props.onShowLocationForm}
            onAddressBookClick={() =>
              props.setState(fp.set("showAddressDialog", true))
            }
            onFindMyLocation={() =>
              getCurrentLocation(({ coords: { latitude, longitude } }) => {
                props.change("pickupLocation", {
                  lat: latitude,
                  lng: longitude,
                });
                props.onShowLocationForm();
              })
            }
            suggestions={props.suggestions}
            onSuggestionClick={item => {
              props.change("pickupLocation", {
                lat: item.get("lat"),
                lng: item.get("lon"),
                country: countryCode,
                address: item.get("address"),
                geo_address: getProperGeoAddressString(
                  item.get("geo_address") || item.get("address"),
                ),
              });

              props.change("pickupAddressType", item.get("address_type"));
              props.change("pickupStreet", item.get("street"));
              props.change("pickupBuilding", item.get("building"));
              props.change("pickupCountry", item.get("country").toJS());
              props.change("pickupApartment", item.get("apartment"));
              props.change("pickupNearestLandmark", item.get("landmark"));
              props.change("pickupContactPhoneCode", {
                code: item.get("phone_code"),
              });
              props.change("pickupContactPhone", item.get("phone"));
              props.change("pickupContactName", item.get("name"));

              props.touch(
                "pickupLocation",
                "pickupAddressType",
                "pickupStreet",
                "pickupBuilding",
                "pickupCountry",
                "pickupApartment",
                "pickupNearestLandmark",
                "pickupContactPhoneCode",
                "pickupContactPhone",
                "pickupContactName",
              );
              props.onShowLocationForm();
            }}
          />
        )
      }
    >
      <CustomerAddressBookSelectV2Dialog
        open={state.showAddressDialog}
        countryId={countryId}
        onRequestClose={() =>
          props.setState(fp.set("showAddressDialog", false))
        }
        onSubmit={values => {
          props.setState(fp.set("showAddressDialog", false));

          props.change("pickupLocation", {
            country: countryCode,
            lat: values.address.lat,
            lng: values.address.lon,
            address: values.address.address,
            geo_address: getProperGeoAddressObj(values.address),
          });

          props.change("pickupContactName", values.address.name);
          props.change("pickupContactPhone", values.address.phone);
          props.change("pickupContactPhoneCode", {
            code: values.address.phone_code,
          });
          props.change("pickupAddressType", values.address.address_type);
          props.change("pickupStreet", values.address.street);
          props.change("pickupBuilding", values.address.building);
          props.change("pickupApartment", values.address.apartment);
          props.change("pickupNearestLandmark", values.address.landmark);

          props.touch(
            "pickupContactName",
            "pickupContactPhoneCode",
            "pickupContactPhone",
            "pickupLocation",
            "pickupAddressType",
            "pickupStreet",
            "pickupBuilding",
            "pickupApartment",
            "pickupNearestLandmark",
          );

          if (!props.showLocationForm) {
            props.onShowLocationForm();
          }
        }}
      />

      <div className={classes.container}>
        <Paper>
          <FlexBox>
            <FlexBox align="center">
              <IconButton onClick={props.onBackButtonClick}>
                <ArrowBack />
              </IconButton>
            </FlexBox>

            <FlexBox flex={true}>
              {isYandexMap ? (
                <FormYandexGeoAutoComplete
                  fullWidth={true}
                  autoComplete="off"
                  className={classes.search}
                  name="pickupLocation"
                  countryCode={countryCode}
                  hintText={`${getLocalisationMessage(
                    "search_address",
                    "Search Address",
                  )} *`}
                  focus={state.focusLocation}
                  popoverProps={{ style: { minWidth: "352px" } }}
                />
              ) : (
                <FormGeoAutoComplete
                  fullWidth={true}
                  autoComplete="off"
                  className={classes.search}
                  name="pickupLocation"
                  countryCode={countryCode}
                  hintText={`${getLocalisationMessage(
                    "search_address",
                    "Search Address",
                  )} *`}
                  focus={state.focusLocation}
                  popoverProps={{ style: { minWidth: "352px" } }}
                />
              )}
            </FlexBox>

            <FlexBox align="center">
              <IconButton onClick={props.onShowLocationForm}>
                <Place />
              </IconButton>
            </FlexBox>
          </FlexBox>
        </Paper>

        <Paper className={classes.details}>
          <FormGeoAutoComplete
            fullWidth={true}
            autoComplete="off"
            className={classes.search}
            name="pickupLocation"
            countryCode={countryCode}
            hintText={`${getLocalisationMessage(
              "search_address",
              "Search Address",
            )} *`}
            focus={state.focusLocation}
            style={{ visibility: "hidden", height: 0, overflow: "hidden" }}
            popoverProps={{ style: { minWidth: "352px" } }}
          />
          <FlexBox>
            <FlexBox flex={true} direction="column">
              <FormTextField
                name="pickupContactName"
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "sender_name",
                  "Sender name",
                )} *`}
              />
            </FlexBox>

            <FlexBox align="center">
              <IconButton
                onClick={() =>
                  props.setState(fp.set("showAddressDialog", true))
                }
              >
                <ContactPhone />
              </IconButton>
            </FlexBox>
          </FlexBox>

          <FlexBox>
            <FlexBox flex={6} style={{ paddingRight: "5px" }}>
              <FormPhoneCodeWithoutCountryField
                className={
                  isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                }
                name="pickupContactPhoneCode"
                fullWidth={true}
                label={getLocalisationMessage("phone_code", "Phone Code")}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            </FlexBox>
            <FlexBox flex={true} style={{ paddingLeft: "5px" }}>
              <FormTextField
                name="pickupContactPhone"
                focus={true}
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "phone_number",
                  "Phone number",
                )} ${disabledOrderMandatoryFields ? "" : " *"}`}
                parse={parsePhone}
                ignoreRtl={isRTL}
              />
            </FlexBox>
          </FlexBox>

          <FormTextField
            fullWidth={true}
            name="pickupContactEmail"
            label={getLocalisationMessage("sender_email", "Sender Email")}
          />

          <FlexBox>
            <FlexBox
              align="flex-end"
              justify="center"
              style={{ paddingBottom: "10px", paddingRight: "8px" }}
            >
              <FlagIcon code={countryCode} />
            </FlexBox>

            <FlexBox flex={true}>
              <FormCountryAutoComplete
                readOnly={true}
                fullWidth={true}
                openOnFocus={false}
                name="pickupCountry"
                label={`${getLocalisationMessage("country", "Country")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            </FlexBox>
          </FlexBox>

          {props.pickUpLocationDetails && (
            <FlexBox>
              <FlexBox flex={true}>
                <OrderCityField
                  readOnly={true}
                  name="pickupCity"
                  label={`${getLocalisationMessage("city", "City")} ${
                    disabledOrderMandatoryFields ? "" : " *"
                  }`}
                  // onClick={readonly =>
                  //   readonly
                  //     ? props.setState(fp.set("focusLocation", true))
                  //     : null
                  // }
                  location={props.pickUpLocationDetails}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <OrderNeighborhoodField
                  readOnly={true}
                  name="pickupNeighborhood"
                  label={`${getLocalisationMessage("region", "Region")} ${
                    disabledOrderMandatoryFields ? "" : " *"
                  }`}
                  // onClick={readonly =>
                  //   readonly
                  //     ? props.setState(fp.set("focusLocation", true))
                  //     : null
                  // }
                  location={props.pickUpLocationDetails}
                />
              </FlexBox>
            </FlexBox>
          )}

          <FlexBox gutter={8}>
            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name="pickupStreet"
                label={getLocalisationMessage("street", "Street")}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox gutter={8}>
            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name="pickupBuilding"
                label={`${getLocalisationMessage(
                  "building_villa",
                  "Building / Villa",
                )} ${disabledOrderMandatoryFields ? "" : " *"}`}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name="pickupApartment"
                label={`${getLocalisationMessage(
                  "apartment_office",
                  "Apartment / Office",
                )} ${disabledOrderMandatoryFields ? "" : " *"}`}
              />
            </FlexBox>
          </FlexBox>

          <FormSelectField
            name="pickupAddressType"
            label={`${getLocalisationMessage(
              "address_type",
              "Address Type",
            )} *`}
            fullWidth={true}
            options={AddressTypes}
            formatOption={value =>
              formatText(getLocalisationMessage(value, value))
            }
          />

          <FormTextField
            fullWidth={true}
            name="pickupNearestLandmark"
            label={getLocalisationMessage(
              "nearest_landmark",
              "Nearest Landmark",
            )}
          />

          <FlexBox flex={true} className={classes.checkBoxAddressBook}>
            <FormCheckbox
              name="pickupSaveAddressBook"
              label={getLocalisationMessage(
                "save_to_address_book",
                "Save to Address Book",
              )}
            />
          </FlexBox>
        </Paper>
      </div>
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepFromForm);
