import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Placemark } from "react-yandex-maps";
import { PanTo } from "react-google-map-components";
import FormTextField from "../form/FormTextField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormYandexGeoAutoComplete from "../form/FormYandexGeoAutoComplete";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import YandexMapWrapper from "../maps/YandexMapWrapper";
import PageLoading from "../ui-core/PageLoading";
import { pureComponent } from "../../helpers/HOCUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import {
  YANDEX_MAP_PROVIDER,
  GOOGLE_MAP_PROVIDER,
} from "../../../shared/constants/MapsControllerConstants";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      mapProvider: getMapProvider(state),
      getLocalisationMessage,
    };
  }),
  useSheet({
    map: { height: "256px", marginTop: "12px" },
    actions: { marginTop: "12px", textAlign: "right" },
  }),
  reduxForm({
    enableReinitialize: true,
    form: "CustomerOrderLocationForm",
    validate: (values, props) => ({
      details:
        !values.details &&
        props.getLocalisationMessage(
          "enter_dropoff_address_details",
          "Enter Dropoff Address Details",
        ),
      location:
        !isValidCoordinates(values.location) &&
        props.getLocalisationMessage(
          "select_dropoff_locations",
          "Select Dropoff Locations",
        ),
    }),
  }),
  formValues({ location: "location" }),
  pureComponent(fp.pick(["submitting", "location"])),
);

CustomerOrderLocationForm.propTypes = {
  classes: PropTypes.object,

  reset: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  location: PropTypes.object,

  initialValues: PropTypes.object,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  mapProvider: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerOrderLocationForm(props) {
  const { classes, getLocalisationMessage } = props;

  const isYandex = props.mapProvider === YANDEX_MAP_PROVIDER;

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <form autoComplete="off" onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />
      {isYandex && (
        <YandexMapWrapper
          mapCenter={props.location}
          onClick={event => {
            const x = event.get("coords");
            props.change("location", {
              lat: x[0],
              lng: x[1],
            });
          }}
        >
          {isValidCoordinates(props.location) && (
            <Placemark geometry={[props.location.lat, props.location.lng]} />
          )}
        </YandexMapWrapper>
      )}

      {isGoogleMapProvider && (
        <GoogleMapWrapper
          className={classes.map}
          onClick={event =>
            props.change("location", {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            })
          }
        >
          {isValidCoordinates(props.location) && (
            <div>
              <PanTo latLng={props.location} />

              <BrandMarker position={props.location} />
            </div>
          )}
        </GoogleMapWrapper>
      )}
      {!isYandex && !isGoogleMapProvider && (
        <LeafletMapWrapper
          className={classes.map}
          onClick={event =>
            props.change("location", {
              lat: event.latlng.lat,
              lng: event.latng.lng,
            })
          }
        >
          {isValidCoordinates(props.location) && (
            <div>
              <LeafletPanTo latLng={props.location} />

              <LeafletBrandMarker position={props.location} />
            </div>
          )}
        </LeafletMapWrapper>
      )}

      {isYandex ? (
        <FormYandexGeoAutoComplete
          fullWidth={true}
          countryCode="AE"
          name="location"
          label={`${getLocalisationMessage(
            "pickup_location",
            "Pickup Location",
          )} *`}
        />
      ) : (
        <FormGeoAutoComplete
          name="location"
          fullWidth={true}
          countryCode="AE"
          label={getLocalisationMessage("address", "Address")}
        />
      )}

      <FormTextField
        name="details"
        fullWidth={true}
        label={getLocalisationMessage("address_details", "Address Details")}
      />

      <div className={classes.actions}>
        <Button onClick={props.reset}>
          {getLocalisationMessage("reset", "Reset")}
        </Button>

        <Button type="submit">
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </div>
    </form>
  );
}

export default enhancer(CustomerOrderLocationForm);
