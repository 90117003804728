import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import reactHtmlParser from "react-html-parser";
import { InfoOutlined } from "@material-ui/icons";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FlexBox from "../../ui-core/FlexBox";
import ModalPaper from "../../ui-core/ModalPaper";
import { parseFloat } from "../../../helpers/FormatUtils";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardDefault");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    paper: {
      minWidth: "800px",
      maxWidth: "800px",
      minHeight: "auto",
    },
  }),
  reduxForm({
    form: "OrderCreateWizardDefault",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: (values, props) => ({
      acceptCodTerms:
        props.codTerms.get("required") && !values.acceptCodTerms
          ? props.getLocalisationMessage(
              "cod_order_terms_and_conditions",
              "COD order Terms and Conditions",
            )
          : null,
    }),
  }),
  connect(state => ({
    values: valueSelector(state, "codCharge", "acceptCodTerms"),
  })),
  mapPropsStream(propsStream => {
    const {
      handler: setOpenTermsDialog,
      stream: openTermsDialogStream,
    } = createEventHandler();

    return propsStream.combineLatest(
      openTermsDialogStream.startWith(false),
      (props, openTermsDialog) => ({
        ...props,
        openTermsDialog,
        setOpenTermsDialog,
      }),
    );
  }),
);

OrderCreateWizardStepCollectForm.propTypes = {
  openTermsDialog: PropTypes.bool,
  setOpenTermsDialog: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  initialValues: PropTypes.object,

  showContent: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  codTerms: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
};

function OrderCreateWizardStepCollectForm(props) {
  const { getLocalisationMessage, classes, codTerms } = props;
  return (
    <OrderCreateWizardStepCard
      title={`${getLocalisationMessage(
        "collect_cash_on_delivery",
        "Collect cash on delivery",
      )}?`}
      onSubmit={props.handleSubmit}
      showContent={props.showContent}
      showSubmit={Boolean(props.showContent && props.values.codCharge > 0)}
      onDismiss={() => {
        props.change("acceptCodTerms", false);
        props.change("codCharge", props.initialValues.codCharge || null);

        props.onDismiss();
      }}
    >
      <ModalPaper
        open={props.openTermsDialog}
        title={getLocalisationMessage(
          "cod_order_terms_and_conditions",
          "COD order Terms and Conditions",
        )}
        onRequestClose={() => props.setOpenTermsDialog(false)}
        paperClassName={classes.paper}
      >
        <Card>
          <CardContent>
            <FlexBox flex={true} direction="column">
              <div>{reactHtmlParser(codTerms.get("terms"))}</div>
            </FlexBox>
          </CardContent>
          <CardActions>
            <Button onClick={() => props.setOpenTermsDialog(false)}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
            <Button
              onClick={() => {
                props.setOpenTermsDialog(false);
                props.change("acceptCodTerms", true);
              }}
            >
              {getLocalisationMessage("accept", "Accept")}
            </Button>
          </CardActions>
        </Card>
      </ModalPaper>

      <FormTextField
        name="codCharge"
        fullWidth={true}
        parseOnBlur={parseFloat}
        label={`${getLocalisationMessage(
          "how_much_to_collect",
          "How much to collect?",
        )} *`}
      />

      {codTerms.get("enabled") && (
        <FlexBox align="center" justify="space-between">
          <FlexBox flex={true}>
            <FormCheckbox
              name="acceptCodTerms"
              label={`${getLocalisationMessage(
                "i_accept_cod_terms_and_conditions",
                "I accept COD terms and conditions.",
              )} ${props.codTerms.get("required") ? "*" : ""}`}
            />
          </FlexBox>

          <FlexBox>
            <IconButton onClick={() => props.setOpenTermsDialog(true)}>
              <InfoOutlined />
            </IconButton>
          </FlexBox>
        </FlexBox>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepCollectForm);
