import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, formValueSelector } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { ContactPhone } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardAvatars from "../internal/OrderCreateWizardAvatars";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCardNew";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormSelectField from "../../form/FormSelectField";
import FormCountryAutoComplete from "../../form/FormCountryV2AutoComplete";
import FormPhoneCodeWithoutCountryField from "../../form/FormPhoneCodeWithoutCountryField";
import { getCurrentLocation } from "../../maps/Geolocation";
import FlexBox from "../../ui-core/FlexBox";
import OrderCityFieldDefault from "../../orders-core/OrderCityFieldDefault";
import OrderNeighborhoodDefaultField from "../../orders-core/OrderNeighborhoodDefaultField";
import CustomerAddressBookSelectV2Dialog from "../../address-book-core/CustomerAddressBookSelectV2Dialog";
import {
  getProperGeoAddressObj,
  getProperGeoAddressString,
} from "../../../helpers/GeoUtils";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import {
  formatText,
  parsePhone,
  formatPhone,
} from "../../../helpers/FormatUtils";
import ResponseError from "../../../helpers/ResponseError";
import { getMessage as getMessaegeTitle } from "../../../reducers/MessageReducer";
import { marketplaceMandatoryFieldsDisabled } from "../../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import AddressTypes from "../../../constants/AddressType";
import { phoneCountyCodeList } from "../../../constants/PhoneCountryCodes";
import { createAddressBook } from "../../../api/shared/CustomerAddressBookV2Api";

const valueSelector = formValueSelector("OrderCreateWizardDefault");

// const getName = fp.get("name");
// const getActualPlaceName = fp.get("actual_place_name");

const enhancer = compose(
  useSheet({
    search: {
      "& > input + div": { display: "none" },
    },
    checkBoxAddressBook: { paddingBottom: "10px", paddingTop: "10px" },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
  }),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      if (values.pickupSaveAddressBook) {
        const addressBook = {};
        addressBook.address = values.pickupLocationAddress;
        addressBook.address_type = values.pickupAddressType;
        addressBook.apartment = values.pickupApartment;
        addressBook.building = values.pickupBuilding;
        addressBook.city = _.isObject(values.pickupCity)
          ? values.pickupCity
          : { name: values.pickupCity };
        addressBook.country = values.pickupCountry;
        addressBook.landmark = values.pickupNearestLandmark;
        addressBook.name = values.pickupContactName;
        addressBook.neighborhood = _.isObject(values.pickupNeighborhood)
          ? values.pickupNeighborhood
          : { name: values.pickupNeighborhood };
        addressBook.phone = values.pickupContactPhone;
        addressBook.phone_code = values.pickupContactPhoneCode.get("code");
        addressBook.street = values.pickupStreet;
        addressBook.geo_address = values.pickupLocationAddress;
        addressBook.pickup = true;

        Promise.resolve(
          createAddressBook(addressBook).catch(ResponseError.throw),
        ).catch(props.onSubmitFail);
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "OrderCreateWizardDefault",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  formValues({
    pickupContactPhone: "pickupContactPhone",
    pickupContactPhoneCode: "pickupContactPhoneCode",
    pickupAddressType: "pickupAddressType",
    pickupLocationAddress: "pickupLocationAddress",
    pickupBuilding: "pickupBuilding",
    pickupApartment: "pickupApartment",
    pickupNeighborhood: "pickupNeighborhood",
    pickupCity: "pickupCity",
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "pickupCountry",
      "pickupCity",
      "pickupNeighborhood",
      "pickupAddressType",
      "pickupBuilding",
      "initialPickupLocation",
      "pickupApartment",
      "pickupContactPhone",
      "pickupLocationAddress",
    ),
    pickupCountry: valueSelector(state, "pickupCountry"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    title: getMessaegeTitle(
      state,
      "orderCreatePickUpLocation",
      getMessage(
        state,
        "who_are_we_picking_it_from",
        "Who are we picking it from?",
      ),
      getMessage(
        state,
        "who_are_we_picking_it_from",
        "Who are we picking it from?",
      ),
    ),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
    isRTL: getIsRTL(state),
  })),
  withState("state", "setState", {
    showMapDialog: false,
    showAddressDialog: false,
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .distinctUntilKeyChanged("pickupCountry", isEqualData)
        .delay(200)
        .do(props => {
          if (fp.get("name", props.pickupCountry)) {
            const countryPhoneCode = _.find(phoneCountyCodeList, {
              country: props.pickupCountry.name,
            });
            props.change("pickupContactPhoneCode", fromJS(countryPhoneCode));
          }
        }),
      propsStream.take(1).do(props => {
        if (props.values.initialPickupLocation) {
          props.change("initialPickupLocation", null);
          props.change("pickupLocation", props.values.initialPickupLocation);

          props.touch(
            "pickupLocation",
            "pickupDetails",
            "pickupContactPhone",
            "pickupContactName",
          );

          props.onShowForm();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("showForm")
        .skip(1)
        .do(props => {
          if (props.valid && props.showForm) {
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream.combineLatest(sideEffectsStream, fp.identity);
  }),
);

OrderCreateWizardStepFromForm.propTypes = {
  classes: PropTypes.object,
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,

  title: PropTypes.string,
  state: PropTypes.object,
  initialValues: PropTypes.object,
  setState: PropTypes.func,

  showForm: PropTypes.bool,
  onShowForm: PropTypes.func,

  pickupLocation: PropTypes.object,
  showContent: PropTypes.bool,
  pickupBuilding: PropTypes.string,
  pickupApartment: PropTypes.string,
  pickupContactPhone: PropTypes.string,
  pickupLocationAddress: PropTypes.string,
  pickupCountry: PropTypes.object,
  pickupCity: PropTypes.any,
  pickupNeighborhood: PropTypes.any,

  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,
  pickupContactPhoneCode: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  disabledOrderMandatoryFields: PropTypes.bool,
  isRTL: PropTypes.bool,
};

function OrderCreateWizardStepFromForm(props) {
  const {
    state,
    classes,
    getLocalisationMessage,
    disabledOrderMandatoryFields,
    isRTL,
  } = props;

  const countryCode = getValue(props.values.pickupCountry, "description");
  const countryId = getValue(props.values.pickupCountry, "id");
  const cityId = getValue(props.values.pickupCity, "id")
    ? getValue(props.values.pickupCity, "id")
    : 0;
  const showForm = props.showForm && props.showContent;

  return (
    <OrderCreateWizardStepCard
      title={props.title}
      showContent={showForm}
      showSubmit={showForm && props.valid}
      onSubmit={props.handleSubmit}
      disableTitleClick={showForm}
      onTitleClick={props.onShowForm}
      subContent={
        Boolean(props.showContent && !props.showForm) && (
          <OrderCreateWizardAvatars
            onAddNew={props.onShowForm}
            onAddressBookClick={() =>
              props.setState(fp.set("showAddressDialog", true))
            }
            onFindMyLocation={() =>
              getCurrentLocation(({ coords: { latitude, longitude } }) => {
                props.change("pickupLocation", {
                  lat: latitude,
                  lng: longitude,
                });
                props.onShowForm();
              })
            }
            suggestions={props.suggestions}
            onSuggestionClick={item => {
              props.change("pickupLocation", {
                lat: item.get("lat"),
                lng: item.get("lon"),
                country: countryCode,
                address: item.get("address"),
                geo_address: getProperGeoAddressString(
                  item.get("geo_address") || item.get("address"),
                ),
              });

              props.change(
                "pickupCity",
                _.isObject(item.get("city"))
                  ? item.get("city").toJS()
                  : item.get("city"),
              );
              props.change(
                "pickupNeighborhood",
                _.isObject(item.get("neighborhood"))
                  ? item.get("neighborhood").toJS()
                  : item.get("neighborhood"),
              );
              props.change("pickupAddressType", item.get("address_type"));
              props.change("pickupLocationAddress", item.get("address"));
              props.change("pickupStreet", item.get("street"));
              props.change("pickupBuilding", item.get("building"));
              props.change("pickupCountry", item.get("country").toJS());
              props.change("pickupApartment", item.get("apartment"));
              props.change("pickupNearestLandmark", item.get("landmark"));
              props.change(
                "pickupContactPhoneCode",
                fromJS({ code: item.get("phone_code") }),
              );
              props.change("pickupContactPhone", item.get("phone"));
              props.change("pickupContactName", item.get("name"));

              props.touch(
                "pickupLocation",
                "pickupAddressType",
                "pickupLocationAddress",
                "pickupStreet",
                "pickupBuilding",
                "pickupCountry",
                "pickupApartment",
                "pickupNearestLandmark",
                "pickupContactPhoneCode",
                "pickupContactPhone",
                "pickupContactName",
                "pickupCity",
                "pickupNeighborhood",
              );
              props.onShowForm();
            }}
          />
        )
      }
    >
      <CustomerAddressBookSelectV2Dialog
        open={state.showAddressDialog}
        countryId={countryId}
        onRequestClose={() =>
          props.setState(fp.set("showAddressDialog", false))
        }
        onSubmit={values => {
          props.setState(fp.set("showAddressDialog", false));

          props.change("pickupLocation", {
            country: countryCode,
            lat: values.address.lat,
            lng: values.address.lon,
            address: values.address.address,
            geo_address: getProperGeoAddressObj(values.address),
          });

          props.change("pickupCity", values.address.city);
          props.change("pickupNeighborhood", values.address.neighborhood);
          props.change("pickupContactName", values.address.name);
          props.change("pickupContactPhone", values.address.phone);
          props.change(
            "pickupContactPhoneCode",
            fromJS({ code: values.address.phone_code }),
          );
          props.change("pickupAddressType", values.address.address_type);
          props.change("pickupLocationAddress", values.address.address);
          props.change("pickupStreet", values.address.street);
          props.change("pickupBuilding", values.address.building);
          props.change("pickupApartment", values.address.apartment);
          props.change("pickupNearestLandmark", values.address.landmark);

          props.touch(
            "pickupContactName",
            "pickupContactPhoneCode",
            "pickupContactPhone",
            "pickupLocation",
            "pickupAddressType",
            "pickupLocationAddress",
            "pickupStreet",
            "pickupBuilding",
            "pickupApartment",
            "pickupNearestLandmark",
            "pickupCity",
            "pickupNeighborhood",
          );

          if (!props.showForm) {
            props.onShowForm();
          }
        }}
      />

      <FlexBox>
        <FlexBox flex={true} direction="column">
          <FormTextField
            name="pickupContactName"
            fullWidth={true}
            label={`${getLocalisationMessage("sender_name", "Sender name")} *`}
          />
        </FlexBox>

        <FlexBox align="center">
          <IconButton
            onClick={() => props.setState(fp.set("showAddressDialog", true))}
          >
            <ContactPhone />
          </IconButton>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox flex={6} style={{ paddingRight: "5px" }}>
          <FormPhoneCodeWithoutCountryField
            className={
              isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
            }
            name="pickupContactPhoneCode"
            fullWidth={true}
            label={getLocalisationMessage("phone_code", "Phone Code")}
          />
        </FlexBox>

        <FlexBox flex={true} style={{ paddingLeft: "5px" }}>
          <FormTextField
            ignoreRtl={isRTL}
            name="pickupContactPhone"
            focus={true}
            fullWidth={true}
            label={`${getLocalisationMessage("phone_number", "Phone number")} ${
              disabledOrderMandatoryFields ? "" : " *"
            }`}
            parse={parsePhone}
            format={value =>
              formatPhone(
                props.pickupContactPhoneCode &&
                  props.pickupContactPhoneCode.get("code")
                  ? props.pickupContactPhoneCode.get("code")
                  : null,
                value,
              )
            }
          />
        </FlexBox>
      </FlexBox>

      <FormTextField
        fullWidth={true}
        name="pickupContactEmail"
        label={getLocalisationMessage("sender_email", "Sender Email")}
      />

      <FlexBox>
        <FlexBox
          align="flex-end"
          justify="center"
          style={{ paddingBottom: "10px", paddingRight: "8px" }}
        >
          <FlagIcon code={fp.toUpper(countryCode)} />
        </FlexBox>

        <FlexBox flex={true}>
          <FormCountryAutoComplete
            readOnly={true}
            fullWidth={true}
            openOnFocus={false}
            name="pickupCountry"
            label={`${getLocalisationMessage("country", "Country")} *`}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type to search ...",
            )}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox flex={true}>
          <OrderCityFieldDefault
            readOnly={true}
            name="pickupCity"
            label={`${getLocalisationMessage("city", "City")}  *`}
            countryId={countryId}
          />
        </FlexBox>
        <FlexBox flex={true}>
          <OrderNeighborhoodDefaultField
            readOnly={true}
            name="pickupNeighborhood"
            label={getLocalisationMessage("region", "Region")}
            countryId={countryId}
            cityId={cityId}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox gutter={8}>
        <FlexBox flex={true}>
          <FormTextField
            fullWidth={true}
            name="pickupStreet"
            label={getLocalisationMessage("street", "Street")}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox flex={true}>
        <FormTextField
          fullWidth={true}
          required={true}
          name="pickupLocationAddress"
          label={`${getLocalisationMessage("address", "Address")} *`}
        />
      </FlexBox>

      <FlexBox gutter={8}>
        <FlexBox flex={true}>
          <FormTextField
            fullWidth={true}
            name="pickupBuilding"
            label={`${getLocalisationMessage(
              "building_villa",
              "Building / Villa",
            )} ${disabledOrderMandatoryFields ? "" : " *"}`}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormTextField
            fullWidth={true}
            name="pickupApartment"
            label={`${getLocalisationMessage(
              "apartment_office",
              "Apartment / Office",
            )} ${disabledOrderMandatoryFields ? "" : " *"}`}
          />
        </FlexBox>
      </FlexBox>

      <FormSelectField
        name="pickupAddressType"
        label={`${getLocalisationMessage("address_type", "Address Type")} *`}
        fullWidth={true}
        options={AddressTypes}
        formatOption={value => formatText(getLocalisationMessage(value, value))}
      />

      <FormTextField
        fullWidth={true}
        name="pickupNearestLandmark"
        label={getLocalisationMessage("nearest_landmark", "Nearest Landmark")}
      />

      <FlexBox flex={true} className={classes.checkBoxAddressBook}>
        <FormCheckbox
          name="pickupSaveAddressBook"
          label={getLocalisationMessage(
            "save_to_address_book",
            "Save to Address Book",
          )}
        />
      </FlexBox>
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepFromForm);
