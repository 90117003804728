import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toCODTransferFilter } from "../../helpers/CODTransferFilterMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import CODTransferStatus from "../../constants/CODTransferStatus";
import { FINANCE_WITHDRAW_ITEM_URL } from "../../constants/CustomerPathConstants";
import {
  getCODWithdrawList,
  getCODWithdrawStatusCount,
} from "../../api/customer/CustomerFinanceApi";
import Redirect from "../../components/router/Redirect";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CODWithdrawTable from "../../components/finance-core/CODWithdrawTable";
import CODWithdrawTableTabs from "../../components/finance-core/CODWithdrawTableTabs";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const createCODWithdrawHref = id => FINANCE_WITHDRAW_ITEM_URL + id;

const enhancer = compose(
  getContext({ setLocationQueryFilter: PropTypes.func }),
  connect(state => ({
    i18n: getMessages(state),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toCODTransferFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }));
      },
      propsStream => {
        const statusCountsStream = propsStream
          .map(
            fp.flow(fp.get("filter"), (filter: DataListFilter) =>
              filter.setValueMap({
                page: null,
                status: null,
                order_by: null,
                order_by_direction: null,
              }),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODWithdrawStatusCount(filter).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.get("payload.data"),
              fp.keyBy("cod_transfer_status"),
              fp.mapValues("count"),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const responseStream = propsStream
          .pluck("filter")
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODWithdrawList(filter).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  payload: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            responseStream,
            statusCountsStream,
            (props, response, statusCounts) => ({
              ...props,
              statusCounts,
              count: response.get("total"),
              list: response.get("payload"),
              isFetching: response.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

CustomerCODWithdrawListContainer.propTypes = {
  location: PropTypes.object,
  statusCounts: PropTypes.instanceOf(Map),
  list: PropTypes.instanceOf(List),
  count: PropTypes.number,
  isFetching: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function CustomerCODWithdrawListContainer(props) {
  const { location, i18n } = props;

  return (
    <CustomerAppLayout
      slug="withdraw_requests"
      title={i18n.get("withdraw_requests", "Withdraw Requests")}
    >
      <Redirect
        when={!CODTransferStatus.has(location.query.status)}
        to={updateQuery(location, fp.set("status", CODTransferStatus.first()))}
      />

      <CODWithdrawTableTabs
        location={location}
        statusCounts={props.statusCounts}
      />

      <CODWithdrawTable
        list={props.list}
        count={props.count}
        isLoading={props.isFetching}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        createCODWithdrawHref={createCODWithdrawHref}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerCODWithdrawListContainer);
