import React from "react";
import useSheet from "react-jss";
import PropTypes from "prop-types";
import { Toolbar, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const enhancer = useSheet({
  root: { overflowX: "auto", "& svg": { fill: "rgba(0, 0, 0, 0.87)" } },
  backButton: { alignSelf: "center" },
});

class PageBreadcrumb extends React.Component {
  static propTypes = {
    sheet: PropTypes.object,
    title: PropTypes.string.isRequired,
    fallbackBackUrl: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  static contextTypes = { router: PropTypes.object.isRequired };

  handleGoBackClick = () => {
    const { router } = this.context;

    const { fallbackBackUrl } = this.props;

    if (history.length > 2) {
      router.goBack();
    } else {
      router.push(fallbackBackUrl);
    }
  };

  render() {
    const {
      sheet: {
        classes: { root, backButton },
      },
      title,
      children,
    } = this.props;

    return (
      <Toolbar className={root}>
        <IconButton className={backButton} onClick={this.handleGoBackClick}>
          <ArrowBack />
        </IconButton>
        <h6>{title}</h6>
        {children}
      </Toolbar>
    );
  }
}

export default enhancer(PageBreadcrumb);
