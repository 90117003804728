import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { primary1, success1 } from "../../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet({
    root: { position: "relative", marginTop: "8px" },

    labelInitial: {
      top: 0,
      color: "#fff",
      marginRight: "8px",
      position: "absolute",
      whiteSpace: "nowrap",
    },
    label: { right: "56px", lineHeight: "56px", extend: "labelInitial" },
    labelMini: { right: "40px", lineHeight: "40px", extend: "labelInitial" },
    button: { backgroundColor: "#fff" },
    buttonPrimary: { "& svg": { fill: primary1 } },
    buttonSuccess: { "& svg": { fill: success1 } },
  }),
);

OrderCreateWizardFab.propTypes = {
  classes: PropTypes.object,
  mini: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.node,
  children: PropTypes.node,
};

function OrderCreateWizardFab(props) {
  return (
    <div className={props.classes.root}>
      <span
        className={cx(
          props.mini ? props.classes.labelMini : props.classes.label,
        )}
      >
        {props.label}
      </span>

      <IconButton
        size={props.mini ? "small" : "medium"}
        type={props.type}
        onClick={props.onClick}
        className={cx(
          props.classes.button,
          props.success
            ? props.classes.buttonSuccess
            : props.classes.buttonPrimary,
        )}
      >
        {props.children}
      </IconButton>
    </div>
  );
}

export default enhancer(OrderCreateWizardFab);
