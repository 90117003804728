import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function DownloadIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon/Outline/download">
          <path
            id="Icon"
            d="M3.33334 13.3333L3.33334 14.1667C3.33334 15.5474 4.45263 16.6667 5.83334 16.6667L14.1667 16.6667C15.5474 16.6667 16.6667 15.5474 16.6667 14.1667L16.6667 13.3333M13.3333 9.99999L10 13.3333M10 13.3333L6.66668 9.99999M10 13.3333L10 3.33332"
            stroke="#52525B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
