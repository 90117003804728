import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { accent1, accent4 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    buttonYes: {
      width: "100%",
      backgroundColor: accent1,
      "&:hover": { backgroundColor: accent4 },
    },
  }),
);

OrderConfirmForm.propTypes = {
  handleSubmit: PropTypes.func,
  onConfirm: PropTypes.func,
  publicId: PropTypes.string,
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderConfirmForm(props) {
  return (
    <FlexBox gutter={8}>
      <FlexBox flex={true} align="center">
        <FlexBox flex={true} align="center">
          <Button
            className={props.classes.buttonYes}
            fullWidth={true}
            onClick={() =>
              props.onConfirm({ public_id: props.publicId, confirmed: true })
            }
          >
            {props.getLocalisationMessage("yes", "Yes")}
          </Button>
        </FlexBox>

        <FlexBox flex={true} align="center">
          <Button
            fullWidth={true}
            onClick={() =>
              props.onConfirm({ public_id: props.publicId, confirmed: false })
            }
          >
            {props.getLocalisationMessage("no", "No")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderConfirmForm);
