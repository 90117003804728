import React from "react";
import _ from "lodash";
import { Map, List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import OrderChargeEditDialogForm from "./OrderChargeEditDialogForm";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  COD,
  COD_FEE,
  SERVICE,
  DISCOUNT,
  SERVICE_CUSTOM,
} from "../../constants/OrderChargeItemTypes";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  useSheet({
    row: { height: "48px", alignItems: "center" },
    paidColumn: { width: "120px" },
    submitColumn: { width: "150px" },
    typeColumn: { width: "150px" },
  }),
);

OrderChargeEditDialog.propTypes = {
  classes: PropTypes.object,
  charges: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),

  chargeTypes: PropTypes.arrayOf(
    PropTypes.oneOf([COD, COD_FEE, DISCOUNT, SERVICE, SERVICE_CUSTOM]),
  ).isRequired,
};

function OrderChargeEditDialog(props) {
  const { classes, i18n } = props;

  return (
    <Dialog open={true} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.isLoading} />

      <DialogContent>
        <FlexBox container={8} direction="column">
          <FlexBox gutter={8} flex={true} className={classes.row}>
            <FlexBox className={classes.typeColumn}>{i18n.get("type")}</FlexBox>
            <FlexBox flex={true}>{i18n.get("charge")}</FlexBox>
            <FlexBox className={classes.paidColumn}>
              {i18n.get("payed", "Payed")}
            </FlexBox>
            <FlexBox className={classes.submitColumn} />
          </FlexBox>

          {props.charges.reduce((acc, item) => {
            const type = item.get("charge_type");

            if (_.includes(props.chargeTypes, type)) {
              acc.push(
                <OrderChargeEditDialogForm
                  key={type}
                  onSubmit={props.onSubmit}
                  onSubmitSuccess={props.onSubmitSuccess}
                  onSubmitFail={props.onSubmitFail}
                  form={`OrderChargeEditDialogForm_${type}`}
                  initialValues={{
                    type,
                    paid: item.get("paid"),
                    charge: item.get("charge"),
                  }}
                />,
              );
            }

            return acc;
          }, [])}
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {i18n.get("dismiss", "Dismiss")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderChargeEditDialog);
