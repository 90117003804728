import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { formValues, formValueSelector, reduxForm } from "redux-form";
import { IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { ArrowBack, ContactPhone, Place } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardStepToLocationForm from "../2-to/OrderCreateWizardStepToLocationForm";
import OrderCreateWizardAvatars from "../internal/OrderCreateWizardAvatars";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCardNew";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormSelectField from "../../form/FormSelectField";
import FormGeoAutoComplete from "../../form/NewFormGeoAutoComplete";
import FormCountryAutoComplete from "../../form/FormCountryV2AutoComplete";
import FormYandexGeoAutoComplete from "../../form/FormYandexGeoAutoComplete";
import FormPhoneCodeWithoutCountryField from "../../form/FormPhoneCodeWithoutCountryField";
import FlexBox from "../../ui-core/FlexBox";
import OrderCityField from "../../orders-core/OrderCityField";
import OrderNeighborhoodField from "../../orders-core/OrderNeighborhoodField";
import CustomerAddressBookSelectV2Dialog from "../../address-book-core/CustomerAddressBookSelectV2Dialog";
import {
  getProperGeoAddressObj,
  getProperGeoAddressString,
} from "../../../helpers/GeoUtils";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import { formatText, parsePhone } from "../../../helpers/FormatUtils";
import { isValidCoordinates } from "../../../helpers/ValidateUtils";
import { getMessage as getMessaegeTitle } from "../../../reducers/MessageReducer";
import {
  getMarketplace,
  marketplaceMandatoryFieldsDisabled,
} from "../../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import AddressTypes from "../../../constants/AddressType";
import { phoneCountyCodeList } from "../../../constants/PhoneCountryCodes";
import { getMapProvider } from "../../../../shared/reducers/AppReducer";
import { YANDEX_MAP_PROVIDER } from "../../../../shared/constants/MapsControllerConstants";

const valueSelector = formValueSelector("OrderCreateWizardNew");

const enhancer = compose(
  useSheet({
    container: { marginBottom: "20px" },
    details: { marginTop: "10px", padding: "7px 15px", borderRadius: "2px" },
    search: {
      "& > input + div": { display: "none" },
      "& > input + div + div": { display: "none" },
    },
    nextButton: {
      marginTop: "10px",
      color: "#fff",
      textTransform: "uppercase",
    },
    checkBoxAddressBook: { paddingBottom: "10px", paddingTop: "10px" },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
  }),
  reduxForm({
    form: "OrderCreateWizardNew",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "dropoffCountry",
      "dropoffContactPhone",
      "dropoffContactPhoneCode",
      "dropoffLocation",
      "initialDropoffCountry",
      "initialDropoffLocation",
      "dropoffAddressType",
      "dropoffCity",
      "dropoffNeighborhood",
      "dropoffApartment",
      "dropoffBuilding",
    ),
    dropoffCountry: valueSelector(state, "dropoffCountry"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    title: getMessaegeTitle(
      state,
      getMessage(
        state,
        "to_whom_are_we_shipping_it",
        "To whom are we shipping it?",
      ),
      getMessage(
        state,
        "to_whom_are_we_shipping_it",
        "To whom are we shipping it?",
      ),
    ),
    orderReferenceIdVisibility: getMarketplace(state).getIn(
      ["setting", "settings", "orderReferenceIdVisibility"],
      false,
    ),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
    mapProvider: getMapProvider(state),
    isRTL: getIsRTL(state),
  })),
  withState("state", "setState", {
    focusCountry: false,
    focusLocation: false,
    showMapBackground: true,
    showAddressDialog: false,
  }),
  formValues({
    dropoffContactPhone: "dropoffContactPhone",
    dropoffCity: "dropoffCity",
    dropoffContactPhoneCode: "dropoffContactPhoneCode",
    dropoffAddressType: "dropoffAddressType",
    dropoffBuilding: "dropoffBuilding",
    dropoffApartment: "dropoffApartment",
    dropoffNeighborhood: "dropoffNeighborhood",
    dropoffLocation: "dropoffLocation",
    dropoffTempCountry: "dropoffTempCountry",
  }),
  mapPropsStream(propsStream => {
    propsStream
      .filter(props =>
        Boolean(props.values.dropoffLocation && props.dropOffLocationDetails),
      )
      .skip(1)
      .map(fp.get("dropOffLocationDetails"))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .subscribe(([, props]) => {
        props.change("dropoffTempCountry", true);

        if (props.dropOffLocationDetails.get("country")) {
          props.change(
            "dropoffCountry",
            props.dropOffLocationDetails.get("country").toJS(),
          );
        }

        if (props.dropOffLocationDetails.get("city")) {
          if (props.dropOffLocationDetails.getIn(["city", "id"])) {
            props.change(
              "dropoffCity",
              props.dropOffLocationDetails.get("city").toJS(),
            );
          } else
            props.change(
              "dropoffCity",
              props.dropOffLocationDetails.getIn(["city", "name"]) || "",
            );
        } else if (_.isObject(props.dropoffCity))
          props.change("dropoffCity", null);

        if (props.dropOffLocationDetails.get("neighborhood")) {
          if (props.dropOffLocationDetails.getIn(["neighborhood", "id"]))
            props.change(
              "dropoffNeighborhood",
              props.dropOffLocationDetails.get("neighborhood").toJS(),
            );
          else
            props.change(
              "dropoffNeighborhood",
              props.dropOffLocationDetails.getIn(["neighborhood", "name"]) ||
                "",
            );
        } else if (_.isObject(props.dropoffNeighborhood))
          props.change("dropoffNeighborhood", null);
      });

    const sideEffectsStream = Observable.merge(
      propsStream
        .distinctUntilKeyChanged("state", isEqualData)
        .delay(200)
        .do(props => {
          if (props.state.focusCountry) {
            props.setState(fp.set("focusCountry", false));
          }
          if (props.state.focusLocation) {
            props.setState(fp.set("focusLocation", false));
          }
        }),
      propsStream
        .distinctUntilKeyChanged("dropoffCountry", isEqualData)
        .delay(200)
        .do(props => {
          if (
            isValidCoordinates(props.dropoffLocation) &&
            fp.get("name", props.dropoffCountry)
          ) {
            const countryPhoneCode = _.find(phoneCountyCodeList, {
              country: props.dropoffCountry.name,
            });
            props.change("dropoffContactPhoneCode", countryPhoneCode);
          }
        }),
      propsStream.take(1).do(props => {
        const { initialDropoffCountry, initialDropoffLocation } = props.values;

        if (initialDropoffCountry || initialDropoffLocation) {
          if (initialDropoffLocation) {
            props.change("dropoffLocation", initialDropoffLocation);
          }

          props.touch(
            "dropoffCountry",
            "dropoffLocation",
            "dropoffContactPhoneCode",
            "dropoffContactPhone",
            "dropoffContactName",
            "dropoffAddressType",
            "dropoffCity",
            "dropoffNeighborhood",
            "dropoffBuilding",
            "dropoffApartment",
          );

          props.onShowLocationForm();
        }

        if (props.values.initialDropoffLocation) {
          props.change("initialDropoffLocation", null);
          props.change("dropoffLocation", props.values.initialDropoffLocation);

          props.touch("dropoffLocation");

          props.onShowLocationForm();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("showLocationForm")
        .skip(1)
        .do(props => {
          if (
            props.valid &&
            props.showLocationForm &&
            isValidCoordinates("dropoffLocation")
          ) {
            props.touch("dropoffLocation");
            props.touch(
              "dropoffCountry",
              "dropoffContactPhoneCode",
              "dropoffContactPhone",
              "dropoffContactName",
              "dropoffCity",
              "dropoffNeighborhood",
              "dropoffBuilding",
              "dropoffApartment",
              "dropoffAddressType",
            );
            props.onShowFormDetails();
          }
        }),
      propsStream
        .distinctUntilKeyChanged("showFormDetails")
        .skip(1)
        .do(props => {
          if (
            props.valid &&
            props.showLocationForm &&
            props.showFormDetails &&
            props.showContent
          ) {
            props.touch(
              "dropoffLocation",
              "dropoffContactPhoneCode",
              "dropoffContactPhone",
              "dropoffContactName",
              "dropoffCity",
              "dropoffNeighborhood",
              "dropoffBuilding",
              "dropoffApartment",
              "dropoffAddressType",
            );

            props.onShowForm();
          }
        }),
      propsStream
        .distinctUntilKeyChanged("showForm")
        .skip(1)
        .do(props => {
          if (
            props.valid &&
            props.showForm &&
            props.showFormDetails &&
            props.dropoffTempCountry &&
            props.showLocationForm
          ) {
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

OrderCreateWizardStepToForm.propTypes = {
  classes: PropTypes.object,
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  title: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,
  showLocationForm: PropTypes.bool,
  onShowLocationForm: PropTypes.func,
  orderReferenceIdVisibility: PropTypes.bool,
  showContent: PropTypes.bool,
  showFormDetails: PropTypes.bool,
  onShowFormDetails: PropTypes.func,
  dropoffLocation: PropTypes.object,
  onBackButtonClick: PropTypes.func,
  dropOffLocationDetails: PropTypes.instanceOf(Map),
  suggestions: PropTypes.instanceOf(List).isRequired,
  dropoffContactPhone: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  disabledOrderMandatoryFields: PropTypes.bool,
  mapProvider: PropTypes.string,
  isRTL: PropTypes.bool,
};

function OrderCreateWizardStepToForm(props) {
  const {
    state,
    classes,
    getLocalisationMessage,
    disabledOrderMandatoryFields,
    isRTL,
  } = props;

  const countryCode = getValue(props.values.dropoffCountry, "sort_name")
    ? getValue(props.values.dropoffCountry, "sort_name")
    : getValue(props.values.dropoffCountry, "code");
  const showForm = props.showLocationForm && props.showContent;

  const countryId = getValue(props.values.dropoffCountry, "id");
  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;

  if (props.showLocationForm && !props.showFormDetails)
    return (
      <OrderCreateWizardStepToLocationForm
        onBackButtonClick={props.onBackButtonClick}
        onSubmit={values => {
          props.change("dropoffLocation", values.location);
          props.change("dropoffTempCountry", false);
          props.onShowFormDetails();
        }}
      />
    );

  return (
    <OrderCreateWizardStepCard
      title={props.title}
      showContent={showForm}
      style={{ marginTop: "-20px" }}
      disableTitleClick={showForm && false}
      onSubmit={props.handleSubmit}
      showSubmit={showForm && props.valid}
      onTitleClick={props.onShowLocationForm}
      showNextButton={
        props.showFormDetails && isValidCoordinates(props.dropoffLocation)
      }
      subContent={
        Boolean(props.showContent && !props.showLocationForm) && (
          <OrderCreateWizardAvatars
            onAddNew={props.onShowLocationForm}
            onAddressBookClick={() =>
              props.setState(fp.set("showAddressDialog", true))
            }
            suggestions={props.suggestions}
            onSuggestionClick={item => {
              props.change("dropoffLocation", {
                lat: item.get("lat"),
                lng: item.get("lon"),
                country: countryCode,
                address: item.get("address"),
                geo_address: getProperGeoAddressString(
                  item.get("geo_address") || item.get("address"),
                ),
              });

              props.change("dropoffAddressType", item.get("address_type"));
              props.change("dropoffStreet", item.get("street"));
              props.change("dropoffBuilding", item.get("building"));
              props.change("dropoffCountry", item.get("country").toJS());
              props.change("dropoffApartment", item.get("apartment"));
              props.change("dropoffNearestLandmark", item.get("landmark"));
              props.change("dropoffContactPhoneCode", {
                code: item.get("phone_code"),
              });
              props.change("dropoffContactPhone", item.get("phone"));
              props.change("dropoffContactName", item.get("name"));
              props.change("dropoffTempCountry", true);

              props.touch(
                "dropoffLocation",
                "dropoffAddressType",
                "dropoffStreet",
                "dropoffBuilding",
                "dropoffCountry",
                "dropoffApartment",
                "dropoffNearestLandmark",
                "dropoffContactPhoneCode",
                "dropoffContactPhone",
                "dropoffContactName",
              );

              props.onShowFormDetails();
            }}
          />
        )
      }
    >
      <CustomerAddressBookSelectV2Dialog
        open={state.showAddressDialog}
        countryId={countryId}
        onRequestClose={() =>
          props.setState(fp.set("showAddressDialog", false))
        }
        onSubmit={values => {
          props.setState(fp.set("showAddressDialog", false));

          props.change("dropoffLocation", {
            country: countryCode,
            lat: values.address.lat,
            lng: values.address.lon,
            address: values.address.address,
            geo_address: getProperGeoAddressObj(values.address),
          });

          props.change("dropoffContactName", values.address.name);
          props.change("dropoffContactPhone", values.address.phone);
          props.change("dropoffContactPhoneCode", {
            code: values.address.phone_code,
          });
          props.change("dropoffAddressType", values.address.address_type);
          props.change("dropoffStreet", values.address.street);
          props.change("dropoffBuilding", values.address.building);
          props.change("dropoffApartment", values.address.apartment);
          props.change("dropoffNearestLandmark", values.address.landmark);
          props.change("dropoffTempCountry", true);

          props.touch(
            "dropoffContactName",
            "dropoffContactPhoneCode",
            "dropoffContactPhone",
            "dropoffLocation",
            "dropoffAddressType",
            "dropoffStreet",
            "dropoffBuilding",
            "dropoffApartment",
            "dropoffNearestLandmark",
          );

          if (!props.showLocationForm) {
            props.onShowLocationForm();
          }
        }}
      />

      <div className={classes.container}>
        <Paper>
          <FlexBox>
            <FlexBox align="center">
              <IconButton onClick={props.onBackButtonClick}>
                <ArrowBack />
              </IconButton>
            </FlexBox>

            <FlexBox flex={true}>
              {isYandexMap ? (
                <FormYandexGeoAutoComplete
                  fullWidth={true}
                  autoComplete="off"
                  className={classes.search}
                  name="dropoffLocation"
                  countryCode={countryCode}
                  hintText={`${getLocalisationMessage(
                    "search_address",
                    "Search Address",
                  )} *`}
                  focus={state.focusLocation}
                  popoverProps={{ style: { minWidth: "352px" } }}
                />
              ) : (
                <FormGeoAutoComplete
                  fullWidth={true}
                  autoComplete="off"
                  className={classes.search}
                  name="dropoffLocation"
                  countryCode={countryCode}
                  hintText={`${getLocalisationMessage(
                    "search_address",
                    "Search Address",
                  )} *`}
                  focus={state.focusLocation}
                  popoverProps={{ style: { minWidth: "352px" } }}
                />
              )}
            </FlexBox>

            <FlexBox align="center">
              <IconButton onClick={props.onShowLocationForm}>
                <Place />
              </IconButton>
            </FlexBox>
          </FlexBox>
        </Paper>

        <Paper className={classes.details}>
          <FlexBox>
            <FlexBox flex={true} direction="column">
              <FormTextField
                name="dropoffContactName"
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "recipient_name",
                  "Recipient Name",
                )} *`}
              />
            </FlexBox>

            <FlexBox align="center">
              <IconButton
                onClick={() =>
                  props.setState(fp.set("showAddressDialog", true))
                }
              >
                <ContactPhone />
              </IconButton>
            </FlexBox>
          </FlexBox>

          <FlexBox>
            <FlexBox flex={6} style={{ paddingRight: "5px" }}>
              <FormPhoneCodeWithoutCountryField
                className={
                  isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                }
                name="dropoffContactPhoneCode"
                fullWidth={true}
                label={getLocalisationMessage("phone_code", "Phone Code")}
              />
            </FlexBox>

            <FlexBox flex={true} style={{ paddingLeft: "5px" }}>
              <FormTextField
                ignoreRtl={isRTL}
                name="dropoffContactPhone"
                focus={true}
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "phone_number",
                  "Phone number",
                )} ${disabledOrderMandatoryFields ? "" : " *"}`}
                parse={parsePhone}
              />
            </FlexBox>
          </FlexBox>

          {props.orderReferenceIdVisibility && (
            <FormTextField
              name="reference_id"
              autoWidth={true}
              fullWidth={true}
              label={getLocalisationMessage("reference_id", "Reference ID")}
            />
          )}
          <FormTextField
            fullWidth={true}
            name="dropoffContactEmail"
            label={getLocalisationMessage("recipient_email", "Recipient Email")}
          />

          <FlexBox>
            <FlexBox
              align="flex-end"
              justify="center"
              style={{ paddingBottom: "10px", paddingRight: "8px" }}
            >
              <FlagIcon code={countryCode} />
            </FlexBox>

            <FlexBox flex={true}>
              <FormCountryAutoComplete
                fullWidth={true}
                readOnly={true}
                name="dropoffCountry"
                focus={state.focusCountry}
                label={`${getLocalisationMessage("country", "Country")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>
          </FlexBox>

          {props.dropOffLocationDetails && (
            <FlexBox>
              <FlexBox flex={true}>
                <OrderCityField
                  readOnly={true}
                  name="dropoffCity"
                  label={`${getLocalisationMessage("city", "City")} ${
                    disabledOrderMandatoryFields ? "" : " *"
                  }`}
                  // onClick={readonly =>
                  //   readonly
                  //     ? props.setState(fp.set("focusLocation", true))
                  //     : null
                  // }
                  location={props.dropOffLocationDetails}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <OrderNeighborhoodField
                  readOnly={true}
                  name="dropoffNeighborhood"
                  label={`${getLocalisationMessage("region", "Region")} ${
                    disabledOrderMandatoryFields ? "" : " *"
                  }`}
                  // onClick={readonly =>
                  //   readonly
                  //     ? props.setState(fp.set("focusLocation", true))
                  //     : null
                  // }
                  location={props.dropOffLocationDetails}
                />
              </FlexBox>
            </FlexBox>
          )}

          <FlexBox flex={true}>
            <FormTextField
              fullWidth={true}
              name="dropoffStreet"
              label={getLocalisationMessage("street", "Street")}
            />
          </FlexBox>

          <FlexBox gutter={8}>
            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name="dropoffBuilding"
                label={`${getLocalisationMessage(
                  "building_villa",
                  "Building / Villa",
                )} ${disabledOrderMandatoryFields ? "" : " *"}`}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name="dropoffApartment"
                label={`${getLocalisationMessage(
                  "apartment_office",
                  "Apartment / Office",
                )} ${disabledOrderMandatoryFields ? "" : " *"}`}
              />
            </FlexBox>
          </FlexBox>

          <FormSelectField
            name="dropoffAddressType"
            label={`${getLocalisationMessage(
              "address_type",
              "Address Type",
            )} *`}
            fullWidth={true}
            options={AddressTypes}
            formatOption={value =>
              getLocalisationMessage(value, formatText(value))
            }
          />

          <FormTextField
            fullWidth={true}
            name="dropoffNearestLandmark"
            label={getLocalisationMessage(
              "nearest_landmark",
              "Nearest Landmark",
            )}
          />

          <FlexBox flex={true} className={classes.checkBoxAddressBook}>
            <FormCheckbox
              name="dropoffSaveAddressBook"
              label={getLocalisationMessage(
                "save_to_address_book",
                "Save to Address Book",
              )}
            />
          </FlexBox>
        </Paper>
      </div>
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepToForm);
