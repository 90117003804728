import React from "react";
import { Map, OrderedMap } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FinanceOrderTableTabs from "../finance-core/FinanceOrderTableTabs";
import { formatCustomerCODStatusCodeLocalised } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_WITH_WING_BANK,
  COD_TO_BE_COLLECTED,
  COD_PAID_TO_MERCHANT,
} from "../../constants/CODStatus";
import { FINANCE_ORDER_LIST_URL } from "../../constants/CustomerPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const ALL_TAB_STATUS_LIST = [
  COD_TO_BE_COLLECTED,
  COD_COLLECTED,
  COD_PAID_TO_MERCHANT,
  COD_PENDING,
].join(",");

const WITHDRAWN_STATUS_LIST = [COD_PAID_TO_MERCHANT, COD_PENDING].join(",");

export const ALL_TAB_URL = updateQuery(FINANCE_ORDER_LIST_URL, {
  cod_status: ALL_TAB_STATUS_LIST,
});
export const TO_BE_COLLECTED_TAB_URL = updateQuery(FINANCE_ORDER_LIST_URL, {
  cod_status: COD_TO_BE_COLLECTED,
});
export const COLLECTED_TAB_URL = updateQuery(FINANCE_ORDER_LIST_URL, {
  cod_status: COD_COLLECTED,
});
export const WITHDRAWABLE_TAB_URL = updateQuery(FINANCE_ORDER_LIST_URL, {
  cod_status: COD_WITH_WING_BANK,
});
export const WITHDRAWN_TAB_URL = updateQuery(FINANCE_ORDER_LIST_URL, {
  cod_status: WITHDRAWN_STATUS_LIST,
});

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CustomerFinanceOrderListTabs.propTypes = {
  location: PropTypes.object.isRequired,
  totalCounts: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function CustomerFinanceOrderListTabs(props) {
  const { getLocalisationMessage } = props;

  const statusCodes = OrderedMap()
    .set(ALL_TAB_STATUS_LIST, getLocalisationMessage("all", "All"))
    .set(
      COD_TO_BE_COLLECTED,
      formatCustomerCODStatusCodeLocalised(
        COD_TO_BE_COLLECTED,
        getLocalisationMessage,
      ),
    )
    .set(
      COD_COLLECTED,
      formatCustomerCODStatusCodeLocalised(
        COD_COLLECTED,
        getLocalisationMessage,
      ),
    )
    .set(
      COD_WITH_WING_BANK,
      formatCustomerCODStatusCodeLocalised(
        COD_WITH_WING_BANK,
        getLocalisationMessage,
      ),
    )
    .set(
      WITHDRAWN_STATUS_LIST,
      getLocalisationMessage("withdrawn", "Withdrawn"),
    );

  return (
    <FinanceOrderTableTabs
      location={props.location}
      statusCodes={statusCodes}
      totalCounts={props.totalCounts}
      formatCODStatusCode={code => statusCodes.get(code)}
    />
  );
}

export default enhancer(CustomerFinanceOrderListTabs);
