import React from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";

import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  connect(
    state => ({
      isRTL: getIsRTL(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func }),
  useSheet({
    menu: {
      text: "#555",
    },
    active: {
      color: "var(--Primary-Blue, #183E98)",
      borderRadius: "12px",
      border: "1px solid var(--zinc-300, #E4E4E7)",
      background: "var(--white, #FFF)",
      "& .MuiSvgIcon-root": {
        "&>svg": {
          "&>g": {
            "&>path": {
              fill: "none",
              stroke: "var(--Primary-Blue, #183E98)",
            },
          },
        },
      },
      "& .MuiTypography-root": {
        color: "var(--Primary-Blue, #183E98)",
        fontSize: "17px",
        fontWeight: 400,
      },
    },
    link: {
      color: "#555",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: "17px",
      },
    },
  }),
);

CustomerAppSidebarLink.propTypes = {
  classes: PropTypes.object,
  leftIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  style: PropTypes.object,
  sidebarStatus: PropTypes.bool,
  router: PropTypes.string,
};

function CustomerAppSidebarLink(props) {
  const { classes, style, leftIcon } = props;

  const pathname = getValue(props.router, "location.pathname");

  return (
    <Link to={props.to}>
      <ListItem
        className={props.to === pathname ? classes.active : classes.menu}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        button={true}
      >
        {props.sidebarStatus ? (
          <ListItemIcon
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {leftIcon}{" "}
          </ListItemIcon>
        ) : (
          <Tooltip
            title={
              <ListItem style={{ fontSize: 14, textAlign: "center" }}>
                {props.children}
              </ListItem>
            }
            placement="right"
            PopperProps={{
              style: {
                width: 150,
              },
            }}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              {leftIcon}{" "}
            </ListItemIcon>
          </Tooltip>
        )}
        {props.sidebarStatus && (
          <ListItemText className={classes.link}>
            {" "}
            {props.children}{" "}
          </ListItemText>
        )}
      </ListItem>
    </Link>
  );
}

export default enhancer(CustomerAppSidebarLink);
