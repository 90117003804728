import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import PaymentTypeAvatar from "../../orders-core/PaymentTypeAvatar";
import { formatLocalisedPaymentType } from "../../../helpers/OrderHelper";
import { getMessage } from "../../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
);

OrderCreateWizardStepPayItem.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
  paymentType: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepPayItem(props) {
  return (
    <OrderCreateWizardStepDetails
      label={props.label}
      onClick={props.onClick}
      primaryText={formatLocalisedPaymentType(
        props.paymentType,
        props.getLocalisationMessage,
      )}
      avatar={<PaymentTypeAvatar paymentType={props.paymentType} />}
      secondaryText={
        2 === 3
          ? props.getLocalisationMessage(
              "powered_by_payfort",
              "Powered by: Payfort",
            )
          : ""
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepPayItem);
