import React from "react";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ADDRESS_BOOK_URL } from "../../constants/CustomerPathConstants";
import {
  getAddressBook,
  updateAddressBook,
} from "../../api/shared/CustomerAddressBookApi";
import PageLoading from "../../components/ui-core/PageLoading";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import AddressBookForm from "../../components/address-book-core/AddressBookForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const addressBookStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["params", "id"]))
      .switchMap(props =>
        getAddressBook(props.params.id).let(mapObjectResponseStream),
      )
      .distinctUntilChanged(isEqualData)
      .map(x => x.update("payload", toCamelCase));

    return propsStream.combineLatest(
      addressBookStream,
      (props, addressBook) => ({
        ...props,

        isLoading: addressBook.get("pending"),
        initialValues: addressBook.get("payload"),
      }),
    );
  }),
  pureComponent(fp.pick(["isLoading", "initialValues"])),
);

CustomerAddressBookItem.propTypes = {
  setLocation: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerAddressBookItem(props) {
  return (
    <CustomerAppLayout
      title={props.getLocalisationMessage(
        "edit_address_book",
        "Edit Address Book",
      )}
    >
      <PageLoading isLoading={props.isLoading} />

      <AddressBookForm
        initialValues={props.initialValues}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateAddressBook(values.id, values).catch(ResponseError.throw),
        )}
        onSubmitSuccess={() => {
          props.setLocation(ADDRESS_BOOK_URL);
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerAddressBookItem);
