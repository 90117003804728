import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FeedbackEmotions from "./FeedbackEmotionsv2";
import FormRatingBar from "../form/FormRatingBar";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    root: { margin: "auto", width: "330px", padding: 0 },
    comment: {
      margin: "0 12px",
      "& .MuiSvgIcon-root": {
        width: "38px",
        height: "38px",
      },
    },
    progress: { textAlign: "center", width: "88px" },
    title: { fontFamily: "Blogger Sans", padding: "18px" },
    actions: {
      margin: "10px",
      marginRight: "0",
      "& button": { fontFamily: "Blogger Sans" },
    },
  }),
  reduxForm({
    form: "FeedbackForm",
    enableReinitialize: true,
  }),
  formValues("score"),
);

FeedbackForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  score: PropTypes.number,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function FeedbackForm(props) {
  const { classes } = props;

  return (
    <FlexBox direction="column" gutter={24} className={classes.root}>
      <PageLoading isLoading={props.submitting} />

      <FeedbackEmotions emotion={props.score} />

      <form className={classes.comment}>
        <FormRatingBar name="score" />

        <FormTextField
          name="note"
          fullWidth={true}
          rows={4}
          multiLine={true}
          label={props.getLocalisationMessage("add_comment", "Add Comment")}
        />

        <FlexBox className={classes.actions} justify="flex-end">
          <Button onClick={props.handleSubmit}>
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </form>
    </FlexBox>
  );
}

export default enhancer(FeedbackForm);
