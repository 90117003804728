import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const ORDER_URL = `${API_V2_ROOT_URL}/customer/order`;
const ORDER_ITEM_URL = `${API_ROOT_URL}/customer/order/%s/mobile`;
const ORDER_LOCATION_URL = `${API_V2_ROOT_URL}/customer/order/%s/location`;

export const createOrder = values =>
  api.post(ORDER_URL, {
    body: values,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getOrder = id =>
  api.getStream(sprintf(ORDER_ITEM_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getOrderWithoutStream = id =>
  api.get(sprintf(ORDER_ITEM_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const updateOrderLocation = (orderNumber, body) =>
  api.put(sprintf(ORDER_LOCATION_URL, orderNumber), {
    body,
    headers: {
      "x-app-type": "customer-web",
    },
  });
