import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { isAfter, isSameDay } from "date-fns";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  withContext,
  withHandlers,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import {
  DoneAll,
  AccessTime,
  AttachMoney,
  MoreHoriz,
} from "@material-ui/icons";
import OrderCreateWizardStepToForm from "./2-to/OrderCreateWizardStepToForm";
import OrderCreateWizardStepToDetails from "./2-to/OrderCreateWizardStepToDetails";
import OrderCreateWizardStepHowForm from "./4-how/OrderCreateWizardStepHowForm";
import OrderCreateWizardStepHowDetails from "./4-how/OrderCreateWizardStepHowDetails";
import OrderCreateWizardStepPayForm from "./6-pay/OrderCreateWizardStepPayForm";
import OrderCreateWizardStepPayDetails from "./6-pay/OrderCreateWizardStepPayDetails";
import OrderCreateWizardStepFromForm from "./1-from/OrderCreateWizardStepFromForm";
import OrderCreateWizardStepFromDetails from "./1-from/OrderCreateWizardStepFromDetails";
import OrderCreateWizardStepWhatForm from "./3-what/OrderCreateWizardStepWhatForm";
import OrderCreateWizardStepWhatDetails from "./3-what/OrderCreateWizardStepWhatDetails";
import OrderCreateWizardStepWhenForm from "./7-when/OrderCreateWizardStepWhenForm";
import OrderCreateWizardStepWhenDetails from "./7-when/OrderCreateWizardStepWhenDetails";
import OrderCreateWizardStepMoreForm from "./9-more/OrderCreateWizardStepMoreForm";
import OrderCreateWizardStepMoreDetails from "./9-more/OrderCreateWizardStepMoreDetails";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import OrderCreateWizardStepTermsDialog from "./10-terms/OrderCreateWizardStepTermsDialog";
import OrderCreateWizardFab from "./internal/OrderCreateWizardFab";
import OrderCreateWizardCard from "./internal/OrderCreateWizardCard";
import OrderCreateWizardActions from "./internal/OrderCreateWizardActions";
import OrderCreateWizardStepper from "./internal/OrderCreateWizardStepper";
import OrderCreateWizardStepCourierForm from "./5-courier/OrderCreateWizardStepCourierForm";
import OrderCreateWizardStepCourierDetails from "./5-courier/OrderCreateWizardStepCourierDetails";
import OrderCreateWizardStepCollectForm from "./8-collect/OrderCreateWizardStepCollectForm";
import OrderCreateWizardStepCollectDetails from "./8-collect/OrderCreateWizardStepCollectDetails";
import { getProperGeoAddressObj } from "../../helpers/GeoUtils";
import {
  isEmpty,
  getValue,
  isEqualData,
  isEqualDataIn,
  isShallowEqual,
} from "../../helpers/DataUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  isPositive,
  isValidDate,
  isBlankString,
  isValidCoordinates,
  validatePhoneNumberForLocalisation,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  getMarketplaceId,
  marketplaceMandatoryFieldsDisabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { REGULAR_ORDER, REVERSE_ORDER } from "../../constants/OrderTypes";
import { DROP, PICKUP } from "../../constants/TimeSlotType";
import { SENDER, RECIPIENT } from "../../constants/OrderPayerTypes";
import { CASH, CREDIT_BALANCE } from "../../constants/OrderPaymentTypes";
import { COD, SERVICE_CUSTOM } from "../../constants/OrderChargeItemTypes";
import { DO_NOT_DELIVER } from "../../constants/OrderRecipientNotAvailableActionTypes";
import OrderCreateWizardOverlay from "../order-create-wizard/internal/OrderCreateWizardOverlay";
import OrderCreateWizardStepReverseDetails from "../order-create-wizard-without-map/0-reverse/OrderCreateWizardStepReverseDetails";
import {
  getCustomerCodTerms,
  getCustomerPublicTerms,
  getCustomerPublicProhibits,
} from "../../api/admin/AdminTermsApi";
import { getSampleItems } from "../../api/shared/SampleItemsApi";
import { getCourierPrices } from "../../api/shared/CourierPricesApi";
import { createAddressBook } from "../../api/shared/CustomerAddressBookV2Api";
import { getChargeableWeight } from "../../api/shared/PackageVolumetricWeightApi";
import {
  getPackagesNSA,
  getPaymentMethods,
  getLocationDetails,
  getCustomerPackagePricesStaringFrom,
} from "../../api/customer/CustomerApiV2";
import {
  WING_AE_ID,
  WING_SA_ID,
} from "../../../server/constants/MarketplaceId";
import regular from "../../assets/regular.svg";
import reverse from "../../assets/reverse.svg";

const getValues = getFormValues("OrderCreateWizardNew");
const valueSelector = formValueSelector("OrderCreateWizardNew");

const sum = fp.rest(fp.flow(fp.map(fp.toFinite), fp.sum));

const REVERSE = "reverse";
const PAGE_FROM = "from";
const PAGE_TO = "to";
const PAGE_WHAT = "what";
const PAGE_HOW = "how";
const PAGE_COURIER = "courier";
const PAGE_PAY = "pay";
const PAGE_WHEN = "when";
const PAGE_COLLECT = "collect";
const PAGE_MORE = "more";
const PAGE_FINAL = "final";

const pages = [
  REVERSE,
  PAGE_FROM,
  PAGE_TO,
  PAGE_WHAT,
  PAGE_HOW,
  PAGE_COURIER,
  PAGE_PAY,
  PAGE_WHEN,
  PAGE_COLLECT,
  PAGE_MORE,
  PAGE_FINAL,
];

const hasSameId = isEqualDataIn("id");

const validateLocation = (location, country, getLocalisationMessage) =>
  !isValidCoordinates(location)
    ? getLocalisationMessage(
        "type_address_above_to_search",
        "Type address above to search",
      )
    : getValue(location, "country") &&
      getValue(country, "description") &&
      getValue(location, "country") !== getValue(country, "description") &&
      getLocalisationMessage(
        "please_select_address_with_selected_country",
        "Please select address with selected country",
      );

const mapResponseDataAsList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data"], List()),
    }),
);

const mapResponseDataMap = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      data: response.getIn(["payload", "data"]),
    }),
);

const mapResponseDataList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data", "list"], List()),
    }),
);

const isValidCODCharge = fp.overSome([
  fp.isNil,
  fp.overEvery([isPositive, fp.lt(0)]),
]);

const enhancer = compose(
  useSheet({
    root: {
      zIndex: 10,
      minWidth: "500px",
      maxWidth: "500px",
      position: "relative",
      margin: "64px auto 0",
    },
    reverse: {
      fontSize: "1.2rem",
      color: "white",
      justifyContent: "space-evenly",
    },
    regularDiv: {
      transition: "all .4s ease",
      width: 50,
      height: 50,
      backgroundColor: "#FF6347",
      borderRadius: "50%",
      border: "5px solid white",
      margin: "0 5px",
    },
    reverseDiv: {
      transition: "all .4s ease",
      width: 50,
      height: 50,
      backgroundColor: "#FF6347",
      borderRadius: "50%",
      border: "5px solid white",
      margin: "0 5px",
    },
    regularImg: {
      transition: "all .4s ease",
      width: 40,
      height: 40,
      padding: 5,
    },
    reverseImg: {
      transition: "all .4s ease",
      // transform: "rotate(135deg)",
      width: 40,
      height: 40,
      padding: 5,
    },
    regularDivWrapper: {
      padding: "5px 10px",
      borderRadius: "10px",
      backdropFilter: "brightness(0.7)",
      cursor: "pointer",
      transition: "all .4s ease",
      "&:hover": {
        backdropFilter: "brightness(0.5)",
      },
      "&:hover>div>img": {
        transform: "rotate(360deg)",
      },
      // "&:hover>div": {
      //   backgroundColor: "red",
      // },
    },
    reverseDivWrapper: {
      padding: "5px 10px",
      borderRadius: "10px",
      backdropFilter: "brightness(0.7)",
      cursor: "pointer",
      transition: "all .4s ease",
      "&:hover": {
        backdropFilter: "brightness(0.5)",
      },
      "&:hover>div>img": {
        transform: "rotate(360deg)",
      },
      // "&:hover>div": {
      //   backgroundColor: "red",
      // },
    },
  }),
  connect(
    state => ({
      marketplaceId: getMarketplaceId(state),
      disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withState("state", "setState", {
    logistic_type: REGULAR_ORDER,
  }),
  withContext(
    {
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      const order = {};
      order.logistic_type = props.state.logistic_type.toUpperCase();
      order.recipient_not_available = values.recipientNotAvailable;

      order.charge_items = [];
      order.sender_data = {
        address_type: values.pickupAddressType,
        name: values.pickupContactName,
        email: values.pickupContactEmail,
        apartment: values.pickupApartment,
        building: values.pickupBuilding,
        city: _.isObject(values.pickupCity)
          ? values.pickupCity
          : {
              name: values.pickupCity,
            },
        country: values.pickupCountry,
        floor: values.pickupFloor,
        landmark: values.pickupNearestLandmark,
        street: values.pickupStreet,
        neighborhood: _.isObject(values.pickupNeighborhood)
          ? values.pickupNeighborhood
          : {
              name: values.pickupNeighborhood,
            },
        phone: values.pickupContactPhone,
        phone_code: values.pickupContactPhoneCode.get("code"),

        lat: values.pickupLocation.lat,
        lon: values.pickupLocation.lng,
        geo_address: getProperGeoAddressObj(values.pickupLocation),
      };

      order.recipient_data = {
        address_type: values.dropoffAddressType,
        name: values.dropoffContactName,
        email: values.dropoffContactEmail,
        apartment: values.dropoffApartment,
        building: values.dropoffBuilding,
        city: _.isObject(values.dropoffCity)
          ? values.dropoffCity
          : {
              name: values.dropoffCity,
            },
        country: values.dropoffCountry,
        floor: values.dropoffFloor,
        landmark: values.dropoffNearestLandmark,
        street: values.dropoffStreet,
        neighborhood: _.isObject(values.dropoffNeighborhood)
          ? values.dropoffNeighborhood
          : {
              name: values.dropoffNeighborhood,
            },
        phone: values.dropoffContactPhone,
        phone_code: values.dropoffContactPhoneCode.get("code"),
        lat: values.dropoffLocation.lat,
        lon: values.dropoffLocation.lng,
        geo_address: getProperGeoAddressObj(values.dropoffLocation),
        second_phone: values.dropoffSecondPhone,
        third_phone: values.dropoffThirdPhone,
      };

      order.dimensions = {
        width: values.width,
        length: values.length,
        height: values.height,
        unit: values.unit,
        weight: values.weight,
        domestic: values.domestic,
      };

      order.package_type = {
        id: values.package.get("id"),
        package_price: {
          id: values.courier.getIn(["price", "id"]),
        },
      };

      if (values.attachments) {
        order.attachments = values.attachments;
      }

      order.reference_id = values.reference_id;
      order.payment_type = values.paymentType;
      order.payer = values.payer;

      order.parcel_value = values.itemValue;
      order.note = values.note;
      order.piece_count = values.pieceCount || 1;

      order.force_create = false;
      order.fragile = values.fragile;

      if (values.promo) {
        order.promo_code = values.promo.code;
      }

      if (values.codCharge > 0) {
        order.charge_items.push({
          quantity: 1,
          paid: false,
          charge: values.codCharge,
          charge_type: COD,
        });
      }

      if (order.payment_type === CREDIT_BALANCE) {
        order.charge_items.push({
          charge: 0,
          paid: false,
          quantity: 1,
          charge_type: SERVICE_CUSTOM,
        });
      }

      if (values.pickupTimeSlot) {
        order.pickup_timeslot_availability_id = values.pickupTimeSlot.get(
          "timeslot_availability_id",
        );
        order.pickup_timeslot_time = values.pickupTimeslotTime;
      }

      if (values.deliveryTimeSlot) {
        order.drop_off_timeslot_availability_id = values.deliveryTimeSlot.get(
          "timeslot_availability_id",
        );
        order.drop_off_timeslot_time = values.dropOffTimeslotTime;
      }
      return props.onSubmit(order);
    },
  }),
  reduxForm({
    form: "OrderCreateWizardNew",
    destroyOnUnmount: false,
    validate: (values, props) => ({
      pickupCountry:
        !getValue(values.pickupCountry, "id") &&
        props.getLocalisationMessage(
          "please_select_pickup_country",
          "Please select pickup country",
        ),

      pickupLocation: validateLocation(
        values.pickupLocation,
        values.pickupCountry,
        props.getLocalisationMessage,
      ),

      pickupBuilding: !props.disabledOrderMandatoryFields
        ? isBlankString(values.pickupBuilding)
          ? props.getLocalisationMessage("enter_building", "Enter Building")
          : fp.size(values.pickupBuilding) > 512 &&
            props.getLocalisationMessage(
              "building_name_is_too_long",
              "Building name is too long",
            )
        : null,

      pickupApartment: !props.disabledOrderMandatoryFields
        ? isBlankString(values.pickupApartment)
          ? props.getLocalisationMessage(
              "enter_apartment_office",
              "Enter Apartment/Office",
            )
          : fp.size(values.pickupApartment) > 512 &&
            props.getLocalisationMessage(
              "apartment_office_name_is_too_long",
              "Apartment/Office name is too long",
            )
        : null,

      pickupAddressType:
        isBlankString(values.pickupAddressType) &&
        props.getLocalisationMessage(
          "select_address_type",
          "Select Address Type",
        ),
      pickupCity: !props.disabledOrderMandatoryFields
        ? isBlankString(values.pickupCity) &&
          props.getLocalisationMessage(
            "please_select_city",
            "Please select city",
          )
        : null,
      pickupNeighborhood: !props.disabledOrderMandatoryFields
        ? isBlankString(values.pickupNeighborhood) &&
          props.getLocalisationMessage(
            "region_fields_required",
            "Region fields required",
          )
        : null,

      pickupContactName: isBlankString(values.pickupContactName)
        ? props.getLocalisationMessage(
            "please_enter_sender_name",
            "Please enter sender name",
          )
        : fp.size(values.pickupContactName) > 512 &&
          props.getLocalisationMessage("name_is_too_long", "Name is too long"),

      pickupContactPhone: validatePhoneNumberForLocalisation(
        values.pickupContactPhone,
        props.getLocalisationMessage,
      ),

      dropoffCountry:
        !getValue(values.dropoffCountry, "id") &&
        props.getLocalisationMessage(
          "please_select_dropoff_country",
          "Please select dropoff country",
        ),

      dropoffLocation: validateLocation(
        values.dropoffLocation,
        values.dropoffCountry,
        props.getLocalisationMessage,
      ),

      dropoffBuilding: !props.disabledOrderMandatoryFields
        ? isBlankString(values.dropoffBuilding)
          ? props.getLocalisationMessage("enter_building", "Enter Building")
          : fp.size(values.dropoffBuilding) > 512 &&
            props.getLocalisationMessage(
              "building_name_is_too_long",
              "Building name is too long",
            )
        : null,

      dropoffApartment: !props.disabledOrderMandatoryFields
        ? isBlankString(values.dropoffApartment)
          ? props.getLocalisationMessage(
              "enter_apartment_office",
              "Enter Apartment/Office",
            )
          : fp.size(values.dropoffApartment) > 512 &&
            props.getLocalisationMessage(
              "apartment_office_name_is_too_long",
              "Apartment/Office name is too long",
            )
        : null,

      dropoffAddressType:
        isBlankString(values.dropoffAddressType) &&
        props.getLocalisationMessage(
          "select_address_type",
          "Select Address Type",
        ),
      dropoffCity: !props.disabledOrderMandatoryFields
        ? isBlankString(values.dropoffCity) &&
          props.getLocalisationMessage(
            "please_select_city",
            "Please select city",
          )
        : null,
      dropoffNeighborhood: !props.disabledOrderMandatoryFields
        ? isBlankString(values.dropoffNeighborhood) &&
          props.getLocalisationMessage(
            "region_fields_required",
            "Region fields required",
          )
        : null,

      dropoffContactName: isBlankString(values.dropoffContactName)
        ? props.getLocalisationMessage(
            "please_enter_recipient_name",
            "Please enter recipient name",
          )
        : fp.size(values.dropoffContactName) > 512 &&
          props.getLocalisationMessage("name_is_too_long", "Name is too long"),

      dropoffContactPhone: validatePhoneNumberForLocalisation(
        values.dropoffContactPhone,
        props.getLocalisationMessage,
      ),

      itemValue:
        !hasSameId(values.pickupCountry, values.dropoffCountry) &&
        values.itemValue <= 0 &&
        props.getLocalisationMessage("enter_item_value", "Enter Item Value"),

      weight:
        values.weight <= 0 &&
        fp.isEmpty(values.weight) &&
        props.getLocalisationMessage(
          "enter_weight_value",
          "Enter Weight Value",
        ),
      height:
        values.height <= 0 &&
        fp.isEmpty(values.height) &&
        props.getLocalisationMessage(
          "enter_height_value",
          "Enter Height Value",
        ),
      width:
        values.width <= 0 &&
        fp.isEmpty(values.width) &&
        props.getLocalisationMessage("enter_width_value", "Enter Width Value"),
      length:
        values.length <= 0 &&
        fp.isEmpty(values.length) &&
        props.getLocalisationMessage(
          "enter_length_value",
          "Enter Length Value",
        ),

      deliveryTimeSlot:
        Boolean(
          values.deliveryTimeSlot &&
            values.pickupTimeSlot &&
            (isAfter(values.pickupTimeslotTime, values.dropOffTimeslotTime) ||
              (isSameDay(
                values.pickupTimeslotTime,
                values.dropOffTimeslotTime,
              ) &&
                values.pickupTimeSlot.get("start_time") >=
                  values.deliveryTimeSlot.get("end_time"))),
        ) &&
        props.getLocalisationMessage(
          "dropoff_time_can_not_be_before_pickup_time",
          "Dropoff Time can not be before Pickup Time",
        ),

      codCharge:
        !isValidCODCharge(values.codCharge) &&
        props.getLocalisationMessage(
          "please_enter_valid_price",
          "Please enter valid price",
        ),
    }),
  }),
  connect(state => ({
    values: getValues(state),
    nsa: valueSelector(state, "pickupLocation", "dropoffLocation"),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .map(
            fp.flow(
              fp.update("values", values => ({
                ...values,
                menuId: values.menu && values.menu.get("id"),
                packageId: values.package && values.package.get("id"),
                domestic: hasSameId(
                  values.pickupCountry,
                  values.dropoffCountry,
                ),
                validLocations:
                  isValidCoordinates(values.pickupLocation) &&
                  isValidCoordinates(values.dropoffLocation),
              })),
              props => ({
                ...props,
                page: props.values.page,
                showView: props.values.showView,
                currencyCode: getValue(props.values.package, [
                  "price",
                  "currency",
                  "code",
                ]),
                allowCod:
                  props.allowInternationalCOD ||
                  hasSameId(
                    props.values.pickupCountry,
                    props.values.dropoffCountry,
                  ),
                total: sum(
                  props.values.codCharge,
                  -getValue(props.values.promo, "discount", 0),
                  props.values.paymentType === CREDIT_BALANCE
                    ? 0
                    : getValue(props.values.courier, ["price", "total"], 0),
                ),
              }),
            ),
          )
          .distinctUntilChanged(isShallowEqual),
      propsStream => {
        const pickUpLocationDetailsResponseStream = propsStream
          .map(props =>
            props.values.pickupLocation && props.values.pickupLocation.lat
              ? new DataListFilter({
                  lat: props.values.pickupLocation.lat,
                  lon: props.values.pickupLocation.lng,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getLocationDetails(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataMap)
          .distinctUntilChanged(isEqualData);

        const dropOffLocationDetailsResponseStream = propsStream
          .map(props =>
            props.values.dropoffLocation && props.values.dropoffLocation.lat
              ? new DataListFilter({
                  lat: props.values.dropoffLocation.lat,
                  lon: props.values.dropoffLocation.lng,
                  country: props.values.dropoffCountry
                    ? getValue(props.values.dropoffCountry, "id")
                    : null,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getLocationDetails(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataMap)
          .distinctUntilChanged(isEqualData);

        const sampleItemsResponseStream = propsStream
          .map(() => new DataListFilter({}))
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getSampleItems(filter).catch(error => Observable.of({ error })),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const chargeableWeightResponseStream = propsStream
          .map(props =>
            props.values.unit &&
            props.values.width &&
            props.values.height &&
            props.values.length
              ? new DataListFilter({
                  width: props.values.width,
                  height: props.values.height,
                  length: props.values.length,
                  weight: props.values.weight ? props.values.weight : 0,
                  domestic: props.values.domestic,
                  unit: props.values.unit,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getChargeableWeight(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataMap)
          .distinctUntilChanged(isEqualData);

        const packagePricesResponseStream = propsStream
          .map(props =>
            props.values.unit &&
            props.values.validLocations &&
            props.values.weight &&
            props.values.pickupCountry &&
            props.values.dropoffCountry
              ? new DataListFilter({
                  logistic_type: props.state.logistic_type.toUpperCase(),
                  "dimensions.unit": props.values.unit,
                  "dimensions.length": props.values.length,
                  "dimensions.width": props.values.width,
                  "dimensions.height": props.values.height,
                  "dimensions.weight": props.values.weight,
                  "dimensions.domestic": props.values.domestic,
                  from_latitude: props.values.pickupLocation.lat,
                  from_longitude: props.values.pickupLocation.lng,
                  from_country_id: props.values.pickupCountry.id,
                  to_latitude: props.values.dropoffLocation.lat,
                  to_longitude: props.values.dropoffLocation.lng,
                  to_country_id: props.values.dropoffCountry.id,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getCustomerPackagePricesStaringFrom(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const courierPriceResponseStream = propsStream
          .map(props =>
            props.values.package &&
            props.values.weight &&
            props.values.pickupCountry &&
            props.values.dropoffCountry &&
            props.values.validLocations
              ? new DataListFilter({
                  logistic_type: props.state.logistic_type.toUpperCase(),
                  "dimensions.unit": props.values.unit,
                  "dimensions.length": props.values.length,
                  "dimensions.width": props.values.width,
                  "dimensions.height": props.values.height,
                  "dimensions.weight": props.values.weight,
                  "dimensions.domestic": props.values.domestic,
                  from_latitude: props.values.pickupLocation.lat,
                  from_longitude: props.values.pickupLocation.lng,
                  from_country_id: props.values.pickupCountry.id,
                  to_latitude: props.values.dropoffLocation.lat,
                  to_longitude: props.values.dropoffLocation.lng,
                  to_country_id: props.values.dropoffCountry.id,
                  courier_type: fp.toUpper(
                    props.values.package.getIn(["courier_type", "type"]),
                  ),
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getCourierPrices(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const paymentTypesResponseStream = propsStream
          .map(props =>
            props.values.package &&
            props.values.courier &&
            props.values.weight &&
            props.values.length &&
            props.values.width &&
            props.values.height &&
            props.values.pickupCountry &&
            props.values.dropoffCountry &&
            props.values.validLocations
              ? new DataListFilter({
                  "dimensions.unit": props.values.unit,
                  "dimensions.length": props.values.length,
                  "dimensions.width": props.values.width,
                  "dimensions.height": props.values.height,
                  "dimensions.weight": props.values.weight,
                  "dimensions.domestic": props.values.domestic,
                  from_latitude: props.values.pickupLocation.lat,
                  from_longitude: props.values.pickupLocation.lng,
                  from_country_id: props.values.pickupCountry.id,
                  to_latitude: props.values.dropoffLocation.lat,
                  to_longitude: props.values.dropoffLocation.lng,
                  to_country_id: props.values.dropoffCountry.id,
                  price_id: props.values.package.getIn(["price", "id"]),
                  package_id: props.values.package.get("id"),
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getPaymentMethods(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataAsList)
          .distinctUntilChanged(isEqualData);

        const pickupTimeSlotsResponseStream = propsStream
          .map(props => ({
            getTimeSlots: props.getTimeSlots,
            filter:
              props.values.packageId > 0 &&
              props.values.courier &&
              props.values.courier.getIn(["supplier", "id"]) &&
              props.values.validLocations &&
              isValidDate(props.values.pickupTimeslotTime)
                ? new DataListFilter({
                    courier_type: fp.toUpper(
                      props.values.courier.getIn(["courier_type", "type"]),
                    ),
                    supplier_id: props.values.courier.getIn(["supplier", "id"]),
                    timeslot_type: fp.toUpper(PICKUP),
                    lat: props.values.pickupLocation.lat,
                    lng: props.values.pickupLocation.lng,
                    date: formatDateToUrl(props.values.pickupTimeslotTime),
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props
                  .getTimeSlots(props.filter)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const deliveryTimeSlotsResponseStream = propsStream
          .map(props => ({
            getTimeSlots: props.getTimeSlots,
            filter:
              props.values.packageId > 0 &&
              props.values.courier &&
              props.values.courier.getIn(["supplier", "id"]) &&
              props.values.validLocations &&
              isValidDate(props.values.dropOffTimeslotTime)
                ? new DataListFilter({
                    courier_type: fp.toUpper(
                      props.values.courier.getIn(["courier_type", "type"]),
                    ),
                    supplier_id: props.values.courier.getIn(["supplier", "id"]),
                    timeslot_type: fp.toUpper(DROP),
                    lat: props.values.dropoffLocation.lat,
                    lng: props.values.dropoffLocation.lng,
                    date: formatDateToUrl(props.values.dropOffTimeslotTime),
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props
                  .getTimeSlots(props.filter)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const promoFilterStream = propsStream.map(props =>
          props.values.package &&
          props.values.courier &&
          props.values.length &&
          props.values.width &&
          props.values.height &&
          props.values.weight &&
          props.values.pickupCountry &&
          props.values.dropoffCountry &&
          props.values.validLocations
            ? fromJS({
                "dimensions.unit": props.values.unit,
                "dimensions.length": props.values.length,
                "dimensions.width": props.values.width,
                "dimensions.height": props.values.height,
                "dimensions.weight": props.values.weight,
                "dimensions.domestic": props.values.domestic,
                from_latitude: props.values.pickupLocation.lat,
                from_longitude: props.values.pickupLocation.lng,
                from_country_id: props.values.pickupCountry.id,
                to_country_id: props.values.dropoffCountry.id,
                to_latitude: props.values.dropoffLocation.lat,
                to_longitude: props.values.dropoffLocation.lng,
                price_id: props.values.package.getIn(["price", "id"]),
                package_id: props.values.package.get("id"),
                promo_code: props.values.promo,
              })
            : null,
        );

        const sideEffectsStream = Observable.merge(
          propsStream
            .combineLatest(promoFilterStream, (props, filter) => ({
              filter,
              change: props.change,
              validatePromo: props.validatePromo,
            }))
            .distinctUntilKeyChanged("filter", isEqualData)
            .switchMap(
              props =>
                props.filter && props.filter.promo_code
                  ? props
                      .validatePromo(props.filter)
                      .then(fp.get("data"), fp.constant(null))
                  : Observable.of(null),
              (props, response) => [props, response],
            )
            .distinctUntilKeyChanged(1, isEqualData)
            .do(([props, promo]) => props.change("promo", promo)),
          propsStream
            .distinctUntilKeyChanged("nsa", isEqualData)
            .map(props => ({
              filter:
                isValidCoordinates(props.nsa.pickupLocation) &&
                isValidCoordinates(props.nsa.dropoffLocation)
                  ? new DataListFilter({
                      from_latitude: props.nsa.pickupLocation.lat,
                      from_longitude: props.nsa.pickupLocation.lng,
                      to_latitude: props.nsa.dropoffLocation.lat,
                      to_longitude: props.nsa.dropoffLocation.lng,
                    })
                  : null,
            }))
            .distinctUntilKeyChanged("filter", isEqualData)
            .switchMap(props =>
              props.filter
                ? getPackagesNSA(props.filter).catch(error =>
                    Observable.of({ error }),
                  )
                : Observable.of({}),
            )
            .map(mapResponseDataMap)
            .distinctUntilChanged(isEqualData)
            .withLatestFrom(propsStream)
            .do(([response, props]) => {
              if (
                !response.get("pending") &&
                !response.get("data") &&
                !fp.isUndefined(response.get("data"))
              ) {
                props.showErrorMessage(
                  props.getLocalisationMessage(
                    "sorry_we_do_not_service_the_specified_areas",
                    "Sorry, we do not service the specified areas",
                  ),
                  10000,
                );
              }
            }),
          // propsStream
          //   .distinctUntilKeyChanged("marketplace", isEqualData)
          //   .map(fp.pick(["change", "marketplace"]))
          //   .do(props =>
          //     props.change(
          //       "pickupCountry",
          //       props.marketplace.get("country").toJS(),
          //     ),
          //   ),
          propsStream
            .map(
              fp.flow(
                fp.pick(["change", "values"]),
                fp.update(
                  "values",
                  fp.pick(["pickupCountry", "dropoffCountry"]),
                ),
              ),
            )
            .distinctUntilKeyChanged("values", isEqualData)
            .do(props => {
              const { pickupCountry, dropoffCountry } = props.values;

              const pickupCountryId = fp.get("id", pickupCountry);
              const dropoffCountryId = fp.get("id", dropoffCountry);

              if (pickupCountryId !== dropoffCountryId) {
                props.change("acceptedTerms", false);
                props.change("domestic", false);
              } else {
                props.change("domestic", true);
              }
            }),
          propsStream
            .map(
              fp.flow(
                fp.pick(["change", "values", "marketplaceId"]),
                fp.update(
                  "values",
                  fp.pick([
                    "codCharge",
                    "payer",
                    "paymentType",
                    "recipientNotAvailable",
                  ]),
                ),
              ),
            )
            .distinctUntilKeyChanged("values", isEqualData)
            .do(props => {
              const {
                codCharge,
                payer,
                paymentType,
                recipientNotAvailable,
              } = props.values;

              if (
                props.marketplaceId === WING_SA_ID ||
                props.marketplaceId === WING_AE_ID
              ) {
                if (payer !== SENDER) {
                  props.change("payer", SENDER);
                }
              } else if (paymentType !== CASH && payer !== SENDER) {
                props.change("payer", SENDER);
              } else if (
                paymentType === CASH &&
                codCharge > 0 &&
                payer !== RECIPIENT
              ) {
                props.change("payer", RECIPIENT);
              } else if (
                (payer === RECIPIENT || codCharge > 0) &&
                recipientNotAvailable !== DO_NOT_DELIVER
              ) {
                props.change("recipientNotAvailable", DO_NOT_DELIVER);
              }
            }),
          propsStream
            .combineLatest(
              chargeableWeightResponseStream,
              (props, chargeableWeight) => ({
                chargeableWeight,
                ...props,
              }),
            )
            .map(fp.pick(["change", "chargeableWeight"]))
            .distinctUntilKeyChanged("chargeableWeight")
            .filter(fp.get("chargeableWeight"))
            .do(props => {
              props.change(
                "chargeableWeight",
                props.chargeableWeight.get("data"),
              );
            }),
          propsStream
            .map(fp.pick(["change", "values", "allowCod"]))
            .distinctUntilKeyChanged("allowCod")
            .filter(fp.get("allowCod"))
            .do(props => {
              if (props.values.codCharge > 0) {
                props.change("codCharge", 0);
              }

              if (props.values.acceptCodTerms) {
                props.change("acceptCodTerms", false);
              }
            }),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream
          .map(
            fp.pick([
              "classes",
              "page",
              "total",
              "values",
              "setState",
              "state",
              "showView",
              "allowCod",
              "domestic",
              "currencyCode",
              "change",
              "reset",
              "submitting",
              "handleSubmit",
              "isReverseLogisticEnabled",
              "disabledOrderMandatoryFields",
              "initialValues",
              "getTimeSlots",
              "promoFilter",
              "marketplaceId",
              "validatePromo",
              "countries",
              "senderLocations",
              "recipientLocations",
              "getLocalisationMessage",
              "values",
            ]),
          )
          .combineLatest(
            promoFilterStream,
            sampleItemsResponseStream,
            packagePricesResponseStream,
            courierPriceResponseStream,
            paymentTypesResponseStream,
            pickupTimeSlotsResponseStream,
            chargeableWeightResponseStream,
            deliveryTimeSlotsResponseStream,
            pickUpLocationDetailsResponseStream,
            dropOffLocationDetailsResponseStream,
            sideEffectsStream,
            (
              props,
              promoFilter,
              sampleItemsResponse,
              packagePricesResponse,
              courierPriceResponse,
              paymentTypesResponse,
              pickupTimeSlotsResponse,
              chargeableWeightResponse,
              deliveryTimeSlotsResponse,
              pickUpLocationDetailsResponse,
              dropOffLocationDetailsResponse,
            ) => {
              const paymentResponse = paymentTypesResponse.get("list");

              const paymentTypes =
                props.marketplaceId === WING_AE_ID && props.values.domestic
                  ? paymentResponse
                  : paymentResponse.filter(x => x !== "pay_fort");

              return {
                ...props,
                promoFilter,
                paymentTypes,

                pickUpLocationDetails: pickUpLocationDetailsResponse.get(
                  "data",
                ),
                pickUpLocationDetailsFetching: pickUpLocationDetailsResponse.get(
                  "pending",
                ),
                dropOffLocationDetails: dropOffLocationDetailsResponse.get(
                  "data",
                ),
                dropOffLocationDetailsFetching: dropOffLocationDetailsResponse.get(
                  "pending",
                ),
                sampleItems: sampleItemsResponse.get("list"),
                sampleItemsFetching: sampleItemsResponse.get("pending"),
                packagePrices: packagePricesResponse.get("list"),
                packagePricesFetching: packagePricesResponse.get("pending"),
                courierPrice: courierPriceResponse.get("list"),
                courierPriceFetching: courierPriceResponse.get("pending"),
                // paymentTypes: paymentTypesResponse.get("list"),
                paymentTypesFetching: paymentTypesResponse.get("pending"),
                pickupTimeSlots: pickupTimeSlotsResponse.get("list"),
                pickupTimeSlotsFetching: pickupTimeSlotsResponse.get("pending"),
                chargeableWeight: chargeableWeightResponse.get("data"),
                chargeableWeightFetching: chargeableWeightResponse.get(
                  "pending",
                ),
                deliveryTimeSlots: deliveryTimeSlotsResponse.get("list"),
                deliveryTimeSlotsFetching: deliveryTimeSlotsResponse.get(
                  "pending",
                ),
              };
            },
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
  mapPropsStream(propsStream => {
    const customerPublicTerms = propsStream
      .first()
      .switchMap(() =>
        getCustomerPublicTerms().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );
    return propsStream.combineLatest(
      customerPublicTerms,
      (props, customerTerms) => ({
        ...props,
        publicTerms: customerTerms.get("payload"),
      }),
    );
  }),
  mapPropsStream(propsStream => {
    const customerPublicProhibits = propsStream
      .first()
      .switchMap(() =>
        getCustomerPublicProhibits().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );
    return propsStream.combineLatest(
      customerPublicProhibits,
      (props, customerProhibits) => ({
        ...props,
        publicProhibits: customerProhibits.get("payload"),
      }),
    );
  }),
  mapPropsStream(propsStream => {
    const customerCodTerms = propsStream
      .first()
      .switchMap(() =>
        getCustomerCodTerms().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );
    return propsStream.combineLatest(
      customerCodTerms,
      (props, customerCod) => ({
        ...props,
        codTerms: customerCod.get("payload"),
      }),
    );
  }),
);

OrderCreateWizard.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.string,
  total: PropTypes.number,
  showView: PropTypes.object,
  values: PropTypes.object,
  allowCod: PropTypes.bool,
  currencyCode: PropTypes.string,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  sampleItemsFetching: PropTypes.bool,
  sampleItems: PropTypes.instanceOf(List),
  packagePricesFetching: PropTypes.bool,
  packagePrices: PropTypes.instanceOf(List),
  courierPriceFetching: PropTypes.bool,
  courierPrice: PropTypes.instanceOf(List),
  paymentTypesFetching: PropTypes.bool,
  paymentTypes: PropTypes.instanceOf(List),
  pickupTimeSlotsFetching: PropTypes.bool,
  pickupTimeSlots: PropTypes.instanceOf(List),
  deliveryTimeSlotsFetching: PropTypes.bool,
  deliveryTimeSlots: PropTypes.instanceOf(List),
  chargeableWeightFetching: PropTypes.bool,
  pickUpLocationDetails: PropTypes.instanceOf(Map),
  dropOffLocationDetails: PropTypes.instanceOf(Map),
  promoFilter: PropTypes.instanceOf(Map),
  pickUpLocationDetailsFetching: PropTypes.bool,
  dropOffLocationDetailsFetching: PropTypes.bool,
  onSubmitFail: PropTypes.func,
  validatePromo: PropTypes.func.isRequired,
  countries: PropTypes.instanceOf(Map).isRequired,
  senderLocations: PropTypes.instanceOf(List).isRequired,
  recipientLocations: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  publicProhibits: PropTypes.instanceOf(Map),
  publicTerms: PropTypes.instanceOf(Map),
  codTerms: PropTypes.instanceOf(Map),
  isReverseLogisticEnabled: PropTypes.bool,
  state: PropTypes.object,
  setState: PropTypes.any,
};

function OrderCreateWizard(props) {
  const { classes, isReverseLogisticEnabled, getLocalisationMessage } = props;
  const page = pages.includes(props.page)
    ? props.page
    : isReverseLogisticEnabled
    ? pages[0]
    : pages[1];
  const pageIndex = pages.indexOf(page);
  const setPage = v => props.change("page", v);

  const showView = fp.toPlainObject(props.showView);

  const setViewVisibility = (view, visibility) =>
    props.change("showView", { ...showView, [view]: visibility });

  return (
    <div>
      <PageLoading
        isLoading={
          props.submitting ||
          props.pickUpLocationDetailsFetching ||
          props.dropOffLocationDetailsFetching
        }
      />

      <div className={classes.root}>
        <OrderCreateWizardStepper>
          {isReverseLogisticEnabled && pageIndex > 0 && (
            <OrderCreateWizardStepReverseDetails
              logistic_type={props.state.logistic_type}
              onClick={() => setPage(REVERSE)}
            />
          )}
          {pageIndex > 1 && Boolean(pageIndex === 1 ? !showView.to : true) && (
            <OrderCreateWizardStepFromDetails
              showSwapButton={false}
              onClick={() => setPage(PAGE_FROM)}
            />
          )}
          {pageIndex > 2 && (
            <OrderCreateWizardStepToDetails onClick={() => setPage(PAGE_TO)} />
          )}

          {pageIndex > 3 && !isEmpty(props.sampleItems) && (
            <OrderCreateWizardStepWhatDetails
              onClick={() => setPage(PAGE_WHAT)}
            />
          )}

          {pageIndex > 4 && (
            <OrderCreateWizardStepHowDetails
              onClick={() => setPage(PAGE_HOW)}
            />
          )}

          {pageIndex > 5 && (
            <OrderCreateWizardStepCourierDetails
              onClick={() => setPage(PAGE_COURIER)}
            />
          )}

          {pageIndex > 6 && (
            <OrderCreateWizardStepPayDetails
              onClick={() => setPage(PAGE_PAY)}
            />
          )}

          {Boolean(pageIndex > 7 && showView.when) && (
            <OrderCreateWizardStepWhenDetails
              onClick={() => setPage(PAGE_WHEN)}
            />
          )}

          {Boolean(pageIndex > 8 && showView.collect) && (
            <OrderCreateWizardStepCollectDetails
              currencyCode={props.currencyCode}
              onClick={() => setPage(PAGE_COLLECT)}
            />
          )}

          {Boolean(pageIndex > 9 && showView.more && !props.values.note) && (
            <OrderCreateWizardStepMoreDetails
              getLocalisationMessage={getLocalisationMessage}
              onClick={() => setPage(PAGE_MORE)}
            />
          )}
        </OrderCreateWizardStepper>

        <OrderCreateWizardCard page={page}>
          {page === REVERSE && (
            <FlexBox className={classes.reverse}>
              <FlexBox
                align="center"
                justify="center"
                className={classes.regularDivWrapper}
                onClick={() => {
                  props.setState(fp.set("logistic_type", REGULAR_ORDER));
                  setPage(PAGE_FROM);
                }}
              >
                <span>{getLocalisationMessage(REGULAR_ORDER)}</span>
                <div className={classes.regularDiv}>
                  <img
                    src={regular}
                    alt="regular"
                    className={classes.regularImg}
                  />
                </div>
              </FlexBox>
              <FlexBox
                align="center"
                justify="center"
                className={classes.reverseDivWrapper}
                onClick={() => {
                  props.setState(fp.set("logistic_type", REVERSE_ORDER));
                  setPage(PAGE_FROM);
                }}
              >
                <span>{getLocalisationMessage(REVERSE_ORDER)}</span>
                <div className={classes.reverseDiv}>
                  <img
                    src={reverse}
                    alt="reverse"
                    className={classes.reverseImg}
                  />
                </div>
              </FlexBox>
            </FlexBox>
          )}
          {page === PAGE_FROM && (
            <OrderCreateWizardStepFromForm
              key={PAGE_FROM}
              initialValues={props.initialValues}
              onSubmit={values => {
                if (values.pickupSaveAddressBook) {
                  const addressBook = {};
                  addressBook.address = values.pickupLocation.address;
                  addressBook.address_type = values.pickupAddressType;
                  addressBook.apartment = values.pickupApartment;
                  addressBook.building = values.pickupBuilding;
                  addressBook.city = _.isObject(values.pickupCity)
                    ? values.pickupCity
                    : { name: values.pickupCity };
                  addressBook.country = values.pickupCountry;
                  addressBook.landmark = values.pickupNearestLandmark;
                  addressBook.name = values.pickupContactName;
                  addressBook.neighborhood = _.isObject(
                    values.pickupNeighborhood,
                  )
                    ? values.pickupNeighborhood
                    : { name: values.pickupNeighborhood };
                  addressBook.phone = values.pickupContactPhone;
                  addressBook.phone_code = values.pickupContactPhoneCode.get(
                    "code",
                  );
                  addressBook.street = values.pickupStreet;
                  addressBook.lat = values.pickupLocation.lat;
                  addressBook.lon = values.pickupLocation.lng;
                  addressBook.geo_address = getProperGeoAddressObj(
                    values.pickupLocation,
                  );
                  addressBook.pickup = true;

                  Promise.resolve(
                    createAddressBook(addressBook).catch(ResponseError.throw),
                  ).catch(props.onSubmitFail);
                }
                setPage(PAGE_TO);
              }}
              pickUpLocationDetails={props.pickUpLocationDetails}
              isLoading={props.pickUpLocationDetailsFetching}
              suggestions={props.senderLocations}
              showLocationForm={Boolean(showView.from)}
              onShowLocationForm={() =>
                props.change("showView", {
                  ...showView,
                  from: true,
                  fromFormDetails: false,
                  fromForm: false,
                })
              }
              showFormDetails={Boolean(showView.fromFormDetails)}
              showForm={Boolean(showView.fromForm)}
              onShowForm={() => {
                setPage(PAGE_FROM);
                props.change("showView", {
                  ...showView,
                  fromForm: true,
                });
              }}
              onShowFormDetails={() =>
                props.change("showView", {
                  ...showView,
                  from: true,
                  fromFormDetails: true,
                  fromForm: false,
                })
              }
              onBackButtonClick={() => {
                setPage(PAGE_FROM);
                props.change("showView", {
                  ...showView,
                  from: false,
                  fromFormDetails: false,
                  fromForm: false,
                });
              }}
            />
          )}

          {page === PAGE_TO && (
            <OrderCreateWizardStepToForm
              key={PAGE_TO}
              countries={props.countries}
              dropOffLocationDetails={props.dropOffLocationDetails}
              isLoading={props.dropOffLocationDetailsFetching}
              onSubmit={values => {
                if (values.dropoffSaveAddressBook) {
                  const addressBook = {};
                  addressBook.address = values.dropoffLocation.address;
                  addressBook.address_type = values.dropoffAddressType;
                  addressBook.apartment = values.dropoffApartment;
                  addressBook.building = values.dropoffBuilding;
                  addressBook.city = _.isObject(values.dropoffCity)
                    ? values.dropoffCity
                    : { name: values.dropoffCity };
                  addressBook.country = values.dropoffCountry;
                  addressBook.landmark = values.dropoffNearestLandmark;
                  addressBook.name = values.dropoffContactName;
                  addressBook.neighborhood = _.isObject(
                    values.dropoffNeighborhood,
                  )
                    ? values.dropoffNeighborhood
                    : { name: values.dropoffNeighborhood };
                  addressBook.phone = values.dropoffContactPhone;
                  addressBook.phone_code = values.dropoffContactPhoneCode.get(
                    "code",
                  );
                  addressBook.street = values.dropoffStreet;
                  addressBook.lat = values.dropoffLocation.lat;
                  addressBook.lon = values.dropoffLocation.lng;
                  addressBook.geo_address = getProperGeoAddressObj(
                    values.dropoffLocation,
                  );
                  addressBook.pickup = false;

                  Promise.resolve(
                    createAddressBook(addressBook).catch(ResponseError.throw),
                  ).catch(props.onSubmitFail);
                }
                setPage(PAGE_WHAT);
              }}
              suggestions={props.recipientLocations}
              showLocationForm={Boolean(showView.to)}
              onShowLocationForm={() => {
                setPage(PAGE_TO);
                props.change("showView", {
                  ...showView,
                  to: true,
                  toFormDetails: false,
                  toForm: false,
                });
              }}
              showFormDetails={Boolean(showView.toFormDetails)}
              onShowFormDetails={() => {
                setPage(PAGE_TO);
                props.change("showView", {
                  ...showView,
                  to: true,
                  toFormDetails: true,
                  toForm: false,
                });
              }}
              showForm={Boolean(showView.toForm)}
              onShowForm={() => {
                setPage(PAGE_TO);
                props.change("showView", {
                  ...showView,
                  toForm: true,
                });
              }}
              onBackButtonClick={() => {
                setPage(PAGE_TO);
                props.change("showView", {
                  ...showView,
                  to: false,
                  toFormDetails: false,
                  toForm: false,
                });
              }}
            />
          )}

          {page === PAGE_WHAT && (
            <OrderCreateWizardStepWhatForm
              publicTerms={props.publicTerms}
              publicProhibits={props.publicProhibits}
              key={PAGE_WHAT}
              sampleItems={props.sampleItems}
              initialValues={props.initialValues}
              sampleItemsFetching={props.sampleItemsFetching}
              isLoading={props.chargeableWeightFetching}
              onSubmit={() => setPage(PAGE_HOW)}
            />
          )}

          {page === PAGE_HOW && (
            <OrderCreateWizardStepHowForm
              key={PAGE_HOW}
              packagePrices={props.packagePrices}
              packagePricesFetching={props.packagePricesFetching}
              onSubmit={() => setPage(PAGE_COURIER)}
            />
          )}

          {page === PAGE_COURIER && (
            <OrderCreateWizardStepCourierForm
              key={PAGE_COURIER}
              courierPrice={props.courierPrice}
              courierPriceFetching={props.courierPriceFetching}
              onSubmit={() => setPage(PAGE_PAY)}
            />
          )}

          {page === PAGE_PAY && (
            <OrderCreateWizardStepPayForm
              key={PAGE_PAY}
              onSubmit={({ paymentType: pType }) =>
                pType === CASH ? setPage(PAGE_COLLECT) : setPage(PAGE_FINAL)
              }
              paymentTypes={props.paymentTypes}
              paymentTypesFetching={props.paymentTypesFetching}
            />
          )}

          {page === PAGE_WHEN && (
            <OrderCreateWizardStepWhenForm
              key={PAGE_WHEN}
              initialValues={props.initialValues}
              onSubmit={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("when", true);
              }}
              onDismiss={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("when", false);
              }}
              pickupTimeSlots={props.pickupTimeSlots}
              pickupTimeSlotsFetching={props.pickupTimeSlotsFetching}
              deliveryTimeSlots={props.deliveryTimeSlots}
              deliveryTimeSlotsFetching={props.deliveryTimeSlotsFetching}
            />
          )}

          {Boolean(props.allowCod && page === PAGE_COLLECT) && (
            <OrderCreateWizardStepCollectForm
              codTerms={props.codTerms}
              key={PAGE_COLLECT}
              initialValues={props.initialValues}
              onSubmit={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("collect", true);
              }}
              onDismiss={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("collect", false);
              }}
            />
          )}
        </OrderCreateWizardCard>

        {page === PAGE_MORE && (
          <OrderCreateWizardStepMoreForm
            validatePromo={code =>
              props.validatePromo(props.promoFilter.set("promo_code", code))
            }
            onSubmit={() => {
              setPage(PAGE_FINAL);
              setViewVisibility("more", true);
            }}
          />
        )}

        {page === PAGE_FINAL && (
          <OrderCreateWizardStepTermsDialog
            onSubmit={() => {
              setPage(PAGE_FINAL);
            }}
            onDismiss={() => {
              setPage(PAGE_TO);
            }}
          />
        )}

        <OrderCreateWizardActions
          total={props.total}
          currencyCode={props.currencyCode}
          showTotal={page === PAGE_FINAL}
        >
          {Boolean(page === PAGE_FINAL && !showView.when) && (
            <OrderCreateWizardFab
              mini={true}
              label={getLocalisationMessage("schedule", "Schedule")}
              onClick={() => setPage(PAGE_WHEN)}
            >
              <AccessTime />
            </OrderCreateWizardFab>
          )}

          {Boolean(
            props.allowCod && page === PAGE_FINAL && !showView.collect,
          ) && (
            <OrderCreateWizardFab
              mini={true}
              label={getLocalisationMessage(
                "collect_cash_on_delivery",
                "Collect cash on delivery",
              )}
              onClick={() => setPage(PAGE_COLLECT)}
            >
              <AttachMoney />
            </OrderCreateWizardFab>
          )}

          {Boolean(
            page === PAGE_FINAL && (!showView.more || props.values.note),
          ) && (
            <OrderCreateWizardFab
              mini={true}
              label={getLocalisationMessage("customize_more", "Customize more")}
              onClick={() => setPage(PAGE_MORE)}
            >
              <MoreHoriz />
            </OrderCreateWizardFab>
          )}

          {Boolean(page === PAGE_FINAL) && (
            <OrderCreateWizardFab
              success={true}
              label={getLocalisationMessage("place_an_order", "Place an Order")}
              onClick={props.handleSubmit}
            >
              <DoneAll />
            </OrderCreateWizardFab>
          )}
        </OrderCreateWizardActions>
      </div>

      <OrderCreateWizardOverlay />
    </div>
  );
}

export default enhancer(OrderCreateWizard);
