import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, formValueSelector } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { ContactPhone } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardAvatars from "../internal/OrderCreateWizardAvatars";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCardNew";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormSelectField from "../../form/FormSelectField";
import FormCountryAutoComplete from "../../form/FormCountryV2AutoComplete";
import FormPhoneCodeWithoutCountryField from "../../form/FormPhoneCodeWithoutCountryField";
import FlexBox from "../../ui-core/FlexBox";
import OrderCityFieldDefault from "../../orders-core/OrderCityFieldDefault";
import OrderNeighborhoodDefaultField from "../../orders-core/OrderNeighborhoodDefaultField";
import CustomerAddressBookSelectV2Dialog from "../../address-book-core/CustomerAddressBookSelectV2Dialog";
import {
  getProperGeoAddressObj,
  getProperGeoAddressString,
} from "../../../helpers/GeoUtils";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import {
  formatText,
  parsePhone,
  formatPhone,
} from "../../../helpers/FormatUtils";
import ResponseError from "../../../helpers/ResponseError";
import { getMessage as getMessaegeTitle } from "../../../reducers/MessageReducer";
import {
  getMarketplace,
  marketplaceMandatoryFieldsDisabled,
} from "../../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import AddressTypes from "../../../constants/AddressType";
import { phoneCountyCodeList } from "../../../constants/PhoneCountryCodes";
import { createAddressBook } from "../../../api/shared/CustomerAddressBookV2Api";

const valueSelector = formValueSelector("OrderCreateWizardDefault");
// const getName = fp.get("name");

const enhancer = compose(
  useSheet({
    search: {
      "& > input + div": { display: "none" },
      "& > input + div + div": { display: "none" },
    },
    checkBoxAddressBook: { paddingBottom: "10px", paddingTop: "10px" },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
  }),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      if (values.dropoffSaveAddressBook) {
        const addressBook = {};
        addressBook.address = values.dropoffLocationAddress;
        addressBook.address_type = values.dropoffAddressType;
        addressBook.apartment = values.dropoffApartment;
        addressBook.building = values.dropoffBuilding;
        addressBook.city = _.isObject(values.dropoffCity)
          ? values.dropoffCity
          : { name: values.dropoffCity };
        addressBook.country = values.dropoffCountry;
        addressBook.landmark = values.dropoffNearestLandmark;
        addressBook.name = values.dropoffContactName;
        addressBook.neighborhood = _.isObject(values.dropoffNeighborhood)
          ? values.dropoffNeighborhood
          : { name: values.dropoffNeighborhood };
        addressBook.phone = values.dropoffContactPhone;
        addressBook.phone_code = values.dropoffContactPhoneCode.get("code");
        addressBook.street = values.dropoffStreet;
        addressBook.geo_address = values.dropoffLocationAddress;
        addressBook.pickup = false;

        Promise.resolve(
          createAddressBook(addressBook).catch(ResponseError.throw),
        ).catch(props.onSubmitFail);
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "OrderCreateWizardDefault",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  formValues({
    dropoffContactPhone: "dropoffContactPhone",
    dropoffContactPhoneCode: "dropoffContactPhoneCode",
    dropoffAddressType: "dropoffAddressType",
    dropoffBuilding: "dropoffBuilding",
    dropoffApartment: "dropoffApartment",
    dropoffpNeighborhood: "dropoffNeighborhood",
    dropoffCity: "dropoffCity",
    dropoffLocationAddress: "dropoffLocationAddress",
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "dropoffCountry",
      "dropoffCity",
      "dropoffNeighborhood",
      "dropoffAddressType",
      "initialDropoffLocation",
      "dropoffBuilding",
      "dropoffApartment",
      "dropoffContactPhone",
      "dropoffLocationAddress",
    ),
    dropoffCountry: valueSelector(state, "dropoffCountry"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    title: getMessaegeTitle(
      state,
      getMessage(
        state,
        "to_whom_are_we_shipping_it",
        "To whom are we shipping it?",
      ),
      getMessage(state, "dropoff_location", "Drop-off Location"),
    ),
    orderReferenceIdVisibility: getMarketplace(state).getIn(
      ["setting", "settings", "orderReferenceIdVisibility"],
      false,
    ),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
    isRTL: getIsRTL(state),
  })),
  withState("state", "setState", {
    focusCountry: false,
    showAddressDialog: false,
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .distinctUntilKeyChanged("dropoffCountry", isEqualData)
        .delay(200)
        .do(props => {
          if (fp.get("name", props.dropoffCountry)) {
            const countryPhoneCode = _.find(phoneCountyCodeList, {
              country: props.dropoffCountry.name,
            });
            props.change("dropoffContactPhoneCode", fromJS(countryPhoneCode));
          }
        }),
      propsStream.take(1).do(props => {
        if (props.values.initialDropoffLocation) {
          props.change("initialDropoffLocation", null);
          props.change("dropoffLocation", props.values.initialDropoffLocation);

          props.touch(
            "dropoffLocation",
            "dropoffDetails",
            "dropoffContactPhone",
            "dropoffContactName",
          );

          props.onShowForm();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("showForm")
        .skip(1)
        .do(props => {
          if (props.valid && props.showForm) {
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

OrderCreateWizardStepToForm.propTypes = {
  classes: PropTypes.object,
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  dropoffBuilding: PropTypes.string,
  dropoffApartment: PropTypes.string,
  dropoffCity: PropTypes.any,
  dropoffCountry: PropTypes.object,
  dropoffNeighborhood: PropTypes.any,

  title: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,

  orderReferenceIdVisibility: PropTypes.bool,
  showContent: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showForm: PropTypes.bool,
  onShowForm: PropTypes.func,
  dropoffLocation: PropTypes.object,
  suggestions: PropTypes.instanceOf(List).isRequired,
  dropoffContactPhoneCode: PropTypes.instanceOf(Map),
  dropoffContactPhone: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  disabledOrderMandatoryFields: PropTypes.bool,
  isRTL: PropTypes.bool,
};

function OrderCreateWizardStepToForm(props) {
  const {
    state,
    classes,
    getLocalisationMessage,
    disabledOrderMandatoryFields,
    isRTL,
  } = props;

  const countryCode = getValue(props.values.dropoffCountry, "sort_name")
    ? getValue(props.values.dropoffCountry, "sort_name")
    : getValue(props.values.dropoffCountry, "code");
  // const countryCode = getValue(props.values.dropoffCountry, "description");
  const countryId = getValue(props.values.dropoffCountry, "id");
  const cityId = getValue(props.values.dropoffCity, "id")
    ? getValue(props.values.dropoffCity, "id")
    : 0;
  const showForm = props.showForm && props.showContent;

  return (
    <OrderCreateWizardStepCard
      title={props.title}
      showContent={showForm}
      disableTitleClick={showForm}
      onSubmit={props.handleSubmit}
      showSubmit={showForm && props.valid}
      onTitleClick={props.onShowForm}
      subContent={
        Boolean(props.showContent && !props.showForm) && (
          <OrderCreateWizardAvatars
            onAddNew={props.onShowForm}
            onAddressBookClick={() =>
              props.setState(fp.set("showAddressDialog", true))
            }
            suggestions={props.suggestions}
            onSuggestionClick={item => {
              props.change("dropoffLocation", {
                lat: item.get("lat"),
                lng: item.get("lon"),
                country: countryCode,
                address: item.get("address"),
                geo_address: getProperGeoAddressString(
                  item.get("geo_address") || item.get("address"),
                ),
              });

              props.change(
                "dropoffCity",
                _.isObject(item.get("city"))
                  ? item.get("city").toJS()
                  : item.get("city"),
              );
              props.change(
                "dropoffNeighborhood",
                _.isObject(item.get("neighborhood"))
                  ? item.get("neighborhood").toJS()
                  : item.get("neighborhood"),
              );
              props.change("dropoffAddressType", item.get("address_type"));
              props.change("dropoffLocationAddress", item.get("address"));
              props.change("dropoffStreet", item.get("street"));
              props.change("dropoffBuilding", item.get("building"));
              props.change("dropoffCountry", item.get("country").toJS());
              props.change("dropoffApartment", item.get("apartment"));
              props.change("dropoffNearestLandmark", item.get("landmark"));
              props.change(
                "dropoffContactPhoneCode",
                fromJS({ code: item.get("phone_code") }),
              );
              props.change("dropoffContactPhone", item.get("phone"));
              props.change("dropoffContactName", item.get("name"));
              props.change("dropoffTempCountry", true);

              props.touch(
                "dropoffLocation",
                "dropoffAddressType",
                "dropoffLocationAddress",
                "dropoffStreet",
                "dropoffBuilding",
                "dropoffCountry",
                "dropoffApartment",
                "dropoffNearestLandmark",
                "dropoffContactPhoneCode",
                "dropoffContactPhone",
                "dropoffContactName",
                "dropoffCity",
                "dropoffNeighborhood",
              );

              props.onShowForm();
            }}
          />
        )
      }
    >
      <CustomerAddressBookSelectV2Dialog
        countryId={countryId}
        open={state.showAddressDialog}
        onRequestClose={() =>
          props.setState(fp.set("showAddressDialog", false))
        }
        onSubmit={values => {
          props.setState(fp.set("showAddressDialog", false));

          props.change("dropoffLocation", {
            country: countryCode,
            lat: values.address.lat,
            lng: values.address.lon,
            address: values.address.address,
            geo_address: getProperGeoAddressObj(values.address),
          });

          props.change("dropoffCity", values.address.city);
          props.change("dropoffNeighborhood", values.address.neighborhood);
          props.change("dropoffContactName", values.address.name);
          props.change("dropoffContactPhone", values.address.phone);
          props.change(
            "dropoffContactPhoneCode",
            fromJS({ code: values.address.phone_code }),
          );
          props.change("dropoffAddressType", values.address.address_type);
          props.change("dropoffLocationAddress", values.address.address);
          props.change("dropoffStreet", values.address.street);
          props.change("dropoffBuilding", values.address.building);
          props.change("dropoffApartment", values.address.apartment);
          props.change("dropoffNearestLandmark", values.address.landmark);
          props.change("dropoffTempCountry", true);

          props.touch(
            "dropoffContactName",
            "dropoffContactPhoneCode",
            "dropoffContactPhone",
            "dropoffLocationAddress",
            "dropoffLocation",
            "dropoffAddressType",
            "dropoffStreet",
            "dropoffBuilding",
            "dropoffApartment",
            "dropoffNearestLandmark",
            "dropoffCity",
            "dropoffNeighborhood",
          );

          if (!props.showForm) {
            props.onShowForm();
          }
        }}
      />

      <FlexBox>
        <FlexBox flex={true} direction="column">
          <FormTextField
            name="dropoffContactName"
            fullWidth={true}
            label={`${getLocalisationMessage(
              "recipient_name",
              "Recipient Name",
            )} *`}
          />
        </FlexBox>

        <FlexBox align="center">
          <IconButton
            onClick={() => props.setState(fp.set("showAddressDialog", true))}
          >
            <ContactPhone />
          </IconButton>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox flex={6} style={{ paddingRight: "5px" }}>
          <FormPhoneCodeWithoutCountryField
            className={
              isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
            }
            name="dropoffContactPhoneCode"
            fullWidth={true}
            label={getLocalisationMessage("phone_code", "Phone Code")}
          />
        </FlexBox>

        <FlexBox flex={true} style={{ paddingLeft: "5px" }}>
          <FormTextField
            ignoreRtl={isRTL}
            name="dropoffContactPhone"
            focus={true}
            fullWidth={true}
            label={`${getLocalisationMessage("phone_number", "Phone number")} ${
              disabledOrderMandatoryFields ? "" : " *"
            }`}
            parse={parsePhone}
            format={value =>
              formatPhone(
                props.dropoffContactPhoneCode &&
                  props.dropoffContactPhoneCode.get("code")
                  ? props.dropoffContactPhoneCode.get("code")
                  : null,
                value,
              )
            }
          />
        </FlexBox>
      </FlexBox>

      {props.orderReferenceIdVisibility && (
        <FormTextField
          name="reference_id"
          fullWidth={true}
          label={getLocalisationMessage("reference_id", "Reference ID")}
        />
      )}
      <FormTextField
        fullWidth={true}
        name="dropoffContactEmail"
        label={getLocalisationMessage("recipient_email", "Recipient Email")}
      />

      <FlexBox>
        <FlexBox
          align="flex-end"
          justify="center"
          style={{ paddingBottom: "10px", paddingRight: "8px" }}
        >
          <FlagIcon code={fp.toUpper(countryCode)} />
        </FlexBox>

        <FlexBox flex={true}>
          <FormCountryAutoComplete
            fullWidth={true}
            name="dropoffCountry"
            focus={state.focusCountry}
            label={`${getLocalisationMessage("country", "Country")} *`}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type to search ...",
            )}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox flex={true}>
          <OrderCityFieldDefault
            readOnly={true}
            name="dropoffCity"
            label={`${getLocalisationMessage("city", "City")} *`}
            countryId={countryId}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <OrderNeighborhoodDefaultField
            readOnly={true}
            name="dropoffNeighborhood"
            label={getLocalisationMessage("region", "Region")}
            countryId={countryId}
            cityId={cityId}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox flex={true}>
        <FormTextField
          fullWidth={true}
          name="dropoffStreet"
          label={getLocalisationMessage("street", "Street")}
        />
      </FlexBox>

      <FlexBox flex={true}>
        <FormTextField
          fullWidth={true}
          required={true}
          name="dropoffLocationAddress"
          label={`${getLocalisationMessage("address", "Address")} *`}
        />
      </FlexBox>

      <FlexBox gutter={8}>
        <FlexBox flex={true}>
          <FormTextField
            fullWidth={true}
            name="dropoffBuilding"
            label={`${getLocalisationMessage(
              "building_villa",
              "Building / Villa",
            )} ${disabledOrderMandatoryFields ? "" : " *"}`}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormTextField
            fullWidth={true}
            name="dropoffApartment"
            label={`${getLocalisationMessage(
              "apartment_office",
              "Apartment / Office",
            )} ${disabledOrderMandatoryFields ? "" : " *"}`}
          />
        </FlexBox>
      </FlexBox>

      <FormSelectField
        name="dropoffAddressType"
        label={`${getLocalisationMessage("address_type", "Address Type")} *`}
        fullWidth={true}
        options={AddressTypes}
        formatOption={value => getLocalisationMessage(value, formatText(value))}
      />

      <FormTextField
        fullWidth={true}
        name="dropoffNearestLandmark"
        label={getLocalisationMessage("nearest_landmark", "Nearest Landmark")}
      />

      <FlexBox flex={true} className={classes.checkBoxAddressBook}>
        <FormCheckbox
          name="dropoffSaveAddressBook"
          label={getLocalisationMessage(
            "save_to_address_book",
            "Save to Address Book",
          )}
        />
      </FlexBox>
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepToForm);
