import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { CM_KG, INCHES_POUNDS } from "../../../constants/UnitsOfMeasureTypes";

const valueSelector = formValueSelector("OrderCreateWizardNew");

const formatWight = (type, getLocalisationMessage) => {
  switch (type) {
    case CM_KG:
      return getLocalisationMessage("kg", "kg");
    case INCHES_POUNDS:
      return getLocalisationMessage("pound", "pound");
    default:
      return type;
  }
};

const enhancer = compose(
  connect(state => ({
    values: valueSelector(state, "menu", "unit", "weight", "chargeableWeight"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepWhatDetails.propTypes = {
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepWhatDetails({ values, ...props }) {
  const { menu, chargeableWeight, unit } = values;

  return (
    (menu && (
      <OrderCreateWizardStepDetails
        label={props.getLocalisationMessage("what", "What")}
        onClick={props.onClick}
        primaryText={<div>{menu.get("name")}</div>}
        secondaryText={
          <div>
            {props.getLocalisationMessage(
              "chargeable_weight",
              "Chargeable Weight",
            )}
          </div>
        }
        rightPrimaryText={
          <strong>
            {chargeableWeight}
            {formatWight(unit, props.getLocalisationMessage)}
          </strong>
        }
        avatar={
          <Avatar
            src={menu.get("image")}
            style={{
              backgroundColor: "transparent",
            }}
          />
        }
      />
    )) ||
    null
  );
}

export default enhancer(OrderCreateWizardStepWhatDetails);
