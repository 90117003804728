import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, formValueSelector } from "redux-form";
import { Paper, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import OrderCreateWizardBackgroundMap from "../map/OrderCreateWizardBackgroundMap";
import OrderCreateWizardYandexBackgroundMap from "../map/OrderCreateWizardYandexBackgroundMap";
import OrderCreateWizardLeafletBackgroundMap from "../map/OrderCreateWizardLeafletBackgroundMap";
import FormGeoAutoComplete from "../../form/NewFormGeoAutoComplete";
import FormYandexGeoAutoComplete from "../../form/FormYandexGeoAutoComplete";
import FlexBox from "../../ui-core/FlexBox";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import {
  isBlankString,
  isValidCoordinates,
} from "../../../helpers/ValidateUtils";
import { getMarketplaceId } from "../../../reducers/MarketplaceReducer";
import { getMessages } from "../../../reducers/LocalizationReducer";
import { getMapProvider } from "../../../../shared/reducers/AppReducer";
import {
  YANDEX_MAP_PROVIDER,
  GOOGLE_MAP_PROVIDER,
} from "../../../../shared/constants/MapsControllerConstants";

const getFormValue = formValueSelector("OrderCreateWizardNew");
const valueSelector = formValueSelector("OrderCreateWizardPickupLocationForm");

const enhancer = compose(
  useSheet({
    container: { marginBottom: 0 },
    locationMapContainer: {
      height: "100vh",
      position: "fixed",
      left: "50%",
      right: "50%",
      top: "-135px",
      marginLeft: "-50vw",
      marginRight: "-50vw",
    },
    locationAutoComplete: {
      zIndex: 2,
      top: "128px",
      position: "absolute",
      left: "50%",
      width: "100%",
      maxWidth: "500px",
      transform: "translateX(-50%)",
    },
    details: { marginTop: "10px", padding: "7px 15px", borderRadius: "2px" },
    search: {
      "& > input + div": { display: "none" },
    },
    checkBoxAddressBook: { paddingBottom: "10px", paddingTop: "10px" },
  }),
  connect(state => ({
    i18n: getMessages(state),
    marketplaceId: getMarketplaceId(state),
    mapProvider: getMapProvider(state),
  })),
  reduxForm({
    form: "OrderCreateWizardPickupLocationForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      location:
        !isValidCoordinates(values.location) &&
        props.i18n.get("invalid_coordinate", "Invalid Coordinate"),
    }),
  }),
  formValues({
    location: "location",
  }),
  connect(state => ({
    values: valueSelector(state, "location", "initialPickupLocation"),
    pickupLocation: getFormValue(state, "pickupLocation"),
    pickupCountry: getFormValue(state, "pickupCountry"),
  })),
  withState("state", "setState", {
    focusLocation: false,
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .distinctUntilKeyChanged("state", isEqualData)
        .delay(200)
        .do(props => {
          if (props.state.focusLocation) {
            props.setState(fp.set("focusLocation", false));
          }
        }),
      propsStream.distinctUntilKeyChanged("location", isEqualData).do(props => {
        if (
          isValidCoordinates(props.values.location) &&
          isBlankString(getValue(props.values.location, "address"))
        ) {
          props.handleSubmit();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("pickupLocation", isEqualData)
        .do(props => {
          if (isValidCoordinates(props.pickupLocation)) {
            props.change("location", props.pickupLocation);
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream.combineLatest(sideEffectsStream, fp.identity);
  }),
);

OrderCreateWizardStepFromLocationForm.propTypes = {
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  marketplaceId: PropTypes.number,

  mapProvider: PropTypes.string,

  state: PropTypes.object,
  initialValues: PropTypes.object,
  setState: PropTypes.func,

  location: PropTypes.object,
  pickupCountry: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func,
};

function OrderCreateWizardStepFromLocationForm(props) {
  const { state, classes, i18n } = props;

  const countryCode = getValue(props.pickupCountry, "description");
  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;
  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <FlexBox className={classes.locationMapContainer}>
      <div className={classes.locationAutoComplete}>
        <Paper>
          <FlexBox element={<form onSubmit={props.handleSubmit} />}>
            <FlexBox align="center">
              <IconButton onClick={props.onBackButtonClick}>
                <ArrowBack />
              </IconButton>
            </FlexBox>

            <FlexBox flex={true}>
              {isYandexMap ? (
                <FormYandexGeoAutoComplete
                  fullWidth={true}
                  autoComplete="off"
                  className={classes.search}
                  name="location"
                  countryCode={countryCode}
                  hintText={i18n.get("search_address", "Search Address")}
                  focus={state.focusLocation}
                  popoverProps={{ style: { minWidth: "352px" } }}
                />
              ) : (
                <FormGeoAutoComplete
                  fullWidth={true}
                  autoComplete="off"
                  className={classes.search}
                  name="location"
                  countryCode={countryCode}
                  hintText={i18n.get("search_address", "Search Address")}
                  focus={state.focusLocation}
                  popoverProps={{ style: { minWidth: "352px" } }}
                />
              )}
            </FlexBox>
          </FlexBox>
        </Paper>
      </div>

      {isYandexMap && (
        <OrderCreateWizardYandexBackgroundMap
          initialValues={{ location: props.values.location }}
          onSubmit={x => {
            props.change("location", {
              lat: x.location.lat,
              lng: x.location.lng,
            });
          }}
        />
      )}
      {isGoogleMapProvider && (
        <OrderCreateWizardBackgroundMap
          initialValues={{ location: props.values.location }}
          onSubmit={x => {
            props.change("location", {
              lat: x.location.lat,
              lng: x.location.lng,
            });
          }}
        />
      )}
      {!isGoogleMapProvider && !isYandexMap && (
        <OrderCreateWizardLeafletBackgroundMap
          initialValues={{ location: props.values.location }}
          onSubmit={x => {
            props.change("location", {
              lat: x.location.lat,
              lng: x.location.lng,
            });
          }}
        />
      )}
    </FlexBox>
  );
}

export default enhancer(OrderCreateWizardStepFromLocationForm);
