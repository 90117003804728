import { Observable } from "rxjs";
import { mapPropsStream } from "recompose";
import { getWindowScrollTop, getWindowScrollLeft } from "./WindowHelper";

export default function windowScrollHandler(mapToProps, { delay = 100 } = {}) {
  return mapPropsStream(propsStream => {
    const scrollStateStream = Observable.fromEvent(window, "scroll")
      .throttleTime(delay)
      .startWith({ prevScrollTop: null, prevScrollLeft: null })
      .scan(acc => {
        const scrollTop = getWindowScrollTop();
        const scrollLeft = getWindowScrollLeft();
        const prevScrollTop =
          acc.prevScrollTop === null ? scrollTop : acc.scrollTop;
        const prevScrollLeft =
          acc.prevScrollLeft === null ? scrollLeft : acc.scrollLeft;

        return {
          scrollTop,
          scrollLeft,
          prevScrollTop,
          prevScrollLeft,
          scrollingFromTop: scrollTop > prevScrollTop,
          scrollingFromLeft: scrollLeft > prevScrollLeft,
        };
      }, {})
      .map(mapToProps);

    return propsStream.combineLatest(
      scrollStateStream,
      (props, scrollState) => ({ ...props, ...scrollState }),
    );
  });
}
