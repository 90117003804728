import { Observable } from "rxjs";
import React from "react";
import sprintf from "sprintf";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import { Refresh } from "@material-ui/icons";
import Tabs from "../ui-core/Tabs";
import Text, { PRIMARY } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatOrderStatusCodeForLocalisation,
  mapOrderNotesAndHistoryToActivities,
} from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { REVERSE_ORDER } from "../../constants/OrderTypes";
import {
  getAppMarketplace,
  getMapProvider,
} from "../../../shared/reducers/AppReducer";
import TextWithLink from "../../../client/components/router/TextWithLink";

import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import LeafletBrandMarkerCar from "../maps/osm/BrandMarkerCar";
import { FitBounds as LeafletFitBounds } from "react-leflet-map-components";
import OrderActivitiesTimeline from "../orders-core/OrderActivitiesTimeline";

const INFO_TAB = "info";
const MAP_TAB = "map";
const HISTORY_TAB = "history";

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      mapProvider: getMapProvider(state),
      marketplaceId: getAppMarketplace(state),
      getLocalisationMessage,
    };
  }),
  useSheet({
    map: { height: "440px" },
    refreshActivities: { marginBottom: "8px" },
  }),
  withState("state", "setState", { tab: INFO_TAB }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
          createEventHandler();

        const orderStream = propsStream
          .distinctUntilKeyChanged("id")
          .switchMap((props) =>
            props
              .getOrder(props.id, { marketplace_id: props.marketplaceId })
              .catch((error) => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.get("data")),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(orderStream, (props, response) => ({
          ...props,
          response,
          onRequestRefresh,
        }));
      },
      (propsStream) => {
        const {
          handler: onRequestRefreshActivities,
          // eslint-disable-next-line no-unused-vars
          stream: onRequestRefreshActivitiesStream,
        } = createEventHandler();

        const driverLocationStream = propsStream
          .distinctUntilKeyChanged("response", isEqualData)
          .filter((props) => props.response.getIn(["payload", "id"]) > 0)
          .switchMap(
            (props) =>
              props
                .getOrderDriver(props.id)
                .takeLast(1)
                .catch((error) => Observable.of({ error })),
            (props, driver) => ({ ...props, driver }),
          )
          .switchMap((props) =>
            props.driver.error
              ? Observable.of(null)
              : props
                  .getOrderDriverLocation(props.id)
                  .takeLast(1)
                  .map(fp.flow(fp.get("payload.data"), fromJS))
                  .retryWhen((observer) => observer.delay(5 * 1000))
                  .repeatWhen((observer) => observer.delay(60 * 1000)),
          )
          .startWith(null)
          .distinctUntilChanged(isEqualData);

        const activitiesResponseStream = propsStream
          .distinctUntilKeyChanged("response", isEqualData)
          .filter((props) => props.response.getIn(["payload", "id"]) > 0)
          .switchMap((props) =>
            props
              .getOrderActivities(
                props.id,
                props.response.getIn(["payload", "order_number"]),
              )
              .catch((error) => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", mapOrderNotesAndHistoryToActivities),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            driverLocationStream,
            activitiesResponseStream,
            (props, driverLocation, activitiesResponse) => ({
              ...props,
              driverLocation,
              onRequestRefreshActivities,
              activities: activitiesResponse.get("payload"),
              activitiesLoading: activitiesResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

OrderTrackItem.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  response: PropTypes.instanceOf(Map),
  driverLocation: PropTypes.instanceOf(Map),

  activities: PropTypes.instanceOf(List),
  activitiesLoading: PropTypes.bool,
  onRequestRefreshActivities: PropTypes.func,

  id: PropTypes.string.isRequired,
  marketplaceId: PropTypes.number,

  className: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  getOrderActivities: PropTypes.func.isRequired,
  getOrderDriver: PropTypes.func.isRequired,
  getOrderDriverLocation: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  mapProvider: PropTypes.string,
  setLocationQuery: PropTypes.any,
};

function OrderTrackItem(props) {
  const { state, response, classes, getLocalisationMessage } = props;
  const tabs = [
    { label: getLocalisationMessage("info", "Info"), value: INFO_TAB },
    { label: getLocalisationMessage("map", "Map"), value: MAP_TAB },
    { label: getLocalisationMessage("history", "History"), value: HISTORY_TAB },
  ];

  const pickupMarkerPosition = {
    lat: response.getIn(["payload", "locations", 0, "lat"]),
    lng: response.getIn(["payload", "locations", 0, "lon"]),
  };

  const dropoffMarkerPosition = {
    lat: response.getIn(["payload", "locations", 1, "lat"]),
    lng: response.getIn(["payload", "locations", 1, "lon"]),
  };

  const driverMarkerPosition = !props.driverLocation
    ? null
    : {
        lat: props.driverLocation.get("lat"),
        lng: props.driverLocation.get("lon"),
      };

  const orderNumber = response.getIn(["payload", "order_number"]);
  const reverseOrderNumber = response.getIn(
    ["payload", "reverse_order_number"],
    false,
  );

  return (
    <Card className={props.className}>
      <CardHeader
        title={
          <h4 style={{ textAlign: "center" }}>
            {getLocalisationMessage("tracking_id", "TRACKING ID")} :{" "}
            {orderNumber || props.id}
          </h4>
        }
        subtitle={
          response.getIn(["payload", "supplier", "phone"]) && (
            <div>
              <p style={{ textAlign: "center" }}>
                {getLocalisationMessage(
                  "use_this_number_for_all_inquiries_about_your_shipment",
                  "Use this number for all inquiries about your shipment.",
                )}
              </p>
              <FlexBox align="center">
                <FlexBox flex={true} direction="column">
                  <span>
                    {getLocalisationMessage("courier", "Courier")}:{" "}
                    <Text type={PRIMARY}>
                      {response.getIn(["payload", "supplier", "name"])}
                    </Text>
                  </span>
                  <span>
                    {getLocalisationMessage("hotline", "Hotline")}:{" "}
                    <TextWithLink
                      target="_blank"
                      type={PRIMARY}
                      to={sprintf(
                        "tel:%s",
                        response.getIn(["payload", "supplier", "phone"]),
                      )}
                    >
                      {response.getIn(["payload", "supplier", "phone"])}
                    </TextWithLink>
                  </span>
                </FlexBox>
                {reverseOrderNumber && (
                  <FlexBox>
                    <ListItem>
                      <ListItemText
                        primary={getLocalisationMessage(
                          "shipping_type",
                          "Shipping Type",
                        )}
                        secondary={
                          <div>
                            <span>
                              {getLocalisationMessage(
                                REVERSE_ORDER,
                                "Reverse Shipping",
                              )}
                            </span>
                            <TextWithLink
                              onClick={() =>
                                props.setLocationQuery(
                                  fp.set("id", reverseOrderNumber),
                                )
                              }
                              style={{ color: "#9c0008", cursor: "pointer" }}
                            >
                              {" : "}
                              {reverseOrderNumber}
                            </TextWithLink>
                          </div>
                        }
                        secondaryLines={1}
                      />
                    </ListItem>
                  </FlexBox>
                )}
              </FlexBox>
            </div>
          )
        }
      />

      <Tabs
        width={120}
        tabs={tabs}
        value={state.tab}
        onChange={(e, tab) => props.setState(fp.set("tab", tab))}
      />

      <CardContent>
        <Collapse isOpened={true}>
          {response.get("pending") ? (
            <FlexBox justify="center">
              <CircularProgress />
            </FlexBox>
          ) : response.get("error") ? (
            <FlexBox justify="center">
              <p>
                {getLocalisationMessage(
                  "failed_to_load_order_do_you_want_to",
                  "Failed to load order. Do you want to",
                )}{" "}
                <LinkButton onClick={props.onRequestRefresh}>
                  {getLocalisationMessage("retry", "retry")}
                </LinkButton>
                ?
              </p>
            </FlexBox>
          ) : state.tab === INFO_TAB ? (
            <div>
              <ListItem>
                <ListItemText
                  primary={getLocalisationMessage("status", "Status")}
                  secondary={formatOrderStatusCodeForLocalisation(
                    response.getIn(["payload", "status"]),
                    getLocalisationMessage,
                  )}
                />
                <ListItemIcon>
                  <IconButton onClick={props.onRequestRefresh}>
                    <Refresh />
                  </IconButton>
                </ListItemIcon>
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={getLocalisationMessage(
                    "delivery_service",
                    "Delivery Service",
                  )}
                  secondary={response.getIn(["payload", "package", "name"])}
                  secondaryLines={2}
                />
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemText
                  primary={getLocalisationMessage("sender_address")}
                  secondary={response.getIn([
                    "payload",
                    "locations",
                    0,
                    "address",
                  ])}
                  secondaryLines={2}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={getLocalisationMessage("dropoff_to", "Dropoff To")}
                  secondary={response.getIn([
                    "payload",
                    "locations",
                    1,
                    "address",
                  ])}
                  secondaryLines={2}
                />
              </ListItem>
            </div>
          ) : state.tab === MAP_TAB ? (
            <div>
              <LeafletMapWrapper className={classes.map} track={true}>
                <LeafletBrandMarker position={pickupMarkerPosition} />
                <LeafletBrandMarker
                  position={dropoffMarkerPosition}
                  accent={false}
                />
                {isValidCoordinates(driverMarkerPosition) ? (
                  <div>
                    <LeafletBrandMarkerCar position={driverMarkerPosition} />

                    <LeafletFitBounds
                      latLngBounds={[
                        driverMarkerPosition,
                        pickupMarkerPosition,
                        dropoffMarkerPosition,
                      ]}
                    />
                  </div>
                ) : (
                  <LeafletFitBounds
                    latLngBounds={[pickupMarkerPosition, dropoffMarkerPosition]}
                  />
                )}
              </LeafletMapWrapper>
            </div>
          ) : (
            <FlexBox direction="column">
              <FlexBox justify="flex-end" className={classes.refreshActivities}>
                <Button
                  disabled={props.activitiesLoading}
                  onClick={props.onRequestRefreshActivities}
                >
                  {getLocalisationMessage("refresh", "Refresh")}
                </Button>
              </FlexBox>

              {props.activitiesLoading ? (
                <LinearProgress />
              ) : (
                <OrderActivitiesTimeline
                  hideBatchDetails={true}
                  showWarehouse={true}
                  showCallLogUrl={false}
                  activities={props.activities}
                />
              )}
            </FlexBox>
          )}
        </Collapse>
      </CardContent>

      <CardActions>
        <Button onClick={props.onDismiss}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
      </CardActions>
    </Card>
  );
}

export default enhancer(OrderTrackItem);
