import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { savePublicCustomerFeedback } from "../../api/shared/OrderApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import FeedbackForm from "../../components/feedback-dialog/FeedbackForm";
import feedbackIcon from "../../components/feedback-dialog/assets/feedback-icon.svg";

const LOCATION_PAGE = "location";
const SUCCESS_PAGE = "success";

const enhancer = compose(
  useSheet({
    progress: {
      display: "flex",
      paddingTop: "24px",
      paddingBottom: "12px",
      alignItems: "center",
      justifyContent: "center",
      flex: "1 1 0%",
    },
    paper: { width: "330px", minHeight: "200px" },
    title: {
      backgroundColor: "#2e3359",
      color: "#ffffff",
      padding: "20px",
    },
    container: { height: "100%" },
    notFound: { height: "80px", width: "80px", color: "#f44336" },
    success: {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      textAlign: "center",
    },
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showErrorMessage,
      showSuccessMessage,
    };
  }),
  withState("page", "onSetPage", LOCATION_PAGE),
  withState("pageTitle", "onSetPageTitle", ""),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .combineLatest(
            propsStream
              .map(fp.flow(fp.get("params.orderNumber"), fp.toString))
              .distinctUntilChanged(isEqualData),
            (props, orderNumber) => ({
              ...props,
              orderNumber,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream => {
        const sideEffectsStream = propsStream
          .distinctUntilKeyChanged(isEqualData)
          .do(props => {
            if (props.page !== SUCCESS_PAGE) {
              props.onSetPage(LOCATION_PAGE);
              props.onSetPageTitle(
                props.getLocalisationMessage("your_feedback", "Your Feedback"),
              );
            }
          })
          .startWith(null);

        return propsStream
          .combineLatest(sideEffectsStream, props => ({
            ...props,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
  withHandlers({
    onSubmitCustomerFeedback: props => values =>
      savePublicCustomerFeedback(props.orderNumber, values).catch(
        ResponseError.throw,
      ),

    onSubmitSuccessCustomerFeedback: props => () => {
      props.onSetPageTitle(
        props.getLocalisationMessage("thank_you", "Thank you"),
      );
      props.onSetPage(SUCCESS_PAGE);
    },
  }),
);

CustomerPublicFeedbackContainer.propTypes = {
  classes: PropTypes.object,
  isMaskedPhoneLoading: PropTypes.bool,
  isPhoneValidation: PropTypes.bool,
  orderNumber: PropTypes.string,
  showErrorMessage: PropTypes.func,
  page: PropTypes.string,
  pageTitle: PropTypes.string,
  phoneNumber: PropTypes.string,
  maskedPhone: PropTypes.string,
  onSubmitPhoneNumber: PropTypes.func,
  onSubmitSuccessPhoneNumber: PropTypes.func,
  onSubmitCustomerFeedback: PropTypes.func,
  onSubmitSuccessCustomerFeedback: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerPublicFeedbackContainer(props) {
  const { classes } = props;

  return (
    <FlexBox container={8} flex={true} className={classes.container}>
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} align="center" justify="center">
          <FlexBox
            element={<Paper />}
            gutter={24}
            className={classes.paper}
            direction="column"
          >
            <PageLoading isLoading={props.isMaskedPhoneLoading} />

            <FlexBox className={classes.title}>
              <h5>{props.pageTitle}</h5>
            </FlexBox>

            {Boolean(
              Boolean(props.page !== SUCCESS_PAGE) ||
                Boolean(props.orderNumber && props.page === LOCATION_PAGE),
            ) && (
              <FeedbackForm
                initialValues={{ score: 3 }}
                onSubmit={props.onSubmitCustomerFeedback}
                onSubmitSuccess={props.onSubmitSuccessCustomerFeedback}
                onSubmitFail={props.showErrorMessage}
              />
            )}

            {props.page === SUCCESS_PAGE && (
              <FlexBox direction="column">
                <FlexBox justify="center" flex={true}>
                  <Avatar
                    src={feedbackIcon}
                    size={100}
                    backgroundColor="#fff"
                  />
                </FlexBox>
                <FlexBox
                  justify="center"
                  flex={true}
                  className={classes.success}
                >
                  {props.getLocalisationMessage(
                    "thank_you_for_your_feedback",
                    "Thank you for your feedback",
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerPublicFeedbackContainer);
