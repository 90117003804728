import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import spring from "react-motion/lib/spring";
import Motion from "react-motion/lib/Motion";
import presets from "react-motion/lib/presets";
import windowScrollHandler from "../../helpers/WindowScrollHandler";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  useSheet({
    root: {
      right: "24px",
      bottom: "24px",
      position: "fixed",
      zIndex: 1300,
    },
  }),
  windowScrollHandler(fp.pick(["scrollingFromTop"]), { delay: 100 }),
  withTheme,
);

PageFab.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,
  theme: PropTypes.object,
  scrollingFromTop: PropTypes.bool,
  children: PropTypes.node,
  autoHide: PropTypes.bool,
  className: PropTypes.string,
};

PageFab.defaultProps = {
  autoHide: true,
};

const defaultState = { y: 0 };
const hiddenState = { y: spring(128, presets.wobbly) };
const visibleState = { y: spring(0, presets.wobbly) };

function PageFab(props) {
  const {
    sheet,
    classes,
    scrollingFromTop,
    autoHide,
    className,
    children,
    theme,
    ...restProps
  } = props;

  return (
    <Motion
      defaultStyle={defaultState}
      style={autoHide && scrollingFromTop ? hiddenState : visibleState}
    >
      {state => (
        <IconButton
          {...restProps}
          className={cx(classes.root, className)}
          style={{
            backgroundColor: theme.palette.buttonColor,
            color: "#ffffff",
            transform: `translate3d(0, ${state.y}px, 0)`,
          }}
        >
          {children}
        </IconButton>
      )}
    </Motion>
  );
}

export default enhancer(PageFab);
