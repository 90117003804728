import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Add } from "@material-ui/icons";
import { loadBankList } from "../../actions/AdminBankActions";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getBankList } from "../../api/customer/CustomerFinanceApi";
import Toggle from "../../components/form/Toggle";
import CustomerBankEditDialogWrapper from "../../wrappers/customer/CustomerBankEditDialogWrapper";
import CustomerBankCreateDialogWrapper from "../../wrappers/customer/CustomerBankCreateDialogWrapper";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import BankList from "../../components/bank-core/BankList";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_SUPPLIER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import FlexBox from "../../components/ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "10px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      loadBankList,
    };
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map(query => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter]) =>
        getBankList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .startWith({})
      .map(response => fromJS(response))
      .map(response =>
        Map({
          pending: response.get("pending", false),
          total: response.getIn(["payload", "data", "total"], 0),
          list: response.getIn(["payload", "data", "list"], List()),
        }),
      );

    return propsStream
      .combineLatest(
        filterStream,
        listResponseStream,
        (props, filter, listResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: listResponse.get("list"),
          total: listResponse.get("total"),
          isLoading: listResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

CustomerBankListContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerBankListContainer(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <CustomerAppLayout
      slug="banks"
      title={getLocalisationMessage("banks", "Banks")}
      appBarRightAction={
        <FlexBox
          flex={true}
          align="center"
          justify="flex-end"
          className={classes.appBarRightAction}
        >
          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </FlexBox>
      }
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <CustomerBankCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <CustomerBankEditDialogWrapper
        bankId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view"))}
      />

      <BankList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        cardActionIcons={
          <div>
            <Tooltip
              title={getLocalisationMessage(
                "create_new_bank",
                "Create New Bank",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_SUPPLIER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerBankListContainer);
