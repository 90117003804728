import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { validateString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({ padding: { paddingTop: 10 } }),
  reduxForm({
    form: "CustomerBankForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("enter_name", "Enter name")
        : null,
      accountNumber: validateString(values.accountNumber)
        ? props.getLocalisationMessage(
            "enter_account_number",
            "Enter Account Number",
          )
        : null,
      beneficiaryName: validateString(values.beneficiaryName)
        ? props.getLocalisationMessage(
            "enter_beneficiary_name",
            "Enter Beneficiary Name",
          )
        : null,
      swiftCode: validateString(values.swiftCode)
        ? props.getLocalisationMessage("enter_swift_code", "Enter Swift Code")
        : null,
      address: validateString(values.address)
        ? props.getLocalisationMessage("enter_address", "Enter Address")
        : null,
    }),
  }),
);

CustomerBankForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerBankForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "bank_name",
                    "Bank Name",
                  )} *`}
                />

                <FormTextField
                  name="branch"
                  fullWidth={true}
                  label={getLocalisationMessage("bank_branch", "Bank Branch")}
                />

                <FormTextField
                  name="beneficiaryName"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "bank_beneficiary_name",
                    "Bank Beneficiary Name",
                  )} *`}
                />

                <FormTextField
                  name="swiftCode"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "swift_code",
                    "Swift Code",
                  )} *`}
                />

                <FormTextField
                  name="accountNumber"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "account_number",
                    "Account Number",
                  )} *`}
                />

                <FormTextField
                  name="ibanCode"
                  fullWidth={true}
                  label={getLocalisationMessage("iban_code", "IBAN Code")}
                />

                <FormTextField
                  name="address"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "address_1",
                    "Address 1",
                  )} *`}
                />

                <FormTextField
                  name="address2"
                  fullWidth={true}
                  label={getLocalisationMessage("address_2", "Address 2")}
                />

                <FlexBox flex={true} className={classes.padding}>
                  <FormCheckbox
                    name="default"
                    label={getLocalisationMessage("is_default", "Is Default")}
                  />
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(CustomerBankForm);
