import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { Motorcycle, DirectionsCar, LocalShipping } from "@material-ui/icons";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import Text, { MUTED, SMALL, DANGER, DELETED } from "../../ui-core/Text";
import PriceWrapper from "../../ui-core/PriceWrapper";
import DistanceWrapper from "../../ui-core/DistanceWrapper";
import { getValue } from "../../../helpers/DataUtils";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { URGENT } from "../../../constants/CourierTypes";
import { BIKE, SEDAN } from "../../../constants/VehicleTypes";
import { primary1 } from "../../../../shared/theme/main-theme";

const formatCountry = fp.flow(
  c => getValue(c, "name"),
  fp.trim,
  c => (fp.toLower(c) === "united arab emirates" ? "UAE" : c),
);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    vehicleIcon: {
      width: "18px",
      height: "18px",
      color: primary1,
      marginLeft: "6px",
      verticalAlign: "sub",
    },
  }),
);

VehicleIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
};

function VehicleIcon(props) {
  switch (props.type) {
    case BIKE:
      return <Motorcycle className={props.className} />;
    case SEDAN:
      return <DirectionsCar className={props.className} />;
    default:
      return <LocalShipping className={props.className} />;
  }
}

OrderCreateWizardStepHowItem.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  pickupCountry: PropTypes.instanceOf(Map).isRequired,
  dropoffCountry: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepHowItem(props) {
  const { item } = props;

  if (!item) {
    return null;
  }

  const pickupCountry = formatCountry(props.pickupCountry);
  const dropoffCountry = formatCountry(props.dropoffCountry);

  const isUrgent = fp.toLower(item.get("courier_type")) === URGENT;
  const vehicleType = item.get("vehicle_type");
  const oldTotal = item.getIn(["price", "old_total"]);
  const currencyCode = item.getIn(["price", "currency", "code"]);

  return (
    <OrderCreateWizardStepDetails
      label={props.label}
      onClick={props.onClick}
      avatar={<Avatar src={item.get("courier_type_icon")} />}
      primaryText={
        <div>
          {item.get("delivery_label")}

          {Boolean(vehicleType && isUrgent) && (
            <VehicleIcon
              type={vehicleType}
              className={props.classes.vehicleIcon}
            />
          )}
        </div>
      }
      rightPrimaryText={
        <Text type={SMALL}>
          {isUrgent ? (
            <span>
              <PriceWrapper
                price={item.getIn(["price", "base_rate"])}
                code={currencyCode}
              />
              {" + "}
              <PriceWrapper
                price={item.getIn(["price", "moving_rate"])}
                code={currencyCode}
              />
              {" * "}
              <DistanceWrapper
                distance={item.getIn([
                  "price",
                  "direction_data",
                  "distance",
                  "value",
                ])}
              />
            </span>
          ) : (
            <span>
              <PriceWrapper
                price={item.getIn(["price", "base_rate"])}
                code={currencyCode}
              />{" "}
              {pickupCountry === dropoffCountry
                ? `${props.getLocalisationMessage(
                    "all_over",
                    "All over",
                  )} ${dropoffCountry}`
                : `${props.getLocalisationMessage(
                    "to",
                    "to",
                  )} ${dropoffCountry}`}
            </span>
          )}
        </Text>
      }
      secondaryText={item.get("delivery_label_helper")}
      rightSecondaryText={
        <div>
          {oldTotal > 0 && (
            <PriceWrapper
              type={[SMALL, DELETED, MUTED]}
              price={oldTotal}
              code={currencyCode}
            />
          )}{" "}
          <PriceWrapper
            type={DANGER}
            price={item.getIn(["price", "total"])}
            code={currencyCode}
          />
        </div>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepHowItem);
