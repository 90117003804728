import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizard");

const enhancer = compose(
  connect(state => ({
    menu: valueSelector(state, "menu"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepWhatDetails.propTypes = {
  menu: PropTypes.instanceOf(Map),
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderCreateWizardStepWhatDetails(props) {
  return (
    props.menu && (
      <OrderCreateWizardStepDetails
        label={props.getLocalisationMessage("what", "What")}
        onClick={props.onClick}
        primaryText={<div>{props.menu.get("title")}</div>}
        avatar={
          <Avatar
            src={props.menu.get("icon")}
            style={{
              backgroundColor: "transparent",
            }}
          />
        }
      />
    )
  );
}

export default enhancer(OrderCreateWizardStepWhatDetails);
