import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { Placemark } from "react-yandex-maps";
import { MyLocation } from "@material-ui/icons";
import Geolocation from "../../maps/Geolocation";
import YandexMapWrapper from "../../maps/YandexMapWrapper";
import { isValidCoordinates } from "../../../helpers/ValidateUtils";
import { primary1 } from "../../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet({
    root: {
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      top: 0,
      display: "flex",
      position: "absolute",
    },
    map: { flex: "1 1 0%", zIndex: 1, position: "relative" },
    mapNextButton: {
      color: "#fff",
      textTransform: "uppercase",
      position: "absolute",
      bottom: "10%",
      left: "50%",
      minWidth: "500px",
      width: "500px",
      zIndex: "1000",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    point: {
      position: "absolute",
      padding: 10,
      right: 0,
      bottom: 0,
      zIndex: 9,
    },
  }),
  reduxForm({
    form: "OrderCreateWizardPickupLocationPin",
    enableReinitialize: true,
  }),
  formValues({ location: "location" }),
);

OrderCreateWizardYandexBackgroundMap.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  valid: PropTypes.bool,
  location: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitButtonView: PropTypes.bool,
};

function OrderCreateWizardYandexBackgroundMap(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <YandexMapWrapper
        className={classes.map}
        zoom={15}
        mapCenter={props.location}
        onClick={event => {
          const x = event.get("coords");
          props.change("location", {
            lat: x[0],
            lng: x[1],
          });
        }}
      >
        {isValidCoordinates(props.location) && (
          <Placemark geometry={[props.location.lat, props.location.lng]} />
        )}
      </YandexMapWrapper>

      {props.valid && isValidCoordinates(props.location) && (
        <div>
          <Button
            className={classes.mapNextButton}
            buttonStyle={{
              color: "#fff",
              textTransform: "uppercase",
              lineHeight: "48px",
              height: "48px",
            }}
            overlayStyle={{ height: "48px" }}
            fullWidth={true}
            primary={true}
            onClick={props.handleSubmit}
          >
            Next
          </Button>
        </div>
      )}

      <div className={classes.point}>
        <Geolocation
          onSuccess={({ coords: { latitude, longitude } }) => {
            props.change("location", { lat: latitude, lng: longitude });
          }}
          render={({ getCurrentPosition }) => (
            <div>
              <Button
                mini={true}
                backgroundColor="#fff"
                onClick={getCurrentPosition}
              >
                <MyLocation style={{ fill: primary1 }} />
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default enhancer(OrderCreateWizardYandexBackgroundMap);
