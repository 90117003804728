import { Observable } from "rxjs";
import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Avatar, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import { isEqualData } from "../../../helpers/DataUtils";
import { getMessage } from "../../../reducers/LocalizationReducer";
import OrderCreateNoNSAArea from "../../order-create-wizard-new/internal/OrderCreateNoNSAArea";

const valueSelector = formValueSelector("OrderCreateWizard");

const enhancer = compose(
  useSheet({
    progress: { display: "flex", paddingTop: "24px", justifyContent: "center" },
  }),
  reduxForm({
    form: "OrderCreateWizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    initialMenuId: valueSelector(state, "initialMenuId"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .filter(
          props => props.initialMenuId > 0 && !props.packageMenu.isEmpty(),
        )
        .take(1)
        .do(props => {
          const item = props.packageMenu.find(
            v => v.get("menu_id") === props.initialMenuId,
          );

          props.change("initialMenuId", null);

          if (item) {
            props.change("menu", item);
            props.handleSubmit();
          }
        }),
      propsStream
        .filter(
          props =>
            !props.initialMenuId &&
            !props.packageMenu.isEmpty() &&
            props.packageMenu.size === 1,
        )
        .take(1)
        .do(props => {
          const item = props.packageMenu.get(0);

          if (item) {
            props.change("menu", item);
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
  // mapPropsStream(propsStream =>
  //   propsStream.combineLatest(
  //     propsStream
  //       .filter(
  //         props => props.initialMenuId > 0 && !props.packageMenu.isEmpty(),
  //       )
  //       .take(1)
  //       .do(props => {
  //         const item = props.packageMenu.find(
  //           v => v.get("menu_id") === props.initialMenuId,
  //         );
  //
  //         props.change("initialMenuId", null);
  //
  //         if (item) {
  //           props.change("menu", item);
  //           props.handleSubmit();
  //         }
  //       })
  //       .startWith(null),
  //     props => props,
  //   ),
  // ),
);

OrderCreateWizardStepWhatForm.propTypes = {
  classes: PropTypes.object,
  showContent: PropTypes.bool,
  onSubmit: PropTypes.func,
  packageMenuFetching: PropTypes.bool.isRequired,
  packageMenu: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
};

function OrderCreateWizardStepWhatForm(props) {
  return (
    <OrderCreateWizardStepCard
      title={props.getLocalisationMessage(
        "what_are_we_shipping",
        "What are we shipping?",
      )}
      showContent={props.showContent}
    >
      {props.packageMenuFetching ? (
        <div className={props.classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.packageMenu.size > 0 ? (
            props.packageMenu
              .map(item => (
                <OrderCreateWizardStepDetails
                  key={item.get("menu_id")}
                  primaryText={item.get("title")}
                  avatar={
                    <Avatar
                      src={item.get("icon")}
                      style={{
                        backgroundColor: "transparent",
                      }}
                    />
                  }
                  onClick={() => {
                    props.change("menu", item);
                    props.handleSubmit();
                  }}
                />
              ))
              .toArray()
          ) : (
            <OrderCreateNoNSAArea />
          )}
        </div>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepWhatForm);
