import { Observable } from "rxjs";
import React from "react";
import Immutable, { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Report } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { isNewVersion } from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { DROP, PICKUP } from "../../constants/TimeSlotType";
import {
  ORDER_RESCHEDULE_TIMESLOT_URL,
  ORDER_RESCHEDULE_TIMESLOT_V2_URL,
} from "../../constants/CustomerPathConstants";
import { SIGN_IN_URL } from "../../constants/DashboardPathConstants";
import { validateRecipientOrder } from "../../api/shared/OrderApi";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import {
  getPublicOrder,
  updatePublicOrderPickupLocation,
  updatePublicOrderDropoffLocation,
} from "../../api/shared/PublicOrderApi";
import {
  getTimeSlots,
  updateOrderPickupTimeslot,
  updateOrderDeliveryTimeslot,
} from "../../api/customer/CustomerTimeSlotApi";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import DescriptionTerm from "../../components/ui-core/DescriptionTerm";
import OrderAddressForm from "../../components/orders-core/OrderAddressForm";
import RescheduleTimeslotForm from "../../components/time-slots-core/RescheduleTimeslotForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { isTokenValid } from "../../../shared/reducers/AuthReducer";
import Background from "../../components/time-slots-core/assets/background.png";

const RESCHEDULE_PICKUP = "reschedule_pick_up";

const SUCCESS_PAGE = "success";
const TIMESLOT_PAGE = "timslot";
const LOCATION_PAGE = "location";

const enhancer = compose(
  useSheet({
    progress: {
      display: "flex",
      paddingTop: "24px",
      paddingBottom: "12px",
      alignItems: "center",
      justifyContent: "center",
      flex: "1 1 0%",
    },
    paper: { width: "400px", minHeight: "500px" },
    title: {
      backgroundColor: "#2e3359",
      color: "#ffffff",
      padding: "20px",
    },
    container: { height: "100%" },
    notFound: { height: "80px", width: "80px", color: "#f44336" },
    successContainer: {
      backgroundImage: `url(${Background})`,
      margin: "-8px",
      minHeight: "533px",
    },
    success: {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      textAlign: "center",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAuthorized: isTokenValid(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withState("page", "onSetPage", LOCATION_PAGE),
  withState("pageTitle", "onSetPageTitle", "Confirm Location"),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .combineLatest(
            propsStream
              .map(fp.flow(fp.get("location.query.action"), fp.toString))
              .distinctUntilChanged(isEqualData),
            (props, timeslotAction) => ({
              ...props,
              timeslotAction,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream =>
        propsStream
          .combineLatest(
            propsStream
              .map(fp.flow(fp.get("params.orderNumber"), fp.toFinite))
              .distinctUntilChanged(isEqualData),
            (props, orderNumber) => ({
              ...props,
              orderNumber,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream => {
        const validateRecipientOrderStream = propsStream
          .distinctUntilKeyChanged("orderNumber")
          .filter(props => props.isAuthorized && props.orderNumber > 0)
          .switchMap(props =>
            validateRecipientOrder(props.orderNumber).catch(error =>
              Observable.of({ error }),
            ),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(fp.get("data.has_session"), Boolean),
              ),
              fromJS,
            ),
          );

        return propsStream
          .combineLatest(
            validateRecipientOrderStream,
            (props, validateRecipient) => ({
              ...props,
              isLoadingValidRecipient: validateRecipient.get("pending"),
              isValidRecipient: validateRecipient.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const publicOrderResponseStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(props => props.isAuthorized && !props.isValidRecipient)
          .switchMap(props =>
            getPublicOrder(props.orderNumber)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          );

        const marketplaceStream = getMarketplace()
          .takeLast(1)
          .catch(() => Observable.of({}))
          .map(
            fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS),
          );

        return propsStream
          .combineLatest(
            publicOrderResponseStream,
            marketplaceStream,
            (props, publicOrderResponse, marketplace) => ({
              ...props,
              onRequestRefresh,
              marketplace,
              order: publicOrderResponse.get("payload"),
              isLoadingOrder: publicOrderResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const initialValuesStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(props => props.order.size > 0)
          .map(
            fp.flow(
              props =>
                props.timeslotAction === RESCHEDULE_PICKUP
                  ? props.order.getIn(["locations", 0])
                  : props.order.getIn(["locations", 1]),
              location => ({
                location: {
                  lat: location.get("lat"),
                  lng: location.get("lon"),
                  address: location.get("address"),
                },
                addressCity: location.get("address_city"),
                details: location.get("details"),
                postcode: location.get("postcode"),
              }),
            ),
          )
          .startWith({});

        return propsStream
          .combineLatest(initialValuesStream, (props, initialValues) => ({
            ...props,
            initialValues,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onSelectDate,
          stream: onSelectDateStream,
        } = createEventHandler();

        return propsStream
          .combineLatest(
            onSelectDateStream.filter(date => Boolean(date)).startWith(null),
            (props, timeslotDate) => ({
              ...props,
              timeslotDate,
              onSelectDate,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const timeslotsResponseStream = propsStream
          .distinctUntilKeyChanged("timeslotDate")
          .filter(props =>
            Boolean(
              !props.isLoadingOrder &&
                props.order.size > 0 &&
                props.timeslotDate &&
                props.page === TIMESLOT_PAGE,
            ),
          )
          .switchMap(props =>
            getTimeSlots(
              new DataListFilter().setValueMap({
                package_id: props.order.getIn(["package", "id"]),
                lat: props.order.getIn(["locations", 0, "lat"]),
                lng: props.order.getIn(["locations", 0, "lon"]),
                timeslot_type:
                  props.timeslotAction === RESCHEDULE_PICKUP ? PICKUP : DROP,
                date: formatDateToUrl(props.timeslotDate),
              }),
            ).catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data.list"), fp.toArray)),
              fromJS,
            ),
          );

        return propsStream
          .combineLatest(
            timeslotsResponseStream,
            (props, timeslotsResponse) => ({
              ...props,
              timeSlotList: timeslotsResponse.get("payload"),
              timeSlotsFetching: timeslotsResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

CustomerRescheduleTimeSlotContainer.propTypes = {
  classes: PropTypes.object,
  onSelectDate: PropTypes.func,
  timeSlotList: PropTypes.instanceOf(List),
  timeSlotsFetching: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  isLoadingOrder: PropTypes.bool,
  orderNumber: PropTypes.number,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  timeslotDate: PropTypes.instanceOf(Date),
  timeslotAction: PropTypes.string,
  isAuthorized: PropTypes.bool,
  page: PropTypes.string,
  onSetPage: PropTypes.func,
  initialValues: PropTypes.object,
  pageTitle: PropTypes.string,
  onSetPageTitle: PropTypes.func,
  isLoadingValidRecipient: PropTypes.bool,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
};

function CustomerRescheduleTimeSlotContainer(props) {
  const { classes, getLocalisationMessage } = props;
  const isLoading = Boolean(
    props.isLoadingOrder || props.isLoadingValidRecipient,
  );

  return (
    <FlexBox container={8} flex={true} className={classes.container}>
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} align="center" justify="center">
          <FlexBox
            element={<Paper />}
            gutter={24}
            className={classes.paper}
            direction="column"
          >
            <PageLoading isLoading={isLoading} />

            <Redirect
              to={updateQuery(SIGN_IN_URL, {
                next: `${ORDER_RESCHEDULE_TIMESLOT_URL}${props.orderNumber}?action=${props.timeslotAction}`,
              })}
              when={!props.isAuthorized}
            />

            <Redirect
              to={updateQuery(
                `${ORDER_RESCHEDULE_TIMESLOT_V2_URL}${props.orderNumber}?action=${props.timeslotAction}`,
              )}
              when={isNewVersion(props.marketplace)}
            />

            <FlexBox className={classes.title}>
              <h5>{props.pageTitle}</h5>
            </FlexBox>

            {Boolean(!props.isLoadingOrder && props.order.isEmpty()) && (
              <FlexBox flex={true} direction="column" align="center">
                <FlexBox gutter={8} flex={true} direction="column">
                  <FlexBox justify="center">
                    <Report className={classes.notFound} />
                  </FlexBox>
                  <FlexBox>
                    <h5>
                      {getLocalisationMessage(
                        "oops_we_couldnt_find_that_order",
                        "Oops, we couldn't find that order!",
                      )}
                    </h5>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}

            {Boolean(
              props.isAuthorized &&
                props.order.size > 0 &&
                !props.isLoadingOrder,
            ) && (
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  {props.page === LOCATION_PAGE && (
                    <FlexBox flex={true}>
                      <OrderAddressForm
                        initialValues={props.initialValues}
                        onSubmit={({ location, ...values }) => {
                          const orderLocation =
                            props.timeslotAction === RESCHEDULE_PICKUP
                              ? props.order.getIn(["locations", 0])
                              : props.order.getIn(["locations", 1]);

                          return props.timeslotAction === RESCHEDULE_PICKUP
                            ? updatePublicOrderPickupLocation(
                                props.orderNumber,
                                orderLocation
                                  .merge(
                                    toSnakeCase({
                                      ...values,
                                      lat: location.lat,
                                      lon: location.lng,
                                      address: location.address,
                                    }),
                                  )
                                  .toJS(),
                              ).catch(ResponseError.throw)
                            : updatePublicOrderDropoffLocation(
                                props.orderNumber,
                                orderLocation
                                  .merge(
                                    toSnakeCase({
                                      ...values,
                                      lat: location.lat,
                                      lon: location.lng,
                                      address: location.address,
                                    }),
                                  )
                                  .toJS(),
                              ).catch(ResponseError.throw);
                        }}
                        onSubmitSuccess={() => {
                          props.showSuccessMessage(
                            getLocalisationMessage(
                              "location_successfully_updated",
                              "Location Successfully Updated",
                            ),
                          );
                          props.onSetPageTitle(
                            getLocalisationMessage(
                              "location_successfully_updated",
                              "Location Successfully Updated",
                            ),
                          );
                          props.onSetPage(SUCCESS_PAGE);
                        }}
                        onSubmitFail={props.showErrorMessage}
                      />
                    </FlexBox>
                  )}

                  {props.page === SUCCESS_PAGE && (
                    <FlexBox flex={true} className={classes.successContainer}>
                      <FlexBox gutter={8} flex={true}>
                        <FlexBox flex={true} align="flex-end">
                          <FlexBox
                            gutter={8}
                            direction="column"
                            flex={true}
                            className={classes.success}
                          >
                            <FlexBox justify="flex-end">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  props.onSetPageTitle(
                                    getLocalisationMessage(
                                      "select_timeslot",
                                      "Select Timeslot",
                                    ),
                                  );
                                  props.onSetPage(TIMESLOT_PAGE);
                                }}
                              >
                                {getLocalisationMessage(
                                  "select_timeslot",
                                  "Select Timeslot",
                                )}
                              </Button>
                            </FlexBox>
                          </FlexBox>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  )}

                  {props.page === TIMESLOT_PAGE && (
                    <FlexBox flex={true} direction="column">
                      <FlexBox gutter={8}>
                        <FlexBox flex={true}>
                          <DescriptionTerm
                            title={getLocalisationMessage("address", "Address")}
                          >
                            {props.timeslotAction === RESCHEDULE_PICKUP
                              ? props.order.getIn(["locations", 0, "address"])
                              : props.order.getIn(["locations", 1, "address"])}
                          </DescriptionTerm>
                        </FlexBox>
                      </FlexBox>

                      <RescheduleTimeslotForm
                        initialValues={{
                          timeslotDate: props.timeslotDate || new Date(),
                        }}
                        onChange={values =>
                          props.onSelectDate(values.timeslotDate)
                        }
                        timeslotList={props.timeSlotList}
                        onSubmit={values =>
                          props.timeslotAction === RESCHEDULE_PICKUP
                            ? updateOrderPickupTimeslot(props.orderNumber, {
                                ...values.timeSlot.toJS(),
                                estimated_pickup_time: values.timeslotDate,
                              })
                                .then(() => {
                                  props.showSuccessMessage(
                                    getLocalisationMessage(
                                      "successfully_updated_pick_up_time",
                                      "Successfully Updated Pick up time",
                                    ),
                                  );
                                  props.onSetPage(SUCCESS_PAGE);
                                })
                                .catch(props.showErrorMessage)
                            : updateOrderDeliveryTimeslot(props.orderNumber, {
                                ...values.timeSlot.toJS(),
                                estimated_delivery_time: values.timeslotDate,
                              })
                                .then(() => {
                                  props.showSuccessMessage(
                                    getLocalisationMessage(
                                      "successfully_updated_drop_off_time",
                                      "Successfully Updated Drop off time",
                                    ),
                                  );
                                  props.onSetPage(SUCCESS_PAGE);
                                })
                                .catch(props.showErrorMessage)
                        }
                        onSubmitSuccess={() => {
                          props.onSetPageTitle(
                            getLocalisationMessage(
                              "timeslot_succesfully_updated",
                              "Timeslot Succesfully Updated!",
                            ),
                          );
                        }}
                        onDismiss={() => props.onSetPage(SUCCESS_PAGE)}
                        withTime={true}
                        timeSlotsFetching={props.timeSlotsFetching}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerRescheduleTimeSlotContainer);
