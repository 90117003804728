import React from "react";
import { List } from "immutable";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import FormDialog from "./FormDialog";
import FormSelectField from "./FormSelectField";
import { formatText } from "../../helpers/FormatUtils";

const enhancer = compose(
  withContext(
    {
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

FormCustomerDumbWarehouseDialog.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
};

function FormCustomerDumbWarehouseDialog(props) {
  const warehouses = List([
    { id: 10001, name: "Spinneys" },
    { id: 10002, name: "UnionCoop" },
  ]);

  return (
    <FormDialog
      form="FormCustomerDumbWarehouseDialog"
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    >
      <FormSelectField
        name="warehouse"
        fullWidth={true}
        options={warehouses}
        formatOption={x => formatText(x.name)}
        label="Warehouse"
      />
    </FormDialog>
  );
}

export default enhancer(FormCustomerDumbWarehouseDialog);
