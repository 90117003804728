import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormTextField from "./FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { validateEmailLocalisation } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  withTheme,
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
        color: props => props.theme.palette.primary1Color,
      },
    },
  }),
  reduxForm({
    form: "SendEmailsDialog",
    validate: (values, props) => ({
      emails: validateEmailLocalisation(
        values.emails,
        props.getLocalisationMessage,
      ),
    }),
  }),
);

SendEmailDialog.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SendEmailDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      className={classes.rootStyle}
      PaperProps={{
        style: {
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage(
          "send_invoice_to_email",
          "Send Invoice to Email",
        )}
      </DialogTitle>
      <DialogContent>
        <PageLoading isLoading={props.submitting} />

        <div style={{ maxWidth: "600px" }}>
          <FormTextField
            name="emails"
            fullWidth={true}
            label={getLocalisationMessage("enter_email", "Enter Email")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("send_email", "Send Email")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(SendEmailDialog);
