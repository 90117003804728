import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import reactHtmlParser from "react-html-parser";
import FlexBox from "../../ui-core/FlexBox";
import { getMessage } from "../../../reducers/LocalizationReducer";

const getValues = formValueSelector("OrderCreateWizardNew");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizardNew",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    values: getValues(
      state,
      "termsAndConditions",
      "pickupCountry",
      "dropoffCountry",
    ),
  })),
);

OrderCreateWizardStepTermsConditionsDialog.propTypes = {
  values: PropTypes.object,
  change: PropTypes.func,
  open: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  defaultTerms: PropTypes.instanceOf(Map),
};

function OrderCreateWizardStepTermsConditionsDialog(props) {
  const { open, getLocalisationMessage, defaultTerms } = props;

  return (
    <Dialog open={open} modal={true} autoScrollBodyContent={true}>
      <DialogContent>
        <FlexBox gutter={16} flex={true} direction="column">
          <div>{reactHtmlParser(defaultTerms.get("terms"))}</div>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            onClick={() => {
              props.change("termsAndConditions", true);
              props.onAccept();
            }}
          >
            {getLocalisationMessage("accept", "Accept")}
          </Button>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderCreateWizardStepTermsConditionsDialog);
