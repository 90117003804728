import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ADDRESS_BOOK_URL } from "../../constants/CustomerPathConstants";
import { createAddressBook } from "../../api/shared/CustomerAddressBookApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import AddressBookForm from "../../components/address-book-core/AddressBookForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

CustomerAddressBookCreate.propTypes = {
  setLocation: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerAddressBookCreate(props) {
  return (
    <CustomerAppLayout
      title={props.getLocalisationMessage(
        "create_address_book",
        "Create Address Book",
      )}
    >
      <AddressBookForm
        onSubmit={fp.flow(toSnakeCase, values =>
          createAddressBook(values).catch(ResponseError.throw),
        )}
        onSubmitSuccess={() => {
          props.setLocation(ADDRESS_BOOK_URL);
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerAddressBookCreate);
