import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import customize from "./assets/customize.png";

OrderCreateWizardStepMoreDetails.propTypes = {
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

export default function OrderCreateWizardStepMoreDetails(props) {
  return (
    <OrderCreateWizardStepDetails
      label={props.getLocalisationMessage("more", "More")}
      onClick={props.onClick}
      primaryText={props.getLocalisationMessage(
        "customized_information",
        "Customized Information",
      )}
      avatar={
        <Avatar
          src={customize}
          style={{
            backgroundColor: "transparent",
          }}
        />
      }
    />
  );
}
