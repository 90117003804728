import { Observable } from "rxjs";
import React from "react";
import { isAfter, isSameDay } from "date-fns";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import {
  DoneAll,
  AccessTime,
  AttachMoney,
  MoreHoriz,
} from "@material-ui/icons";
import OrderCreateWizardStepToForm from "./2-to/OrderCreateWizardStepToForm";
import OrderCreateWizardStepToDetails from "./2-to/OrderCreateWizardStepToDetails";
import OrderCreateWizardStepHowForm from "./4-how/OrderCreateWizardStepHowForm";
import OrderCreateWizardStepHowDetails from "./4-how/OrderCreateWizardStepHowDetails";
import OrderCreateWizardStepPayForm from "./5-pay/OrderCreateWizardStepPayForm";
import OrderCreateWizardStepPayDetails from "./5-pay/OrderCreateWizardStepPayDetails";
import OrderCreateWizardStepFromForm from "./1-from/OrderCreateWizardStepFromForm";
import OrderCreateWizardStepFromDetails from "./1-from/OrderCreateWizardStepFromDetails";
import OrderCreateWizardStepWhatForm from "./3-what/OrderCreateWizardStepWhatForm";
import OrderCreateWizardStepWhatDetails from "./3-what/OrderCreateWizardStepWhatDetails";
import OrderCreateWizardStepWhenForm from "./6-when/OrderCreateWizardStepWhenForm";
import OrderCreateWizardStepWhenDetails from "./6-when/OrderCreateWizardStepWhenDetails";
import OrderCreateWizardStepMoreForm from "./8-more/OrderCreateWizardStepMoreForm";
import OrderCreateWizardStepMoreDetails from "./8-more/OrderCreateWizardStepMoreDetails";
import OrderCreateWizardStepTermsDialog from "./9-terms/OrderCreateWizardStepTermsDialog";
import PageLoading from "../ui-core/PageLoading";
import OrderCreateWizardFab from "./internal/OrderCreateWizardFab";
import OrderCreateWizardCard from "./internal/OrderCreateWizardCard";
import OrderCreateWizardActions from "./internal/OrderCreateWizardActions";
import OrderCreateWizardOverlay from "./internal/OrderCreateWizardOverlay";
import OrderCreateWizardStepper from "./internal/OrderCreateWizardStepper";
import OrderCreateWizardStepCollectForm from "./7-collect/OrderCreateWizardStepCollectForm";
import OrderCreateWizardStepCollectDetails from "./7-collect/OrderCreateWizardStepCollectDetails";
import {
  getValue,
  isEqualData,
  isEqualDataIn,
  isShallowEqual,
} from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  isPositive,
  isValidDate,
  isBlankString,
  isValidCoordinates,
  validatePhoneNumberFori18n,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getCustomerId } from "../../reducers/CustomerReducer";
import {
  getMarketplaceId,
  marketplaceMandatoryFieldsDisabled,
} from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import { DROP, PICKUP } from "../../constants/TimeSlotType";
import { SENDER, RECIPIENT } from "../../constants/OrderPayerTypes";
import { CASH, CREDIT_BALANCE } from "../../constants/OrderPaymentTypes";
import { COD, SERVICE_CUSTOM } from "../../constants/OrderChargeItemTypes";
import { DO_NOT_DELIVER } from "../../constants/OrderRecipientNotAvailableActionTypes";
import { getCountryCenter } from "../../api/shared/CountryApi";
import { getPackageMenu } from "../../api/shared/PackageMenuApi";
import { getPackagePrices } from "../../api/shared/PackagePricesApi";
import { createAddressBook } from "../../api/shared/CustomerAddressBookApi";
import {
  WING_AE_ID,
  WING_SA_ID,
} from "../../../server/constants/MarketplaceId";

const getValues = getFormValues("OrderCreateWizard");

const sum = fp.rest(fp.flow(fp.map(fp.toFinite), fp.sum));

const PAGE_FROM = "from";
const PAGE_TO = "to";
const PAGE_WHAT = "what";
const PAGE_HOW = "how";
const PAGE_PAY = "pay";
const PAGE_WHEN = "when";
const PAGE_COLLECT = "collect";
const PAGE_MORE = "more";
const PAGE_FINAL = "final";

const pages = [
  PAGE_FROM,
  PAGE_TO,
  PAGE_WHAT,
  PAGE_HOW,
  PAGE_PAY,
  PAGE_WHEN,
  PAGE_COLLECT,
  PAGE_MORE,
  PAGE_FINAL,
];

const hasSameId = isEqualDataIn("id");

const orderToSnakeCase = fp.flow(
  fp.omit([
    "menu",
    "page",
    "showView",
    "pickupCountry",
    "pickupLocation",
    "pickupDetails",
    "pickupContactPhone",
    "pickupContactName",
    "dropoffCountry",
    "dropoffLocation",
    "dropoffDetails",
    "dropoffContactPhone",
    "dropoffContactName",
    "codCharge",
    "acceptCodTerms",
    "pickupTimeSlot",
    "deliveryTimeSlot",
    "initialMenuId",
    "initialPackageId",
    "initialPickupLocation",
    "initialDropoffLocation",
    "pieceCount",
  ]),
  toSnakeCase,
);

const validateLocation = (location, country, i18n) =>
  !isValidCoordinates(location)
    ? i18n.get("type_address_above_to_search", "Type address above to search")
    : getValue(location, "country") &&
      getValue(country, "description") &&
      getValue(location, "country") !== getValue(country, "description") &&
      i18n.get(
        "please_select_address_with_selected_country",
        "Please select address with selected country",
      );

const mapResponseDataAsList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data"], List()),
    }),
);

const mapResponseDataList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data", "list"], List()),
    }),
);

const isValidCODCharge = fp.overSome([
  fp.isNil,
  fp.overEvery([isPositive, fp.lt(0)]),
]);

const getCountryName = fp.flow(
  fp.get("values.pickupCountry.name"),
  s => `${s}'s center place`,
);

const enhancer = compose(
  useSheet({
    root: {
      zIndex: 10,
      minWidth: "400px",
      maxWidth: "400px",
      position: "relative",
      margin: "64px auto 0",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
    customerId: getCustomerId(state),
  })),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      const order = orderToSnakeCase(values);

      // legacy api
      order.pickup_time_now = true;
      order.piece_count = values.pieceCount || 1;

      order.charge_items = [];
      order.locations = [
        {
          pickup: true,
          lat: values.pickupLocation.lat,
          lon: values.pickupLocation.lng,
          address: values.pickupLocation.address,
          details: values.pickupDetails,
          phone: values.pickupContactPhone,
          contact_name: values.pickupContactName,
          email: values.pickupContactEmail,
        },
        {
          pickup: false,
          lat: values.dropoffLocation.lat,
          lon: values.dropoffLocation.lng,
          address: values.dropoffLocation.address,
          details: values.dropoffDetails,
          phone: values.dropoffContactPhone,
          contact_name: values.dropoffContactName,
          email: values.dropoffContactEmail,
          second_phone: values.dropoffSecondPhone,
          third_phone: values.dropoffThirdPhone,
        },
      ];

      if (values.codCharge > 0) {
        order.charge_items.push({
          quantity: 1,
          paid: false,
          charge: values.codCharge,
          charge_type: COD,
        });
      }

      if (order.payment_type === CREDIT_BALANCE) {
        order.charge_items.push({
          charge: 0,
          paid: false,
          quantity: 1,
          charge_type: SERVICE_CUSTOM,
        });
      }

      if (values.pickupTimeSlot) {
        order.pickup_timeslot_id = values.pickupTimeSlot.get(
          "timeslot_availability_id",
        );
      } else {
        delete order.estimated_pickup_time;
      }

      if (values.deliveryTimeSlot) {
        order.delivery_timeslot_id = values.deliveryTimeSlot.get(
          "timeslot_availability_id",
        );
      } else {
        delete order.estimated_delivery_time;
      }

      return props.onSubmit(order);
    },
  }),
  reduxForm({
    form: "OrderCreateWizard",
    destroyOnUnmount: false,
    validate: (values, props) => ({
      pickupCountry:
        !getValue(values.pickupCountry, "id") &&
        props.i18n.get(
          "please_select_pickup_country",
          "Please select pickup country",
        ),

      pickupLocation: validateLocation(
        values.pickupLocation,
        values.pickupCountry,
        props.i18n,
      ),

      pickupDetails: !props.disabledOrderMandatoryFields
        ? isBlankString(values.pickupDetails)
          ? props.i18n.get("enter_suite", "Enter suite")
          : fp.size(values.pickupDetails) > 512 &&
            props.i18n.get("address_is_too_long", "Address is too long")
        : null,

      external_user_name:
        props.customerId === 516650676
          ? isBlankString(values.external_user_name) &&
            props.i18n.get("enter_amazon_id", "Enter Amazon ID")
          : null,
      reference_id:
        props.customerId === 516650676
          ? isBlankString(values.reference_id) &&
            props.i18n.get("enter_reference_id", "Enter Reference ID")
          : null,

      pickupContactName: isBlankString(values.pickupContactName)
        ? props.i18n.get("please_enter_sender_name", "Please enter sender name")
        : fp.size(values.pickupContactName) > 512 &&
          props.i18n.get("name_is_too_long", "Name is too long"),

      pickupContactPhone: !props.disabledOrderMandatoryFields
        ? validatePhoneNumberFori18n(values.dropoffContactPhone, props.i18n)
        : null,

      dropoffCountry:
        !getValue(values.dropoffCountry, "id") &&
        props.i18n.get(
          "please_select_dropoff_country",
          "Please select dropoff country",
        ),

      dropoffLocation: validateLocation(
        values.dropoffLocation,
        values.dropoffCountry,
        props.i18n,
      ),

      dropoffDetails: !props.disabledOrderMandatoryFields
        ? isBlankString(values.dropoffDetails)
          ? props.i18n.get("enter_suite", "Enter suite")
          : fp.size(values.dropoffDetails) > 512 &&
            props.i18n.get("address_is_too_long", "Address is too long")
        : null,

      dropoffContactName: isBlankString(values.dropoffContactName)
        ? props.i18n.get(
            "please_enter_recipient_name",
            "Please enter recipient name",
          )
        : fp.size(values.dropoffContactName) > 512 &&
          props.i18n.get("name_is_too_long", "Name is too long"),

      dropoffContactPhone: !props.disabledOrderMandatoryFields
        ? validatePhoneNumberFori18n(values.dropoffContactPhone, props.i18n)
        : null,

      deliveryTimeSlot:
        Boolean(
          values.deliveryTimeSlot &&
            values.pickupTimeSlot &&
            (isAfter(
              values.estimatedPickupTime,
              values.estimatedDeliveryTime,
            ) ||
              (isSameDay(
                values.estimatedPickupTime,
                values.estimatedDeliveryTime,
              ) &&
                values.pickupTimeSlot.get("start_time") >=
                  values.deliveryTimeSlot.get("end_time"))),
        ) &&
        props.i18n.get(
          "dropoff_time_can_not_be_before_pickup_time",
          "Dropoff Time can not be before Pickup Time",
        ),

      codCharge:
        !isValidCODCharge(values.codCharge) &&
        props.i18n.get("please_enter_valid_price", "Please enter valid price"),
    }),
  }),
  connect(state => ({
    marketplaceId: getMarketplaceId(state),
    values: getValues(state),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .map(
            fp.flow(
              fp.update("values", values => ({
                ...values,
                menuId: values.menu && values.menu.get("menu_id"),
                packageId: values.package && values.package.get("id"),
                validLocations:
                  isValidCoordinates(values.pickupLocation) &&
                  isValidCoordinates(values.dropoffLocation),
              })),
              props => ({
                ...props,
                page: props.values.page,
                showView: props.values.showView,
                currencyCode: getValue(props.values.package, [
                  "price",
                  "currency",
                  "code",
                ]),
                allowCod:
                  props.allowInternationalCOD ||
                  hasSameId(
                    props.values.pickupCountry,
                    props.values.dropoffCountry,
                  ),
                total: sum(
                  props.values.codCharge,
                  -getValue(props.values.promo, "discount", 0),
                  props.values.paymentType === CREDIT_BALANCE
                    ? 0
                    : getValue(props.values.package, ["price", "total"], 0),
                ),
              }),
            ),
          )
          .distinctUntilChanged(isShallowEqual),
      propsStream => {
        const packageMenuResponseStream = propsStream
          .map(
            props =>
              new DataListFilter({
                from_country_id: getValue(props.values.pickupCountry, "id"),
                to_country_id: getValue(props.values.dropoffCountry, "id"),
              }),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getPackageMenu(filter).catch(error => Observable.of({ error })),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const packagePricesResponseStream = propsStream
          .map(props =>
            props.values.menuId > 0 && props.values.validLocations
              ? new DataListFilter({
                  menu_id: props.values.menuId,
                  from_lat: props.values.pickupLocation.lat,
                  from_lon: props.values.pickupLocation.lng,
                  to_lat: props.values.dropoffLocation.lat,
                  to_lon: props.values.dropoffLocation.lng,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getPackagePrices(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const paymentTypesResponseStream = propsStream
          .map(props => ({
            getPaymentMethods: props.getPaymentMethods,
            filter:
              props.values.packageId > 0 && props.values.validLocations
                ? fromJS({
                    package_id: props.values.packageId,
                    locations: [
                      {
                        pickup: true,
                        lat: props.values.pickupLocation.lat,
                        lon: props.values.pickupLocation.lng,
                      },
                      {
                        pickup: false,
                        lat: props.values.dropoffLocation.lat,
                        lon: props.values.dropoffLocation.lng,
                      },
                    ],
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props
                  .getPaymentMethods(props.filter)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(mapResponseDataAsList)
          .distinctUntilChanged(isEqualData);

        const pickupTimeSlotsResponseStream = propsStream
          .map(props => ({
            getTimeSlots: props.getTimeSlots,
            filter:
              props.values.packageId > 0 &&
              props.values.validLocations &&
              isValidDate(props.values.estimatedPickupTime)
                ? new DataListFilter({
                    package_id: props.values.packageId,
                    timeslot_type: PICKUP,
                    lat: props.values.pickupLocation.lat,
                    lng: props.values.pickupLocation.lng,
                    date: formatDateToUrl(props.values.estimatedPickupTime),
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props
                  .getTimeSlots(props.filter)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const deliveryTimeSlotsResponseStream = propsStream
          .map(props => ({
            getTimeSlots: props.getTimeSlots,
            filter:
              props.values.packageId > 0 &&
              props.values.validLocations &&
              isValidDate(props.values.estimatedDeliveryTime)
                ? new DataListFilter({
                    package_id: props.values.packageId,
                    timeslot_type: DROP,
                    lat: props.values.dropoffLocation.lat,
                    lng: props.values.dropoffLocation.lng,
                    date: formatDateToUrl(props.values.estimatedDeliveryTime),
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props
                  .getTimeSlots(props.filter)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(mapResponseDataList)
          .distinctUntilChanged(isEqualData);

        const promoFilterStream = propsStream.map(props =>
          props.values.packageId > 0 && props.values.validLocations
            ? fromJS({
                code: props.values.promoCode,
                package_id: props.values.packageId,
                locations: [
                  {
                    pickup: true,
                    details: props.values.pickupDetails,
                    lat: props.values.pickupLocation.lat,
                    lon: props.values.pickupLocation.lng,
                    address: props.values.pickupLocation.address,
                  },
                  {
                    pickup: false,
                    details: props.values.dropoffDetails,
                    lat: props.values.dropoffLocation.lat,
                    lon: props.values.dropoffLocation.lng,
                    address: props.values.dropoffLocation.address,
                  },
                ],
              })
            : null,
        );

        const pickupCountryChangeStream = propsStream
          .map(fp.get("values.pickupCountry.id"))
          .filter(Boolean)
          .distinctUntilChanged(isEqualData)
          .switchMap(countryId =>
            getCountryCenter(countryId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .filter(fp.has("payload.data.lat"))
          .map(fp.get("payload.data"))
          .withLatestFrom(propsStream)
          .do(([location, props]) =>
            props.change("pickupLocation", {
              lat: location.lat,
              lng: location.lon,
              address: getCountryName(props),
            }),
          )
          .startWith(null)
          .distinctUntilChanged(isEqualData);

        const sideEffectsStream = Observable.merge(
          propsStream
            .combineLatest(promoFilterStream, (props, filter) => ({
              filter,
              change: props.change,
              validatePromo: props.validatePromo,
            }))
            .distinctUntilKeyChanged("filter", isEqualData)
            .switchMap(
              props =>
                props.filter
                  ? props
                      .validatePromo(props.filter)
                      .then(fp.get("data"), fp.constant(null))
                  : Observable.of(null),
              (props, response) => [props, response],
            )
            .distinctUntilKeyChanged(1, isEqualData)
            .do(([props, promo]) => props.change("promo", promo)),
          propsStream
            .map(
              fp.flow(
                fp.pick(["change", "values"]),
                fp.update(
                  "values",
                  fp.pick(["pickupCountry", "dropoffCountry"]),
                ),
              ),
            )
            .distinctUntilKeyChanged("values", isEqualData)
            .do(props => {
              const { pickupCountry, dropoffCountry } = props.values;

              if (!isEqualData(pickupCountry, dropoffCountry)) {
                props.change("acceptedTerms", false);
                props.change("domestic", false);
              } else {
                props.change("domestic", true);
              }
            }),
          propsStream
            .map(
              fp.flow(
                fp.pick(["change", "values", "marketplaceId"]),
                fp.update(
                  "values",
                  fp.pick([
                    "codCharge",
                    "payer",
                    "paymentType",
                    "recipientNotAvailable",
                  ]),
                ),
              ),
            )
            .distinctUntilKeyChanged("values", isEqualData)
            .do(props => {
              const {
                codCharge,
                payer,
                paymentType,
                recipientNotAvailable,
              } = props.values;

              if (
                props.marketplaceId === WING_SA_ID ||
                props.marketplaceId === WING_AE_ID
              ) {
                if (payer !== SENDER) {
                  props.change("payer", SENDER);
                }
              } else if (paymentType !== CASH && payer !== SENDER) {
                props.change("payer", SENDER);
              } else if (
                paymentType === CASH &&
                codCharge > 0 &&
                payer !== RECIPIENT
              ) {
                props.change("payer", RECIPIENT);
              } else if (
                (payer === RECIPIENT || codCharge > 0) &&
                recipientNotAvailable !== DO_NOT_DELIVER
              ) {
                props.change("recipientNotAvailable", DO_NOT_DELIVER);
              }
            }),
          propsStream
            .map(fp.pick(["change", "values", "allowCod"]))
            .distinctUntilKeyChanged("allowCod")
            .filter(fp.get("allowCod"))
            .do(props => {
              if (props.values.codCharge > 0) {
                props.change("codCharge", 0);
              }

              if (props.values.acceptCodTerms) {
                props.change("acceptCodTerms", false);
              }
            }),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream
          .map(
            fp.pick([
              "classes",
              "page",
              "total",
              "showView",
              "allowCod",
              "currencyCode",
              "change",
              "reset",
              "submitting",
              "handleSubmit",
              "initialValues",
              "getTimeSlots",
              "marketplaceId",
              "getPaymentMethods",
              "promoFilter",
              "validatePromo",
              "countries",
              "senderLocations",
              "recipientLocations",
              "values",
              "i18n",
            ]),
          )
          .combineLatest(
            promoFilterStream,
            packageMenuResponseStream,
            packagePricesResponseStream,
            paymentTypesResponseStream,
            pickupTimeSlotsResponseStream,
            deliveryTimeSlotsResponseStream,
            sideEffectsStream,
            pickupCountryChangeStream,
            (
              props,
              promoFilter,
              packageMenuResponse,
              packagePricesResponse,
              paymentTypesResponse,
              pickupTimeSlotsResponse,
              deliveryTimeSlotsResponse,
            ) => {
              const paymentResponse = paymentTypesResponse.get("list");

              const paymentTypes =
                props.marketplaceId === WING_AE_ID &&
                hasSameId(
                  props.values.pickupCountry,
                  props.values.dropoffCountry,
                )
                  ? paymentResponse
                  : paymentResponse.filter(x => x !== "pay_fort");

              return {
                ...props,
                promoFilter,
                paymentTypes,

                packageMenu: packageMenuResponse.get("list"),
                packageMenuFetching: packageMenuResponse.get("pending"),
                packagePrices: packagePricesResponse.get("list"),
                packagePricesFetching: packagePricesResponse.get("pending"),
                paymentTypesFetching: paymentTypesResponse.get("pending"),
                pickupTimeSlots: pickupTimeSlotsResponse.get("list"),
                pickupTimeSlotsFetching: pickupTimeSlotsResponse.get("pending"),
                deliveryTimeSlots: deliveryTimeSlotsResponse.get("list"),
                deliveryTimeSlotsFetching: deliveryTimeSlotsResponse.get(
                  "pending",
                ),
              };
            },
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

OrderCreateWizard.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.string,
  values: PropTypes.object,
  total: PropTypes.number,
  showView: PropTypes.object,
  allowCod: PropTypes.bool,
  currencyCode: PropTypes.string,

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isMerchant: PropTypes.bool,

  packageMenuFetching: PropTypes.bool,
  packageMenu: PropTypes.instanceOf(List),
  packagePricesFetching: PropTypes.bool,
  packagePrices: PropTypes.instanceOf(List),
  paymentTypesFetching: PropTypes.bool,
  paymentTypes: PropTypes.instanceOf(List),
  pickupTimeSlotsFetching: PropTypes.bool,
  pickupTimeSlots: PropTypes.instanceOf(List),
  deliveryTimeSlotsFetching: PropTypes.bool,
  deliveryTimeSlots: PropTypes.instanceOf(List),

  promoFilter: PropTypes.instanceOf(Map),

  allowInternationalCOD: PropTypes.bool,
  disabledOrderMandatoryFields: PropTypes.bool,
  marketplaceId: PropTypes.number,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getTimeSlots: PropTypes.func.isRequired,
  validatePromo: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,

  countries: PropTypes.instanceOf(Map).isRequired,
  senderLocations: PropTypes.instanceOf(List).isRequired,
  recipientLocations: PropTypes.instanceOf(List).isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function OrderCreateWizard(props) {
  const { classes, i18n } = props;
  const page = pages.includes(props.page) ? props.page : pages[0];
  const pageIndex = pages.indexOf(page);
  const setPage = v => props.change("page", v);
  const showView = fp.toPlainObject(props.showView);
  const setViewVisibility = (view, visibility) =>
    props.change("showView", { ...showView, [view]: visibility });

  return (
    <div>
      <PageLoading isLoading={props.submitting} />

      <div className={classes.root}>
        <OrderCreateWizardStepper>
          {pageIndex > 0 && (
            <OrderCreateWizardStepFromDetails
              showSwapButton={false}
              onClick={() => setPage(PAGE_FROM)}
            />
          )}
          {pageIndex > 1 && (
            <OrderCreateWizardStepToDetails onClick={() => setPage(PAGE_TO)} />
          )}
          {pageIndex > 2 && (
            <OrderCreateWizardStepWhatDetails
              onClick={() => setPage(PAGE_WHAT)}
            />
          )}
          {pageIndex > 3 && (
            <OrderCreateWizardStepHowDetails
              onClick={() => setPage(PAGE_HOW)}
            />
          )}
          {pageIndex > 4 && (
            <OrderCreateWizardStepPayDetails
              onClick={() => setPage(PAGE_PAY)}
            />
          )}

          {Boolean(pageIndex > 5 && showView.when) && (
            <OrderCreateWizardStepWhenDetails
              onClick={() => setPage(PAGE_WHEN)}
            />
          )}

          {Boolean(pageIndex > 6 && showView.collect) && (
            <OrderCreateWizardStepCollectDetails
              currencyCode={props.currencyCode}
              onClick={() => setPage(PAGE_COLLECT)}
            />
          )}

          {Boolean(pageIndex > 7 && showView.more) && (
            <OrderCreateWizardStepMoreDetails
              i18n={props.i18n}
              onClick={() => setPage(PAGE_MORE)}
            />
          )}
        </OrderCreateWizardStepper>

        <OrderCreateWizardCard page={page}>
          {page === PAGE_FROM && (
            <OrderCreateWizardStepFromForm
              key={PAGE_FROM}
              onSubmit={values => {
                if (values.pickupSaveAddressBook) {
                  const addressBook = {};

                  addressBook.address = values.pickupLocation.address;
                  addressBook.contact_name = values.pickupContactName;
                  addressBook.phone = values.pickupContactPhone;
                  addressBook.email = values.pickupContactEmail;
                  addressBook.pickup = true;
                  addressBook.lat = values.pickupLocation.lat;
                  addressBook.lon = values.pickupLocation.lng;
                  addressBook.address =
                    values.pickupLocation.address || values.pickupDetails;
                  addressBook.details = values.pickupDetails;
                  addressBook.phone = values.pickupContactPhone;
                  addressBook.email = values.pickupContactEmail;
                  addressBook.name = values.pickupContactName;

                  Promise.resolve(
                    createAddressBook(addressBook).catch(ResponseError.throw),
                  ).catch(props.onSubmitFail);
                }
                setPage(PAGE_TO);
              }}
              showForm={Boolean(showView.from)}
              suggestions={props.senderLocations}
              onShowForm={() => setViewVisibility("from", true)}
            />
          )}

          {page === PAGE_TO && (
            <OrderCreateWizardStepToForm
              key={PAGE_TO}
              countries={props.countries}
              showForm={Boolean(showView.to)}
              onSubmit={values => {
                if (values.dropoffSaveAddressBook) {
                  const addressBook = {};

                  addressBook.address = values.dropoffLocation.address;
                  addressBook.contact_name = values.dropoffContactName;
                  addressBook.phone = values.dropoffContactPhone;
                  addressBook.email = values.dropoffContactEmail;
                  addressBook.pickup = false;
                  addressBook.lat = values.dropoffLocation.lat;
                  addressBook.lon = values.dropoffLocation.lng;
                  addressBook.address =
                    values.dropoffLocation.address || values.dropoffDetails;
                  addressBook.details = values.dropoffDetails;
                  addressBook.phone = values.dropoffContactPhone;
                  addressBook.email = values.dropoffContactEmail;
                  addressBook.name = values.dropoffContactName;

                  Promise.resolve(
                    createAddressBook(addressBook).catch(ResponseError.throw),
                  ).catch(props.onSubmitFail);
                }
                setPage(PAGE_WHAT);
              }}
              suggestions={props.recipientLocations}
              onShowForm={() => setViewVisibility("to", true)}
            />
          )}

          {page === PAGE_WHAT && (
            <OrderCreateWizardStepWhatForm
              key={PAGE_WHAT}
              packageMenu={props.packageMenu}
              packageMenuFetching={props.packageMenuFetching}
              onSubmit={() => setPage(PAGE_HOW)}
            />
          )}

          {page === PAGE_HOW && (
            <OrderCreateWizardStepHowForm
              key={PAGE_HOW}
              packagePrices={props.packagePrices}
              packagePricesFetching={props.packagePricesFetching}
              onSubmit={() => setPage(PAGE_PAY)}
            />
          )}

          {page === PAGE_PAY && (
            <OrderCreateWizardStepPayForm
              key={PAGE_PAY}
              onSubmit={({ paymentType: pType }) =>
                pType === CASH ? setPage(PAGE_COLLECT) : setPage(PAGE_FINAL)
              }
              paymentTypes={props.paymentTypes}
              paymentTypesFetching={props.paymentTypesFetching}
            />
          )}

          {page === PAGE_WHEN && (
            <OrderCreateWizardStepWhenForm
              key={PAGE_WHEN}
              initialValues={props.initialValues}
              onSubmit={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("when", true);
              }}
              onDismiss={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("when", false);
              }}
              pickupTimeSlots={props.pickupTimeSlots}
              pickupTimeSlotsFetching={props.pickupTimeSlotsFetching}
              deliveryTimeSlots={props.deliveryTimeSlots}
              deliveryTimeSlotsFetching={props.deliveryTimeSlotsFetching}
            />
          )}

          {Boolean(props.allowCod && page === PAGE_COLLECT) && (
            <OrderCreateWizardStepCollectForm
              key={PAGE_COLLECT}
              initialValues={props.initialValues}
              onSubmit={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("collect", true);
              }}
              onDismiss={() => {
                setPage(PAGE_FINAL);
                setViewVisibility("collect", false);
              }}
            />
          )}
        </OrderCreateWizardCard>

        {page === PAGE_MORE && (
          <OrderCreateWizardStepMoreForm
            isMerchant={props.isMerchant}
            validatePromo={code =>
              props.validatePromo(props.promoFilter.set("code", code))
            }
            onSubmit={() => {
              setPage(PAGE_FINAL);
              setViewVisibility("more", true);
            }}
          />
        )}

        {page === PAGE_FINAL && (
          <OrderCreateWizardStepTermsDialog
            onSubmit={() => {
              setPage(PAGE_FINAL);
            }}
            onDismiss={() => {
              setPage(PAGE_TO);
            }}
          />
        )}

        <OrderCreateWizardActions
          total={props.total}
          currencyCode={props.currencyCode}
          showTotal={page === PAGE_FINAL}
        >
          {Boolean(page === PAGE_FINAL && !showView.when) && (
            <OrderCreateWizardFab
              mini={true}
              label={i18n.get("schedule", "Schedule")}
              onClick={() => setPage(PAGE_WHEN)}
            >
              <AccessTime />
            </OrderCreateWizardFab>
          )}

          {Boolean(
            props.allowCod && page === PAGE_FINAL && !showView.collect,
          ) && (
            <OrderCreateWizardFab
              mini={true}
              label={i18n.get(
                "collect_cash_on_delivery",
                "Collect cash on delivery",
              )}
              onClick={() => setPage(PAGE_COLLECT)}
            >
              <AttachMoney />
            </OrderCreateWizardFab>
          )}

          {Boolean(page === PAGE_FINAL && !showView.more) && (
            <OrderCreateWizardFab
              mini={true}
              label={i18n.get("customize_more", "Customize more")}
              onClick={() => setPage(PAGE_MORE)}
            >
              <MoreHoriz />
            </OrderCreateWizardFab>
          )}

          {Boolean(page === PAGE_FINAL) && (
            <OrderCreateWizardFab
              success={true}
              label={i18n.get("place_an_order", "Place an Order")}
              onClick={props.handleSubmit}
            >
              <DoneAll />
            </OrderCreateWizardFab>
          )}
        </OrderCreateWizardActions>
      </div>

      <OrderCreateWizardOverlay />
    </div>
  );
}

export default enhancer(OrderCreateWizard);
