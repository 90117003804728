import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

PaypalDialog.propTypes = {
  url: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default function PaypalDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <DialogContent style={{ maxWidth: 320 }}>
        <DialogContentText>
          Please proceed to payments page to complete order creation process.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>Dismiss</Button>
          <Button
            component="a"
            target="_blank"
            href={props.url}
            onClick={props.onRequestClose}
          >
            Proceed
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
