import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

//
// Finance Orders
//

const COD_TOTAL_COUNT_URL = `${API_ROOT_URL}/customer/cod/total_counts`;

export const getCODTotalCount = (request: DataListFilter) =>
  api.getStream(COD_TOTAL_COUNT_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

//
// Balance
//

const MY_BALANCE = `${API_ROOT_URL}/customer/mybalance`;
const MY_BALANCE_TRANSACTIONS = `${
  API_ROOT_URL
}/customer/mybalance/transactions`;

export const getMyBalance = () =>
  api.getStream(MY_BALANCE, {
    headers: {
      "x-app-type": "customer-web",
    },
  });
export const getMyLatestTransactions = () =>
  api.getStream(MY_BALANCE_TRANSACTIONS, {
    headers: {
      "x-app-type": "customer-web",
    },
  });

//
// Transactions
//

const TRANSACTION_LIST_URL = `${API_ROOT_URL}/customer/transactions`;
const ENDING_BALANCE_URL = `${
  API_ROOT_URL
}/customer/transaction/ending_balance`;
const TRANSACTION_ITEM_URL = `${API_ROOT_URL}/customer/transaction/%s/details`;

export const getTransactionList = (request: DataListFilter) =>
  api.getStream(TRANSACTION_LIST_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getEndingBalance = () => api.getStream(ENDING_BALANCE_URL);
export const getTransactionItem = (id: number) =>
  api.getStream(sprintf(TRANSACTION_ITEM_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

//
// COD Withdraw
//

const COD_WITHDRAW_URL = `${API_ROOT_URL}/customer/codwithdraw`;
const COD_WITHDRAW_TOTAL_URL = `${
  API_ROOT_URL
}/customer/codwithdraw/calculate_total`;
const COD_WITHDRAW_STATUS_COUNT_URL = `${
  API_ROOT_URL
}/customer/codwithdraw/status_counts`;
const COD_WITHDRAW_ITEM_URL = `${API_ROOT_URL}/customer/codwithdraw/%s`;
const COD_WITHDRAW_ITEM_ORDERS_URL = `${
  API_ROOT_URL
}/customer/codwithdraw/%s/orders`;
const COD_WITHDRAW_LIST_URL = `${API_ROOT_URL}/customer/codwithdraws/`;

export const calculateCodWithdrawTotal = body =>
  api.getStream(COD_WITHDRAW_TOTAL_URL, {
    body,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getCODWithdrawList = (request: DataListFilter) =>
  api.getStream(COD_WITHDRAW_LIST_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getCODWithdrawStatusCount = (request: DataListFilter) =>
  api.getStream(COD_WITHDRAW_STATUS_COUNT_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const createCODWithdraw = request =>
  api.post(COD_WITHDRAW_URL, {
    body: request,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getCODWithdraw = (id: number) =>
  api.getStream(sprintf(COD_WITHDRAW_ITEM_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getCODWithdrawOrders = (id: number, request: DataListFilter) =>
  api.getStream(sprintf(COD_WITHDRAW_ITEM_ORDERS_URL, id), {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

const BANK_LIST_URL = `${API_ROOT_URL}/customer/banks`;
const CUSTOMER_BANK_URL = `${API_ROOT_URL}/customer/bank`;
const CUSTOMER_BANK_ITEM_URL = `${API_ROOT_URL}/customer/bank/%s`;

export const getBankList = (request: DataListFilter) =>
  api.getStream(BANK_LIST_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const createBank = values =>
  api.post(CUSTOMER_BANK_URL, {
    body: values,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getBankItem = id =>
  api.getStream(sprintf(CUSTOMER_BANK_ITEM_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const updateBank = values =>
  api.put(CUSTOMER_BANK_URL, {
    body: values,
    headers: {
      "x-app-type": "customer-web",
    },
  });
