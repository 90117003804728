import React from "react";
// import Reaptcha from "reaptcha";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import FormTextField from "../form/FormTextField";
import AvatarWithName from "../avatars/AvatarWithName";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { parsePhone } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import phoneIcon from "./assets/phone-icon.svg";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  // withState("captchaVerified", "onVerifyCaptcha", false),
  useSheet({
    captcha: { marginTop: "12px" },
    actions: { marginTop: "12px" },
    maskedPhone: { backgroundColor: grey[200], padding: "0 15px" },
  }),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "VerifyPhoneNumberForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      phone:
        !values.phone &&
        props.getLocalisationMessage(
          "enter_phone_number",
          "Enter Phone Number",
        ),
    }),
  }),
  formValues({
    verified: "verified",
  }),
);

VerifyPhoneNumberForm.propTypes = {
  classes: PropTypes.object,

  reset: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  // captchaVerified: PropTypes.bool,
  // verified: PropTypes.bool,
  handleSubmit: PropTypes.func,
  // onVerifyCaptcha: PropTypes.func,

  maskedPhone: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function VerifyPhoneNumberForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox
      gutter={8}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox direction="column">
        <div className={classes.maskedPhone}>
          <AvatarWithName name="Test" size={48} imagePath={phoneIcon}>
            {props.maskedPhone}
          </AvatarWithName>
        </div>

        <FlexBox>
          <FormTextField
            fullWidth={true}
            name="phone"
            label={getLocalisationMessage("phone_number", "Phone Number")}
            parse={parsePhone}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+</InputAdornment>
              ),
            }}
          />
        </FlexBox>

        <FlexBox justify="flex-end" className={classes.actions}>
          <Button
            label={getLocalisationMessage("reset", "Reset")}
            onClick={props.reset}
          />
          <Button
            type="submit"
            // disabled={!props.verified}
            label={getLocalisationMessage("verify", "Verify")}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(VerifyPhoneNumberForm);
