import React from "react";
import CustomerSignIn from "./CustomerSignIn";
import { VERIFY_PHONE_NUMBER_URL } from "../../constants/CustomerPathConstants";
import DashboardRoot from "../../components/app-core/DashboardRoot";

export default function CustomerRoot(props) {
  return (
    <DashboardRoot
      {...props}
      skipVerification={true}
      SignIn={CustomerSignIn}
      verifyPhoneNumberUrl={VERIFY_PHONE_NUMBER_URL}
    />
  );
}
