import sprintf from "sprintf";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ADDRESS_BOOK_LIST_URL = `${API_ROOT_URL}/customer/address_books`;
const ADDRESS_BOOK_CREATE_URL = `${API_ROOT_URL}/customer/address_books/`;
const ADDRESS_BOOK_ITEM_URL = `${API_ROOT_URL}/customer/address_books/%s`;
const ADDRESS_BOOK_CHOOSE_URL = `${
  API_ROOT_URL
}/customer/address_books/search_by`;
const ADDRESS_BOOK_BATCH_DELETE_URL = `${
  API_ROOT_URL
}/customer/address_books/batch_delete`;

export const chooseAddressBook = (filter: DataListFilter) =>
  api.getStream(ADDRESS_BOOK_CHOOSE_URL, { params: filter.getDefinedValues() });

export const getAddressBook = id =>
  api.getStream(sprintf(ADDRESS_BOOK_ITEM_URL, id));

export type AddressBookRequest = {
  lat: number,
  marketplace_id: number,
  lon: number,
  marketplace_ids: number[],
  details: string,
  address: string,
  contact_name: string,
  email: string,
  phone: string,
  company_name: string,
  id: number,
  name: string,
};

export const createAddressBook = (body: AddressBookRequest) =>
  api.post(ADDRESS_BOOK_CREATE_URL, { body });

export const updateAddressBook = (id, body: AddressBookRequest) =>
  api.put(sprintf(ADDRESS_BOOK_ITEM_URL, id), { body });

export const getAddressBookList = (request: DataListFilter) =>
  api.getStream(ADDRESS_BOOK_LIST_URL, { params: request.getDefinedValues() });

export const deleteBatchAddressBook = ids =>
  api.delete(ADDRESS_BOOK_BATCH_DELETE_URL, { params: { ids } });
