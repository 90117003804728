import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { phoneCountyCodeList } from "../../constants/PhoneCountryCodes";
import { ADDRESS_BOOK_NEW_URL } from "../../constants/CustomerPathConstants";
import { createAddressBook } from "../../api/shared/CustomerAddressBookV2Api";
import CustomerAddressBookItemWrapper from "../../wrappers/customer/CustomerAddressBookItemWrapper";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func,
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

CustomerAddressBookCreateNew.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerAddressBookCreateNew(props) {
  return (
    <CustomerAppLayout
      title={props.getLocalisationMessage(
        "create_address_book",
        "Create Address Book",
      )}
    >
      <CustomerAddressBookItemWrapper
        onSubmit={values => {
          const data = toSnakeCase(values);
          createAddressBook({
            ...data,
            phone_code: phoneCountyCodeList.filter(
              x => x.country === fp.get("country.name", data),
            )[0].code,
          })
            .catch(ResponseError.throw)
            .then(res => {
              if (res.status === "success") {
                props.setLocation(ADDRESS_BOOK_NEW_URL);
                props.showSuccessMessage(
                  props.getLocalisationMessage(
                    "successfully_saved",
                    "Successfully saved",
                  ),
                );
              } else {
                props.showErrorMessage(res.message);
              }
            });
        }}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerAddressBookCreateNew);
