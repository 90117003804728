import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormSelectField from "../../form/FormSelectField";
import FormImageListUpload from "../../form/FormImageListUploadV2";
import FlexBox from "../../ui-core/FlexBox";
import FormPromoField from "../../promos-core/FormPromoField";
import { formatText, parsePhone } from "../../../helpers/FormatUtils";
import {
  isCustomMarketplace,
  showMarketplaceAlternatePhoneNumbers,
} from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import OrderPayerTypes, { SENDER } from "../../../constants/OrderPayerTypes";
import { CASH } from "../../../constants/OrderPaymentTypes";
import OrderRecipientNotAvailableActionTypes from "../../../constants/OrderRecipientNotAvailableActionTypes";

const enhancer = compose(
  useSheet({
    content: {
      width: "600px",
      "@media (max-width: 997px)": {
        width: "100%",
      },
    },
    flexRoot: {
      flexWrap: "wrap",
    },
    flexChild: {
      width: "calc(50%)",
      "@media (max-width: 997px)": {
        width: "calc(100%)",
      },
    },
  }),
  connect(state => ({
    isCustom: isCustomMarketplace(state),
    showMarketplaceAlternatePhoneNumbers: showMarketplaceAlternatePhoneNumbers(
      state,
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "OrderCreateWizardDefault",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  formValues({
    payer: "payer",
    codCharge: "codCharge",
    paymentType: "paymentType",
  }),
);

OrderCreateWizardStepMoreForm.propTypes = {
  classes: PropTypes.object,
  payer: PropTypes.string,
  codCharge: PropTypes.number,
  paymentType: PropTypes.string,
  handleSubmit: PropTypes.func,
  currencyCode: PropTypes.string,
  validatePromo: PropTypes.func.isRequired,
  isCustom: PropTypes.bool,
  showMarketplaceAlternatePhoneNumbers: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepMoreForm(props) {
  const { getLocalisationMessage, classes } = props;
  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.handleSubmit}
    >
      <DialogTitle>
        {getLocalisationMessage("customize", "Customize")}
      </DialogTitle>
      <DialogContent className={props.classes.content}>
        <form>
          <FlexBox flex={true} className={classes.flexRoot}>
            <FlexBox
              className={classes.flexChild}
              direction="column"
              container={8}
            >
              <FormTextField
                name="note"
                rows={1}
                rowsMax={4}
                fullWidth={true}
                multiLine={true}
                label={getLocalisationMessage(
                  "add_description",
                  "Add Description",
                )}
              />

              <FormCheckbox
                name="fragile"
                label={getLocalisationMessage(
                  "parcel_is_fragile",
                  "Parcel is Fragile",
                )}
              />

              <FormTextField
                name="pieceCount"
                label={getLocalisationMessage("piece_count", "Piece Count")}
                fullWidth={true}
                type="number"
              />

              {props.showMarketplaceAlternatePhoneNumbers && (
                <div>
                  <FormTextField
                    parse={parsePhone}
                    name="dropoffSecondPhone"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "alternate_phone1",
                      "Alternate Phone 1",
                    )}`}
                  />

                  <FormTextField
                    parse={parsePhone}
                    name="dropoffThirdPhone"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "alternate_phone2",
                      "Alternate Phone 2",
                    )}`}
                  />
                </div>
              )}
            </FlexBox>
            <FlexBox
              className={classes.flexChild}
              direction="column"
              container={8}
            >
              {props.paymentType === CASH && (
                <FormSelectField
                  name="payer"
                  autoWidth={true}
                  fullWidth={true}
                  disabled={!props.isCustom}
                  options={OrderPayerTypes}
                  formatOption={value =>
                    formatText(getLocalisationMessage(value, value))
                  }
                  label={getLocalisationMessage(
                    "payment_done_by",
                    "Delivery Charge paid by",
                  )}
                />
              )}

              {Boolean(!props.codCharge > 0 && props.payer === SENDER) && (
                <FormSelectField
                  autoWidth={true}
                  fullWidth={true}
                  formatOption={value =>
                    formatText(getLocalisationMessage(value, value))
                  }
                  name="recipientNotAvailable"
                  label={getLocalisationMessage(
                    "if_recipient_not_available",
                    "If recipient not available",
                  )}
                  options={OrderRecipientNotAvailableActionTypes}
                />
              )}

              <FormImageListUpload
                name="attachments"
                label={getLocalisationMessage("parcel_photos", "Parcel Photos")}
              />

              <FormPromoField
                name="promo"
                currencyCode={props.currencyCode}
                validatePromo={props.validatePromo}
              />
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderCreateWizardStepMoreForm);
