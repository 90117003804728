import React from "react";
import _ from "lodash";
import { isDate, isValid, startOfMonth } from "date-fns";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormDateField from "../form/FormDateField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { renderIf } from "../../helpers/HOCUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  updateQuery,
  combineCleanQuery,
} from "../../../shared/helpers/UrlUtils";
import { CREATE_CUSTOMER_PENDING_BALANCE_URL } from "../../../shared/constants/FileProxyControllerConstants";

const valueSelector = formValueSelector("PendingBalanceDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({ dateField: { flex: "1 1 0%" } }),
  getContext({ getCachedCustomer: PropTypes.func }),
  reduxForm({
    form: "PendingBalanceDialog",
    initialValues: {
      fromDate: startOfMonth(new Date()),
      toDate: new Date(),
    },
    validate: (values, props) => ({
      fromDate:
        !isDate(values.fromDate) || !isValid(values.fromDate)
          ? props.getLocalisationMessage(
              "select_start_date",
              "Select Start Date",
            )
          : null,
      toDate:
        !isDate(values.toDate) || !isValid(values.toDate)
          ? props.getLocalisationMessage("select_end_date", "Select End Date")
          : null,
      customer:
        props.getCachedCustomer && !isValidObjectId(values.customer)
          ? props.getLocalisationMessage("select_customer", "Select Customer")
          : null,
    }),
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    values: valueSelector(state, "fromDate", "toDate", "customer"),
  })),
);

PendingBalanceDialog.propTypes = {
  classes: PropTypes.object,
  invalid: PropTypes.bool,
  values: PropTypes.object,
  getCachedCustomer: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  theme: PropTypes.object,
};

function PendingBalanceDialog(props) {
  const { classes, values, getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 800,
          maxWidth: 1000,
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary1Color,
        }}
      >
        {getLocalisationMessage("pending_balance", "Pending Balance")}
      </DialogTitle>
      <DialogContent>
        <FlexBox container={8}>
          <FlexBox gutter={8} flex={true}>
            {props.getCachedCustomer && (
              <FlexBox flex={true}>
                <FormCustomerAutoComplete
                  name="customer"
                  fullWidth={true}
                  label={getLocalisationMessage("customer", "Customer")}
                />
              </FlexBox>
            )}

            <FlexBox flex={true}>
              <FormDateField
                name="fromDate"
                label={getLocalisationMessage("from_date", "From Date")}
                fullWidth={true}
                className={classes.dateField}
                hintText={getLocalisationMessage("from_date", "From Date")}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormDateField
                name="toDate"
                label={getLocalisationMessage("to_date", "To Date")}
                className={classes.dateField}
                fullWidth={true}
                hintText={getLocalisationMessage("to_date", "To Date")}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          <Button primary={true} onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button
            primary={true}
            target="_blank"
            disabled={props.invalid}
            component="a"
            href={updateQuery(
              CREATE_CUSTOMER_PENDING_BALANCE_URL,
              combineCleanQuery({
                customer_id: _.get(values, "customer.id"),
                from_date: formatDateToUrl(values.fromDate),
                to_date: formatDateToUrl(values.toDate),
              }),
            )}
          >
            {getLocalisationMessage("download", "Download")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(PendingBalanceDialog);
