import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../../ui-core/FlexBox";
import { getMessage } from "../../../reducers/LocalizationReducer";
import noResultsImage from "../../../assets/no-results.jpg";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    root: {
      marginTop: "10px",
    },
    photo: {
      width: "100%",
      height: "100%",
      marginBottom: "10px",
    },
  }),
);

OrderCreateWizardNoNSAArea.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardNoNSAArea(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox direction="column" className={classes.root}>
      <FlexBox flex={true}>
        <img className={classes.photo} alt="No Results" src={noResultsImage} />
      </FlexBox>
      <FlexBox flex={true}>
        {getLocalisationMessage(
          "not_service_needed",
          "Unfortunately, Seems like our partners do not provide the service you\n" +
            "        need. Please try changing your location or parcel weight.",
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderCreateWizardNoNSAArea);
