import { Observable } from "rxjs";
import React from "react";
import { Map, List as IList, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  List,
  Table,
  Divider,
  CardContent,
  ListItem,
  TableRow,
  ListSubheader,
  TableBody,
  Button,
  CardActions,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { FINANCE_TRANSACTIONS_URL } from "../../constants/CustomerPathConstants";
import {
  getMyBalance,
  getMyLatestTransactions,
} from "../../api/customer/CustomerFinanceApi";
import TextWithLink from "../../components/router/TextWithLink";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import {
  ALL_TAB_URL,
  COLLECTED_TAB_URL,
  WITHDRAWN_TAB_URL,
  WITHDRAWABLE_TAB_URL,
  TO_BE_COLLECTED_TAB_URL,
} from "../../components/customer/CustomerFinanceOrderListTabs";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_COD_STATEMENT_HISTORY_URL } from "../../../shared/constants/FileProxyControllerConstants";

const getColumnUrl = (columnKey, rowKey) => {
  if (rowKey === "cod_balance_total") {
    if (columnKey === "allOrders") {
      return ALL_TAB_URL;
    } else if (columnKey === "notCollectedOrders") {
      return TO_BE_COLLECTED_TAB_URL;
    } else if (columnKey === "inProgressOrders") {
      return COLLECTED_TAB_URL;
    } else if (columnKey === "deliveredOrders") {
      return WITHDRAWABLE_TAB_URL;
    } else if (columnKey === "settledOrders") {
      return WITHDRAWN_TAB_URL;
    }
  }

  return null;
};

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    th: { textAlign: "center", whiteSpace: "normal" },
    td: { textAlign: "center" },
    downloadButton: { float: "right", marginTop: "6px", marginRight: "6px" },
  }),
  mapPropsStream(propsStream => {
    const balanceStream = getMyBalance()
      .catch(error => Observable.of({ error }))
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const transactionsStream = getMyLatestTransactions()
      .catch(error => Observable.of({ error }))
      .map(
        fp.flow(
          fp.flow(
            fp.update("pending", Boolean),
            fp.update("payload", fp.flow(fp.get("data"), fp.toArray)),
            fromJS,
          ),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        balanceStream,
        transactionsStream,
        (props, balanceResponse, transactionsResponse) => ({
          ...props,
          balance: balanceResponse.get("payload"),
          balanceFetching: balanceResponse.get("pending"),
          transactions: transactionsResponse.get("payload"),
          transactionsFetching: transactionsResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

CustomerBalanceContainer.propTypes = {
  classes: PropTypes.object,
  balanceFetching: PropTypes.bool,
  balance: PropTypes.instanceOf(Map),
  transactionsFetching: PropTypes.bool,
  transactions: PropTypes.instanceOf(IList),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerBalanceContainer(props) {
  const { classes, getLocalisationMessage } = props;

  const ROWS = OrderedMap()
    .set(
      "non_cod_orders",
      getLocalisationMessage("non_cod_orders", "Non COD Orders"),
    )
    .set(
      "number_of_orders",
      getLocalisationMessage("number_of_cod_orders", "Number of COD Orders"),
    )
    .set(
      "delivery_charge_total",
      getLocalisationMessage("delivery_charge", "Delivery Charge"),
    )
    .set(
      "cod_balance_total",
      `${getLocalisationMessage("cod_balance", "COD Balance")}*`,
    )
    .set(
      "net_balance_total",
      `${getLocalisationMessage("net_balance", "NET Balance")}**`,
    );

  const COLUMNS = OrderedMap()
    .set(
      "allOrders",
      getLocalisationMessage("all_orders_to_date", "All Orders to Date"),
    )
    .set(
      "notCollectedOrders",
      getLocalisationMessage(
        "orders_not_collected_yet",
        "Orders not Collected Yet",
      ),
    )
    .set(
      "inProgressOrders",
      getLocalisationMessage(
        "orders_in_progress_&_cod_collectible",
        "Orders in Progress & COD Collectible",
      ),
    )
    .set(
      "deliveredOrders",
      getLocalisationMessage(
        "orders_delivered_rto_&_cod_payable",
        "Orders Delivered/RTO & COD Payable",
      ),
    )
    .set(
      "settledOrders",
      getLocalisationMessage(
        "fees_settled_&_cod_paid",
        "Fees Settled & COD Paid",
      ),
    );

  return (
    <CustomerAppLayout title={getLocalisationMessage("balance", "Balance")}>
      <Card>
        <PageLoading
          isLoading={props.balanceFetching || props.transactionsFetching}
        />
        <ListSubheader>
          My Balance
          <Button
            target="_blank"
            containerElement="a"
            className={classes.downloadButton}
            href={CREATE_COD_STATEMENT_HISTORY_URL}
          >
            {getLocalisationMessage("download_statement", "Download Statement")}
          </Button>
        </ListSubheader>

        <Table selectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell />
              {COLUMNS.map((columnTitle, columnKey) => (
                <TableCell key={columnKey} className={classes.th}>
                  {columnTitle}
                </TableCell>
              )).toArray()}
            </TableRow>
          </TableHead>
          <TableBody selectable={false} displayRowCheckbox={false}>
            {ROWS.map((rowTitle, rowKey) => (
              <TableRow key={rowKey}>
                <TableCell>{rowTitle}</TableCell>
                {COLUMNS.map((columnTitle, columnKey) => (
                  <TableCell key={columnKey} className={classes.td}>
                    <TextWithLink to={getColumnUrl(columnKey, rowKey)}>
                      {formatNumber(
                        props.balance.getIn([
                          "balance_items",
                          columnKey,
                          rowKey,
                        ]),
                      )}
                    </TextWithLink>
                  </TableCell>
                )).toArray()}
              </TableRow>
            )).toArray()}
          </TableBody>
        </Table>

        <CardContent>
          <p>
            {getLocalisationMessage(
              "balance_container_text_1",
              "* COD balance is just for COD orders only where by includes any cash\n" +
                "            amount collected/to be collected from the recipient.",
            )}
          </p>
          <p>
            {getLocalisationMessage(
              "balance_container_text_2",
              "** Four numbers are tentative and may change real time. COD transfer\n" +
                "            fees and other charges may apply where necessary.",
            )}{" "}
            <Link to={FINANCE_TRANSACTIONS_URL}>See</Link>{" "}
            {getLocalisationMessage(
              "transaction_page_for_details",
              "transaction page for details.",
            )}
          </p>
        </CardContent>
      </Card>

      <br />

      <FlexBox gutter={8} container={8}>
        <FlexBox flex={true} direction="column">
          <Card>
            <ListSubheader>
              {getLocalisationMessage("my_cod_balance", "My COD Balance")} (
              <PriceWrapper
                price={props.balance.get("cod_balance")}
                code={props.balance.getIn(["currency", "code"])}
              />
              )
            </ListSubheader>

            <CardContent>
              <p>
                {getLocalisationMessage(
                  "balance_container_text_3",
                  "We'll send your payment once a week. Only the amount accepted by\n" +
                    "                Wing will be transferred to you unless agreed otherwise.",
                )}
              </p>

              {Boolean(props.balance.get("last_payment_date")) && (
                <p>
                  {getLocalisationMessage(
                    "your_last_payment_was_issued_on",
                    "Your last payment was issued on",
                  )}{" "}
                  <strong>
                    {formatDate(props.balance.get("last_payment_date"))}{" "}
                    <PriceWrapper
                      price={props.balance.get("last_payment_amount")}
                      code={props.balance.getIn(["currency", "code"])}
                    />
                  </strong>
                </p>
              )}
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox flex={true} direction="column">
          <Card>
            <ListSubheader>
              {getLocalisationMessage("received_payments", "Received Payments")}
            </ListSubheader>
            <List>
              {props.transactions
                .map((item, key) => (
                  <div key={key}>
                    <ListItem
                      primaryText={
                        <PriceWrapper
                          price={item.get("amount")}
                          code={item.getIn(["currency", "code"])}
                        />
                      }
                      secondaryText={`${formatDate(
                        item.get("from_date"),
                      )} - ${formatDate(item.get("to_date"))}`}
                      containerElement={
                        <Link
                          to={updateQuery(FINANCE_TRANSACTIONS_URL, {
                            id: item.get("id"),
                          })}
                        />
                      }
                    />
                    <Divider />
                  </div>
                ))
                .toArray()}
            </List>
            <CardActions>
              <Button containerElement={<Link to={FINANCE_TRANSACTIONS_URL} />}>
                {getLocalisationMessage(
                  "view_transactions",
                  "View Transactions",
                )}
              </Button>
            </CardActions>
          </Card>
        </FlexBox>
      </FlexBox>
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerBalanceContainer);
