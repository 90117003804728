import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Avatar,
  Divider,
  CardContent,
  ListItem,
  ListSubheader,
  Button,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  Edit,
  Navigation,
  CreditCard,
  Cancel,
  Phone,
} from "@material-ui/icons";
import { FitBounds } from "react-google-map-components";
import {
  FitBounds as LeafletFitBounds,
  Route,
} from "react-leflet-map-components";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  formatDate,
  formatText,
  formatDateTime,
  cleanupStringArray,
} from "../../helpers/FormatUtils";
import {
  formatLocalisedPaymentType,
  mapOrderNotesAndHistoryToActivities,
} from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  isCustomMarketplace,
  isEnableDriverPhoneNumber,
  isEnableCancelOrderForCustomer,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { CUSTOMER_FEEDBACK } from "../../constants/RaitingType";
import {
  CANCELLED,
  COMPLETED,
  DELIVERY_FAILED,
} from "../../constants/OrderStatusCodes";
import { CASH, CREDIT_BALANCE } from "../../constants/OrderPaymentTypes";
import {
  COD,
  TOTAL,
  SERVICE,
  DISCOUNT,
  SERVICE_CUSTOM,
} from "../../constants/OrderChargeItemTypes";
import { ORDER_LIST_URL } from "../../constants/CustomerPathConstants";
import { getFileUrl } from "../../api/shared/FileApi";
import {
  getOrder,
  createOrderNote,
  updateOrderStatus,
  getOrderActivities,
  getCustomerFeedback,
  saveCustomerFeedback,
  updateOrderPaymentDetails,
} from "../../api/customer/CustomerOrderApi";
import BrandMarker from "../../components/maps/BrandMarker";
import PolylineWrapper from "../../components/maps/PolylineWrapper";
import GoogleMapWrapper from "../../components/maps/GoogleMapWrapper";
import AccountCardDetails from "../../components/icons/AccountCardDetails";
import Redirect from "../../components/router/Redirect";
import CustomerOrderChargeEditDialogWrapper from "../../wrappers/customer/CustomerOrderChargeEditDialogWrapper";
import Text, {
  BOLD,
  MUTED,
  DANGER,
  PRIMARY,
  SUCCESS,
} from "../../components/ui-core/Text";
import FlexBox from "../../components/ui-core/FlexBox";
import PageFab from "../../components/ui-core/PageFab";
import PageLoading from "../../components/ui-core/PageLoading";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import OrderStatusBreadcrumb from "../../components/deprecated/OrderStatusBreadcrumb";
import OrderDetailsBreadcrumb, {
  TAB_MAP,
  TAB_DETAILS,
  TAB_ACTIVITIES,
} from "../../components/deprecated/OrderDetailsBreadcrumb";
import PaypalDialog from "../../components/orders-core/PaypalDialog";
import PayfortDialog from "../../components/orders-core/PayfortDialog";
import OrderNoteDialog from "../../components/orders-core/OrderNoteDialog";
import CancelOrderDialog from "../../components/orders-core/CancelOrderDialog";
import StripePaymentDialog from "../../components/orders-core/StripePaymentDialog";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import OrderSupplierLandlineDialog from "../../components/orders-core/OrderSupplierLandlineDialog";
import UpdateOrderPaymentTypeDialog from "../../components/orders-core/UpdateOrderPaymentTypeDialog";
import Notification from "../../components/notifications/Notification";
import FeedbackDialog from "../../components/feedback-dialog/FeedbackDialog";
import { primary1 } from "../../../shared/theme/main-theme";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import LeafletBrandMarker from "../../components/maps/osm/BrandMaker";

import MarkerAccent from "../../components/maps/assets/marker-accent.png";
import MarkerPrimary from "../../components/maps/assets/marker-primary.png";

const NOTE_DIALOG_HASH = "#NDH";
const FEEDBACK_DIALOG_HASH = "#FDH";
const SUPPLIER_LANDLINE_HASH = "#SLH";
const SERVICE_CHARGE_EDIT_HASH = "#SCEH";
const ORDER_CANCEL_DIALOG_HASH = "#OCDH";
const PAY_FOR_ORDER_DIALOG_HASH = "#PFODH";
const UPDATE_PAYMENT_TYPE_DIALOG_HASH = "#UPTDH";

const NA = "N/A";

const formatTimeSlot = fp.flow(
  fp.map(fp.flow(fp.trim, v => v.slice(0, 5))),
  fp.join(" - "),
);

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
  }),
  useSheet({
    map: { height: "100%" },
    recipientSignature: { height: "152px", "& > img": { maxHeight: "100%" } },
    recipientPhoto: {
      overflow: "hidden",
      maxWidth: "250px",
      "& > img": { maxWidth: "100%", maxHeight: "100%" },
    },
    callToSupplier: {
      position: "fixed",
      bottom: "80px",
      right: 0,
      maxWidth: "120px",
      textAlign: "center",
      width: "100%",
      color: primary1,
      fontWeight: "bold",
      padding: "10px",
      zIndex: 10,
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isCustom: isCustomMarketplace(state),
      enableCancelOrder: isEnableCancelOrderForCustomer(state),
      enableDriverPhoneNumber: isEnableDriverPhoneNumber(state),
      mapProvider: getMapProvider(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const orderIdStream = propsStream
          .map(fp.flow(fp.get("params.id"), fp.toInteger))
          .distinctUntilChanged();

        const orderResponseStream = orderIdStream
          .switchMap(id =>
            getOrder(id)
              .catch(() => Observable.of({ notFound: true }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(fp.identity, fromJS, response =>
              fromJS({
                notFound: response.get("notFound", false),
                pending: response.get("pending", false),
                payload: response.getIn(["payload", "data"], Map()),
                orderNumber: response.getIn(
                  ["payload", "data", "order_number"],
                  null,
                ),
                deliverySignatureId: response.getIn(
                  ["payload", "data", "delivery_signature_id"],
                  null,
                ),
                deliveryPhotoId: response.getIn([
                  "payload",
                  "data",
                  "delivered_to_photo_id",
                ]),
              }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const activitiesResponseStream = orderResponseStream
          .filter(orderResponse => orderResponse.get("orderNumber"))
          .withLatestFrom(orderIdStream)
          .switchMap(([orderResponse, orderId]) =>
            getOrderActivities(
              orderId,
              orderResponse.get("orderNumber"),
            ).catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response =>
            fromJS({
              pending: response.pending || false,
              payload: mapOrderNotesAndHistoryToActivities(response.payload),
            }),
          )
          .distinctUntilChanged(isEqualData);

        const signatureUrlStream = orderResponseStream
          .filter(
            orderResponse =>
              fp.isFinite(orderResponse.get("deliverySignatureId")) &&
              orderResponse.get("deliverySignatureId") > 0,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(orderResponse =>
            getFileUrl(orderResponse.get("deliverySignatureId")).catch(error =>
              Observable.of({ error }),
            ),
          )
          .startWith({})
          .map(mapResponseData)
          .distinctUntilChanged(isEqualData);

        const photoUrlStream = orderResponseStream
          .filter(
            orderResponse =>
              fp.isFinite(orderResponse.get("deliveryPhotoId")) &&
              orderResponse.get("deliveryPhotoId") > 0,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(orderResponse =>
            getFileUrl(orderResponse.get("deliveryPhotoId")).catch(error =>
              Observable.of({ error }),
            ),
          )
          .startWith({})
          .map(mapResponseData)
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            orderResponseStream,
            activitiesResponseStream,
            signatureUrlStream,
            photoUrlStream,
            (
              props,
              orderResponse,
              activitiesResponse,
              signatureUrlResponse,
              photoUrlResponse,
            ) => ({
              ...props,
              onRequestRefresh,
              order: orderResponse.get("payload"),
              isFetching: orderResponse.get("pending"),
              isNotFound: orderResponse.get("notFound"),
              activities: activitiesResponse.get("payload"),
              activitiesFetching: activitiesResponse.get("pending"),
              signatureUrl: signatureUrlResponse.get("payload"),
              photoUrl: photoUrlResponse.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const chargeItemsStream = propsStream
          .distinctUntilKeyChanged("order")
          .map(
            fp.flow(
              fp.get("order"),
              order => order.get("charge_items"),
              chargeItems => (List.isList(chargeItems) ? chargeItems : List()),
              chargeItems =>
                chargeItems
                  .toMap()
                  .mapEntries(([, item]) => [item.get("charge_type"), item]),
            ),
          );

        const sideEffectsStream = Observable.merge(
          propsStream
            .map(
              fp.update("order", (item: Map) => ({
                id: item.get("id"),
                paymentType: item.get("payment_type"),
                paymentStatus: item.get("payment_status"),
              })),
            )
            .distinctUntilKeyChanged("order", isEqualData)
            .filter(props => !props.order.paymentStatus && 2 === 3)
            .do(props => props.replaceLocationHash(PAY_FOR_ORDER_DIALOG_HASH)),
          propsStream
            .map(
              fp.update("order", (item: Map) => ({
                id: item.get("id"),
                status: item.get("status"),
              })),
            )
            .distinctUntilKeyChanged("order", isEqualData)
            .filter(props => props.order.status === COMPLETED)
            .switchMap(
              props =>
                getCustomerFeedback(props.order.id)
                  .takeLast(1)
                  .catch(() => Observable.of({})),
              (props, response) => ({ props, response }),
            )
            .filter(fp.flow(fp.get("response.payload.data.id"), fp.isNil))
            .do(({ props }) => props.replaceLocationHash(FEEDBACK_DIALOG_HASH)),
        )
          .mapTo(null)
          .startWith(null)
          .catch(error => {
            console.error(error);

            return Observable.of(null);
          })
          .distinctUntilChanged();

        return propsStream.combineLatest(
          chargeItemsStream,
          sideEffectsStream,
          (props, chargeItems) => ({
            ...props,
            chargeItems,
          }),
        );
      },
    ),
  ),
);

CustomerOrderItem.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  replaceLocationQuery: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isFetching: PropTypes.bool,
  isNotFound: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  chargeItems: PropTypes.instanceOf(Map),

  activitiesFetching: PropTypes.bool,
  activities: PropTypes.instanceOf(List),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  signatureUrl: PropTypes.string,
  photoUrl: PropTypes.string,
  isCustom: PropTypes.bool,
  enableCancelOrder: PropTypes.bool,
  enableDriverPhoneNumber: PropTypes.bool,
  mapProvider: PropTypes.string,
};

function CustomerOrderItem(props) {
  const {
    order,
    location,
    classes,
    location: {
      query: { tab, successMessage, errorMessage },
    },
    getLocalisationMessage,
    signatureUrl,
    photoUrl,
    isCustom,
  } = props;

  const isOrderClosed = Boolean(
    order.get("status") === COMPLETED || order.get("status") === CANCELLED,
  );

  const chargeItems = order
    .get("charge_items", List())
    .toMap()
    .mapEntries(([, v]) => [v.get("charge_type"), v]);
  const currencyCode = order.getIn(["currency", "code"]);

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <CustomerAppLayout title={getLocalisationMessage("new_order", "New Order")}>
      <Redirect
        to={updateQuery(props.location, fp.set("tab", TAB_DETAILS))}
        when={tab !== TAB_DETAILS && tab !== TAB_ACTIVITIES && tab !== TAB_MAP}
      />

      <FeedbackDialog
        open={location.hash === FEEDBACK_DIALOG_HASH}
        onSubmit={values =>
          saveCustomerFeedback(
            order.get("id"),
            toSnakeCase({
              ...values,
              driver: order.get("driver"),
              supplier: order.get("supplier"),
              order_id: order.get("id"),
              rating_type: CUSTOMER_FEEDBACK,
            }),
          )
        }
        onSubmitSuccess={() => {
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <Notification
        type="success"
        uid="CustomerOrderItemSuccess"
        open={Boolean(successMessage)}
        action={
          <Button
            onClick={() =>
              props.replaceLocationQuery(fp.unset("successMessage"))
            }
          >
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        }
      >
        {successMessage}
      </Notification>

      <Notification
        type="error"
        uid="CustomerOrderItemError"
        open={Boolean(errorMessage)}
        action={
          <Button
            onClick={() => props.replaceLocationQuery(fp.unset("errorMessage"))}
          >
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        }
      >
        {errorMessage}
      </Notification>

      <PageLoading
        isLoading={
          tab === TAB_DETAILS ? props.isFetching : props.activitiesFetching
        }
      />

      {props.isNotFound && (
        <Card>
          <CardContent>
            <FlexBox align="center" justify="center" direction="column">
              <div>
                {getLocalisationMessage(
                  "you_are_not_allowed_to_view_this_order",
                  "You are NOT allowed to view this order!",
                )}
              </div>
              <div>
                {getLocalisationMessage(
                  "please_contact_support_team_for_more_information",
                  "Please contact support team for more information.",
                )}
              </div>
            </FlexBox>
          </CardContent>
        </Card>
      )}

      {Boolean(!props.isFetching && !props.isNotFound) && (
        <div>
          <OrderNoteDialog
            open={location.hash === NOTE_DIALOG_HASH}
            onSubmit={values =>
              createOrderNote(order.get("id"), { value: values.content }).catch(
                ResponseError.throw,
              )
            }
            onSubmitSuccess={() => {
              props.onRequestRefresh();
              props.replaceLocationHash(null);
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_note_created",
                  "Successfully Note Created",
                ),
              );
            }}
            onRequestClose={() => props.replaceLocationHash(null)}
            onSubmitFail={() => props.showErrorMessage()}
          />

          <UpdateOrderPaymentTypeDialog
            onRequestClose={() => props.replaceLocationHash(null)}
            open={location.hash === UPDATE_PAYMENT_TYPE_DIALOG_HASH}
            initialValues={{ payer: order.get("payer") }}
            onSubmit={values =>
              updateOrderPaymentDetails(order.get("id"), CASH, values.payer)
            }
            onSubmitSuccess={() => {
              props.onRequestRefresh();
              props.replaceLocationHash(null);
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_order_payment_type_created",
                  "Successfully Order Payment Type Created",
                ),
              );
            }}
            onSubmitFail={() => props.showErrorMessage()}
          />

          {Boolean(
            order.get("payment_url") &&
              !order.get("payment_status") &&
              2 === 3 &&
              location.hash === PAY_FOR_ORDER_DIALOG_HASH,
          ) && (
            <PaypalDialog
              open={true}
              url={order.get("payment_url")}
              onRequestClose={() => props.replaceLocationHash(null)}
            />
          )}

          {Boolean(
            order.size > 0 &&
              !props.chargeItems.getIn(["service", "paid"]) &&
              !props.chargeItems.getIn(["service", "refund"]) &&
              order.get("status") !== CANCELLED &&
              2 === 3 &&
              location.hash === PAY_FOR_ORDER_DIALOG_HASH,
          ) && (
            <PayfortDialog
              open={true}
              orderId={order.get("id")}
              onRequestClose={() => props.replaceLocationHash(null)}
            />
          )}
          {Boolean(
            order.size > 0 &&
              !props.chargeItems.getIn(["service", "paid"]) &&
              !props.chargeItems.getIn(["service", "refund"]) &&
              2 === 3 &&
              location.hash === PAY_FOR_ORDER_DIALOG_HASH,
          ) && (
            <StripePaymentDialog
              open={true}
              orderId={order.get("id")}
              onRequestClose={() => props.replaceLocationHash(null)}
            />
          )}

          {order.getIn(["supplier", "phone"]) && (
            <div>
              <OrderSupplierLandlineDialog
                supplier={order.get("supplier")}
                open={location.hash === SUPPLIER_LANDLINE_HASH}
                onRequestClose={() => props.replaceLocationHash(null)}
              />

              <div className={classes.callToSupplier}>
                {getLocalisationMessage("call_to", "Call to")} <br />
                {order.getIn(["supplier", "name"])}
              </div>
              <PageFab
                autoHide={false}
                onClick={() =>
                  props.replaceLocationHash(SUPPLIER_LANDLINE_HASH)
                }
              >
                <Phone />
              </PageFab>
            </div>
          )}

          {tab && (
            <OrderDetailsBreadcrumb
              replaceLocationHash={props.replaceLocationHash}
              NOTE_DIALOG_HASH={NOTE_DIALOG_HASH}
              tab={tab}
              order={order}
              orderListUrl={ORDER_LIST_URL}
              onTabChange={nextTab =>
                props.replaceLocationQuery(fp.set("tab", nextTab))
              }
            />
          )}

          {tab === TAB_DETAILS && (
            <Card>
              {props.enableCancelOrder && (
                <CancelOrderDialog
                  onRequestClose={() => props.replaceLocationHash(null)}
                  open={
                    !isOrderClosed && location.hash === ORDER_CANCEL_DIALOG_HASH
                  }
                  onSubmit={() =>
                    updateOrderStatus(order.get("id"), CANCELLED).catch(
                      ResponseError.throw,
                    )
                  }
                  onSubmitSuccess={() => {
                    props.onRequestRefresh();
                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "order_successfully_cancelled",
                        "Order Successfully Cancelled",
                      ),
                    );
                  }}
                  onSubmitFail={error => {
                    props.onRequestRefresh();
                    props.showErrorMessage(error);
                    props.replaceLocationHash(null);
                  }}
                />
              )}

              <CustomerOrderChargeEditDialogWrapper
                orderId={order.get("id")}
                open={false && location.hash === SERVICE_CHARGE_EDIT_HASH}
                onRequestClose={() => {
                  props.replaceLocationHash(null);
                  props.onRequestRefresh();
                }}
              />

              <CardContent>
                <FlexBox gutter={8} wrap={true}>
                  <FlexBox flex={true} xs={12}>
                    <FlexBox flex={true} xs={6} direction="column">
                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage(
                          "order_number",
                          "Order Number",
                        )}
                        secondary={order.get("order_number")}
                      />
                      {(order.get("reference_id") && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "reference_id",
                            "Reference Id",
                          )}
                          secondary={order.get("reference_id")}
                        />
                      )) ||
                        null}
                      {(order.get("external_user_name") && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "external_user_name",
                            "Amazon Id",
                          )}
                          secondary={order.get("external_user_name")}
                        />
                      )) ||
                        null}

                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage(
                          "delivery_status",
                          "Delivery Status",
                        )}
                        secondary={
                          <Text
                            type={[
                              BOLD,
                              order.get("status") === COMPLETED
                                ? SUCCESS
                                : order.get("status") === CANCELLED ||
                                  order.get("status") === DELIVERY_FAILED
                                ? DANGER
                                : PRIMARY,
                            ]}
                          >
                            {getLocalisationMessage(
                              order.get("status"),
                              formatText(order.get("status")),
                            )}
                          </Text>
                        }
                        rightIconButton={
                          !isOrderClosed && props.enableCancelOrder ? (
                            <IconButton
                              tooltip={getLocalisationMessage(
                                "cancel_order",
                                "Cancel Order",
                              )}
                              onClick={() =>
                                props.replaceLocationHash(
                                  ORDER_CANCEL_DIALOG_HASH,
                                )
                              }
                            >
                              <Cancel />
                            </IconButton>
                          ) : null
                        }
                      />

                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage(
                          "payment_status",
                          "Payment Status",
                        )}
                        secondary={
                          order.get("payment_status") ? (
                            <Text type={SUCCESS}>
                              {getLocalisationMessage("paid", "Paid")}
                            </Text>
                          ) : (
                            <Text>
                              {getLocalisationMessage("not_paid", "not_paid")}
                            </Text>
                          )
                        }
                        rightIconButton={
                          !isOrderClosed &&
                          !order.get("payment_status") &&
                          2 === 3 ? (
                            <IconButton
                              tooltip={getLocalisationMessage(
                                "pay_for_order",
                                "Pay for Order",
                              )}
                              onClick={() =>
                                props.replaceLocationHash(
                                  PAY_FOR_ORDER_DIALOG_HASH,
                                )
                              }
                            >
                              <CreditCard />
                            </IconButton>
                          ) : null
                        }
                      />

                      <ListItem
                        disabled={true}
                        leftAvatar={
                          <Avatar
                            size={40}
                            src={order.getIn(["supplier", "photo"])}
                          />
                        }
                        primary={getLocalisationMessage("supplier", "Supplier")}
                        secondary={
                          <Text fallbackValue={NA} fallbackType={MUTED}>
                            {order.getIn(["supplier", "name"])}
                          </Text>
                        }
                      />

                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage("driver", "Driver")}
                        secondary={
                          <Text fallbackValue={NA} fallbackType={MUTED}>
                            {order.getIn(["driver", "name"])}
                          </Text>
                        }
                      />
                      {props.enableDriverPhoneNumber && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "driver_phone",
                            "Driver Phone",
                          )}
                          secondary={
                            <Text fallbackValue={NA} fallbackType={MUTED}>
                              {order.getIn(["driver", "phone"])}
                            </Text>
                          }
                        />
                      )}
                    </FlexBox>
                    <FlexBox flex={true} xs={6} direction="column">
                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage(
                          "delivery_service",
                          "Delivery Service",
                        )}
                        secondary={cleanupStringArray([
                          order.getIn(["package", "delivery_label"]),
                          order.getIn(["package", "name"]),
                          cleanupStringArray([
                            order.getIn(["package", "to_city", "name"]),
                            order.getIn(["package", "from_city", "name"]),
                          ]).join(" to "),
                          order.getIn(["package", "menu", "name"]),
                        ]).join(", ")}
                      />

                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage(
                          "created_at",
                          "Created At",
                        )}
                        secondary={formatDateTime(order.get("created_date"))}
                      />

                      {order.get("estimated_pickup_time") && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "pick_up_timeslot",
                            "Pick Up Timeslot",
                          )}
                          secondary={[
                            formatDate(order.get("estimated_pickup_time")),
                            formatTimeSlot([
                              order.getIn(["pickup_timeslot", "start_time"]),
                              order.getIn(["pickup_timeslot", "end_time"]),
                            ]),
                          ].join(" — ")}
                        />
                      )}

                      {!order.get("estimated_pickup_time") && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "pick_up_time",
                            "Pick Up Time",
                          )}
                          secondary={
                            order.get("pickup_time_now")
                              ? getLocalisationMessage("asap", "ASAP")
                              : formatDateTime(order.get("pickup_time"))
                          }
                        />
                      )}

                      {order.get("estimated_delivery_time") && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "delivery_timeslot",
                            "Delivery Timeslot",
                          )}
                          secondary={[
                            formatDate(order.get("estimated_delivery_time")),
                            formatTimeSlot([
                              order.getIn(["delivery_timeslot", "start_time"]),
                              order.getIn(["delivery_timeslot", "end_time"]),
                            ]),
                          ].join(" — ")}
                        />
                      )}

                      {Boolean(
                        !order.get("estimated_delivery_time") &&
                          order.get("deadline_time"),
                      ) && (
                        <ListItem
                          disabled={true}
                          primary={getLocalisationMessage(
                            "pick_up_time",
                            "Pick Up Time",
                          )}
                          secondary={formatDateTime(order.get("deadline_time"))}
                        />
                      )}

                      <ListItem
                        disabled={true}
                        primary={getLocalisationMessage(
                          "if_recipient_not_available",
                          "If Recipient Not Available",
                        )}
                        secondary={getLocalisationMessage(
                          order.get("recipient_not_available"),
                          formatText(order.get("recipient_not_available")),
                        )}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <Divider />

                <FlexBox gutter={8} wrap={true}>
                  <FlexBox flex={true} xs={12} direction="column">
                    <ListSubheader>
                      {getLocalisationMessage(
                        "pickup_location_details",
                        "Pickup Location Details",
                      )}
                    </ListSubheader>

                    <ListItem
                      disabled={true}
                      primary={getLocalisationMessage(
                        "pick_up_location",
                        "Pick Up Location",
                      )}
                      secondary={order.getIn(["locations", 0, "address"])}
                      secondaryTextLines={2}
                      rightIconButton={
                        order.getIn([
                          "locations",
                          0,
                          "confirmed_by_recipient",
                        ]) ? (
                          <IconButton
                            tooltip={getLocalisationMessage(
                              "location_confirmed",
                              "Location Confirmed",
                            )}
                          >
                            <Navigation />
                          </IconButton>
                        ) : null
                      }
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "pickup_details",
                        "Pickup Details",
                      )}
                      secondaryText={order.getIn(["locations", 0, "details"])}
                      secondaryTextLines={2}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "sender_name",
                        "Sender Name",
                      )}
                      secondaryText={order.getIn([
                        "locations",
                        0,
                        "contact_name",
                      ])}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "sender_phone_number",
                        "Sender Phone Number",
                      )}
                      secondaryText={order.getIn(["locations", 0, "phone"])}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "sender_email",
                        "Sender Email",
                      )}
                      secondaryText={order.getIn(["locations", 0, "email"])}
                    />
                  </FlexBox>

                  <FlexBox flex={true} xs={12} direction="column">
                    <ListSubheader>
                      {getLocalisationMessage(
                        "dropoff_location_details",
                        "Dropoff Location Details",
                      )}
                    </ListSubheader>

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "dropoff_to",
                        "Dropoff To",
                      )}
                      secondaryText={order.getIn(["locations", 1, "address"])}
                      secondaryTextLines={2}
                      rightIconButton={
                        order.getIn([
                          "locations",
                          1,
                          "confirmed_by_recipient",
                        ]) ? (
                          <IconButton
                            tooltip={getLocalisationMessage(
                              "location_confirmed",
                              "Location Confirmed",
                            )}
                          >
                            <Navigation />
                          </IconButton>
                        ) : null
                      }
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "dropoff_details",
                        "Dropoff Details",
                      )}
                      secondaryText={order.getIn(["locations", 1, "details"])}
                      secondaryTextLines={2}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "recipient_name",
                        "Recipient Name",
                      )}
                      secondaryText={order.getIn([
                        "locations",
                        1,
                        "contact_name",
                      ])}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "recipient_phone_number",
                        "Recipient Phone Number",
                      )}
                      secondaryText={order.getIn(["locations", 1, "phone"])}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "recipient_email",
                        "Recipient Email",
                      )}
                      secondaryText={order.getIn(["locations", 1, "email"])}
                    />
                  </FlexBox>
                </FlexBox>

                <Divider />

                {Boolean(signatureUrl && order.get("status") === COMPLETED) && (
                  <div>
                    <FlexBox gutter={8} wrap={true}>
                      <FlexBox flex={true} xs={12} direction="column">
                        <ListSubheader>Actual Recipient Details</ListSubheader>
                        <ListItem
                          disabled={true}
                          primaryText={getLocalisationMessage("name", "Name")}
                          secondaryText={order.get("delivered_to") || NA}
                          rightIconButton={
                            order.get("delivered_to_photo") ? (
                              <IconButton
                                href={order.get("delivered_to_photo")}
                                target="_blank"
                                containerElement="a"
                              >
                                <AccountCardDetails />
                              </IconButton>
                            ) : null
                          }
                        />
                        <ListItem
                          disabled={true}
                          primaryText={getLocalisationMessage(
                            "phone_number",
                            "Phone Number",
                          )}
                          secondaryText={order.get("delivered_to_phone") || NA}
                        />
                      </FlexBox>

                      <FlexBox flex={true} xs={12} direction="column">
                        {signatureUrl && (
                          <FlexBox xs={12} direction="column">
                            <ListSubheader>
                              {getLocalisationMessage(
                                "recipient_signature",
                                "Recipient Signature",
                              )}
                            </ListSubheader>
                            <div className={props.classes.recipientSignature}>
                              <img
                                alt={getLocalisationMessage(
                                  "signature",
                                  "Signature",
                                )}
                                src={signatureUrl}
                              />
                            </div>
                          </FlexBox>
                        )}

                        {photoUrl && (
                          <FlexBox flex={true} xs={12} direction="column">
                            <ListSubheader>
                              {getLocalisationMessage("photo", "Photo")}
                            </ListSubheader>
                            <div className={props.classes.recipientPhoto}>
                              <img
                                alt={getLocalisationMessage("photo", "Photo")}
                                src={photoUrl}
                              />
                            </div>
                          </FlexBox>
                        )}
                      </FlexBox>
                    </FlexBox>

                    <Divider />
                  </div>
                )}

                <FlexBox gutter={8} wrap={true}>
                  <FlexBox flex={true} xs={12} direction="column">
                    <ListSubheader>
                      {getLocalisationMessage(
                        "payment_details",
                        "Payment Details",
                      )}
                    </ListSubheader>

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "delivery_charge_paid_by",
                        "Delivery Charge Paid By",
                      )}
                      secondaryText={getLocalisationMessage(
                        order.get("payer"),
                        formatText(order.get("payer")),
                      )}
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "payment_type",
                        "Payment Type",
                      )}
                      secondaryText={formatLocalisedPaymentType(
                        order.get("payment_type"),
                        getLocalisationMessage,
                      )}
                      rightIconButton={
                        !isOrderClosed &&
                        isCustom &&
                        !order.get("payment_status") &&
                        2 === 3 ? (
                          <IconButton
                            tooltip="Edit Service Payment"
                            onClick={() =>
                              props.replaceLocationHash(
                                UPDATE_PAYMENT_TYPE_DIALOG_HASH,
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        ) : null
                      }
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "shipping_price",
                        "Shipping Price",
                      )}
                      secondaryText={
                        <PriceWrapper
                          code={currencyCode}
                          price={chargeItems.getIn([SERVICE, "charge"])}
                          paid={chargeItems.getIn([SERVICE, "paid"])}
                        />
                      }
                    />

                    {order.get("payment_type") === CREDIT_BALANCE && (
                      <ListItem
                        disabled={true}
                        primaryText={getLocalisationMessage(
                          "actual_shipping_price",
                          "Actual Shipping Price",
                        )}
                        secondaryText={
                          <PriceWrapper
                            code={currencyCode}
                            price={chargeItems.getIn([
                              SERVICE_CUSTOM,
                              "charge",
                            ])}
                            paid={chargeItems.getIn([SERVICE_CUSTOM, "paid"])}
                          />
                        }
                      />
                    )}

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "cod_charge",
                        "COD Charge",
                      )}
                      secondaryText={
                        <PriceWrapper
                          code={currencyCode}
                          price={chargeItems.getIn([COD, "charge"])}
                          paid={chargeItems.getIn([COD, "paid"])}
                        />
                      }
                    />

                    {order.get("promo") && (
                      <ListItem
                        disabled={true}
                        primaryText={order.getIn(["promo", "title"])}
                        secondaryText={order.getIn(["promo", "subtitle"])}
                      />
                    )}

                    {chargeItems.getIn([DISCOUNT, "charge"]) > 0 && (
                      <ListItem
                        disabled={true}
                        primaryText={getLocalisationMessage(
                          "discount",
                          "Discount",
                        )}
                        secondaryText={chargeItems.getIn([DISCOUNT, "charge"])}
                      />
                    )}

                    <Divider />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "total_charge",
                        "Total Charge",
                      )}
                      secondaryText={
                        <PriceWrapper
                          code={currencyCode}
                          price={chargeItems.getIn([TOTAL, "charge"])}
                        />
                      }
                    />
                  </FlexBox>

                  <FlexBox flex={true} xs={12} direction="column">
                    <ListSubheader>
                      {getLocalisationMessage(
                        "parcel_details",
                        "Parcel Details",
                      )}
                    </ListSubheader>

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "attachments",
                        "Attachments",
                      )}
                      secondaryText={
                        order.get("attachments") &&
                        order.get("attachments").size
                          ? `${
                              order.get("attachments").size
                            } ${getLocalisationMessage(
                              "attachment_with_s",
                              "attachment(s)",
                            )}`
                          : getLocalisationMessage(
                              "no_attachments",
                              "No attachments",
                            )
                      }
                    />

                    <ListItem
                      disabled={true}
                      primaryText={getLocalisationMessage(
                        "parcel_notes",
                        "Parcel Notes",
                      )}
                      secondaryTextLines={2}
                      secondaryText={order.get("note") || NA}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          )}

          {tab === TAB_ACTIVITIES && (
            <Card>
              <CardContent>
                <OrderStatusBreadcrumb
                  status={order.get("status")}
                  getLocalisationMessage={getLocalisationMessage}
                />
                <OrderActivitiesTimeline
                  showCallLogUrl={false}
                  activities={props.activities}
                />
              </CardContent>
            </Card>
          )}

          {tab === TAB_MAP && (
            <Card>
              <CardContent>
                <FlexBox
                  flex={true}
                  xs={12}
                  style={{ height: 400 }}
                  direction="column"
                >
                  {isGoogleMapProvider ? (
                    <GoogleMapWrapper>
                      <FitBounds
                        latLngBounds={[
                          {
                            lat: order.getIn(["locations", 0, "lat"]),
                            lng: order.getIn(["locations", 0, "lon"]),
                          },
                          {
                            lat: order.getIn(["locations", 1, "lat"]),
                            lng: order.getIn(["locations", 1, "lon"]),
                          },
                        ]}
                      />

                      <LeafletBrandMarker
                        position={{
                          lat: order.getIn(["locations", 0, "lat"]),
                          lng: order.getIn(["locations", 0, "lon"]),
                        }}
                      />

                      <LeafletBrandMarker
                        accent={false}
                        position={{
                          lat: order.getIn(["locations", 1, "lat"]),
                          lng: order.getIn(["locations", 1, "lon"]),
                        }}
                      />

                      <PolylineWrapper
                        origin={{
                          lat: order.getIn(["locations", 0, "lat"]),
                          lng: order.getIn(["locations", 0, "lon"]),
                        }}
                        destination={{
                          lat: order.getIn(["locations", 1, "lat"]),
                          lng: order.getIn(["locations", 1, "lon"]),
                        }}
                      />
                    </GoogleMapWrapper>
                  ) : (
                    <LeafletMapWrapper>
                      <LeafletFitBounds
                        latLngBounds={[
                          {
                            lat: order.getIn(["locations", 0, "lat"]),
                            lng: order.getIn(["locations", 0, "lon"]),
                          },
                          {
                            lat: order.getIn(["locations", 1, "lat"]),
                            lng: order.getIn(["locations", 1, "lon"]),
                          },
                        ]}
                      />

                      <BrandMarker
                        position={{
                          lat: order.getIn(["locations", 0, "lat"]),
                          lng: order.getIn(["locations", 0, "lon"]),
                        }}
                      />

                      <BrandMarker
                        accent={false}
                        position={{
                          lat: order.getIn(["locations", 1, "lat"]),
                          lng: order.getIn(["locations", 1, "lon"]),
                        }}
                      />
                      <Route
                        strokeColor="#FF0000"
                        strokeWeight={3}
                        strokeOpacity={0.8}
                        origin={{
                          lat: order.getIn(["locations", 0, "lat"]),
                          lng: order.getIn(["locations", 0, "lon"]),
                          icon: {
                            url: MarkerPrimary,
                            options: {
                              anchor: { x: 16, y: 16 },
                              size: { width: 32, height: 32 },
                            },
                          },
                        }}
                        destination={{
                          lat: order.getIn(["locations", 1, "lat"]),
                          lng: order.getIn(["locations", 1, "lon"]),
                          icon: {
                            url: MarkerAccent,
                            options: {
                              anchor: { x: 16, y: 16 },
                              size: { width: 32, height: 32 },
                            },
                          },
                        }}
                        path={order.getIn(["locations"])}
                      />
                    </LeafletMapWrapper>
                  )}
                </FlexBox>
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerOrderItem);
