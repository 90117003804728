import React from "react";
import { List } from "immutable";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataListFilter from "../../helpers/DataListFilter";
import { mapOrderListPropsStream } from "../../helpers/OrderListHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ORDER_ITEM_URL } from "../../constants/CustomerPathConstants";
import { getReceivingOrders } from "../../api/shared/OrderApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import OrderList from "../../components/orders-core/OrderList";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({ setLocationQueryFilter: PropTypes.func }),
  mapPropsStream(
    mapOrderListPropsStream(
      getReceivingOrders,
      (props, filter, response, onRequestRefresh) => ({
        ...props,
        filter,
        onRequestRefresh,
        list: response.get("list"),
        total: response.get("total"),
        isLoading: response.get("isLoading"),
      }),
    ),
  ),
);

CustomerReceivingOrders.propTypes = {
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerReceivingOrders(props) {
  return (
    <CustomerAppLayout
      slug="receiving_orders"
      title={props.getLocalisationMessage(
        "receiving_orders",
        "Receiving Orders",
      )}
    >
      <OrderList
        withCustomer={false}
        withCODStatus={false}
        withLastDriver={false}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        createOrderHref={id => ORDER_ITEM_URL + id}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerReceivingOrders);
