import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import { getCachedJM, getJMPredictions } from "../../api/shared/CountryV2Api";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = (name) => ({ name });
const formatOption = fp.get("name");
// const distinctBy = ({ countryId, input: { value } }) => ({ countryId, value });

const enhancer = compose(
  getContext({
    getCachedJM: PropTypes.func,
    getJMPredictions: PropTypes.func,
  }),
);

const JMAutoComplete = enhancer(
  componentFromStream((propsStream) => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ input: { value } }) =>
        value && value.id && !value.name && getCachedJM
          ? getCachedJM(value.id)
              .map((item) => ({ id: value.id, name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value.name"))
      .switchMap(({ withTargetFilter, input: { value } }) =>
        getJMPredictions
          ? getJMPredictions(
              baseFilter.setValueMap({
                search: value && value.name,
                target: withTargetFilter && "ORDER",
              }),
            )
              .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
              .catch(() => Observable.of(List()))
          : Observable.of(List()),
      )
      .distinctUntilChanged(isEqualData)
      .map((v) => v.toJS());

    return propsStream.combineLatest(
      valueStream,
      optionsStream,
      ({ readOnly, ...props }, value, options) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
          readOnly={readOnly}
        />
      ),
    );
  }),
);

FormJMOrPostcodeAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormJMOrPostcodeAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormJMOrPostcodeAutoComplete(props) {
  return <Field {...props} component={JMAutoComplete} />;
}
