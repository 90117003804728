import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { REGULAR_ORDER } from "../../../constants/OrderTypes";
import regular from "../../../assets/regular.svg";
import reverse from "../../../assets/reverse.svg";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    reverseDiv: {
      width: 40,
      height: 40,
      backgroundColor: "#FF6347",
      borderRadius: "50%",
    },
    regularImg: {
      width: 40,
      height: 40,
      padding: 5,
    },
    reverseImg: {
      width: 40,
      height: 40,
      padding: 5,
      // transform: "rotate(135deg)",
    },
    primaryText: {
      textTransform: "capitalize",
    },
  }),
);

OrderCreateWizardStepReverseItem.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  logistic_type: PropTypes.any,
};

function OrderCreateWizardStepReverseItem(props) {
  return (
    <OrderCreateWizardStepDetails
      label={props.getLocalisationMessage("type", "Type")}
      onClick={props.onClick}
      avatar={
        <div className={props.classes.reverseDiv}>
          {props.logistic_type === REGULAR_ORDER ? (
            <img
              src={regular}
              alt="regular"
              className={props.classes.regularImg}
            />
          ) : (
            <img
              src={reverse}
              alt="reverse"
              className={props.classes.reverseImg}
            />
          )}
        </div>
      }
      primaryText={
        <h6 className={props.classes.primaryText}>
          {props.getLocalisationMessage(
            props.logistic_type,
            props.logistic_type,
          )}
        </h6>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepReverseItem);
