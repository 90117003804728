import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { Add, MyLocation, MoreHoriz } from "@material-ui/icons";
import spring from "react-motion/lib/spring";
import presets from "react-motion/lib/presets";
import TransitionMotion from "react-motion/lib/TransitionMotion";
import UserAvatar from "../../avatars/UserAvatar";
import Text, { BOLD, SMALL, INVERT } from "../../ui-core/Text";
import FlexBox from "../../ui-core/FlexBox";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { primary1 } from "../../../../shared/theme/main-theme";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet(
    {
      root: { marginTop: "12px" },
      item: { cursor: "pointer", padding: "0 4px" },
      avatar: {
        transform: "translate3d(0, 0, 0)",
        "& svg": { important: true, fill: primary1 },
      },
      title: {
        minHeight: "24px",
        overflow: "hidden",
        textAlign: "center",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      subtitle: { marginTop: "4px", lineHeight: "1.2", textAlign: "center" },
    },
    { important: false },
  ),
);

OrderCreateWizardAvatars.propTypes = {
  classes: PropTypes.object,
  onAddNew: PropTypes.func,
  onFindMyLocation: PropTypes.func,
  onAddressBookClick: PropTypes.func,
  onSuggestionClick: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

const willEnter = () => ({ opacity: 0, scale: 0 });
const willLeave = () => ({ opacity: spring(0), scale: 1 });

function OrderCreateWizardAvatars(props) {
  const { getLocalisationMessage } = props;
  const styles = [];
  const defaultStyles = [];

  const itemStyle = { opacity: spring(1), scale: spring(1, presets.wobbly) };
  const itemDefaultStyle = { opacity: 0, scale: 0 };

  if (!props.suggestions.isEmpty()) {
    props.suggestions.forEach((item, index) => {
      const key = String(index);

      const data = {
        payload: item,
        title: item.get("name"),
        subtitle: `${item.getIn(["city", "name"])} - ${item.getIn([
          "country",
          "name",
        ])}`,
      };

      styles.push({ key, data, style: itemStyle });
      defaultStyles.push({ key, data, style: itemDefaultStyle });
    });
  } else if (props.onAddNew) {
    const key = "add_new";
    const data = {
      title: getLocalisationMessage("other", "Other"),
      subtitle: getLocalisationMessage("add_new", "Add New"),
      icon: <Add />,
      onClick: props.onAddNew,
    };

    styles.push({ key, data, style: itemStyle });
    defaultStyles.push({ key, data, style: itemDefaultStyle });
  }

  if (props.onAddressBookClick) {
    const key = "address_book";
    const data = {
      title: getLocalisationMessage("other", "Other"),
      subtitle: getLocalisationMessage("address_book", "Address Book"),
      icon: <MoreHoriz />,
      onClick: props.onAddressBookClick,
    };

    styles.push({ key, data, style: itemStyle });
    defaultStyles.push({ key, data, style: itemDefaultStyle });
  }

  if (props.onFindMyLocation) {
    const key = "find_my_location";
    const data = {
      title: getLocalisationMessage("location", "Location"),
      subtitle: getLocalisationMessage("find_my_location", "Find my location"),
      icon: <MyLocation />,
      onClick: props.onFindMyLocation,
    };

    styles.push({ key, data, style: itemStyle });
    defaultStyles.push({ key, data, style: itemDefaultStyle });
  }

  return (
    <TransitionMotion
      willEnter={willEnter}
      willLeave={willLeave}
      styles={styles}
      defaultStyles={defaultStyles}
    >
      {interpolatedStyles => (
        <FlexBox
          flex={true}
          justify="space-around"
          className={props.classes.root}
        >
          {interpolatedStyles.map(item => (
            <FlexBox
              key={item.key}
              flex={true}
              align="center"
              direction="column"
              className={props.classes.item}
              onClick={() =>
                item.data.onClick
                  ? item.data.onClick()
                  : props.onSuggestionClick(item.data.payload)
              }
            >
              <Text
                type={[SMALL, INVERT, BOLD]}
                className={props.classes.title}
                style={{ opacity: item.style.opacity }}
              >
                {item.data.title}
              </Text>

              {item.data.icon ? (
                <Avatar
                  className={props.classes.avatar}
                  onClick={item.data.onClick}
                  style={{
                    transform: `scale3d(${item.style.scale}, ${item.style.scale}, 1)`,
                    backgroundColor: "#fff",
                  }}
                >
                  {item.data.icon}
                </Avatar>
              ) : (
                <UserAvatar
                  name={item.data.title}
                  className={props.classes.avatar}
                  style={{
                    transform: `scale3d(${item.style.scale}, ${item.style.scale}, 1)`,
                  }}
                />
              )}

              <Text
                type={[SMALL, INVERT, BOLD]}
                className={props.classes.subtitle}
                style={{ opacity: item.style.opacity }}
              >
                {item.data.subtitle}
              </Text>
            </FlexBox>
          ))}
        </FlexBox>
      )}
    </TransitionMotion>
  );
}

export default enhancer(OrderCreateWizardAvatars);
