import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  isEqualData,
  isEqualDataIn,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { COMMUNICATION_SETTINGS_LIST_URL } from "../../constants/CustomerPathConstants";
import {
  getCommunicationMessage,
  deleteCommunicationMessage,
  saveCustomerCommunicationMessage,
} from "../../api/shared/CommunicationApi";
import FormDialog from "../../components/form/FormDialog";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CommunicationSettingsForm from "../../components/communication-settings/CommunicationSettingsForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const DELETE_MESSAGE_DIALOG_HASH = "#DMDH";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationHash: PropTypes.func.isRequired,
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showSuccessMessage,
      showErrorMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const itemCommunicationResponseStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["params", "id"]))
      .switchMap(props =>
        getCommunicationMessage(props.params.id).catch(ResponseError.throw),
      )
      .startWith({})
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              item: response.getIn(["payload", "data"], Map()),
              isLoading: response.get("pending", false),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        itemCommunicationResponseStream,
        (props, itemCommunicationResponse) => ({
          ...props,
          item: itemCommunicationResponse.get("item"),
          isLoading: itemCommunicationResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

CustomerCommunicationSettingsItem.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,

  setLocation: PropTypes.func,
  setLocationHash: PropTypes.func,

  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerCommunicationSettingsItem(props) {
  return (
    <CustomerAppLayout
      title={props.getLocalisationMessage(
        "communication_settings",
        "Communication Settings",
      )}
    >
      <FormDialog
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.setLocationHash(null)}
        open={props.location.hash === DELETE_MESSAGE_DIALOG_HASH}
        onSubmit={() =>
          deleteCommunicationMessage(props.params.id).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.setLocation(
            updateQuery(COMMUNICATION_SETTINGS_LIST_URL, {
              ...props.location.query,
            }),
          );
        }}
      >
        {props.getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_message",
          "Are you sure you want to remove this message?",
        )}
      </FormDialog>

      <CommunicationSettingsForm
        isLoading={props.isLoading}
        breadcrumbTitle={props.getLocalisationMessage(
          "edit_message",
          "Edit Message",
        )}
        onSubmitFail={props.showErrorMessage}
        initialValues={toCamelCase(props.item)}
        onSubmitSuccess={() =>
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          )
        }
        onDeleteClick={() => props.setLocationHash(DELETE_MESSAGE_DIALOG_HASH)}
        onSubmit={values =>
          saveCustomerCommunicationMessage(toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerCommunicationSettingsItem);
