import { Observable } from "rxjs";
import React from "react";
import { startOfHour } from "date-fns";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  withHandlers,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Checkbox,
  MenuItem,
  CardHeader,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { loadAllValues, mapListResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { pipeStreams, mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { captureException } from "../../helpers/ErrorTracker";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import {
  isExpiredOrderRecord,
  createOrderSortingBinCreator,
} from "../../helpers/OrderSortingHelper";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  updateSortingTask,
  getOrderSortingTask,
  getOrderSortingOrders,
  cacheOrderSortingOrders,
  clearOrderSortingOrders,
  removeOrderSortingOrders,
  addSortingTaskOrderNumbers,
  addSortingTaskOrderBarcodes,
} from "../../reducers/OrderSortingReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import OrderSizeCodes from "../../constants/OrderSizeCodes";
import { IN_SORTING_FACILITY } from "../../constants/OrderStatusCodes";
import {
  batchSetOrderSize,
  getLocalServerOrderList,
  batchUpdateOrderWarehouse,
  setOrderBarcodeScannedPieces,
} from "../../api/admin/AdminOrderApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getOrderList } from "../../api/customer/CustomerOrderApi";
import FormDialog from "../../components/form/FormDialog";
import FormCustomerDumbWarehouseDialog from "../../components/form/FormCustomerDumbWarehouseDialog";
import NavigationPrompt from "../../components/router/NavigationPrompt";
import FlexBox from "../../components/ui-core/FlexBox";
import LinkButton from "../../components/ui-core/LinkButton";
import PageLoading from "../../components/ui-core/PageLoading";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import FirebaseOfflineDialog from "../../components/firebase/FirebaseOfflineDialog";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import Notification from "../../components/notifications/Notification";
import SimpleOrderSortingCard from "../../components/order-multi-shipment-sorting/SimpleOrderSortingCard";
import SimpleOrderSortingForm from "../../components/order-multi-shipment-sorting/SimpleOrderSortingForm";
import { getTokenUserId } from "../../../shared/reducers/AuthReducer";

const baseFilter = new DataListFilter({
  size: 200,
  is_uae: true,
  simple: true,
  use_solr: true,
  include_dw: true,
  search_type: "order_number",
});

const getAllOrdersByFilter = loadAllValues(getOrderList);

const enhancer = compose(
  useSheet({
    counter: { display: "none" },
    mobile: { fontSize: "12px", lineHeight: "20px" },
    sortingStatsHeader: {
      display: "block",
      lineHeight: "10px",
      marginTop: "15px",
    },
    mobileCounter: { display: "initial" },
    cacheServer: {
      display: "block",
      fontSize: "14px",
      paddingTop: "5px",
    },
    "@media (min-width: 998px)": {
      counter: { display: "flex" },
      mobileCounter: { display: "none" },
      cacheServer: {
        fontSize: "18px",
      },
    },
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => {
      const task = getOrderSortingTask(state);

      return {
        task,
        userId: getTokenUserId(state),
        allOrders: getOrderSortingOrders(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,

      updateSortingTask,

      cacheOrderSortingOrders,
      clearOrderSortingOrders,
      removeOrderSortingOrders,
      addSortingTaskOrderNumbers,
      addSortingTaskOrderBarcodes,
    },
  ),
  withState("state", "setState", {
    pendingTasks: Set(),
    prefetchPending: false,
  }),
  withHandlers({
    refreshOrders: props => (orderNumbers, isMultiBox = false) => {
      const numbers = Set(orderNumbers);

      if (
        props.task.get("cacheServer") &&
        isMultiBox === false &&
        numbers.size === 1
      ) {
        return getLocalServerOrderList(orderNumbers, false)
          .takeLast(1)
          .let(mapListResponseStream)
          .map(x => x.getIn(["payload", "list"]))
          .switchMap(list => {
            const db = new OrderSortingDB(props.warehouseId);

            const values = {};
            const notLoaded = numbers.asMutable();

            list.forEach(order => {
              const orderNumber = order.get("order_number");

              notLoaded.delete(orderNumber);

              if (numbers.has(orderNumber)) {
                values[`${orderNumber}/failed`] = null;
                values[`${orderNumber}/info`] = order.toJS();
                values[`${orderNumber}/scanned_as_box`] = isMultiBox;
                values[`${orderNumber}/hash`] = order.hashCode();
                values[`${orderNumber}/hash_time`] = Date.now();
              }
            });

            notLoaded.forEach(orderNumber => {
              values[`${orderNumber}/failed`] = true;
            });

            return db.batchUpdateOrders(values);
          });
      }

      return getOrderList(baseFilter.setSearch(numbers.join(",")))
        .takeLast(1)
        .let(mapListResponseStream)
        .map(x => x.getIn(["payload", "list"]))
        .switchMap(list => {
          const db = new OrderSortingDB(props.warehouseId);

          const values = {};
          const notLoaded = numbers.asMutable();

          list.forEach(order => {
            const orderNumber = order.get("order_number");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/scanned_as_box`] = isMultiBox;
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach(orderNumber => {
            values[`${orderNumber}/failed`] = true;
          });

          return db.batchUpdateOrders(values);
        });
    },
  }),
  mapPropsStream(
    pipeStreams(
      /**
       * Step 1 - Load sorting job details from Firebase.
       */
      propsStream => {
        const dbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .map(props => new OrderSortingDB(props.warehouseId));

        const initialState = {
          tasks: Map(),
          orders: Map(),
          binRules: null,
          sharedCounter: Map(),
          sharedBoxCounter: Map(),
        };

        const stateStream = dbStream
          .switchMap((db: OrderSortingDB) =>
            !db.warehouseId
              ? Observable.of(initialState)
              : Observable.combineLatest(
                  db.getTasks(),
                  db.getOrders(),
                  db.getBinRules(),
                  db.getCounter(),
                  db.getBoxCounter(),
                  (
                    tasks,
                    orders,
                    binRules,
                    sharedCounter,
                    sharedBoxCounter,
                  ) => ({
                    tasks,
                    orders,
                    binRules,
                    sharedCounter,
                    sharedBoxCounter,
                  }),
                ).startWith(initialState),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },
      /**
       * Step 2 - Normalize task values.
       */
      propsStream => {
        const taskStream = propsStream
          .map(fp.pick(["task", "orders"]))
          .distinctUntilChanged(isEqualData)
          .map(props =>
            props.task.withMutations(task => {
              if (props.orders.size > 0) {
                task.update(
                  "activeOrder",
                  x => x || props.orders.last().get("number"),
                );
              }

              task.update("selectedOrders", selected =>
                selected.filter(x => props.orders.has(x)),
              );
            }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(taskStream, (props, task) => ({
          ...props,
          task,
        }));
      },
      /**
       * Step 3 - Handle order submission of new orders.
       *
       * 1. Register order meta in reducer.
       * 2. Upload order meta into Firebase.
       * 3. Add order number to shared counter
       * 4. Create order task.
       */
      propsStream => {
        const {
          handler: onOrderSubmit,
          stream: onOrderSubmitStream,
        } = createEventHandler();

        const {
          handler: onMultiOrderSubmit,
          stream: onMultiOrderSubmitStream,
        } = createEventHandler();

        const sideEffects = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                onOrderSubmitStream.do(request => {
                  // Set Order to Reducer
                  props.addSortingTaskOrderNumbers(Set(request.orderNumbers));
                }),
                onOrderSubmitStream.do(request => {
                  props.addSortingTaskOrderBarcodes(Set(request.orderNumbers));
                }),
                onOrderSubmitStream.mergeMap(request => {
                  const orderValues = {};
                  const counterValues = {};
                  const boxCounterValues = {};

                  request.orderNumbers.forEach(orderNumber => {
                    counterValues[orderNumber] = true;
                    boxCounterValues[orderNumber] = true;
                    orderValues[`${orderNumber}/number`] = orderNumber;
                  });

                  return Observable.merge(
                    db.batchUpdateOrders(orderValues),
                    db.batchUpdateCounter(counterValues),
                    db.batchUpdateBoxCounter(boxCounterValues),
                  );
                }),
                onOrderSubmitStream
                  .filter(request => request.assignToWarehouse)
                  .do(request => {
                    db.trackAction("assignToWarehouse", request.orderNumbers);

                    props.setState(
                      fp.update("pendingTasks", x =>
                        x.merge(request.orderNumbers),
                      ),
                    );
                  })
                  .mergeMap(request => Observable.from(request.orderNumbers))
                  .bufferTime(5 * 1000, null, 50)
                  .filter(x => x.length > 0)
                  .mergeMap(
                    orderNumbers =>
                      Observable.fromPromise(
                        batchUpdateOrderWarehouse({
                          order_numbers: orderNumbers,
                          order_status: IN_SORTING_FACILITY,
                          warehouse: { id: props.warehouseId },
                        }).catch(captureException),
                      )
                        .finally(() => {
                          db.trackAction(
                            "assignToWarehouseComplete",
                            orderNumbers,
                          );

                          props.setState(
                            fp.update("pendingTasks", x =>
                              x.subtract(orderNumbers),
                            ),
                          );
                        })
                        .switchMap(() => props.refreshOrders(orderNumbers)),
                    5,
                  ),
                onMultiOrderSubmitStream.do(request => {
                  props.addSortingTaskOrderNumbers(
                    Set(request.orderNumbers.orderNumber),
                  );
                }),
                onMultiOrderSubmitStream.do(request => {
                  props.addSortingTaskOrderBarcodes(
                    Set(request.orderNumbers.barcode),
                  );
                }),
                onMultiOrderSubmitStream.mergeMap(request => {
                  const orderValues = {};
                  const counterValues = {};
                  const boxCounterValues = {};
                  const order = request.orderNumbers.orderNumber;
                  boxCounterValues[request.orderNumbers.barcode] = true;

                  order.forEach(orderNumber => {
                    counterValues[orderNumber] = true;
                    orderValues[`${orderNumber}/multi_box`] = true;
                    orderValues[`${orderNumber}/number`] = orderNumber;
                  });

                  return Observable.merge(
                    db.batchUpdateOrders(orderValues),
                    db.batchUpdateCounter(counterValues),
                    db.batchUpdateBoxCounter(boxCounterValues),
                  );
                }),
                onMultiOrderSubmitStream
                  .filter(request => request.assignToWarehouse)
                  .do(request => {
                    db.trackAction(
                      "assignToWarehouse",
                      request.orderNumbers.orderNumber,
                    );

                    props.setState(
                      fp.update("pendingTasks", x =>
                        x.merge(request.orderNumbers.orderNumber),
                      ),
                    );
                  })
                  .mergeMap(
                    request =>
                      Observable.fromPromise(
                        setOrderBarcodeScannedPieces(
                          request.orderNumbers.barcode,
                          {
                            status: IN_SORTING_FACILITY,
                            warehouse: { id: props.warehouseId },
                          },
                        ).catch(error => {
                          props.showErrorMessage(error);
                        }),
                      )
                        .finally(() => {
                          db.trackAction(
                            "assignToWarehouseComplete",
                            request.orderNumbers.orderNumber,
                          );

                          props.setState(
                            fp.update("pendingTasks", x =>
                              x.subtract(request.orderNumbers.orderNumber),
                            ),
                          );
                        })
                        .switchMap(() =>
                          props.refreshOrders(
                            request.orderNumbers.orderNumber,
                            true,
                          ),
                        ),
                    5,
                  ),
              );
            }),
        );

        return propsStream.merge(sideEffects).map(props => ({
          ...props,
          onOrderSubmit,
          onMultiOrderSubmit,
        }));
      },
      /**
       * Step 4 - Handle order task requests.
       *
       * 1. Handle order size change request.
       * 1. Handle failed task retry request.
       */
      propsStream => {
        const {
          handler: onOrderSizeChange,
          stream: onOrderSizeChangeStream,
        } = createEventHandler();

        const {
          handler: onRetryTaskRequest,
          stream: onRetryTaskRequestStream,
        } = createEventHandler();

        const {
          handler: onCancelTaskRequest,
          stream: onCancelTaskRequestStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                onOrderSizeChangeStream.mergeMap(x =>
                  db.updateSize(x.orderNumber, x.size),
                ),
                onRetryTaskRequestStream.mergeMap(taskId =>
                  db.retryTask(taskId),
                ),
                onCancelTaskRequestStream.mergeMap(taskId =>
                  db.removeTask(taskId),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream).map(props => ({
          ...props,
          onOrderSizeChange,
          onRetryTaskRequest,
          onCancelTaskRequest,
        }));
      },
      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 3. Generates bin names for orders without bin names.
       * 4. Execute order tasks.
       * 5. Prefetch orders by filter and repeat every 20 minutes.
       */
      propsStream => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderSortingDB(props.warehouseId);

              const addStream = db.getOrderAddStream();
              const changeStream = db.getOrderChangeStream();
              const removeStream = db.getOrderRemoveStream();

              const addOrChangeStream = Observable.merge(
                addStream,
                changeStream,
              );

              return mergeSideEffectStreams(
                removeStream
                  .map(x => x.get("number"))
                  .bufferTime(1000, null, 100)
                  .filter(buffer => buffer.length > 0)
                  .do(orders => {
                    props.removeOrderSortingOrders(Set(orders));
                  }),

                db
                  .getBinRules()
                  .distinctUntilChanged(isEqualData)
                  .switchMap(rules => {
                    const createBin = createOrderSortingBinCreator(rules);

                    return Observable.of(null).expand(() =>
                      addOrChangeStream
                        .filter(
                          x =>
                            x.get("info") &&
                            !x.get("bin") &&
                            !isExpiredOrderRecord(x),
                        )
                        .bufferTime(1000, null, 100)
                        .filter(buffer => buffer.length > 0)
                        .take(1)
                        .switchMap(orders => {
                          const values = orders.reduce((acc, order) => {
                            const bin = createBin(order.get("info"));

                            acc[`${order.get("number")}/bin`] = bin;

                            return acc;
                          }, {});

                          return db.batchUpdateOrders(values);
                        })
                        .catch(error => {
                          captureException(error);

                          return Observable.of(null);
                        })
                        .delay(1000),
                    );
                  }),

                Observable.of(null).expand(() =>
                  addOrChangeStream
                    .filter(x => !x.get("failed") && isExpiredOrderRecord(x))
                    .bufferTime(1000, null, 100)
                    .filter(x => x.length > 0)
                    .take(1)
                    .switchMap(buffer =>
                      props.refreshOrders(buffer.map(x => x.get("number"))),
                    )
                    .catch(error => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),

                Observable.of(null).expand(() =>
                  db
                    .getCorruptedOrderStream()
                    .map(fp.get("key"))
                    .bufferTime(1000, null, 100)
                    .filter(buffer => buffer.length > 0)
                    .take(1)
                    .switchMap(x => db.batchRemoveOrders(x))
                    .catch(error => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),

                db.getTaskAddStream().mergeMap(response => {
                  const id = response.key;
                  const payload = response.val();

                  // Remove task if it's corrupted.
                  if (!payload || !payload.task || !payload.number) {
                    return db.removeTask(id);
                  }

                  const { task, number: orderNumber } = payload;

                  return db
                    .getOrder(orderNumber)
                    .take(1)
                    .switchMap(order => {
                      // Remove task if it's order not found or failed to load.
                      if (order.isEmpty() || order.get("failed")) {
                        return db.removeTask(id);
                      }

                      // Retry to execute task after one second if it's order not loaded.
                      if (!order.getIn(["info", "id"])) {
                        return Observable.timer(1000).switchMap(() =>
                          db.retryTask(id),
                        );
                      }

                      let taskStream;

                      if (task.size && OrderSizeCodes.has(task.size)) {
                        taskStream = Observable.defer(() =>
                          batchSetOrderSize({
                            size: task.size,
                            order_numbers: [orderNumber],
                          }),
                        );
                      }

                      // If task found - execute it.
                      // If it's succeed remove it and reload order.
                      // If not update it with error message
                      if (taskStream) {
                        return taskStream
                          .switchMap(() =>
                            Observable.merge(
                              db.removeTask(id),
                              db.reloadOrder(orderNumber),
                            ),
                          )
                          .catch(error =>
                            db.updateTask(id, { error: error.message }),
                          );
                      }

                      // If it's unknown task - remove it.
                      return db.removeTask(id);
                    });
                }, 5),
              );
            }),
          propsStream
            .filter(props => props.task.get("filter").size > 0)
            .map(props => ({
              ...props,
              filter: props.task.get("filter"),
            }))
            .distinctUntilKeyChanged("filter", isEqualData)
            .map(
              fp.update("filter", x =>
                new DataListFilter(x).setValueMap(baseFilter),
              ),
            )
            .distinctUntilKeyChanged("filter", isEqualData)
            .switchMap(props => {
              props.setState(fp.set("prefetchPending", true));

              return getAllOrdersByFilter(props.filter)
                .map(response => response.get("list"))
                .do(orders => {
                  if (orders.size > 0) {
                    props.cacheOrderSortingOrders(orders);
                  }
                })
                .takeLast(1)
                .do(() => {
                  props.setState(fp.set("prefetchPending", false));
                  props.updateSortingTask(task =>
                    task.update("filter", x =>
                      x.set(
                        "from_date_time",
                        formatDateTimeToUrl(startOfHour(new Date())),
                      ),
                    ),
                  );
                })
                .repeatWhen(stream => stream.delay(5 * 60 * 1000));
            }),
        );

        return propsStream.merge(sideEffectsStream);
      },
      /**
       * Step 6 - Generate order stats.
       *
       * 1. Count failed orders.
       */
      propsStream => {
        const statsStream = propsStream
          .map(props => ({ orders: props.orders }))
          .distinctUntilChanged(isEqualData)
          .map(({ orders }) => {
            const failedOrders = Set().asMutable();

            orders.forEach((order, orderNumber) => {
              if (!order.hasIn(["info", "id"]) && order.get("failed")) {
                failedOrders.add(orderNumber);
              }
            });

            return Map({ failedOrders: failedOrders.size });
          })
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(statsStream, (props, stats) => ({
          ...props,
          stats,
        }));
      },
      /**
       * Step 7 - Collect order task stats.
       */
      propsStream => {
        const taskStatsStream = propsStream
          .distinctUntilKeyChanged("tasks", isEqualData)
          .map(props => {
            const failed = Set().asMutable();
            const pending = Set().asMutable();

            props.tasks.forEach((task, id) => {
              if (task.get("error")) {
                failed.add(task.set("id", id));
              } else {
                pending.add(task.set("id", id));
              }
            });

            return Map({
              failed: failed.asImmutable(),
              pending: pending.asImmutable(),
            });
          });

        return propsStream.combineLatest(
          taskStatsStream,
          (props, taskStats) => ({ ...props, taskStats }),
        );
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "location",
      "state",
      "task",
      "stats",
      "orders",
      "binRules",
      "allOrders",
      "taskStats",
      "sharedCounter",
      "sharedBoxCounter",
    ]),
  ),
);

CustomerOrderSortingContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  state: PropTypes.object,
  onOrderSubmit: PropTypes.func,
  onMultiOrderSubmit: PropTypes.func,
  onOrderSizeChange: PropTypes.func,
  onRetryTaskRequest: PropTypes.func,
  onCancelTaskRequest: PropTypes.func,
  warehouseId: PropTypes.number,
  task: PropTypes.instanceOf(Map),
  stats: PropTypes.instanceOf(Map),
  allOrders: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  binRules: PropTypes.instanceOf(Map),
  taskStats: PropTypes.instanceOf(Map),
  sharedCounter: PropTypes.instanceOf(Map),
  updateSortingTask: PropTypes.func,
  clearOrderSortingOrders: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerOrderSortingContainer(props) {
  const {
    classes,
    task,
    state,
    stats,
    orders,
    allOrders,
    taskStats,
    getLocalisationMessage,
    location: { query },
  } = props;

  if (!props.warehouseId) {
    return (
      <FormCustomerDumbWarehouseDialog
        open={true}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        onSubmit={values =>
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          )
        }
      />
    );
  }

  const db = new OrderSortingDB(props.warehouseId);

  const isLoading = !props.binRules;
  const activeOrderNumber = task.get("activeOrder");

  return (
    <CustomerAppLayout
      title={`${getLocalisationMessage(
        "order_sorting",
        "Order Sorting",
      )} | ${task.getIn(["warehouse", "name"])}`}
      appBarRightAction={
        <MenuItem
          onClick={() => props.setLocationQuery(fp.set("log_out", true))}
        >
          {getLocalisationMessage("log_out", "Log Out")}
        </MenuItem>
      }
    >
      <PageLoading isLoading={isLoading} />

      <FirebaseOfflineDialog />

      <NavigationPrompt
        when={state.pendingTasks.size > 0}
        message={[
          `${getLocalisationMessage("there_are", "There are")} ${
            state.pendingTasks.size
          } ${getLocalisationMessage("unfinished_tasks", "unfinished tasks!")}`,
          getLocalisationMessage(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          ),
        ].join(" ")}
      />

      {query.remove_all === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onSubmit={() => {
            props.clearOrderSortingOrders();

            return db
              .clearOrders()
              .toPromise()
              .catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => props.setLocationQuery(fp.unset("remove_all"))}
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_orders_from_queue",
            "Are you sure you want to remove all orders from queue?",
          )}
        </FormDialog>
      )}

      {query.log_out === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingOrders();
            props.updateSortingTask(x => x.clear());
            props.setLocationQuery(fp.unset("log_out"));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      <Notification
        uid="pending_count"
        open={taskStats.get("pending").size > 0}
      >
        {taskStats.get("pending").size} pending tasks
      </Notification>

      {taskStats.get("failed").map(x => (
        <Notification
          open={true}
          type="error"
          key={x.get("id")}
          uid={`failed_${x.get("id")}`}
          action={
            <div>
              <Button
                secondary={true}
                onClick={() => props.onRetryTaskRequest(x.get("id"))}
              >
                {getLocalisationMessage("retry", "Retry")}
              </Button>

              <Button
                secondary={true}
                onClick={() => props.onCancelTaskRequest(x.get("id"))}
              >
                {getLocalisationMessage("cancel", "Cancel")}
              </Button>
            </div>
          }
        >
          {getLocalisationMessage(
            "failed_to_execute_background_task",
            "Failed to execute background task",
          )}{" "}
          "{x.get("id")}"
        </Notification>
      ))}

      <div>
        <Card>
          <CardHeader
            title={
              <div>
                <FlexBox justify="space-between" className={classes.counter}>
                  <div>
                    {orders.size}{" "}
                    {getLocalisationMessage(
                      "orders_in_queue",
                      "orders in queue",
                    )}
                    {state.prefetchPending
                      ? `, ${getLocalisationMessage(
                          "prefetch_pending",
                          "prefetch pending",
                        )}`
                      : allOrders.size > 0 && `, ${allOrders.size} prefetched`}
                    {stats.get("failedOrders") > 0 &&
                      `, ${stats.get("failedOrders")} failed`}
                    <div className={classes.cacheServer}>
                      <Checkbox
                        label={getLocalisationMessage(
                          "cache_server",
                          "Cache Server",
                        )}
                        checked={task.get("cacheServer")}
                        className={classes.checkbox}
                        onCheck={(e, x) =>
                          props.updateSortingTask(t => t.set("cacheServer", x))
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      {getLocalisationMessage(
                        "local_counter",
                        "Local Counter:",
                      )}{" "}
                      {task.get("counter").size}, (
                      <LinkButton
                        onClick={() =>
                          props.updateSortingTask(t =>
                            t.update("counter", x => x.clear()),
                          )
                        }
                      >
                        {getLocalisationMessage("reset", "Reset")}
                      </LinkButton>
                      )
                    </div>

                    <div>
                      {getLocalisationMessage(
                        "shared_counter",
                        "Shared Counter:",
                      )}{" "}
                      {props.sharedCounter.size}(
                      <LinkButton
                        onClick={() =>
                          db
                            .clearCounter()
                            .toPromise()
                            .catch(props.showErrorMessage)
                        }
                      >
                        {getLocalisationMessage("reset", "Reset")}
                      </LinkButton>
                      )
                    </div>
                  </div>
                </FlexBox>

                <div className={`${classes.mobileCounter} ${classes.mobile}`}>
                  <div>
                    {orders.size} orders in queue
                    {state.prefetchPending
                      ? `, ${getLocalisationMessage(
                          "prefetch_pending",
                          "prefetch pending",
                        )}`
                      : allOrders.size > 0 &&
                        `, ${allOrders.size} ${getLocalisationMessage(
                          "prefetched",
                          "prefetched",
                        )}`}
                    {stats.get("failedOrders") > 0 &&
                      `, ${stats.get("failedOrders")} ${getLocalisationMessage(
                        "failed",
                        "failed",
                      )}`}
                  </div>
                  <FlexBox
                    justify="space-between"
                    style={{
                      borderBottom: "1px solid #eee",
                      borderTop: "1px solid #eee",
                      lineHeight: "30px",
                    }}
                  >
                    <div>
                      <strong className={classes.sortingStatsHeader}>
                        {getLocalisationMessage(
                          "local_counter",
                          "Local Counter:",
                        )}
                      </strong>
                      {props.sharedCounter.size}(
                      <LinkButton
                        onClick={() =>
                          props.updateSortingTask(t =>
                            t.update("counter", x => x.clear()),
                          )
                        }
                      >
                        {getLocalisationMessage("reset", "Reset")}
                      </LinkButton>
                      )
                    </div>
                    <div>
                      <strong className={classes.sortingStatsHeader}>
                        {getLocalisationMessage(
                          "shared_counter",
                          "Shared Counter:",
                        )}
                      </strong>{" "}
                      {getLocalisationMessage("shipments", "Shipments")}:{" "}
                      {props.sharedCounter.size}, (
                      <LinkButton
                        onClick={() =>
                          db
                            .clearCounter()
                            .toPromise()
                            .catch(props.showErrorMessage)
                        }
                      >
                        {getLocalisationMessage("reset", "Reset")}
                      </LinkButton>
                      )
                    </div>
                  </FlexBox>
                  <div className={classes.cacheServer}>
                    <Checkbox
                      label={getLocalisationMessage(
                        "cache_server",
                        "Cache Server",
                      )}
                      checked={task.get("cacheServer")}
                      className={classes.checkbox}
                      onCheck={(e, x) =>
                        props.updateSortingTask(t => t.set("cacheServer", x))
                      }
                    />
                  </div>
                </div>
              </div>
            }
          />

          <CardContent>
            <SimpleOrderSortingForm
              focusInput={isLoading}
              onSubmit={x =>
                x.multiBox
                  ? props.onMultiOrderSubmit({
                      orderNumbers: x,
                      assignToWarehouse: task.get("autoAssign"),
                    })
                  : props.onOrderSubmit({
                      orderNumbers: x.orders,
                      assignToWarehouse: task.get("autoAssign"),
                    })
              }
              onPrefetchOrdersClick={() =>
                props.setLocationQuery(fp.set("prefetch", true))
              }
              autoAssign={task.get("autoAssign")}
            />

            {Boolean(activeOrderNumber && props.binRules) && (
              <SimpleOrderSortingCard
                queueOrders={orders}
                allOrders={allOrders}
                binRules={props.binRules}
                orderNumber={activeOrderNumber}
                onReloadClick={() =>
                  db
                    .reloadOrder(activeOrderNumber)
                    .toPromise()
                    .catch(props.showErrorMessage)
                }
                onRemoveClick={() =>
                  db
                    .removeOrder(activeOrderNumber)
                    .toPromise()
                    .catch(props.showErrorMessage)
                }
                onSizeChange={size =>
                  props.onOrderSizeChange({
                    size,
                    orderNumber: activeOrderNumber,
                  })
                }
              />
            )}
          </CardContent>
        </Card>

        <br />
      </div>
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerOrderSortingContainer);
