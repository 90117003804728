import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import UserAvatar from "../../avatars/UserAvatar";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardDefault");

const enhancer = compose(
  useSheet({ flag: { verticalAlign: "sub" } }),
  connect(state => ({
    values: valueSelector(
      state,
      "dropoffLocation",
      "dropoffContactName",
      "dropoffCountry",
      "dropoffLocationAddress",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepToDetails.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepToDetails(props) {
  const { classes, values } = props;

  if (!values.dropoffLocation) {
    return null;
  }

  return (
    <OrderCreateWizardStepDetails
      label={props.getLocalisationMessage("to", "To")}
      onClick={props.onClick}
      avatar={<UserAvatar size={40} name={values.dropoffContactName} />}
      primaryText={
        <div>
          {values.dropoffContactName}{" "}
          <FlagIcon
            code={values.dropoffLocation.country}
            size={18}
            className={classes.flag}
          />
        </div>
      }
      secondaryText={values.dropoffLocationAddress}
    />
  );
}

export default enhancer(OrderCreateWizardStepToDetails);
