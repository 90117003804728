import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import OrderCreateWizardStepPayItem from "./OrderCreateWizardStepPayItem";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizard");

const enhancer = compose(
  connect(state => ({
    paymentType: valueSelector(state, "paymentType"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepPayDetails.propTypes = {
  paymentType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepPayDetails(props) {
  return (
    <OrderCreateWizardStepPayItem
      label={props.getLocalisationMessage("pay", "Pay")}
      onClick={props.onClick}
      paymentType={props.paymentType}
    />
  );
}

export default enhancer(OrderCreateWizardStepPayDetails);
