import React from "react";
import _ from "lodash";
import { fromJS } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormAutoComplete from "./FormAutoComplete";
import { phoneCountyCodeList } from "../../constants/PhoneCountryCodes";

const enhancer = compose();

FormPhoneCodeWithoutCountryField.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  focus: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormPhoneCodeWithoutCountryField.defaultProps = {
  maxSearchResults: 10,
};

const parseInput = country => ({ country });
const formatOption = x => {
  if (x && !x.code) {
    return x.country;
  }

  if (x && x.code) {
    if (x.country) {
      return `${x.country} (${x.code})`;
    }
    const countryFromCode = fromJS(
      _.find(phoneCountyCodeList, { code: x.code }),
    );
    return `${countryFromCode.country} (${x.code})`;
  }

  return null;
};

function FormPhoneCodeWithoutCountryField(props) {
  return (
    <FormAutoComplete
      {...props}
      parseInput={parseInput}
      formatOption={formatOption}
      options={phoneCountyCodeList}
    />
  );
}

export default enhancer(FormPhoneCodeWithoutCountryField);
