import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  withHandlers,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { DROP } from "../../constants/TimeSlotType";
import {
  verifyPhoneNumber,
  getMaskedPhoneNumber,
  getDropOffLocationByPublicId,
  getDropOffTimeSlotsByPublicId,
  updateDropOffLocationByPublicId,
  updateDropOffTimeslotByPublicId,
} from "../../api/shared/OrderApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import RescheduleTimeslotForm from "../../components/time-slots-core/RescheduleTimeslotForm";
import AddressLocationForm from "../../components/bad-address-core/AddressLocationForm";
import VerifyPhoneNumberForm from "../../components/bad-address-core/VerifyPhoneNumberForm";
import addressChangedIcon from "../../components/bad-address-core/assets/address-changed.svg";

const PHONE_PAGE = "phone";
const LOCATION_PAGE = "location";
const TIMESLOT_PAGE = "timeslot";
const SUCCESS_PAGE = "success";

const enhancer = compose(
  useSheet({
    progress: {
      display: "flex",
      paddingTop: "24px",
      paddingBottom: "12px",
      alignItems: "center",
      justifyContent: "center",
      flex: "1 1 0%",
    },
    paper: { width: "400px", minHeight: "300px" },
    title: {
      backgroundColor: "#2e3359",
      color: "#ffffff",
      padding: "20px",
    },
    container: { height: "100%" },
    notFound: { height: "80px", width: "80px", color: "#f44336" },
    successContainer: {
      marginTop: "15px",
    },
    success: {
      padding: "10px 0",
      textAlign: "center",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withState("page", "onSetPage", PHONE_PAGE),
  withState("pageTitle", "onSetPageTitle", "Validate Phone Number"),
  withState("phoneNumber", "onSetPhoneNumber", null),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .combineLatest(
            propsStream
              .map(fp.flow(fp.get("params.orderNumber"), fp.toString))
              .distinctUntilChanged(isEqualData),
            (props, orderNumber) => ({
              ...props,
              orderNumber,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream => {
        const getMaskedPhoneNumberStream = propsStream
          .distinctUntilKeyChanged("orderNumber")
          .filter(props => !props.phoneNumber && props.orderNumber)
          .switchMap(props =>
            getMaskedPhoneNumber(props.orderNumber).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            getMaskedPhoneNumberStream,
            (props, maskedPhoneNumberResponse) => ({
              ...props,
              isMaskedPhoneLoading: maskedPhoneNumberResponse.get("pending"),
              maskedPhone: maskedPhoneNumberResponse.getIn([
                "payload",
                "masked_phone",
              ]),
              isPhoneValidation: maskedPhoneNumberResponse.getIn(
                ["payload", "required_phone_validation"],
                true,
              ),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const getOrderLocationStream = propsStream
          .distinctUntilChanged(isEqualData)
          .map(fp.pick(["orderNumber", "phoneNumber", "isPhoneValidation"]))
          .distinctUntilChanged(isEqualData)
          .filter(
            props =>
              Boolean(props.phoneNumber && props.orderNumber) ||
              !props.isPhoneValidation,
          )
          .switchMap(props =>
            getDropOffLocationByPublicId(
              props.orderNumber,
              props.phoneNumber,
            ).catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          );

        return propsStream
          .combineLatest(
            getOrderLocationStream,
            (props, orderLocationResponse) => ({
              ...props,
              isOrderLocationLoading: orderLocationResponse.get("pending"),
              orderLocation: orderLocationResponse.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onSelectDate,
          stream: onSelectDateStream,
        } = createEventHandler();

        return propsStream
          .combineLatest(
            onSelectDateStream.filter(date => Boolean(date)).startWith(null),
            (props, timeslotDate) => ({
              ...props,
              timeslotDate,
              onSelectDate,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const initialValuesStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            props =>
              props.orderLocation.size > 0 && !props.isOrderLocationLoading,
          )
          .map(props => {
            const { orderLocation } = props;
            const region = orderLocation.get("region", Map()) || Map();
            return {
              location: {
                lat: orderLocation.get("lat"),
                lng: orderLocation.get("lon"),
                address: orderLocation.get("address"),
              },
              region: region.toJS(),
              apartment: orderLocation.getIn(["address_details", "apartment"]),
              building: orderLocation.getIn(["address_details", "building"]),
              street: orderLocation.getIn(["address_details", "street"]),
              landmark: orderLocation.getIn(["address_details", "landmark"]),
            };
          })
          .startWith({});

        const getOrderTimeslotStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            props =>
              props.orderLocation.size > 0 &&
              props.timeslotDate &&
              props.page === TIMESLOT_PAGE &&
              !props.isOrderLocationLoading,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(props =>
            getDropOffTimeSlotsByPublicId(
              props.orderNumber,
              new DataListFilter({
                lat: props.orderLocation.get("lat"),
                lng: props.orderLocation.get("lon"),
                timeslot_type: DROP,
                date: formatDateToUrl(props.timeslotDate),
              }),
            ).catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data.list"), fp.toArray)),
              fromJS,
            ),
          );

        return propsStream
          .combineLatest(
            initialValuesStream,
            getOrderTimeslotStream,
            (props, initialValues, timeslotsResponse) => ({
              ...props,
              initialValues,
              timeSlotList: timeslotsResponse.get("payload"),
              timeSlotsFetching: timeslotsResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
  withHandlers({
    onSubmitPhoneNumber: props => values => {
      const { phone } = values;
      return verifyPhoneNumber(props.orderNumber, phone).catch(
        ResponseError.throw,
      );
    },

    onSubmitSuccessPhoneNumber: props => response => {
      if (_.get(response, "data.is_valid")) {
        props.onSetPhoneNumber(response.data.phone);
        props.onSetPageTitle(
          props.getLocalisationMessage("verify_address", "Verify Address"),
        );
        props.onSetPage(LOCATION_PAGE);
      } else {
        props.showErrorMessage(
          props.getLocalisationMessage(
            "this_is_not_a_valid_phone_number_for_your_shipment",
            "This is not a valid phone number for your shipment",
          ),
        );
      }
    },

    onSkipLocationAddress: props => () => {
      props.onSetPageTitle(
        props.getLocalisationMessage(
          "reschedule_delivery_date",
          "Reschedule Delivery Date",
        ),
      );
      props.onSetPage(TIMESLOT_PAGE);
    },

    onSubmitOrderLocationAddress: props => values =>
      updateDropOffLocationByPublicId(
        props.orderNumber,
        props.phoneNumber,
        values,
      ).catch(ResponseError.throw),

    onSubmitSuccessOrderLocationAddress: props => () => {
      props.onSetPageTitle(
        props.getLocalisationMessage(
          "reschedule_delivery_date",
          "Reschedule Delivery Date",
        ),
      );
      props.onSetPage(TIMESLOT_PAGE);
    },

    onSubmitTimeslot: props => values => {
      const body = {
        ...values.timeSlot.toJS(),
        estimated_delivery_time: values.timeslotDate,
      };

      const update = updateDropOffTimeslotByPublicId(
        props.orderNumber,
        props.phoneNumber,
        body,
      ).then(() =>
        props.showSuccessMessage(
          props.getLocalisationMessage(
            "successfully_updated_drop_off_time",
            "Successfully Updated Drop off time",
          ),
        ),
      );

      return update.catch(props.showErrorMessage);
    },

    onSubmitSuccessOrderTimeslot: props => () => {
      props.onSetPageTitle(
        props.getLocalisationMessage("thank_you", "Thank you"),
      );
      props.onSetPage(SUCCESS_PAGE);
    },
  }),
);

CustomerPublicAddressVerifyContainer.propTypes = {
  classes: PropTypes.object,
  isMaskedPhoneLoading: PropTypes.bool,
  isOrderLocationLoading: PropTypes.bool,
  isPhoneValidation: PropTypes.bool,
  orderNumber: PropTypes.string,
  showErrorMessage: PropTypes.func,
  page: PropTypes.string,
  onSetPage: PropTypes.func,
  initialValues: PropTypes.object,
  pageTitle: PropTypes.string,
  phoneNumber: PropTypes.string,
  maskedPhone: PropTypes.string,
  onSubmitPhoneNumber: PropTypes.func,
  onSubmitSuccessPhoneNumber: PropTypes.func,
  onSubmitOrderLocationAddress: PropTypes.func,
  onSubmitSuccessOrderLocationAddress: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onSkipLocationAddress: PropTypes.func,
  onSelectDate: PropTypes.func,
  onSubmitTimeslot: PropTypes.func,
  onSubmitSuccessOrderTimeslot: PropTypes.func,
  timeslotDate: PropTypes.instanceOf(Date),
  timeSlotList: PropTypes.instanceOf(List),
  timeSlotsFetching: PropTypes.bool,
};

function CustomerPublicAddressVerifyContainer(props) {
  const { classes, getLocalisationMessage } = props;
  const isLoading = Boolean(
    props.isMaskedPhoneLoading || props.isOrderLocationLoading,
  );

  return (
    <FlexBox container={8} flex={true} className={classes.container}>
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} align="center" justify="center">
          <FlexBox
            element={<Paper />}
            gutter={24}
            className={classes.paper}
            direction="column"
          >
            <PageLoading isLoading={isLoading} />

            <FlexBox className={classes.title}>
              <h5>{props.pageTitle}</h5>
            </FlexBox>

            {Boolean(
              !props.phoneNumber &&
                props.isPhoneValidation &&
                props.orderNumber &&
                props.page === PHONE_PAGE,
            ) && (
              <VerifyPhoneNumberForm
                maskedPhone={props.maskedPhone}
                onSubmit={props.onSubmitPhoneNumber}
                onSubmitSuccess={props.onSubmitSuccessPhoneNumber}
                onSubmitFail={props.showErrorMessage}
              />
            )}

            {Boolean(
              Boolean(
                !props.isPhoneValidation &&
                  props.page !== SUCCESS_PAGE &&
                  props.page !== TIMESLOT_PAGE,
              ) ||
                Boolean(
                  props.phoneNumber &&
                    props.orderNumber &&
                    props.page === LOCATION_PAGE,
                ),
            ) && (
              <AddressLocationForm
                initialValues={props.initialValues}
                onSubmit={props.onSubmitOrderLocationAddress}
                onSubmitSuccess={props.onSubmitSuccessOrderLocationAddress}
                onSubmitFail={props.showErrorMessage}
                onSkip={props.onSkipLocationAddress}
              />
            )}

            {Boolean(
              Boolean(
                !props.isPhoneValidation &&
                  props.page !== SUCCESS_PAGE &&
                  props.page !== LOCATION_PAGE,
              ) ||
                Boolean(
                  props.phoneNumber &&
                    props.orderNumber &&
                    props.page === TIMESLOT_PAGE,
                ),
            ) && (
              <div>
                <RescheduleTimeslotForm
                  withTime={true}
                  timeslotList={props.timeSlotList}
                  onSubmit={props.onSubmitTimeslot}
                  timeSlotsFetching={props.timeSlotsFetching}
                  onDismiss={() => props.onSetPage(SUCCESS_PAGE)}
                  onChange={values => props.onSelectDate(values.timeslotDate)}
                  initialValues={{
                    timeslotDate: props.timeslotDate || new Date(),
                  }}
                  onSubmitSuccess={props.onSubmitSuccessOrderTimeslot}
                />
              </div>
            )}

            {props.page === SUCCESS_PAGE && (
              <FlexBox direction="column" className={classes.successContainer}>
                <FlexBox justify="center" flex={true}>
                  <Avatar
                    src={addressChangedIcon}
                    size={100}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  />
                </FlexBox>
                <FlexBox
                  justify="center"
                  flex={true}
                  className={classes.success}
                >
                  {getLocalisationMessage(
                    "your_order_details_is_updated_successfully",
                    "Your order details is updated successfully!",
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerPublicAddressVerifyContainer);
