import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { postStripeSessionId } from "../../api/shared/StripeApi";

const enhancer = compose(
  useSheet({
    actions: {
      padding: 8,
      textAlign: "right",
      margin: "24px -24px -24px -24px",
    },
  }),
  // eslint-disable-next-line no-undef
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage },
  ),
  mapPropsStream(propsStream => {
    const sessionIdStream = propsStream
      .map(fp.pick(["orderId"]))
      .filter(fp.flow(fp.get("orderId"), Boolean))
      .distinctUntilKeyChanged()
      .switchMap(props =>
        postStripeSessionId(props.orderId).catch(error => ({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), Map)),
          Map,
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(Map());

    return propsStream.combineLatest(
      sessionIdStream,
      (props, stripeResponse) => ({
        ...props,
        sessionId: stripeResponse.getIn(["payload", "session_id"]),
        publicKey: stripeResponse.getIn(["payload", "public_key"]),
      }),
    );
  }),
);

StripePaymentDialog.propTypes = {
  classes: PropTypes.object,

  sessionId: PropTypes.string,
  publicKey: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function StripePaymentDialog(props) {
  const { classes, i18n } = props;

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <DialogContent>
        <p>
          {i18n.get(
            "please_proceed_to_payments_page_to_complete_order_creation_process",
            "Please proceed to payments page to complete order creation process.",
          )}
        </p>
      </DialogContent>

      <DialogActions>
        <div className={classes.actions}>
          <Button type="button" onClick={props.onRequestClose}>
            {i18n.get("dismiss", "Dismiss")}
          </Button>
          <Button
            type="submit"
            onClick={() => {
              try {
                // eslint-disable-next-line new-cap,no-undef
                const stripe = Stripe(props.publicKey);

                stripe
                  .redirectToCheckout({
                    sessionId: props.sessionId,
                  })
                  // eslint-disable-next-line no-console
                  .catch(error => console.log(error));
              } catch (e) {
                props.showErrorMessage(
                  i18n.get("something_wrong", "Something wrong!"),
                );
                console.warn(e);
              }
            }}
          >
            {i18n.get("proceed", "Proceed")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(StripePaymentDialog);
