import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Check } from "@material-ui/icons";
import { grey, lightGreen } from "@material-ui/core/colors";
import { primary1 } from "../../../../shared/theme/main-theme";
import { Button } from "@material-ui/core";

const enhancer = compose(
  useSheet({
    textLine: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    root: { display: "flex", flexWrap: "nowrap", userSelect: "none" },

    content: {
      flex: 1,
      display: "block",
      padding: "0 4px",
      overflow: "hidden",

      "& > span": {
        display: "flex",
        minHeight: "56px",
        flexWrap: "nowrap",
        alignItems: "center",
      },
    },

    label: {
      flex: "48px 0 0",
      color: grey[500],
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    labelText: { fontSize: "13px", fontWeight: 500 },
    labelIcon: {
      width: "18px",
      height: "18px",
      marginTop: "-6px",
      color: lightGreen[600],
    },
    avatar: { flex: "40px 0 0" },

    textContainer: {
      flex: "1",
      display: "flex",
      marginLeft: "6px",
      marginRight: "4px",
      overflow: "hidden",
      flexDirection: "column",
    },

    primaryTextContainer: {
      display: "flex",
      fontWeight: 400,
      lineHeight: "18px",
      justifyContent: "space-between",
    },
    primaryText: {
      extend: "textLine",
      flex: 1,
      color: primary1,
      fontSize: "15px",
    },
    rightPrimaryText: { extend: "textLine", fontSize: "13px" },

    secondaryTextContainer: {
      fontWeight: 500,
      lineHeight: 1.2,
      fontSize: "13px",
      display: "flex",
      justifyContent: "space-between",
    },
    secondaryText: { extend: "textLine", flex: 1, color: grey[500] },
    rightSecondaryText: { extend: "textLine" },

    floatingActionButton: {
      top: "36px",
      right: "0",
      zIndex: 10,
      height: "100%",
      marginLeft: "2px",
      marginRight: "12px",
      position: "relative",
    },
  }),
);

OrderCreateWizardStepDetails.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.node,
  avatar: PropTypes.node.isRequired,
  primaryText: PropTypes.node.isRequired,
  secondaryText: PropTypes.node,
  rightPrimaryText: PropTypes.node,
  rightSecondaryText: PropTypes.node,
  floatingActionButton: PropTypes.node,
};

function OrderCreateWizardStepDetails(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Button
        onClick={props.onClick}
        className={classes.content}
        containerElement="div"
      >
        {Boolean(props.label) && (
          <div className={classes.label}>
            <div className={classes.labelText}>{props.label}:</div>

            <Check className={classes.labelIcon} />
          </div>
        )}

        <div className={classes.avatar}>{props.avatar}</div>

        <div className={classes.textContainer}>
          <div className={classes.primaryTextContainer}>
            <div className={classes.primaryText}>{props.primaryText}</div>

            {Boolean(props.rightPrimaryText) && (
              <div className={classes.rightPrimaryText}>
                {props.rightPrimaryText}
              </div>
            )}
          </div>

          {Boolean(props.secondaryText) && (
            <div className={classes.secondaryTextContainer}>
              <div className={classes.secondaryText}>{props.secondaryText}</div>

              {Boolean(props.rightSecondaryText) && (
                <div className={classes.rightSecondaryText}>
                  {props.rightSecondaryText}
                </div>
              )}
            </div>
          )}
        </div>
      </Button>

      {Boolean(props.floatingActionButton) && (
        <div className={classes.floatingActionButton}>
          {props.floatingActionButton}
        </div>
      )}
    </div>
  );
}

export default enhancer(OrderCreateWizardStepDetails);
