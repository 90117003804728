import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

const enhancer = compose(
  useSheet({
    root: {
      padding: "6px",
      "& > div": {
        height: "100%",
        display: "flex",
        borderRadius: "50%",
        alignItems: "center",
        backgroundColor: "#fff",
        justifyContent: "center",
        boxShadow:
          "rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px",
      },
    },
    disabled: {
      "& > svg": {
        color: "#868686",
      },
    },
  }),
);

RoundButton.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

function RoundButton({ sheet, classes, className, ...props }) {
  return props.disabled ? (
    <IconButton {...props} className={cx(classes.root, className)}>
      <div className={classes.disabled}>{props.children}</div>
    </IconButton>
  ) : (
    <IconButton {...props} className={cx(classes.root, className)} />
  );
}

export default enhancer(RoundButton);
