import React from "react";
import { Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import { pipeStreams, mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { parseString } from "../../helpers/SerializeUtils";
import { getMarketplaceName } from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  getPublicOrder,
  getPublicOrderDriver,
  getPublicOrderActivities,
  getPublicOrderDriverLocation,
} from "../../api/shared/PublicOrderApi";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderTrackForm from "../../components/order-track/OrderTrackForm";
import OrderTrackItem from "../../components/order-track/OrderTrackItem";
import OrderTrackListItem from "../../components/order-track/OrderTrackListItem";
import { responsive } from "../../../shared/theme/jss-responsive";
import { getAppLogo } from "../../../shared/reducers/AppReducer";
import logoShipoxSrc from "../../assets/shipox_logo_w_text_vertical.svg";

const enhancer = compose(
  useSheet({
    card: {
      minWidth: "640px",
      maxWidth: "640px",
      marginBottom: "8px",

      [responsive("$xs")]: { minWidth: "100%" },
    },
    logo: { padding: "12px", width: "150px" },
  }),
  connect(state => ({
    appLogo: getAppLogo(state),
    marketplaceName: getMarketplaceName(state),
    i18n: getMessages(state),
  })),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const idStream = propsStream.map(
          fp.flow(fp.get("location.query.id"), fp.trim),
        );

        const idsStream = propsStream.map(
          fp.flow(fp.get("location.query.ids"), parseString, OrderedSet),
        );

        return propsStream.combineLatest(
          idStream,
          idsStream,
          (props, id, ids) => ({ ...props, id, ids }),
        );
      },
      propsStream =>
        propsStream.merge(
          mergeSideEffectStreams(
            propsStream
              .take(1)
              .filter(props => props.ids.size === 1)
              .do(props =>
                props.replaceLocationQuery(fp.set("id", props.ids.first())),
              ),
          ),
        ),
    ),
  ),
);

CustomerOrderTrackingContainer.propTypes = {
  classes: PropTypes.object,
  setLocationQuery: PropTypes.func,
  id: PropTypes.string,
  ids: PropTypes.instanceOf(OrderedSet),
  marketplaceName: PropTypes.string,
  appLogo: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
};

function CustomerOrderTrackingContainer(props) {
  const { classes, marketplaceName } = props;

  const logo = props.appLogo || logoShipoxSrc;

  return (
    <FlexBox flex={true} align="center" container={8} direction="column">
      <Card className={classes.card}>
        <FlexBox justify="flex-end">
          <div>
            <img src={logo} alt={marketplaceName} className={classes.logo} />
          </div>
        </FlexBox>

        <CardHeader
          title={
            <FlexBox justify="center">
              <span>
                {props.i18n.get("track_your_order", "Track your order")}
              </span>
            </FlexBox>
          }
          textStyle={{ paddingRight: 0 }}
        />

        <CardContent>
          <OrderTrackForm
            onSubmit={fp.flow(
              fp.get("orderNumber"),
              fp.split(","),
              fp.map(fp.trim),
              fp.compact,
              fp.uniq,
              values => {
                const nextIds = props.ids.subtract(values).merge(values);

                if (nextIds.equals(props.ids)) {
                  if (nextIds.last() !== props.id) {
                    props.setLocationQuery(fp.set("id", nextIds.last()));
                  }
                } else {
                  props.setLocationQuery(
                    fp.flow(
                      fp.set("id", nextIds.last()),
                      fp.set("ids", nextIds.join(",")),
                    ),
                  );
                }
              },
            )}
          />
        </CardContent>
      </Card>

      {props.id ? (
        <OrderTrackItem
          id={props.id}
          className={classes.card}
          getOrder={getPublicOrder}
          getOrderActivities={getPublicOrderActivities}
          getOrderDriver={getPublicOrderDriver}
          getOrderDriverLocation={getPublicOrderDriverLocation}
          onDismiss={() => props.setLocationQuery(fp.unset("id"))}
          setLocationQuery={props.setLocationQuery}
        />
      ) : (
        props.ids.size > 0 && (
          <Card className={classes.card}>
            <CardContent>
              {props.ids.reverse().map(id => (
                <OrderTrackListItem
                  id={id}
                  key={id}
                  getOrder={getPublicOrder}
                  onClick={() => props.setLocationQuery(fp.set("id", id))}
                  onRemoveClick={() =>
                    props.setLocationQuery(
                      fp.set("ids", props.ids.delete(id).join(",")),
                    )
                  }
                />
              ))}
            </CardContent>
          </Card>
        )
      )}
    </FlexBox>
  );
}

export default enhancer(CustomerOrderTrackingContainer);
