import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import {
  ListItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Text, { MUTED, DANGER, PRIMARY } from "../ui-core/Text";
import { isEqualData } from "../../helpers/DataUtils";
import { formatOrderStatusCode } from "../../helpers/OrderHelper";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();
    const responseStream = propsStream
      .distinctUntilKeyChanged("id")
      .switchMap(props =>
        props
          .getOrder(props.id)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.get("data")),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      response,
      onRequestRefresh,
    }));
  }),
);

OrderTrackListItem.propTypes = {
  onRequestRefresh: PropTypes.func,
  response: PropTypes.instanceOf(Map),

  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

function OrderTrackListItem(props) {
  const { response } = props;

  return (
    <ListItem
      disabled={response.get("pending")}
      onClick={response.get("error") ? props.onRequestRefresh : props.onClick}
    >
      <ListItemText
        primary={`Order: ${props.id}`}
        secondary={
          <p>
            Status:{" "}
            {response.get("error") ? (
              <Text type={DANGER}>Not Found</Text>
            ) : response.get("pending") ? (
              <Text type={MUTED}>Loading...</Text>
            ) : (
              <Text type={PRIMARY}>
                {formatOrderStatusCode(response.getIn(["payload", "status"]))}
              </Text>
            )}
          </p>
        }
      />
      <ListItemSecondaryAction>
        <IconButton onClick={props.onRemoveClick}>
          <Close />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default enhancer(OrderTrackListItem);
