import React from "react";
import { Collection } from "immutable";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";

RadioGroupComponent.propTypes = {
  meta: PropTypes.object,
  label: PropTypes.node,
  input: PropTypes.object,
  formatOption: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(Collection),
  ]).isRequired,
};

RadioGroupComponent.defaultProps = { formatOption: fp.identity };

function RadioGroupComponent({
  meta,
  input,
  options,
  label,
  formatOption,
  ...custom
}) {
  const [value, setValue] = React.useState("female");

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        {...custom}
        name={input.name}
        value={value}
        onChange={handleChange}
      >
        {options.map((option, key) => (
          <FormControlLabel
            key={key}
            value={option}
            control={<Radio />}
            label={formatOption(option)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

FormRadioGroup.propTypes = {
  formatOption: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(Collection),
  ]).isRequired,
};

export default function FormRadioGroup(props) {
  return <Field {...props} component={RadioGroupComponent} />;
}
