import React from "react";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({ form: "OrderTrackForm" }),
  withHandlers({
    handleSubmit: props => event => {
      props.handleSubmit(event);
      props.change("orderNumber", "");
    },
  }),
);

OrderTrackForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderTrackForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <form onSubmit={props.handleSubmit}>
      <FlexBox gutter={8}>
        <FlexBox flex={true} align="center">
          <FormTextField
            name="orderNumber"
            fullWidth={true}
            hintText={getLocalisationMessage(
              "enter_order_number",
              "Enter Order Number",
            )}
          />
        </FlexBox>

        <FlexBox align="center">
          <Button type="submit">
            {getLocalisationMessage("search", "Search")}
          </Button>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(OrderTrackForm);
