import React from "react";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { COMMUNICATION_SETTINGS_ITEM_URL } from "../../constants/CustomerPathConstants";
import { saveCustomerCommunicationMessage } from "../../api/shared/CommunicationApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CommunicationSettingsForm from "../../components/communication-settings/CommunicationSettingsForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  getContext({ replaceLocation: PropTypes.func.isRequired }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showSuccessMessage,
      showErrorMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(
        fp.flow(
          fp.get("location.query"),
          fp.pick([
            "type",
            "target",
            "messageType",
            "eventCourierType",
            "toCityId",
          ]),
          fp.update("toCityId", fp.toFinite),
          fp.set("duplicateOfPush", false),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

CustomerCommunicationSettingsCreate.propTypes = {
  location: PropTypes.object,
  replaceLocation: PropTypes.func,

  initialValues: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerCommunicationSettingsCreate(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <CustomerAppLayout
      title={getLocalisationMessage(
        "communication_settings",
        "Communication Settings",
      )}
    >
      <CommunicationSettingsForm
        initialValues={props.initialValues}
        onSubmitFail={props.showErrorMessage}
        breadcrumbTitle={getLocalisationMessage(
          "create_message",
          "Create Message",
        )}
        onSubmitSuccess={fp.flow(fp.get("data.id"), id => {
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );

          if (id) {
            props.replaceLocation(
              updateQuery(COMMUNICATION_SETTINGS_ITEM_URL + id, {
                filterType: location.query.filterType,
                eventType: location.query.eventType,
              }),
            );
          }
        })}
        onSubmit={values =>
          saveCustomerCommunicationMessage(toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerCommunicationSettingsCreate);
