import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import { showSuccessMessage } from "../../reducers/NotificationsReducer";
import {
  getPublicOrder,
  updatePublicOrderDropoffLocation,
} from "../../api/shared/PublicOrderApi";
import Text from "../../components/ui-core/Text";
import CustomerOrderLocationForm from "../../components/customer/CustomerOrderLocationForm";
import CenteredCard from "../../components/deprecated/CenteredCard";

const enhancer = compose(
  useSheet({
    root: { maxWidth: "448px" },
    text: { marginTop: "48px", marginBottom: "32px" },
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showSuccessMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const orderStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["params", "code"]))
      .switchMap(props =>
        getPublicOrder(props.params.code)
          .let(mapObjectResponseStream)
          .map(response =>
            response.update(
              "payload",
              fp.flow(
                x => x.getIn(["locations", 1]),
                toCamelCase,
                fp.toPlainObject,
                dropoffLocation => ({
                  ...dropoffLocation,

                  location: {
                    lat: dropoffLocation.lat,
                    lng: dropoffLocation.lon,
                    address: dropoffLocation.address,
                  },
                }),
              ),
            ),
          )
          .repeatWhen(() => onRequestRefreshStream),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(orderStream, (props, order) => ({
      ...props,
      onRequestRefresh,
      isLoading: order.get("pending"),
      initialValues: order.get("payload"),
      notFound: Boolean(order.get("error")),
    }));
  }),
);

CustomerOrderLocationContainer.propTypes = {
  params: PropTypes.object,
  classes: PropTypes.object,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  notFound: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerOrderLocationContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <CenteredCard
      withLogo={true}
      isLoading={props.isLoading}
      cardClassName={classes.root}
    >
      <Helmet>
        <title>
          {getLocalisationMessage("order_location", "Order Location")}
        </title>
      </Helmet>

      {!props.isLoading && (
        <CardContent>
          {props.notFound ? (
            <Text
              element="p"
              className={classes.text}
              type={["muted", "align-center"]}
            >
              {getLocalisationMessage(
                "could_not_find_any_order",
                "Could not find any order",
              )}
            </Text>
          ) : (
            <CustomerOrderLocationForm
              initialValues={props.initialValues}
              onSubmit={fp.flow(
                toSnakeCase,
                ({ location, ...values }) => ({
                  ...values,
                  lat: location.lat,
                  lon: location.lng,
                  address: location.address,
                }),
                values =>
                  updatePublicOrderDropoffLocation(
                    props.params.code,
                    values,
                  ).catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "saved_thanks_for_your_time",
                    "Saved, Thanks for your time!",
                  ),
                );
              }}
            />
          )}
        </CardContent>
      )}
    </CenteredCard>
  );
}

export default enhancer(CustomerOrderLocationContainer);
