import React, { useState } from "react";
import _ from "lodash";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signUp } from "../../actions/CustomerAuthActions";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { phoneCountyCodeList } from "../../constants/PhoneCountryCodes";
import SignUpForm from "../../components/auth/SignUpForm";
import NavigateFromSignIn from "../../components/auth/NavigateFromSignIn";
import { getCountry } from "../../../shared/reducers/AppReducer";
import {
  isTokenPersisting,
  isTokenValid,
} from "../../../shared/reducers/AuthReducer";
import { formatPhone2 } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect(
    state => ({
      isLoading: isTokenPersisting(state),
      isTokenValid: isTokenValid(state),
      marketplaceCountry: getCountry(state),
    }),
    { signUp, showErrorMessage, showSuccessMessage },
  ),
);

CustomerSignUp.propTypes = {
  signUp: PropTypes.func,
  isLoading: PropTypes.bool,
  isTokenValid: PropTypes.bool,
  marketplaceCountry: PropTypes.string,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

function CustomerSignUp(props) {
  const [isLoading, setIsLoading] = useState(false);
  if (props.isTokenValid) {
    return <NavigateFromSignIn />;
  }

  let initialValues = {};
  const defaultCountry = _.find(phoneCountyCodeList, {
    country: props.marketplaceCountry,
  });

  if (defaultCountry) {
    initialValues = { country: Map(defaultCountry) };
  }

  return (
    <SignUpForm
      isLoading={isLoading}
      initialValues={initialValues}
      onSubmit={values => {
        setIsLoading(true);
        props
          .signUp({
            ...toSnakeCase(values),
            username: formatPhone2(values.username),
          })
          .then(res => {
            if (res.value.status === "success") {
              setIsLoading(false);
              props.showSuccessMessage(res.message);
            } else {
              setIsLoading(false);
              props.showErrorMessage(res.message);
            }
          })
          .catch(error => {
            setIsLoading(false);
            if (props.onSubmitFail) {
              props.onSubmitFail(error);
            } else {
              props.showErrorMessage(error);
            }
          });
      }}
      onSubmitFail={props.showErrorMessage}
    />
  );
}

export default enhancer(CustomerSignUp);
