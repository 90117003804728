import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Paper, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { AttachMoney } from "@material-ui/icons";
import spring from "react-motion/lib/spring";
import Motion from "react-motion/lib/Motion";
import { grey } from "@material-ui/core/colors";
import PriceWrapper from "../../ui-core/PriceWrapper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { primary1 } from "../../../../shared/theme/main-theme";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    root: {
      margin: "36px 12px 0",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },

    total: {
      height: "48px",
      display: "flex",
      minWidth: "128px",
      borderRadius: "4px",
      transform: "translate3d(0, 0, 0)",
    },

    totalIcon: {
      width: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": { fill: primary1 },
      "& > div": { backgroundColor: grey[300] },
    },

    totalText: {
      display: "flex",
      paddingRight: "8px",
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "center",
    },

    totalLabel: {
      lineHeight: 1,
      color: grey[500],
      fontSize: "13px",
      fontWeight: 500,
    },

    totalPrice: { color: primary1, fontSize: "14px" },

    actions: { display: "flex", alignItems: "center", flexDirection: "column" },
  }),
);

OrderCreateWizardActions.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  currencyCode: PropTypes.string,
  total: PropTypes.number.isRequired,
  showTotal: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardActions(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Motion style={{ opacity: props.showTotal ? spring(1) : 0 }}>
      {styles => (
        <div
          className={classes.root}
          style={{
            opacity: styles.opacity,
            display: styles.opacity === 0 ? "none" : "flex",
          }}
        >
          <Paper className={classes.total}>
            <div className={classes.totalIcon}>
              <Avatar size={30}>
                <AttachMoney />
              </Avatar>
            </div>
            <div className={classes.totalText}>
              <span className={classes.totalLabel}>
                {getLocalisationMessage("total", "Total")}
              </span>

              <PriceWrapper
                className={classes.totalPrice}
                price={props.total}
                code={props.currencyCode}
              />
            </div>
          </Paper>

          <div className={classes.actions}>{props.children}</div>
        </div>
      )}
    </Motion>
  );
}

export default enhancer(OrderCreateWizardActions);
