import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import MarkerPrimary from "./assets/marker-order-create.svg";

const enhancer = compose(
  useSheet({
    text: {
      position: "absolute",
      top: "50%",
      right: "50%",
      fontSize: 14,
      width: 200,
      textAlign: "center",
      marginRight: -100,
      marginTop: -130,
      borderRadius: 0,
      padding: "0 6px",
      color: "#fff",
      backgroundColor: "#000",
    },
    marker: {
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 1,
      marginLeft: "-10px",
      marginTop: "-34px",
      height: "34px",
      width: "20px",
      cursor: "pointer",

      "& > img": {
        marginTop: "-16px",
        marginLeft: "-12px",
      },
    },
  }),
);

class WingMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: null };
  }

  componentDidMount() {
    const { mapContext } = this.context;
    const center = mapContext.map.getCenter();
    const location = { lat: center.lat(), lng: center.lng() };

    this.setAddress(location);

    this.listener = mapContext.maps.event.addListener(
      mapContext.map,
      "idle",
      this.onChange,
    );
  }

  componentWillUnmount() {
    const { mapContext } = this.context;
    mapContext.maps.event.clearInstanceListeners(this.listener);
  }

  onChange = () => {
    const { mapContext } = this.context;
    const { onChange } = this.props;

    const center = mapContext.map.getCenter();
    const location = { lat: center.lat(), lng: center.lng() };
    const geocoder = new mapContext.maps.Geocoder();

    this.setAddress(location);

    geocoder.geocode({ location }, results =>
      this.setState({ address: fp.get([0, "formatted_address"], results) }),
    );

    onChange({
      lat: center.lat(),
      lng: center.lng(),
      address: this.state.address,
    });
  };

  setAddress = location => {
    const { mapContext } = this.context;
    const geocoder = new mapContext.maps.Geocoder();

    geocoder.geocode({ location }, results =>
      this.setState({ address: fp.get([0, "formatted_address"], results) }),
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <span className={classes.text}>{this.state.address}</span>
        <div className={classes.marker}>
          <img src={MarkerPrimary} alt="" height={50} />
        </div>
      </div>
    );
  }
}

WingMarker.contextTypes = {
  mapContext: PropTypes.object.isRequired,
};

WingMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default enhancer(WingMarker);
