import React from "react";
import PropTypes from "prop-types";
import SendEmailsDialog from "../../components/form/SendEmailsDialog";

CustomerSendEmailDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmitSuccess: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
};

export default function CustomerSendEmailDialogWrapper(props) {
  return (
    <SendEmailsDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      onSubmit={props.onSubmit}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}
