import React from "react";
import { Map, List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { SENDER, RECIPIENT } from "../../constants/OrderPayerTypes";

const payerTypes = List.of(SENDER, RECIPIENT);

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  useSheet({ content: { maxWidth: "320px" } }),
  reduxForm({ form: "UpdateOrderPaymentTypeDialog" }),
);

UpdateOrderPaymentTypeDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function UpdateOrderPaymentTypeDialog(props) {
  const { i18n } = props;
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      className={props.classes.content}
    >
      <PageLoading isLoading={props.submitting} />

      <DialogTitle>{i18n.get("pay_with_cash", "Pay With Cash")}</DialogTitle>

      <DialogContent className={props.classes.content}>
        <FormSelectField
          name="payer"
          fullWidth={true}
          autoWidth={true}
          options={payerTypes}
          formatOption={x => i18n.get(x, formatText(x))}
          label={i18n.get("payer", "Payer")}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {i18n.get("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {i18n.get("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UpdateOrderPaymentTypeDialog);
