import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import OrderUploadPublishDialog from "./OrderUploadPublishDialog";
import Text, { DANGER, SUCCESS } from "../ui-core/Text";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import DataList, { DataListColumn } from "../data-list/DataList";
import MultiLineCell from "../data-list/MultiLineCell";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { formatNumber, formatText } from "../../helpers/FormatUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { NOT_VALID, VALID } from "../../constants/DraftOrderStatus";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import {
  getOrderUpload,
  getOrderUploadPublishProgressV2,
  publishOrderUploadV2,
} from "../../api/customer/CustomerOrderUploadApi";

const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func,
  }),
  useSheet({
    row: { cursor: "pointer" },
    rowError: { extend: "row", color: "red" },
  }),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();
    const progressStream = propsStream
      .distinctUntilKeyChanged("filter")
      .switchMap((props) =>
        getOrderUpload(props.id, props.filter)
          .catch((error) => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          (response) => fromJS(response),
          (response) =>
            fromJS({
              pending: response.get("pending"),
              data: response.getIn(["payload", "data"], Map()),
              total: response.getIn(["payload", "data", "total"], 0),
              proceed: response.getIn(["payload", "data", "proceed"], 0),
              succeed: response.getIn(["payload", "data", "succeed"], 0),
              failed: response.getIn(["payload", "data", "failed"], 0),
              created: response.getIn(["payload", "data", "created"], 0),
              drafts: response.getIn(["payload", "data", "drafts"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(progressStream, (props, progress) => ({
      ...props,
      onRequestRefresh,
      drafts: toJS(progress.get("drafts")),
      total: progress.get("total"),
      proceed: progress.get("proceed"),
      succeed: progress.get("succeed"),
      failed: progress.get("failed"),
      created: progress.get("created"),
      data: progress.get("data"),
      isLoading: progress.get("pending"),
    }));
  }),
);

CustomerOrderUploadDetails.propTypes = {
  setLocationQuery: PropTypes.func,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  onBackClick: PropTypes.func.isRequired,
  onPublishClick: PropTypes.func.isRequired,
  onDraftSelect: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  create: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  openPublishDialog: PropTypes.bool,
  batchId: PropTypes.number,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  total: PropTypes.number,
  proceed: PropTypes.number,
  succeed: PropTypes.number,
  failed: PropTypes.number,
  created: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  drafts: PropTypes.object,
  data: PropTypes.object,
  isCustomerApp: PropTypes.bool,
};

function CustomerOrderUploadDetails(props) {
  const {
    classes,
    getLocalisationMessage,
    batchId,
    openPublishDialog,
    isLoading,
    total,
    proceed,
    succeed,
    failed,
    created,
    drafts,
  } = props;
  const [succeedState, setsucceed] = useState(0);
  const [failedState, setfailed] = useState(0);
  const [createdState, setcreated] = useState(0);
  const [proceedState, setproceed] = useState(0);
  const [totalState, settotal] = useState(0);
  const [orderList, setOrderList] = useState(fromJS([...drafts]));
  useEffect(() => {
    if (succeed && succeed !== succeedState) setsucceed(succeed);
  }, [succeed]);
  useEffect(() => {
    if (failed && failed !== failedState) setfailed(failed);
  }, [failed]);
  useEffect(() => {
    if (created && created !== createdState) setcreated(created);
  }, [created]);
  useEffect(() => {
    if (proceed && proceed !== proceedState) setproceed(proceed);
  }, [proceed]);
  useEffect(() => {
    if (total && total !== totalState) settotal(total);
  }, [total]);
  useEffect(() => {
    if (drafts.length > 0) {
      const temporderList = fromJS([...drafts]);
      setOrderList(temporderList);
    }
  }, [drafts]);
  let refreshList;
  useEffect(() => {
    if (!isLoading && proceedState < totalState)
      refreshList = setInterval(() => props.onRequestRefresh(), 3000);
    return () => clearInterval(refreshList);
  }, [isLoading, proceedState, totalState]);
  return (
    <FlexBox direction="column" flex={true} gutter={8}>
      <OrderUploadPublishDialog
        batchId={batchId}
        open={openPublishDialog}
        getOrderUploadPublishProgress={getOrderUploadPublishProgressV2}
        onRequestClose={() => props.setLocationQuery(fp.unset(["publish"]))}
        // onRerunButtonClick={() =>
        //   reRunOrderUploadV2(batchId)
        // }
        // hasKeepOrder={false}
        onSubmit={() =>
          publishOrderUploadV2(batchId).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "your_orders_placed_successfully",
              "Congratulations! Your Orders Placed Successfully",
            ),
          );
          props.onRequestRefresh();
        }}
        onSubmitFail={(error) => {
          props.showErrorMessage(error);
          props.onRequestRefresh();
        }}
      />

      <div>
        <IconButton onClick={props.onBackClick}>
          <ArrowBack />
        </IconButton>
        {`${getLocalisationMessage("batch", "Batch")} #
            ${batchId}`}
      </div>

      <FlexBox direction="column">
        <Card>
          <CardContent>
            <FlexBox gutter={8} justify="space-between">
              <FlexBox flex={true} direction="column">
                <ListItem>
                  <ListItemText
                    disabled={true}
                    primary={`${getLocalisationMessage(
                      "total",
                      "Total",
                    )} ${formatNumber(totalState)}`}
                  />
                </ListItem>
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <ListItem>
                  <ListItemText
                    disabled={true}
                    primary={`${getLocalisationMessage(
                      "proceed",
                      "Proceed",
                    )} ${formatNumber(proceedState)}`}
                  />
                </ListItem>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <ListItem>
                  <ListItemText
                    disabled={true}
                    primary={`${getLocalisationMessage(
                      "valid",
                      "Valid",
                    )} ${formatNumber(succeedState)}`}
                  />
                </ListItem>
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <ListItem>
                  <ListItemText
                    disabled={true}
                    primary={`${getLocalisationMessage(
                      "failed",
                      "Failed",
                    )}  ${formatNumber(failedState)}`}
                  />
                </ListItem>
              </FlexBox>

              {totalState === proceedState && (
                <FlexBox flex={true} direction="column">
                  <ListItem>
                    <ListItemText
                      disabled={true}
                      primary={`${getLocalisationMessage(
                        "created",
                        "Created",
                      )} ${formatNumber(createdState)}`}
                    />
                  </ListItem>
                </FlexBox>
              )}

              {2 === 3 && (
                <FlexBox align="center">
                  {totalState === proceedState && (
                    <CustomButton
                      color={SECONDARY}
                      variant={OUTLINED}
                      onClick={() => {
                        publishOrderUploadV2(batchId).catch(
                          ResponseError.throw,
                        );
                        setTimeout(() => {
                          props.onPublishClick();
                        }, 250);
                      }}
                    >
                      {" "}
                      {getLocalisationMessage("publish", "Publish")}{" "}
                    </CustomButton>
                  )}
                </FlexBox>
              )}
            </FlexBox>
          </CardContent>
        </Card>
      </FlexBox>
      {totalState !== proceedState && (
        <FlexBox
          flex={true}
          align={ALIGN_CENTER}
          direction="column"
          style={{ flex: "0 1 auto" }}
        >
          <CircularProgress color="secondary" />
        </FlexBox>
      )}
      {totalState !== proceedState && (
        <LinearProgress
          color="secondary"
          style={{
            margin: ".25rem",
            height: "5px",
          }}
          variant="determinate"
          value={orderList.size > 0 ? (proceedState / totalState) * 100 : 0}
        />
      )}

      <FlexBox flex={true} direction="column">
        <FlexBox flex={true} element={<Paper />}>
          <DataList
            totalCount={totalState}
            list={orderList}
            rowCount={orderList.size}
            rowGetter={(options) => orderList.get(options.index)}
            overscanRowCount={10}
            filter={props.filter}
            onFilterChange={(filter) => props.setLocationQueryFilter(filter)}
            isLoading={isLoading}
            getRowClassName={(row) =>
              row.index === -1
                ? ""
                : orderList.getIn([row.index, "status"]) !== NOT_VALID
                ? classes.row
                : classes.rowError
            }
            onRowClick={(row) => props.onDraftSelect(row.rowData.get("id"))}
          >
            {props.create && (
              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("barcode", "Barcode")}
                dataKey="created_order_id"
                cellRenderer={(row) => (
                  <Text type={DANGER}>
                    {row.cellData.getIn(["barcode", "content"])}
                  </Text>
                )}
              />
            )}

            <DataListColumn
              label={getLocalisationMessage("id", "ID")}
              width={120}
              dataKey="id"
              disableSort={true}
              justifyContent="center"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.get("id")}
                  secondLine={
                    row.cellData.get("status") === VALID ? (
                      <Text type={SUCCESS}>
                        {getLocalisationMessage("valid", "Valid")}
                      </Text>
                    ) : (
                      <Text type={DANGER}>
                        {getLocalisationMessage("invalid", "Invalid")}
                      </Text>
                    )
                  }
                />
              )}
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("reference_id", "Reference ID")}
              dataKey="reference"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn(["reference_id", "content"])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "reference",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />

            <DataListColumn
              width={80}
              label={getLocalisationMessage("status", "Status")}
              disableSort={true}
              dataKey="is_created"
              justifyContent="center"
              cellRenderer={(row) =>
                formatText(
                  getLocalisationMessage(
                    row.cellData.get("is_created"),
                    row.cellData.get("is_created"),
                  ),
                )
              }
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("weight", "Weight Category")}
              dataKey="weight_category"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn(["weight", "content"])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "weight",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("service_type", "Service Type")}
              dataKey="service_type"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn(["service_type", "content"])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "service_type",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("sender_name", "Sender Name")}
              dataKey="sender_name"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn(["sender_name", "content"])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "sender_name",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage(
                "sender_jurisdiction",
                "Sender Jurisdiction",
              )}
              dataKey="sender_city"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn([
                    "sender_jurisdiction",
                    "content",
                  ])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "sender_jurisdiction",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("recipient_name", "Recipient Name")}
              dataKey="recipient_name"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn(["recipient_name", "content"])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "recipient_name",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />

            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage(
                "recipient_jurisdiction",
                "Recipient jurisdiction",
              )}
              dataKey="recipient_jurisdiction"
              cellRenderer={(row) => (
                <MultiLineCell
                  firstLine={row.cellData.getIn([
                    "recipient_jurisdiction",
                    "content",
                  ])}
                  secondLine={
                    <Text type={DANGER}>
                      {row.cellData.getIn([
                        "recipient_jurisdiction",
                        "failed_field",
                        "message",
                      ])}
                    </Text>
                  }
                />
              )}
            />
          </DataList>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerOrderUploadDetails);
