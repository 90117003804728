import { Observable } from "rxjs";
import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, formValueSelector } from "redux-form";
import { Avatar, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { Info } from "@material-ui/icons";
import { red, green } from "@material-ui/core/colors";
import OrderCreateWizardStepWhatSample from "./OrderCreateWizardStepWhatSample";
import OrderCreateWizardStepProhibitsDialog from "../10-terms/OrderCreateWizardStepProhibitsDialog";
import OrderCreateWizardStepTermsConditionsDialog from "../10-terms/OrderCreateWizardStepTermsConditionsDialog";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FormRadioGroup from "../../form/FormRadioGroup";
import FlexBox, { ALIGN_END } from "../../ui-core/FlexBox";
import { isEmpty } from "../../../helpers/DataUtils";
import {
  parseOnlyPositiveFloat,
  parseOnlyPositiveInteger,
} from "../../../helpers/FormatUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import { getMarketplaceSkipItem } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import UnitsOfMeasureTypes, {
  CM_KG,
  INCHES_POUNDS,
} from "../../../constants/UnitsOfMeasureTypes";

const formatUnitsOfMeasureTypes = (type, getLocalisationMessage) => {
  switch (type) {
    case CM_KG:
      return getLocalisationMessage("cm_kg", "cm/kg");
    case INCHES_POUNDS:
      return getLocalisationMessage("inches_pounds", "Inches/Pounds");
    default:
      return type;
  }
};

const formatSize = (type, getLocalisationMessage) => {
  switch (type) {
    case CM_KG:
      return getLocalisationMessage("cm", "cm");
    case INCHES_POUNDS:
      return getLocalisationMessage("inch", "inch");
    default:
      return type;
  }
};

const formatWight = (type, getLocalisationMessage) => {
  switch (type) {
    case CM_KG:
      return getLocalisationMessage("kg", "kg");
    case INCHES_POUNDS:
      return getLocalisationMessage("pound", "pound");
    default:
      return type;
  }
};

const valueSelector = formValueSelector("OrderCreateWizardNew");

const enhancer = compose(
  useSheet({
    progress: { display: "flex", paddingTop: "24px", justifyContent: "center" },

    unit: {
      borderTop: "1px solid #eee",
      borderBottom: "1px solid #eee",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    label: { minWidth: 140 },
    radio: {
      display: "flex",
      alignItems: "center",
      "& > div": {
        marginRight: 20,
      },
    },

    color: { color: "#aaa" },
    unitInfo: { color: "#aaa", paddingTop: "30px" },
    fullWidth: { width: "100%" },
    additional: {
      textTransform: "uppercase",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    chargeInfo: {
      color: red[400],
      paddingTop: "10px",
      paddingBottom: "10px",
      fontSize: "14px",
    },

    actualWeight: {
      marginTop: 20,
      borderTop: "1px solid #eee",
      padding: "5px 0 5px 0",
    },

    volumetricWeight: {
      borderBottom: "1px solid #eee",
      padding: "10px 0 10px 0",
    },
    infoMessageIcon: {
      marginTop: "-5px",
      cursor: "pointer",
    },
  }),
  connect(state => ({
    isSkipItem: getMarketplaceSkipItem(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("state", "setState", {
    showTermsAndConditionsDialog: false,
    showProhibitsDialog: false,
  }),
  reduxForm({
    form: "OrderCreateWizardNew",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: (values, props) => ({
      termsAndConditions:
        props.publicTerms.get("required") && !values.termsAndConditions
          ? props.getLocalisationMessage(
              "terms_and_conditions",
              "Terms and Conditions",
            )
          : null,
      notProhibitedProducts:
        props.publicProhibits.get("required") && !values.notProhibitedProducts
          ? props.getLocalisationMessage(
              "prohibited_and_restricted_terms",
              "Prohibited and Restricted Items",
            )
          : null,
    }),
  }),
  formValues({
    weight: "weight",
    notProhibitedProducts: "notProhibitedProducts",
    termsAndConditions: "termsAndConditions",
  }),
  connect(state => ({
    unit: valueSelector(state, "unit"),
    menu: valueSelector(state, "menu"),
    length: valueSelector(state, "length"),
    width: valueSelector(state, "width"),
    height: valueSelector(state, "height"),
    weight: valueSelector(state, "weight"),
    chargeableWeight: valueSelector(state, "chargeableWeight"),
    domestic: valueSelector(state, "domestic"),
    itemValue: valueSelector(state, "itemValue"),
    values: valueSelector(
      state,
      "initialWeight",
      "weight",
      "pickupCountry",
      "dropoffCountry",
      "chargeableWeight",
      "volumetricWeight",
    ),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          stream: sampleItemStream,
          handler: onSetSampleItem,
        } = createEventHandler();

        Observable.merge(
          sampleItemStream
            .withLatestFrom(propsStream)
            .map(([sampleItem, props]) => ({ sampleItem, ...props })),
          propsStream
            .withLatestFrom(sampleItemStream)
            .map(([props, sampleItem]) => ({ sampleItem, ...props })),
        )
          .filter(fp.flow(fp.get("sampleItem"), fp.negate(isEmpty)))
          .distinctUntilChanged(null, ({ sampleItem, unit }) =>
            fp.join(",", [sampleItem.get("id"), unit]),
          )
          .skipWhile(({ menu }, count) => !count && menu)
          .subscribe(({ sampleItem, ...props }) => {
            const unit = props.unit === CM_KG ? "cm" : "in";

            props.change("menu", sampleItem);
            props.change("length", sampleItem.get(`length_in_${unit}`));
            props.change("width", sampleItem.get(`width_in_${unit}`));
            props.change("height", sampleItem.get(`height_in_${unit}`));
          });

        return propsStream.combineLatest(props => ({
          ...props,
          onSetSampleItem,
        }));
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream.take(1).do(props => {
            if (props.values.initialWeight) {
              props.change("initialWeight", null);
              props.change("weight", props.initialValues.initialWeight);

              props.touch("weight");
            }
            props.touch("weight");
          }),
        );

        return propsStream.combineLatest(
          sideEffectsStream,
          propsStream
            .filter(props => props.isSkipItem && props.weight > 0)
            .take(1)
            .do(props => {
              props.change("notProhibitedProducts", true);
              props.change("termsAndConditions", true);
              props.handleSubmit();
            })
            .startWith(null),
          fp.identity,
        );
      },
    ),
  ),
);

OrderCreateWizardStepWhatForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  state: PropTypes.object,
  change: PropTypes.func,
  showContent: PropTypes.bool,
  setState: PropTypes.func,
  unit: PropTypes.string,
  menu: PropTypes.instanceOf(Map),
  weight: PropTypes.number,
  onSubmit: PropTypes.func,
  onSetSampleItem: PropTypes.func,
  handleSubmit: PropTypes.func,
  sampleItemsFetching: PropTypes.bool.isRequired,
  sampleItems: PropTypes.instanceOf(List).isRequired,
  isLoading: PropTypes.bool.isRequired,
  domestic: PropTypes.bool,
  chargeableWeight: PropTypes.number,
  initialValues: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  notProhibitedProducts: PropTypes.bool,
  termsAndConditions: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  publicProhibits: PropTypes.instanceOf(Map),
  publicTerms: PropTypes.instanceOf(Map),
};

function OrderCreateWizardStepWhatForm(props) {
  const { state, getLocalisationMessage } = props;

  return (
    <OrderCreateWizardStepCard
      title={getLocalisationMessage(
        "what_is_the_size_of_the_shipment",
        "What is the size of the shipment?",
      )}
      showContent={props.showContent}
      showSubmit={Boolean(props.weight)}
      onSubmit={props.handleSubmit}
    >
      {props.sampleItemsFetching ? (
        <div className={props.classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {state.showTermsAndConditionsDialog && (
            <OrderCreateWizardStepTermsConditionsDialog
              defaultTerms={props.publicTerms}
              open={true}
              onRequestClose={() =>
                props.setState(fp.set("showTermsAndConditionsDialog", false))
              }
              onAccept={() => {
                props.setState(fp.set("showTermsAndConditionsDialog", false));
              }}
            />
          )}

          {state.showProhibitsDialog && (
            <OrderCreateWizardStepProhibitsDialog
              defaultTerms={props.publicProhibits}
              open={true}
              onRequestClose={() =>
                props.setState(fp.set("showProhibitsDialog", false))
              }
              onAccept={() => {
                props.setState(fp.set("showProhibitsDialog", false));
              }}
            />
          )}
          <OrderCreateWizardStepWhatSample
            sampleItem={props.menu}
            sampleItems={props.sampleItems}
            onClickArrow={props.onSetSampleItem}
          />
          <FlexBox className={props.classes.unit}>
            <span className={props.classes.label}>
              {getLocalisationMessage("measuring_units", "Measuring Units")}:
            </span>
            <FormRadioGroup
              className={props.classes.radio}
              name="unit"
              options={UnitsOfMeasureTypes}
              formatOption={value =>
                formatUnitsOfMeasureTypes(value, getLocalisationMessage)
              }
            />
          </FlexBox>
          <FlexBox direction="column">
            <FlexBox flex={true}>
              <FlexBox
                className={props.classes.fullWidth}
                style={{ paddingRight: "20px" }}
              >
                <FlexBox align="center">
                  <FormTextField
                    name="length"
                    parse={parseOnlyPositiveInteger}
                    fullWidth={true}
                    label={`${getLocalisationMessage("length", "Length")}: *`}
                  />
                  <span className={props.classes.unitInfo}>
                    {formatSize(props.unit, getLocalisationMessage)}
                  </span>
                </FlexBox>
              </FlexBox>

              <FlexBox
                className={props.classes.fullWidth}
                style={{ paddingRight: "20px" }}
              >
                <FlexBox align="center">
                  <FormTextField
                    name="width"
                    fullWidth={true}
                    parse={parseOnlyPositiveInteger}
                    label={`${getLocalisationMessage("width", "Width")}: *`}
                  />
                  <span className={props.classes.unitInfo}>
                    {formatSize(props.unit, getLocalisationMessage)}
                  </span>
                </FlexBox>
              </FlexBox>

              <FlexBox className={props.classes.fullWidth}>
                <FlexBox align="center">
                  <FormTextField
                    name="height"
                    fullWidth={true}
                    parse={parseOnlyPositiveInteger}
                    label={`${getLocalisationMessage("height", "Height")}: *`}
                  />
                  <span className={props.classes.unitInfo}>
                    {formatSize(props.unit, getLocalisationMessage)}
                  </span>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>
              <FlexBox align="center" className={props.classes.fullWidth}>
                <FormTextField
                  name="weight"
                  fullWidth={true}
                  parseOnBlur={parseOnlyPositiveFloat}
                  label={`${getLocalisationMessage(
                    "actual_weight",
                    "Actual Weight",
                  )} *:`}
                />
                <span className={props.classes.unitInfo}>
                  {formatWight(props.unit, getLocalisationMessage)}
                </span>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox
            align="center"
            justify="space-between"
            className={props.classes.volumetricWeight}
          >
            <span>
              {getLocalisationMessage("chargeable_weight", "Chargeable Weight")}
              :
            </span>
            <strong>
              {props.isLoading
                ? getLocalisationMessage("loading", "loading...")
                : `${props.values.chargeableWeight}${formatWight(
                    props.unit,
                    getLocalisationMessage,
                  )}`}
            </strong>
          </FlexBox>

          <FlexBox
            align="center"
            justify="space-between"
            className={props.classes.additional}
          >
            <span>
              {getLocalisationMessage("additional_info", "Additional Info")}
            </span>
          </FlexBox>

          <FlexBox justify="space-between" align="center">
            <FlexBox flex={true}>
              {getLocalisationMessage("item_value", "Item value")}:
            </FlexBox>
            <FlexBox flex={true} align="center">
              <FormTextField
                name="itemValue"
                parseOnBlur={parseOnlyPositiveFloat}
                fullWidth={true}
                placeholder={`${getLocalisationMessage(
                  "item_value",
                  "Item value",
                )} ${props.domestic ? "" : "*"}`}
              />
              <span className={props.classes.color} />
            </FlexBox>
          </FlexBox>
          <FlexBox justify="space-between" align="center">
            <FlexBox flex={true}>
              {getLocalisationMessage("item_description", "Item Description")}:
            </FlexBox>
            <FlexBox flex={true} align="center">
              <FormTextField
                name="note"
                fullWidth={true}
                placeholder={getLocalisationMessage(
                  "item_description",
                  "Item Description",
                )}
              />
              <span className={props.classes.color} />
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            {props.publicTerms.get("enabled") && (
              <FlexBox flex={true}>
                <FlexBox flex={8}>
                  <FormCheckbox
                    name="termsAndConditions"
                    label={`${getLocalisationMessage(
                      "terms_and_conditions",
                      "Terms and Conditions",
                    )} ${props.publicTerms.get("required") ? "*" : ""}`}
                  />
                </FlexBox>
                <FlexBox justify="flex-end" flex={true}>
                  <Avatar
                    size={30}
                    style={{
                      backgroundColor: "white",
                      color: props.termsAndConditions ? green[500] : red[400],
                    }}
                    className={props.classes.infoMessageIcon}
                    onClick={() => {
                      props.setState(
                        fp.set("showTermsAndConditionsDialog", true),
                      );
                    }}
                  >
                    <Info />
                  </Avatar>
                </FlexBox>
              </FlexBox>
            )}

            {props.publicProhibits.get("enabled") && (
              <FlexBox flex={true}>
                <FlexBox flex={8}>
                  <FormCheckbox
                    name="notProhibitedProducts"
                    label={`${getLocalisationMessage(
                      "prohibited_and_restricted_terms",
                      "Prohibited and Restricted Items",
                    )} ${props.publicProhibits.get("required") ? "*" : ""}`}
                  />
                </FlexBox>
                <FlexBox justify="flex-end" align={ALIGN_END} flex={true}>
                  <Avatar
                    size={30}
                    style={{
                      backgroundColor: "white",
                      color: props.notProhibitedProducts
                        ? green[500]
                        : red[400],
                    }}
                    className={props.classes.infoMessageIcon}
                    onClick={() =>
                      props.setState(fp.set("showProhibitsDialog", true))
                    }
                  >
                    <Info />
                  </Avatar>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </div>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepWhatForm);
