import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function CheckboxIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Checkbox">
          <rect
            id="Checkbox=Unchecked"
            x="1.85721"
            y="1"
            width="16"
            height="16"
            rx="4"
            stroke="#A1A1AA"
            strokeWidth="2"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
