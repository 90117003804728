import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { changeLang } from "../../api/shared/ProfileApi";
import { fetchUser } from "../../actions/CustomerProfileActions";
import { fetchUser as updateUser } from "../../actions/ProfileActions";
import { connect } from "react-redux";
import { getAppName } from "../../../shared/reducers/AppReducer";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import { MenuItem, Select } from "@material-ui/core";
import {
  ENGLISH_LANGUAGE,
  LanguagesList,
  RUSSIAN_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../../constants/LocaleTypes";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCountryFlag from "react-country-flag";
import FlexBox from "../ui-core/FlexBox";
import { fetchUserAccount } from "../../actions/UserAccountActions";
import { fetchMarketplace } from "../../actions/MarketplaceActions";

const languageWithFlagName = (code, name) => {
  switch (code) {
    case ENGLISH_LANGUAGE:
      return (
        <FlexBox style={{ gap: 5 }} align="center">
          <ReactCountryFlag
            countryCode="gb"
            svg={true}
            style={{
              fontSize: "1.2em",
              lineHeight: "1.2em",
            }}
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
          />

          {name}
        </FlexBox>
      );

    case RUSSIAN_LANGUAGE:
      return (
        <FlexBox style={{ gap: 5 }} align="center">
          <ReactCountryFlag
            style={{
              fontSize: "1.2em",
              lineHeight: "1.2em",
            }}
            countryCode={RUSSIAN_LANGUAGE}
            svg={true}
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
          />
          {name}
        </FlexBox>
      );

    default:
      return (
        <FlexBox style={{ gap: 5 }} align="center">
          <ReactCountryFlag
            style={{
              fontSize: "1.2em",
              lineHeight: "1.2em",
            }}
            countryCode={UZBEK_LANGUAGE}
            svg={true}
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
          />
          {name}
        </FlexBox>
      );
  }
};

const languageWithFlag = (code, getLocalisationMessage) => {
  switch (code) {
    case ENGLISH_LANGUAGE:
      return (
        <FlexBox style={{ gap: 5 }} align="center">
          <ReactCountryFlag
            style={{
              fontSize: "1.2em",
              lineHeight: "1.2em",
            }}
            countryCode="gb"
            svg={true}
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
          />

          {getLocalisationMessage(code).toLocaleUpperCase()}
        </FlexBox>
      );

    case RUSSIAN_LANGUAGE:
      return (
        <FlexBox style={{ gap: 5 }} align="center">
          <ReactCountryFlag
            style={{
              fontSize: "1.2em",
              lineHeight: "1.2em",
            }}
            countryCode={RUSSIAN_LANGUAGE}
            svg={true}
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
          />
          {getLocalisationMessage(code).toLocaleUpperCase()}
        </FlexBox>
      );

    default:
      return (
        <FlexBox style={{ gap: 5 }} align="center">
          <ReactCountryFlag
            style={{
              fontSize: "1.2em",
              lineHeight: "1.2em",
            }}
            countryCode={UZBEK_LANGUAGE}
            svg={true}
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={code}
          />
          {getLocalisationMessage(code).toLocaleUpperCase()}
        </FlexBox>
      );
  }
};

const enhancer = compose(
  connect(
    state => ({
      app: getAppName(state),
      currentLanguage: getCurrentLanguage(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      fetchUser,
      updateUser,
      fetchUserAccount,
      fetchMarketplace,
    },
  ),
  reduxForm({
    form: "AppLanguageSelector",
    enableReinitialize: true,
  }),
);

AppLanguageSelector.propTypes = {
  app: PropTypes.string,
  fetchUser: PropTypes.func,
  setIsLoading: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  currentLanguage: PropTypes.string,
  fetchUserAccount: PropTypes.func,
  updateUser: PropTypes.func,
  fetchMarketplace: PropTypes.func,
};

function AppLanguageSelector(props) {
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    style: {
      marginTop: 10,
    },
  };

  return (
    <Select
      style={{
        textAlign: "right",
        textTransform: "uppercase",
        fontSize: 16,
        paddingBottom: "10px",
        paddingTop: "10px",
        color: "#111",
        fontWeight: 500,
      }}
      disableUnderline={true}
      value={props.currentLanguage}
      MenuProps={menuProps}
      onChange={e => {
        props.setIsLoading(true);

        changeLang(e.target.value)
          .then(() => {
            props
              .updateUser()
              .then(() => {
                props.setIsLoading(false);
              })
              .catch(() => props.setIsLoading(false));
            props
              .fetchUser()
              .then(() => {
                props.setIsLoading(false);
              })
              .catch(() => props.setIsLoading(false));
            props
              .fetchUserAccount()
              .then(() => {
                props.setIsLoading(false);
              })
              .catch(() => props.setIsLoading(false));
            props
              .fetchMarketplace()
              .then(() => {
                props.setIsLoading(false);
              })
              .catch(() => props.setIsLoading(false));
          })
          .catch(() => {
            props.setIsLoading(false);
          });
      }}
      renderValue={value =>
        languageWithFlag(value, props.getLocalisationMessage)
      }
    >
      {/* eslint-disable-next-line new-cap */}
      {LanguagesList(props.getLocalisationMessage).map((option, index) => (
        <MenuItem
          style={{
            fontSize: 16,
            paddingBottom: "10px",
            paddingTop: "10px",
            color: "#111",
            fontWeight: 500,
          }}
          key={index}
          value={option.code}
        >
          {" "}
          {languageWithFlagName(option.code, option.name)}
        </MenuItem>
      ))}
    </Select>
  );
}

export default enhancer(AppLanguageSelector);
