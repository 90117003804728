import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardMedia, ListSubheader, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../../ui-core/FlexBox";
import { isEqualData, mapResponseData } from "../../../helpers/DataUtils";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { getFileUrl } from "../../../api/shared/FileApi";
import imgNotFound from "../assets/notfound.png";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    cardMedia: { margin: "0 6px" },
    card: {
      fontFamily: "Blogger Sans",
      height: "100%",
      maxHeight: "400px",
      minHeight: "300px",
    },
    image: {
      objectFit: "contain",
      width: "250px",
      height: "224px",
      margin: "0 6px",
    },
  }),
  mapPropsStream(propsStream => {
    const signatureUrlResponseStream = propsStream
      .pluck("deliverySignatureId")
      .filter(
        deliverySignatureId =>
          fp.isFinite(deliverySignatureId) && deliverySignatureId > 0,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(deliverySignatureId =>
        getFileUrl(deliverySignatureId).catch(error =>
          Observable.of({ error }),
        ),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    const photoUrlResponseStream = propsStream
      .pluck("deliveryPhotoId")
      .filter(
        deliveryPhotoId => fp.isFinite(deliveryPhotoId) && deliveryPhotoId > 0,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(deliveryPhotoId =>
        getFileUrl(deliveryPhotoId).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        signatureUrlResponseStream,
        photoUrlResponseStream,
        (props, signatureUrlResponse, photoUrlResponse) => ({
          ...props,
          signatureUrl: signatureUrlResponse.get("payload"),
          photoUrl: photoUrlResponse.get("payload"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

CustomerOrderDialogDetailsSignature.propTypes = {
  deliverySignatureId: PropTypes.number,
  deliveryPhotoId: PropTypes.number,
  signatureUrl: PropTypes.string,
  photoUrl: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
  order: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  header: PropTypes.string,
};

function CustomerOrderDialogDetailsSignature(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title={props.header} />

      <CardMedia className={classes.cardMedia}>
        <FlexBox
          justify="space-around"
          style={{
            marginBottom: "30px",
          }}
        >
          <FlexBox direction="column">
            <ListSubheader>
              {getLocalisationMessage("order_photo", "Photo")}
            </ListSubheader>

            <img
              alt="Photos"
              src={props.photoUrl || imgNotFound}
              className={classes.image}
            />
          </FlexBox>
          <FlexBox direction="column">
            <ListSubheader>
              {getLocalisationMessage("signature", "Signature")}
            </ListSubheader>
            <img
              alt={getLocalisationMessage("signature", "Signature")}
              src={props.signatureUrl || imgNotFound}
              className={classes.image}
            />
          </FlexBox>
        </FlexBox>
      </CardMedia>
    </Card>
  );
}

export default enhancer(CustomerOrderDialogDetailsSignature);
