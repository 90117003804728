import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  withContext,
  withHandlers,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import { PanTo, CustomControl } from "react-google-map-components";
import FormTextField from "../form/FormTextField";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import FormMapPinDialog from "../deprecated/FormMapPinDialog";
import PublicOrderCityField from "../orders-core/PublicOrderCityField";
import PublicOrderNeighborhoodField from "../orders-core/PublicOrderNeighborhoodField";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  isBlankString,
  isValidCoordinates,
  validatePhoneNumber,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getPublicCachedCity,
  getPublicCityPredictions,
  getPublicCachedNeighborhood,
  getPublicNeighborhoodPredictions,
} from "../../api/shared/CountryV2Api";
import { getPublicLocationDetails } from "../../api/customer/CustomerApiV2";
import { primary1 } from "../../../shared/theme/main-theme";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";

const validateLocation = (location, country, getLocalisationMessage) =>
  !isValidCoordinates(location)
    ? getLocalisationMessage(
        "type_address_above_to_search",
        "Type address above to search",
      )
    : location.country &&
      country.code &&
      location.country !== country.code &&
      getLocalisationMessage(
        "please_select_address_with_selected_country",
        "Please select address with selected country",
      );

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      mapProvider: getMapProvider(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    map: { height: "200px", marginTop: "0", width: "100%" },
    actions: { marginTop: "12px" },
  }),
  withState("state", "setState", {
    showMapDialog: false,
  }),
  withContext(
    {
      getPublicCachedCity: PropTypes.func.isRequired,
      getPublicCityPredictions: PropTypes.func.isRequired,
      getPublicCachedNeighborhood: PropTypes.func.isRequired,
      getPublicNeighborhoodPredictions: PropTypes.func.isRequired,
    },
    fp.always({
      getPublicCachedCity,
      getPublicCityPredictions,
      getPublicCachedNeighborhood,
      getPublicNeighborhoodPredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }
      const request = {};

      request.neighborhood = values.neighborhood;
      request.landmark = values.landmark;
      request.street = values.street;
      request.apartment = values.apartment;
      request.building = values.building;
      request.city = values.city;
      request.lat = values.location.lat;
      request.lon = values.location.lng;
      return props.onSubmit(request);
    },
  }),
  reduxForm({
    form: "OrderAddressForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      apartment:
        !values.apartment &&
        props.getLocalisationMessage(
          "enter_apartment_address",
          "Enter apartment address",
        ),
      building:
        !values.building &&
        props.getLocalisationMessage(
          "enter_building_address",
          "Enter building address",
        ),
      location: validateLocation(
        values.location,
        values.country,
        props.getLocalisationMessage,
      ),
      phone: validatePhoneNumber(values.phone),
      name:
        isBlankString(values.name) &&
        props.getLocalisationMessage("enter_name", "Enter name"),
      city:
        isBlankString(values.city) &&
        props.getLocalisationMessage(
          "please_select_city",
          "Please select city",
        ),
      neighborhood:
        isBlankString(values.neighborhood) &&
        props.getLocalisationMessage(
          "region_fields_required",
          "Region fields required",
        ),
    }),
  }),
  formValues({
    location: "location",
    phoneCode: "phoneCode",
    country: "country",
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const locationDetailsResponseStream = propsStream
          .distinctUntilKeyChanged("location")
          .filter(props => props.location)
          .map(props =>
            props.location && props.location.lat
              ? new DataListFilter({
                  lat: props.location.lat,
                  lon: props.location.lng,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getPublicLocationDetails(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .startWith(Map())
          .map(mapResponseData)
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            locationDetailsResponseStream,
            (props, locationDetailsResponse) => ({
              ...props,
              locationDetails: locationDetailsResponse.get("payload"),
              locationDetailsFetching: locationDetailsResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilKeyChanged("locationDetails")
            .filter(fp.get("locationDetails"))
            .do(props => {
              if (props.locationDetails.get("neighborhood")) {
                if (props.locationDetails.get("neighborhood")) {
                  props.change(
                    "neighborhood",
                    props.locationDetails.get("neighborhood").toJS(),
                  );
                } else
                  props.change(
                    "neighborhood",
                    props.locationDetails.getIn(["neighborhood", "name"]) || "",
                  );
              } else if (_.isObject(props.locationDetails))
                props.change("neighborhood", null);

              if (props.locationDetails.get("country")) {
                if (props.locationDetails.get("country")) {
                  props.change(
                    "country",
                    props.locationDetails.get("country").toJS(),
                  );
                }
              }

              if (props.locationDetails.get("city")) {
                if (props.locationDetails.get("city")) {
                  props.change(
                    "city",
                    props.locationDetails.get("city").toJS(),
                  );
                } else
                  props.change(
                    "city",
                    props.locationDetails.getIn(["city", "name"]) || "",
                  );
              } else if (_.isObject(props.locationDetails))
                props.change("city", null);
            }),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream.combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
);

AddressLocationForm.propTypes = {
  state: PropTypes.object,
  classes: PropTypes.object,
  values: PropTypes.object,
  reset: PropTypes.func,
  onSkip: PropTypes.func,
  setState: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  location: PropTypes.object,
  locationDetails: PropTypes.object,
  locationDetailsFetching: PropTypes.bool,
  phoneCode: PropTypes.instanceOf(Map),
  country: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  mapProvider: PropTypes.string,
};

function AddressLocationForm(props) {
  const { classes, state, getLocalisationMessage } = props;

  const countryCode =
    props.locationDetails &&
    props.locationDetails.getIn(["country", "code"], "AE");

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <FlexBox
      gutter={8}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FormMapPinDialog
        countryCode={countryCode}
        mapCenter={props.location}
        open={state.showMapDialog}
        initialValues={{ location: props.location }}
        onRequestClose={() => props.setState(fp.set("showMapDialog", false))}
        onSubmit={values => {
          props.setState(fp.set("showMapDialog", false));
          props.change("location", values.location);
        }}
      />

      <FlexBox flex={true} gutter={16}>
        {isGoogleMapProvider ? (
          <GoogleMapWrapper className={props.classes.map} zoom={7}>
            {isValidCoordinates(props.location) && (
              <div>
                <PanTo latLng={props.location} />
                <BrandMarker position={props.location} />
              </div>
            )}

            <CustomControl position="TOP_RIGHT">
              <CardActions>
                <Button
                  label={getLocalisationMessage(
                    "select_address_from_map",
                    "Select Address From Map",
                  )}
                  backgroundColor={primary1}
                  labelStyle={{ color: "#fff" }}
                  hoverColor={primary1}
                  onClick={() => props.setState(fp.set("showMapDialog", true))}
                />
              </CardActions>
            </CustomControl>
          </GoogleMapWrapper>
        ) : (
          <LeafletMapWrapper className={props.classes.map} zoom={7}>
            {isValidCoordinates(props.location) && (
              <div>
                <LeafletPanTo latLng={props.location} />
                <LeafletBrandMarker position={props.location} />
              </div>
            )}

            {/** TODO Custom control */}
          </LeafletMapWrapper>
        )}
      </FlexBox>

      {props.locationDetails && !props.locationDetailsFetching && (
        <FlexBox>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <PublicOrderCityField
                name="city"
                fullWidth={true}
                label={getLocalisationMessage("city", "City")}
                readOnly={true}
                location={props.locationDetails}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <PublicOrderNeighborhoodField
                name="neighborhood"
                fullWidth={true}
                label={getLocalisationMessage("region", "Region")}
                readOnly={true}
                location={props.locationDetails}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}

      <FlexBox>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormTextField
              name="street"
              fullWidth={true}
              label={getLocalisationMessage("street", "Street")}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormTextField
              name="building"
              fullWidth={true}
              label={getLocalisationMessage(
                "building_villa_star",
                "Building / Villa *",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              name="apartment"
              fullWidth={true}
              label={getLocalisationMessage(
                "office_apartment",
                "Office/Apartment #",
              )}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FormTextField
          name="landmark"
          fullWidth={true}
          label={getLocalisationMessage("nearest_landmark", "Nearest Landmark")}
        />
      </FlexBox>

      <FlexBox justify="flex-end" className={classes.actions}>
        <Button
          label={getLocalisationMessage("skip", "Skip")}
          onClick={props.onSkip}
        />
        <Button
          label={getLocalisationMessage("reset", "Reset")}
          onClick={props.reset}
        />
        <Button
          type="submit"
          label={getLocalisationMessage("submit", "Submit")}
        />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AddressLocationForm);
