import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    content: {
      maxWidth: "320px",
    },
  }),
);

OrderSupplierLandlineDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  supplier: PropTypes.instanceOf(Map).isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function OrderSupplierLandlineDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      contentClassName={props.classes.content}
      PaperProps={{
        style: {
          maxWidth: "320px",
        },
      }}
    >
      <DialogContent>
        {props.i18n.get("company", "Company")}:{" "}
        <strong>{props.supplier.get("name")}</strong>
        <br />
        {props.i18n.get("phone", "Phone")}: <strong>1165</strong>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {props.i18n.get("dismiss", "Dismiss")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSupplierLandlineDialog);
