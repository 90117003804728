import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import _ from "lodash";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  FileCopy,
  FormatListBulleted,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LocationOn,
} from "@material-ui/icons";
import FlexBox, {
  ALIGN_CENTER,
  ALIGN_END,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import {
  getValue,
  kirilToLatinByKeyboardPlace,
  toJS,
} from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { fromJS, List, Map } from "immutable";
import { getUser, getUserLanguage } from "../../reducers/ProfileReducer";
import {
  showErrorMessage,
  showSuccessMessage,
  SUCCESS_MESSAGE,
} from "../../reducers/NotificationsReducer";
import {
  ORDER_LIST_URL,
  ORDERS_PER_POSTCODES_URL_2,
} from "../../constants/CustomerPathConstants";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";
import FormTextField from "../form/FormTextField";
import { formatText } from "../../helpers/FormatUtils";
import FormSelectField from "../form/FormSelectField";
import { getCustomerServiceTypes } from "../../api/admin/AdminServiceTypesApi";
import { getCustomerPackagePrices } from "../../api/shared/PackagePricesApi";
import {
  ERROR,
  LOADING,
  money,
  NOT_STARTED,
  SUCCESS,
  UZ,
  UZB_CURRENCY,
} from "./AdminPostForm";
import { TransitionUp } from "../dialog/TransitionUp";
import TextWithLink from "../router/TextWithLink";
import { createOrderHref } from "../../containers/customer/CustomerOrderCreateSuccess";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import successImg from "../../assets/customerPostForm/success.png";
import loadingImg from "../../assets/customerPostForm/loading.png";
import errorImg from "../../assets/customerPostForm/error.png";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { getCustomerPaymentMethods2 } from "../../api/v2/admin/AdminCustomerApi";
import { getCustomer, getCustomerId } from "../../reducers/CustomerReducer";
import { api } from "../../api/shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import { COD } from "../../constants/OrderChargeItemTypes";
import { createOrder } from "../../api/customer/CustomerOrderApi";
import PageLoading from "../ui-core/PageLoading";
import { CommissionTINItem } from "./ContentItemsForCustomer";
import { contentItem } from "./CommissionTINDialog";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import { MERCHANTS } from "../../constants/MerchantTypes";

const BARCODE_VALIDATION_URL = `${API_V2_ROOT_URL}/customer/order/barcode/validate`;

const creatPostData = (values, withoutPay) => {
  const totalArray =
    values.contentItems && values.contentItems.length
      ? values.contentItems.map(item => item.total)
      : [];

  const cod =
    totalArray &&
    totalArray.length &&
    totalArray.reduce((acc, current) => acc + current);

  const chargeItems = cod
    ? [
        {
          charge_type: COD,
          charge: cod,
        },
      ]
    : [];
  return {
    content_items:
      values.contentItems &&
      values.contentItems.map(item => ({
        ...item,
        currency: item.currency || UZB_CURRENCY,
      })),
    without_pay: withoutPay,
    charge_items: chargeItems,
    payment_type: values.paymentType,
    transportation_type: values.package.get("transportation_type"),
    package_type: {
      id: values.package.get("id"),
      package_price: {
        id: values.package.getIn(["price", "id"]),
      },
    },
    corporate: values.corporate,
    barcode: values.barcode && _.toUpper(values.barcode),
    sender_data: {
      jurisdiction: values.senderDistrict,
      customer: { name: values.senderName },
      phone: values.senderPhone,
      address: values.senderAddress,
      address_type: values.senderAddressType,
    },
    recipient_data: {
      jurisdiction: _.get(values, "recipientDistrict.id")
        ? values.recipientDistrict
        : _.get(values, "recipientRegion.id")
        ? values.recipientRegion
        : values.recipientCountry,
      customer: { name: values.recipientName },
      phone: values.recipientPhone,
      address: values.recipientAddress,
      address_type: values.recipientAddressType,
      postcode: values.recipientPostcode,
    },
    dimensions: {
      width: values.width,
      length: values.length,
      height: values.height,
      weight: _.divide(values.weight, 1000),
    },
  };
};

const getValues = getFormValues("CustomerPostFormSingle");

const enhancer = compose(
  connect(
    state => ({
      user: getUser(state),
      customerId: getCustomerId(state),
      customer: getCustomer(state),
      userLanguage: getUserLanguage(state),
      mapProvider: getMapProvider(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream.map(({ user, customer }) => ({
      width: 0,
      length: 0,
      height: 0,
      weight: 0,
      senderName: user.get("full_name"),
      senderPhone: user.get("phone"),
      recipientPhone: "+998",
      senderDistrict: customer.get("jurisdiction")
        ? customer.get("jurisdiction").toJS()
        : {},
      senderAddress: customer.getIn(["address", "address"]),
    }));

    const handleSubmit = props => values =>
      props.onSubmit(
        creatPostData({
          ...values,
          corporate: props.customer.get("merchant_type") === MERCHANTS,
        }),
      );
    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "CustomerPostFormSingle",
    validate: (values, { getLocalisationMessage }) => ({
      barcode: values.barcode && _.get(values, "barcodeErrorMessage"),
      senderDistrict:
        !fp.get(["senderDistrict", "id"], values) &&
        getLocalisationMessage("this_field_is_required"),
      recipientDistrict:
        !fp.get(["recipientDistrict", "id"], values) &&
        getLocalisationMessage("this_field_is_required"),
      senderName:
        !values.senderName && getLocalisationMessage("this_field_is_required"),
      senderAddress:
        !values.senderAddress &&
        getLocalisationMessage("this_field_is_required"),
      senderPhone:
        getValue(values.senderDistrict, "code", UZ) === UZ &&
        (!values.recipientPhone || values.recipientPhone.length < 11)
          ? getLocalisationMessage("this_field_is_required")
          : !values.senderPhone || values.senderPhone.length < 8
          ? getLocalisationMessage("this_field_is_required")
          : null,
      recipientName:
        !values.recipientName &&
        getLocalisationMessage("this_field_is_required"),
      recipientAddress:
        !values.recipientAddress &&
        getLocalisationMessage("this_field_is_required"),
      recipientPhone:
        getValue(values.recipientDistrict, "code", UZ) === UZ &&
        (!values.recipientPhone || values.recipientPhone.length < 11)
          ? getLocalisationMessage("this_field_is_required")
          : !values.recipientPhone || values.recipientPhone.length < 8
          ? getLocalisationMessage("this_field_is_required")
          : null,
      package:
        !getValue(values.package, "id") &&
        getLocalisationMessage("this_field_is_required"),
      paymentType:
        !values.paymentType && getLocalisationMessage("this_field_is_required"),
      weight:
        !values.weight && getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(state => ({
    values: getValues(state),
  })),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

const useStyles = makeStyles({
  card: {
    width: "100%",
    height: "100%",
  },
  btn: {
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  firstCardSpansWrapper: {
    alignItems: "center",
    margin: "1rem auto",
  },
  firstCardFirstSpan: {
    minWidth: "2rem",
    height: "2rem",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: ".5rem",
    position: "relative",
    overflow: "visible",
    "&:before": {
      content: '""',
      top: "30px",
      left: "15px",
      width: "0",
      height: "50px",
      bottom: "10px",
      borderLeft: "1px dashed #5d6165",
      position: "absolute",
    },
  },
  firstCardFirstSpanWithoutBefore: {
    minWidth: "2rem",
    height: "2rem",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: ".5rem",
    position: "relative",
    overflow: "visible",
  },

  firstCardSecondSpan: {
    marginRight: ".5rem",
    color: "rgba(38, 50, 56, 0.7)",
    fontWeight: "500",
    fontSize: "1.1rem",
  },
  firstCardLastSpan: {
    fontSize: ".8rem",
    color: "rgba(0, 0, 0, 0.26)",
    marginLeft: "auto",
    cursor: "pointer",
  },
  map: { height: "380px !important", marginTop: ".5rem" },
  packagesWrapper: {
    cursor: "pointer",
  },
  carousel: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  payTypeWrapper: {
    marginTop: "1rem",
    background: "#F6F7F7",
    padding: "1rem",
    borderRadius: ".5rem",
    flexDirection: "column",
  },
  weightWrapper: {
    marginTop: "1rem",
    background: "#F6F7F7",
    padding: "3rem 1rem !important",
    borderRadius: ".5rem",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  img: {
    objectFit: "contain",
  },
  loadingImgCSS: {
    objectFit: "contain",
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1.5s ease 0s infinite normal",
  },
  borderBottom: {
    padding: "1rem 0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  borderBottom2: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  pTB: {
    padding: ".35rem 0",
  },
  "@keyframes rotate": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
});

CustomerPostFormSingle.propTypes = {
  values: PropTypes.object,
  initialValues: PropTypes.object,
  setLocation: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  setIsLoading: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.string,
  resMessage: PropTypes.string,
  orderId: PropTypes.number,
  orderBarcode: PropTypes.number,
  customerId: PropTypes.string,
  userLanguage: PropTypes.string,
  valid: PropTypes.bool,
  customer: PropTypes.instanceOf(Map),
};

function CustomerPostFormSingle({
  orderBarcode,
  change,
  getLocalisationMessage,
  handleSubmit,
  isLoading,
  orderId,
  resMessage,
  setIsLoading,
  userLanguage,
  customer,
  initialValues,
  setLocation,
  customerId,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  valid,
  values = {},
}) {
  const { btn, btnWrapper, card, content, img, loadingImgCSS } = useStyles();
  const {
    shipmentType,
    senderPhone,
    senderDistrict,
    recipientPhone,
    recipientDistrict,
    weight = 0,
    senderLocation,
    contentItems = [],
    barcode,
  } = values;
  const packageToJs = values.package ? toJS(values.package) : {};
  const senderDistrictId = _.get(senderDistrict, "id");
  const recipientDistrictId = _.get(recipientDistrict, "id");
  const senderCountryCode = getValue(senderDistrict, "country_code");
  const recipientCountryCode = getValue(recipientDistrict, "country_code");
  const packagePrice = _.get(packageToJs, "price");
  const packagePriceId = _.get(packageToJs, "price.id");
  const packageId = _.get(packageToJs, "id");

  const [shipmentTypes, setShipmentTypes] = useState(List());
  const [packages, setPackages] = useState(List());
  const [isCod, setIsCod] = useState(false);

  const [paymentTypesIsLoading, setPaymentTypesIsLoading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isLoadingCreatWithoutPay, setIsLoadingCreatWithoutPay] = useState(
    false,
  );

  const [isValidSenderJurisdiction, setIsValidSenderJurisdiction] = useState(
    {},
  );
  const [
    isValidRecipientJurisdiction,
    setIsValidRecipientJurisdiction,
  ] = useState({});

  const [notPaidPosts, setNotPaidPosts] = useState([]);

  const saveNotPaidPost = () => {
    setIsLoadingCreatWithoutPay(true);
    createOrder(creatPostData(values, true))
      .then(res => {
        if (res.status === "success") {
          setNotPaidPosts(prev => [...prev, fp.get("data.id", res)]);
          showSuccessMessage1(
            getLocalisationMessage(
              "successfully_created",
              "Successfully Created",
            ),
          );
          change("contentItems", []);
          [
            "recipientName",
            "recipientLocation",
            "barcode",
            "recipientPostcode",
            "recipientDistrict",
            "recipientRegion",
            "recipientCountry",
            "recipientPhone",
          ].forEach(objectKey => {
            const fieldValue = initialValues[objectKey];
            change(objectKey, fieldValue !== undefined ? fieldValue : null);
          });
        } else showErrorMessage1(res.message);
      })
      .catch(error => showErrorMessage1(error))
      .finally(() => setIsLoadingCreatWithoutPay(false));
  };

  useEffect(() => {
    if (barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        const tempBarcode =
          convertedValue !== barcode ? convertedValue : barcode;
        api
          .get(BARCODE_VALIDATION_URL, {
            params: { barcode: tempBarcode },
          })
          .then(res => {
            if (res.status !== SUCCESS_MESSAGE)
              change("barcodeErrorMessage", res.message);
            else change("barcodeErrorMessage", false);
          })

          .finally(
            () =>
              convertedValue !== barcode && change("barcode", convertedValue),
          );
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [barcode]);

  useEffect(() => {
    if (recipientCountryCode !== UZ) {
      change("contentItems", []);
      setIsCod(false);
    }
  }, [recipientDistrict]);

  useEffect(() => {
    if (senderDistrictId && recipientDistrictId) {
      const delayDebounceFn = setTimeout(() => {
        getCustomerServiceTypes({
          "dimensions.weight": _.divide(weight, 1000),
          fromJurisdictionId: senderDistrictId,
          toJurisdictionId: recipientDistrictId,
        })
          .then(res => {
            const tempShipmentTypes = _.get(res, "data");
            if (tempShipmentTypes.length > 0) {
              setShipmentTypes(fromJS(tempShipmentTypes));
            }
          })
          .catch(error => showErrorMessage(error));
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [senderDistrictId, recipientDistrictId]);

  useEffect(() => {
    if (shipmentType && !shipmentType.isEmpty()) {
      change("maxWeight", shipmentType.getIn(["sample_item", "weight_max"]));
    }
  }, [shipmentType]);

  useEffect(() => {
    if (senderDistrictId) {
      change("senderLocation", {
        lat: senderDistrict.lat,
        lng: senderDistrict.lng,
      });
    }
  }, [senderDistrict]);

  useEffect(() => {
    if (recipientDistrictId)
      change("recipientLocation", {
        lat: recipientDistrict.lat,
        lng: recipientDistrict.lng,
      });
  }, [recipientDistrict]);

  useEffect(() => {
    if (packageId) {
      // eslint-disable-next-line camelcase
      const price_id = _.get(packagePrice, "id");
      setPaymentTypesIsLoading(true);
      getCustomerPaymentMethods2({ customerId, price_id })
        .then(res => setPaymentTypes(_.get(res, "data")))
        .catch(error => showErrorMessage1(error))
        .finally(() => setPaymentTypesIsLoading(false));
    }
  }, [packagePriceId]);

  useEffect(() => {
    change("package", null);

    if (shipmentType && senderDistrictId && recipientDistrictId) {
      const delayDebounceFn = setTimeout(() => {
        getCustomerPackagePrices(
          shipmentType.get("id"),
          _.divide(weight || 1, 1000),
          senderDistrictId,
          recipientDistrictId,
        )
          .then(res => {
            const packagePrices = fromJS(_.get(res, "data.list"));
            setPackages(packagePrices);
            if (packagePrices && packagePrices.length === 1) {
              change("package", packagePrices[0]);
            } else {
              change("package", null);
            }
          })
          .catch(error => {
            showErrorMessage(error);
          });
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [shipmentType, weight, senderDistrictId, recipientDistrictId]);

  const [errorShow, setErrorShow] = useState(false);

  return (
    <FlexBox
      element={<form onSubmit={handleSubmit} />}
      direction="column"
      style={{ margin: "0 0.3rem", gap: 16, height: "100%" }}
    >
      <PageLoading isLoading={isLoadingCreatWithoutPay} />
      <Dialog
        TransitionComponent={TransitionUp}
        open={isLoading !== NOT_STARTED}
        fullWidth={true}
        className={content}
        maxWidth="sm"
      >
        <DialogContent style={{ padding: "3rem 2rem 1.5rem 2rem" }}>
          {isLoading === SUCCESS && (
            <FlexBox flex={true} align={ALIGN_CENTER} direction="column">
              <img src={successImg} alt="success" className={img} />
              <h3 style={{ marginTop: "1rem", fontWeight: "bold" }}>
                {resMessage}!
              </h3>
              <FlexBox align={ALIGN_CENTER} style={{ margin: "1rem" }}>
                <TextWithLink to={createOrderHref(orderId)}>
                  <strong>{orderBarcode}</strong>
                </TextWithLink>
                <CustomButton
                  style={{ marginLeft: "1rem" }}
                  size="small"
                  startIcon={<FileCopy />}
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    navigator.clipboard.writeText(orderBarcode);
                    showSuccessMessage1(
                      getLocalisationMessage(
                        "successfully_copied",
                        "Successfully copied",
                      ),
                    );
                  }}
                >
                  {getLocalisationMessage("copy", "Copy")}
                </CustomButton>
              </FlexBox>
              <h6>
                {getLocalisationMessage(
                  "this_is_your_order_number_to_send_an_order_please_come_to_the_nearest_postal_code",
                  "This is your tracking number. You can bring your shipment to the nearest post office at your current address.",
                )}
              </h6>
            </FlexBox>
          )}
          {isLoading === LOADING && (
            <FlexBox flex={true} align={ALIGN_CENTER} direction="column">
              <img src={loadingImg} alt="success" className={loadingImgCSS} />
              <h3 style={{ marginTop: "1rem", fontWeight: "bold" }}>
                {resMessage}
              </h3>
              <FlexBox align={ALIGN_CENTER} style={{ margin: "1rem" }}>
                <strong>
                  {getLocalisationMessage("sending", "Sending...")}
                </strong>
              </FlexBox>
            </FlexBox>
          )}
          {isLoading === ERROR && (
            <FlexBox flex={true} align={ALIGN_CENTER} direction="column">
              <img src={errorImg} alt="error" className={img} />
              <h3 style={{ marginTop: "1rem", fontWeight: "bold" }}>
                {resMessage}!
              </h3>
            </FlexBox>
          )}
        </DialogContent>
        <DialogActions style={{ padding: "0 2rem 3rem 2rem" }}>
          {isLoading === SUCCESS && (
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true}>
                <CustomButton
                  fullWidth={true}
                  className={btn}
                  onClick={() => setLocation(ORDER_LIST_URL)}
                  startIcon={<FormatListBulleted />}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("go_to_list", "Go To List")}
                </CustomButton>
                <CustomButton
                  fullWidth={true}
                  className={btn}
                  onClick={() =>
                    setLocation(
                      `${ORDERS_PER_POSTCODES_URL_2 + senderLocation.lat}/${
                        senderLocation.lng
                      }`,
                    )
                  }
                  endIcon={<LocationOn />}
                  variant={CONTAINED}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("go_to_postcodes", "Go to postcodes")}
                </CustomButton>
              </FlexBox>
            </FlexBox>
          )}
          {isLoading === ERROR && (
            <FlexBox flex={true}>
              <CustomButton
                className={btn}
                fullWidth={true}
                onClick={() => setIsLoading(NOT_STARTED)}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>
            </FlexBox>
          )}
        </DialogActions>
      </Dialog>

      <FlexBox
        justify={JUSTIFY_SPACE_AROUND}
        align={ALIGN_END}
        style={{ margin: "1rem 0" }}
      >
        <FlexBox direction="column">
          <h4>
            {getLocalisationMessage("create_new_order", "Create New Order")}
          </h4>
        </FlexBox>
        <FlexBox className={btnWrapper}>
          <CustomButton
            className={btn}
            onClick={() => setLocation(ORDER_LIST_URL)}
            startIcon={<KeyboardArrowLeft />}
            variant={OUTLINED}
            color={SECONDARY}
          >
            {getLocalisationMessage("back", "Back")}
          </CustomButton>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} justify={JUSTIFY_SPACE_AROUND} gutter={16}>
        <FlexBox style={{ width: "33.3%" }}>
          <Card key={1} className={card}>
            <CardHeader
              title={
                <h6>
                  {getLocalisationMessage(
                    "sender_information",
                    "Sender information",
                  )}
                </h6>
              }
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            />
            <CardContent>
              <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    name="senderName"
                    label={`${getLocalisationMessage("name", "Name")} *`}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormJMAutoComplete
                    withTargetFilter={true}
                    onChange={value => setIsValidSenderJurisdiction(value)}
                    immediatelyShowError={
                      isValidSenderJurisdiction &&
                      !isValidSenderJurisdiction.id &&
                      isValidSenderJurisdiction.name
                    }
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{option.name}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {option.value.hierarchy.map((item, index, arr) =>
                            index === arr.length - 1
                              ? item.name
                              : `${item.name} > `,
                          )}
                        </span>
                      </FlexBox>
                    )}
                    fullWidth={true}
                    name="senderDistrict"
                    label={`${getLocalisationMessage(
                      "sender_jurisdiction",
                      "Sender Jurisdiction",
                    )} *`}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <MuiPhoneNumber
                    disableAreaCodes={true}
                    isValid={value =>
                      senderCountryCode === UZ
                        ? value.length > 11
                        : value.length > 8
                    }
                    defaultCountry={
                      senderCountryCode ? senderCountryCode.toLowerCase() : "uz"
                    }
                    name="senderPhone"
                    data-cy="senderPhone"
                    value={senderPhone}
                    onChange={v => {
                      change("senderPhone", v);
                    }}
                    variant={OUTLINED}
                    size="small"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )} *`}
                  />
                  <FormTextField
                    style={{ display: "none" }}
                    name="senderPhone"
                    data-cy="senderPhone"
                    label={`${getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )} *`}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="senderAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={`${getLocalisationMessage("address", "Address")} *`}
                    placeholder="Улица, дом, квартира"
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox style={{ width: "33.3%" }}>
          <Card key={2} className={card}>
            <CardHeader
              title={
                <h6>
                  {getLocalisationMessage(
                    "recipient_information",
                    "Recipient information",
                  )}
                </h6>
              }
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            />
            <CardContent>
              <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    name="recipientName"
                    label={`${getLocalisationMessage("name", "Name")} *`}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormJMAutoComplete
                    withTargetFilter={true}
                    onChange={value => setIsValidRecipientJurisdiction(value)}
                    immediatelyShowError={
                      isValidRecipientJurisdiction &&
                      !isValidRecipientJurisdiction.id &&
                      isValidRecipientJurisdiction.name
                    }
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{option.name}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {option.value.hierarchy.map((item, index, arr) =>
                            index === arr.length - 1
                              ? item.name
                              : `${item.name} > `,
                          )}
                        </span>
                      </FlexBox>
                    )}
                    fullWidth={true}
                    name="recipientDistrict"
                    label={`${getLocalisationMessage(
                      "recipient_jurisdiction",
                      "Recipient Jurisdiction",
                    )} *`}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <MuiPhoneNumber
                    value={recipientPhone}
                    disableAreaCodes={true}
                    isValid={value =>
                      recipientCountryCode === UZ
                        ? value.length > 11
                        : value.length > 8
                    }
                    defaultCountry={
                      recipientCountryCode
                        ? recipientCountryCode.toLowerCase()
                        : "uz"
                    }
                    name="recipientPhone"
                    data-cy="recipientPhone"
                    onChange={v => {
                      change("recipientPhone", v);
                    }}
                    variant={OUTLINED}
                    size="small"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )} *`}
                  />
                  <FormTextField
                    style={{ display: "none" }}
                    name="recipientPhone"
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                  />
                </FlexBox>
                {recipientCountryCode && recipientCountryCode !== UZ && (
                  <FlexBox direction="column" flex={true}>
                    <PostcodeAutoCompleteWithJM
                      label={getLocalisationMessage("index", "Index")}
                      name="recipientPostcode"
                      fullWidth={true}
                      jurisdictionId={recipientDistrictId}
                      disabled={!recipientDistrict}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type To Search...",
                      )}
                    />
                  </FlexBox>
                )}
                <FlexBox flex={true}>
                  <FormTextField
                    name="recipientAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={`${getLocalisationMessage("address", "Address")} *`}
                    placeholder="Улица, дом, квартира"
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox style={{ width: "33.3%" }} direction="column">
          <Card key={3} className={card}>
            <CardHeader
              title={<h6>{getLocalisationMessage("order_details")}</h6>}
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            />
            <CardContent>
              <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="weight"
                    data-cy="weight"
                    type="number"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "weight_g",
                      "Weight (g)",
                    )} *`}
                    onFocus={event => {
                      event.target.select();
                    }}
                    parse={value => {
                      if (value <= 0) {
                        return 0;
                      }

                      return String(Math.round(parseFloat(String(value))));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getLocalisationMessage("g", "g")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>

                <FlexBox flex={true}>
                  <FormSelectField
                    name="shipmentType"
                    fullWidth={true}
                    options={shipmentTypes}
                    formatOption={x => x.get("name")}
                    label={`${getLocalisationMessage("shipment_type")} *`}
                    disabled={!shipmentTypes.size}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="package"
                    fullWidth={true}
                    options={packages}
                    label={`${getLocalisationMessage("package")} *`}
                    disabled={!packages.size}
                    formatOption={x => (
                      <FlexBox direction="column">
                        <span>{`${getLocalisationMessage(
                          x.get("transportation_type"),
                        )} ${money(x.getIn(["price", "total"])) +
                          getLocalisationMessage("som")}`}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {userLanguage === UZBEK_LANGUAGE
                            ? x.get("description")
                            : userLanguage === RUSSIAN_LANGUAGE
                            ? x.get("description_ru")
                            : x.get("description_en")}
                        </span>
                      </FlexBox>
                    )}
                  />
                </FlexBox>

                <FlexBox direction="column" flex={true}>
                  <FormTextField
                    name="barcode"
                    label={getLocalisationMessage("barcode", "Barcode")}
                    fullWidth={true}
                    withSuccessIcon={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                            <path
                              fill="currentColor"
                              d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!barcode && (
                    <span style={{ color: "#f57c00" }}>
                      {getLocalisationMessage(
                        "if_you_do_not_enter_a_barcode_it_will_be_generated_automatically",
                        "if you do not enter a barcode, it will be generated automatically",
                      )}
                    </span>
                  )}
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox direction="column" flex={true}>
          <Card>
            <CardHeader
              title={<h6>{getLocalisationMessage("payment")}</h6>}
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            />

            <CardContent>
              <FlexBox style={{ gap: 13 }}>
                <FlexBox style={{ width: 220 }}>
                  <FormSelectField
                    name="paymentType"
                    fullWidth={true}
                    options={paymentTypes}
                    loading={paymentTypesIsLoading}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    label={`${getLocalisationMessage("payment_type")} *`}
                    disabled={!paymentTypes.length}
                  />
                </FlexBox>

                {recipientCountryCode === UZ &&
                  customer.get("can_create_cod_order") && (
                    <FlexBox flex={true}>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCod}
                              onChange={event => {
                                setIsCod(event.target.checked);
                                if (event.target.checked) {
                                  change("contentItems", [contentItem]);
                                } else {
                                  change("contentItems", []);
                                }
                              }}
                            />
                          }
                          label={getLocalisationMessage("is_cod", "Is COD")}
                        />
                      </FormControl>
                    </FlexBox>
                  )}
              </FlexBox>
            </CardContent>

            {isCod && (
              <FlexBox direction="column">
                <CardHeader
                  title={<h6>{getLocalisationMessage("cod", "COD")}</h6>}
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                />
                <CardContent>
                  <FieldArray
                    name="contentItems"
                    component={CommissionTINItem}
                    getLocalisationMessage={getLocalisationMessage}
                    change={change}
                    showErrorMessage={showErrorMessage1}
                    contentItems={contentItems}
                    errorShow={errorShow}
                    setErrorShow={setErrorShow}
                  />
                </CardContent>
              </FlexBox>
            )}
            <CardContent>
              <FlexBox justify="space-between">
                {customer.get("merchant_type") === MERCHANTS ? (
                  <CustomButton
                    color={SECONDARY}
                    variant={OUTLINED}
                    type={valid ? "button" : "submit"}
                    onClick={() => valid && saveNotPaidPost()}
                  >
                    {getLocalisationMessage("create_bulk_orders")}
                  </CustomButton>
                ) : (
                  <div />
                )}
                {notPaidPosts && notPaidPosts.length > 0 ? (
                  <CustomButton
                    color={SECONDARY}
                    variant={CONTAINED}
                    onClick={() => {
                      setLocation(ORDER_LIST_URL);
                    }}
                  >
                    {getLocalisationMessage("complete")} ({notPaidPosts.length})
                  </CustomButton>
                ) : (
                  <CustomButton
                    color={SECONDARY}
                    variant={CONTAINED}
                    onClick={() => {
                      handleSubmit();
                    }}
                    endIcon={
                      isLoading === LOADING ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <KeyboardArrowRight />
                      )
                    }
                  >
                    {getLocalisationMessage("create_order")}
                  </CustomButton>
                )}
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerPostFormSingle);
