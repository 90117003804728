import React, { useState } from "react";
import _ from "lodash";
import Immutable, { fromJS, List, Map, OrderedMap } from "immutable";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isEqualData } from "../../helpers/DataUtils";
import { customerLayoutIcon } from "../../helpers/MarketplaceHelper";
import {
  getMarketplace,
  getMarketplaceCustomerMainMenu,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import customerMenuList from "../../constants/CustomerMarketplaceMenuConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import CustomerAppSidebar from "../app-sidebar/CustomerAppSidebar";
import AppLayoutForCustomer from "../app-layout/AppLayoutForCustomer";
import AppSidebarBlock from "../app-sidebar/AppSidebarBlock";
import CustomerAppSidebarLink from "../app-sidebar/CustomerAppSidebarLink";
import AppSidebarProfileBlock from "../app-sidebar/AppSidebarProfileBlock";
import CustomerAppSidebarProfileBlock from "../app-sidebar/CustomerAppSidebarProfileBlock";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplace: getMarketplace(state),
    customerMainMenuList: getMarketplaceCustomerMainMenu(state),
  })),
  withRouter,
  withState("state", "setState", {
    isOpen: true,
    menuName: "",
    loaded: true,
  }),
  mapPropsStream(propsStream => {
    const settingsStream = propsStream
      .take(1)
      .filter(props => props.marketplace)
      .distinctUntilChanged(isEqualData)
      .map(props => {
        const menuList = props.marketplace.getIn(
          ["setting", "settings", "leftMenuCustomer"],
          Map(),
        );
        const menu = List().asMutable();
        let currentMenu = null;

        customerMenuList.forEach(item => {
          const enabledItem = menuList.get(item.slug, null);
          if (enabledItem && enabledItem.get("active", false)) {
            const menuItem = Map({
              ...item,
              name: enabledItem.get("name", null),
            });

            if (props.slug && props.slug === item.slug) {
              currentMenu = menuItem;
            }

            menu.push(menuItem);
          }
        });

        return Map({
          currentMenu,
          settings: fromJS(_.groupBy(menu.toJS(), "group")).sortBy(item =>
            item.get(0).get("ind"),
          ),
        });
      })
      .startWith(OrderedMap())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(settingsStream, (props, settings) => ({
        ...props,
        settings: settings.get("settings", null),
        currentMenu: settings.get("currentMenu", null),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

export const sidebar = (data, sidebarStatus, router) => {
  const { settings, getLocalisationMessage } = data;

  if (settings && settings.size > 0) {
    return (
      <CustomerAppSidebar>
        {settings
          .map(group =>
            group
              .map((item, index) => (
                <CustomerAppSidebarLink
                  router={router}
                  sidebarStatus={sidebarStatus}
                  key={index}
                  leftIcon={customerLayoutIcon(item.get("icon"))}
                  slug={item.get("slug")}
                  to={
                    item.has("params")
                      ? updateQuery(item.get("url"), item.get("params").toJS())
                      : item.get("url")
                  }
                >
                  {item.get("name") ||
                    getLocalisationMessage(
                      item.get("slug"),
                      _.startCase(item.get("slug")),
                    )}
                </CustomerAppSidebarLink>
              ))
              .toArray(),
          )
          .toArray()}
        <CustomerAppSidebarProfileBlock sidebarStatus={sidebarStatus} />
      </CustomerAppSidebar>
    );
  }

  return (
    <CustomerAppSidebar>
      <CustomerAppSidebarProfileBlock sidebarStatus={sidebarStatus} />
    </CustomerAppSidebar>
  );
};

CustomerAppLayout.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  appBarRightAction: PropTypes.node,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  settings: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  currentMenu: PropTypes.instanceOf(Map),
  state: PropTypes.object,
  setState: PropTypes.func,
  router: PropTypes.object,
};

function CustomerAppLayout(props) {
  const { getLocalisationMessage, title } = props;
  let pageTitle = title;

  const [sidebarStatus, setSidebarStatus] = useState(true);

  if (props.currentMenu)
    pageTitle =
      props.currentMenu.get("name") ||
      getLocalisationMessage(
        props.currentMenu.get("slug"),
        _.startCase(props.currentMenu.get("slug")),
      ) ||
      title;

  if (!props.marketplace)
    return (
      <AppLayoutForCustomer
        {...props}
        title={title}
        sidebarStatus={sidebarStatus}
        onSetSidebarStatus={setSidebarStatus}
        sidebar={
          <CustomerAppSidebar sidebarStatus={sidebarStatus}>
            <AppSidebarBlock
              title={getLocalisationMessage("loading", "Loading...")}
            >
              {getLocalisationMessage("loading", "Loading...")}
            </AppSidebarBlock>
            <AppSidebarProfileBlock />
          </CustomerAppSidebar>
        }
      />
    );

  return (
    <AppLayoutForCustomer
      sidebarStatus={sidebarStatus}
      onSetSidebarStatus={setSidebarStatus}
      {...props}
      title={pageTitle}
      sidebar={sidebar(props, sidebarStatus, props.router)}
    />
  );
}

export default enhancer(CustomerAppLayout);
