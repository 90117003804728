import canUseDom from "../../shared/helpers/canUseDom";

export function getWindowScrollTop() {
  return canUseDom
    ? window.pageYOffset || document.documentElement.scrollTop
    : 0;
}

export function getWindowScrollLeft() {
  return canUseDom
    ? window.pageXOffset || document.documentElement.scrollLeft
    : 0;
}
