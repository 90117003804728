import { Observable } from "rxjs";
import React from "react";
import Immutable, { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import {
  isNewVersion,
  formatCustomerCODStatusCodeLocalised,
} from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_WITH_WING_BANK,
  COD_TO_BE_COLLECTED,
  COD_PAID_TO_MERCHANT,
} from "../../constants/CODStatus";
import {
  ORDER_CREATE_URL,
  ORDER_CREATE_NEW_URL,
} from "../../constants/CustomerPathConstants";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getOrderList } from "../../api/customer/CustomerOrderApi";
import { getCODTotalCount } from "../../api/customer/CustomerFinanceApi";
import Redirect from "../../components/router/Redirect";
import CustomerOrderFilterWrapper from "../../wrappers/customer/CustomerOrderFilterWrapper";
import CustomerOrderDetailsDialogWrapper from "../../wrappers/customer/CustomerOrderDetailsDialogWrapper";
import CustomerOrderDetailsDialogWrapperV2 from "../../wrappers/customer/CustomerOrderDetailsDialogWrapperV2";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CustomerFinanceOrderListTabs from "../../components/customer/CustomerFinanceOrderListTabs";
import OrderList from "../../components/orders-core/OrderList";
import PendingBalanceDialog from "../../components/finance-core/PendingBalanceDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_ORDER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const PENDING_BALANCE_DIALOG_HASH = "#PBD";

const ALL_TAB_STATUS_LIST = [
  COD_TO_BE_COLLECTED,
  COD_COLLECTED,
  COD_PAID_TO_MERCHANT,
  COD_PENDING,
].join(",");

const WITHDRAWN_STATUS_LIST = [COD_PAID_TO_MERCHANT, COD_PENDING].join(",");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toOrderFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }));
      },
      propsStream => {
        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            getOrderList(props.filter).catch(() => Observable.of({})),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  count: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const totalCountsStream = propsStream
          .map(
            fp.flow(fp.get("filter"), (filter: DataListFilter) =>
              filter.setValue("cod_status", null),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODTotalCount(filter).catch(error => Observable.of({ error })),
          )
          .map(fp.flow(fp.get("payload.data"), fp.keyBy("cod_status"), fromJS));

        const marketplaceStream = getMarketplace()
          .takeLast(1)
          .catch(() => Observable.of({}))
          .map(
            fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS),
          );

        return propsStream
          .combineLatest(
            totalCountsStream,
            listResponseStream,
            marketplaceStream,
            (props, totalCounts, listResponse, marketplace) => ({
              ...props,
              totalCounts,
              marketplace,
              list: listResponse.get("list"),
              count: listResponse.get("count"),
              isLoading: listResponse.get("isLoading"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

CustomerFinanceOrderListContainer.propTypes = {
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  columns: PropTypes.array,
  totalCounts: PropTypes.instanceOf(Map),
  list: PropTypes.instanceOf(List),
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerFinanceOrderListContainer(props) {
  const { location, marketplace, getLocalisationMessage } = props;

  const newOrderVersion = isNewVersion(marketplace);

  const statusCodes = OrderedMap()
    .set(ALL_TAB_STATUS_LIST, getLocalisationMessage("all", "All"))
    .set(
      COD_TO_BE_COLLECTED,
      formatCustomerCODStatusCodeLocalised(
        COD_TO_BE_COLLECTED,
        getLocalisationMessage,
      ),
    )
    .set(
      COD_COLLECTED,
      formatCustomerCODStatusCodeLocalised(
        COD_COLLECTED,
        getLocalisationMessage,
      ),
    )
    .set(
      COD_WITH_WING_BANK,
      formatCustomerCODStatusCodeLocalised(
        COD_WITH_WING_BANK,
        getLocalisationMessage,
      ),
    )
    .set(
      WITHDRAWN_STATUS_LIST,
      getLocalisationMessage("withdrawn", "Withdrawn"),
    );

  return (
    <CustomerAppLayout
      slug="orders"
      title={getLocalisationMessage("orders", "Orders")}
    >
      <Redirect
        when={!statusCodes.has(location.query.cod_status)}
        to={updateQuery(location, query => ({
          ...query,
          cod_status: statusCodes.keySeq().first(),
        }))}
      />
      <PendingBalanceDialog
        open={location.hash === PENDING_BALANCE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />
      <CustomerOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_v2", "view_tab"]))
        }
        location={location}
      />
      <CustomerOrderDetailsDialogWrapper
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        } /**/
        location={location}
      />
      <CustomerOrderFilterWrapper
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
      />
      <CustomerFinanceOrderListTabs
        location={location}
        totalCounts={props.totalCounts}
      />
      <OrderList
        cardActionIcons={
          <div>
            {!newOrderVersion && (
              <Link to={ORDER_CREATE_URL}>
                <Tooltip
                  title={getLocalisationMessage("create_order", "Create Order")}
                >
                  <IconButton>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Link>
            )}

            {newOrderVersion && (
              <Link to={ORDER_CREATE_NEW_URL}>
                <Tooltip
                  title={getLocalisationMessage("create_order", "Create Order")}
                >
                  <IconButton>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Link>
            )}

            <Link to={updateHash(props.location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={getLocalisationMessage("filter_orders", "Filter Orders")}
              >
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_ORDER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(PENDING_BALANCE_DIALOG_HASH)
                }
              >
                {getLocalisationMessage("pending_balance", "Pending Balance")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
        withCustomer={false}
        withAttempts={false}
        withPickupTime={false}
        withLastDriver={false}
        withCreatedTime={false}
        withEstDeliveryTime={false}
        createOrderHref={(id, version) =>
          updateQuery(location, fp.set(version === 2 ? "view_v2" : "view", id))
        }
        columns={props.columns}
        list={props.list}
        totalCount={props.count}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerFinanceOrderListContainer);
