import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormTextField from "../form/FormTextField";
import PublicFormCityV2AutoComplete from "../form/PublicFormCityV2AutoComplete";

const enhancer = compose(
  useSheet({
    root: {
      width: "100%",
      paddingRight: 5,
    },
  }),
);

PublicOrderCityField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.object,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  location: PropTypes.instanceOf(Map).isRequired,
};

function PublicOrderCityField(props) {
  const { classes, location } = props;
  const autocomplete = Boolean(
    location.getIn(["country", "id"]) && location.getIn(["city", "id"]),
  );

  return (
    <div className={classes.root}>
      {autocomplete ? (
        <PublicFormCityV2AutoComplete
          name={props.name}
          countryId={location.getIn(["country", "id"])}
          fullWidth={true}
          readOnly={props.readOnly}
          onClick={() => props.onClick(props.readOnly)}
          hintText={props.label}
          label={props.label}
        />
      ) : (
        <FormTextField fullWidth={true} name={props.name} label={props.label} />
      )}
    </div>
  );
}

export default enhancer(PublicOrderCityField);
