import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import spring from "react-motion/lib/spring";
import presets from "react-motion/lib/presets";
import TransitionMotion from "react-motion/lib/TransitionMotion";

const enhancer = compose(
  useSheet(
    {
      root: { margin: "12px 4px 0", position: "relative" },
      item: {
        top: 0,
        left: 0,
        right: 0,
        transformOrigin: "50% 50%",
        transform: "translate3d(0, 0, 0)",
      },
    },
    { important: false },
  ),
);

OrderCreateWizardCard.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.string,
  children: PropTypes.node,
};

const willEnter = () => ({ opacity: 1, showContent: 0, scale: 0 });
const willLeave = () => ({
  opacity: spring(0, { damping: 40, stiffness: 300 }),
  showContent: 0,
  scale: 1,
});

const createStyle = style => ({
  opacity: style.opacity,
  position: style.opacity < 1 ? "absolute" : "static",
  transform: `scale3d(${style.scale}, ${style.scale}, 1) translate3d(0, 0, 0)`,
});

function OrderCreateWizardCard(props) {
  const child = React.Children.toArray(props.children)
    .filter(React.isValidElement)
    .pop();

  return (
    <TransitionMotion
      willEnter={willEnter}
      willLeave={willLeave}
      defaultStyles={
        child
          ? [
              {
                key: props.page,
                data: child,
                style: { showContent: 0, opacity: 1, scale: 0 },
              },
            ]
          : []
      }
      styles={
        child
          ? [
              {
                data: child,
                key: props.page,
                style: {
                  showContent: spring(1),
                  opacity: 1,
                  scale: spring(1, presets.wobbly),
                },
              },
            ]
          : []
      }
    >
      {interpolatedStyles => (
        <div className={props.classes.root}>
          {interpolatedStyles.map(config => (
            <div
              key={config.key}
              className={props.classes.item}
              style={createStyle(config.style)}
            >
              {React.cloneElement(config.data, {
                showContent: config.style.showContent >= 1,
              })}
            </div>
          ))}
        </div>
      )}
    </TransitionMotion>
  );
}

export default enhancer(OrderCreateWizardCard);
