import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Stars as ToggleStar } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import RoundButton from "../ui-core/RoundButton";

const enhancer = compose(
  useSheet({
    root: {
      padding: "12px 0",
      display: "flex",
      textAlign: "center",
      borderBottom: "1px solid #ddd",
      justifyContent: "space-between",
      "& .initial-1 > span svg": { strokeColor: "#d32f2f", fill: "#d32f2f" },
      "& .initial-2 > span svg": { strokeColor: "#ff9800", fill: "#ff9800" },
      "& .initial-3 > span svg": { strokeColor: "#c0ca33", fill: "#c0ca33" },
      "& .initial-4 > span svg": { strokeColor: "#4caf50", fill: "#4caf50" },
      "& .initial-5 > span svg": { strokeColor: "#00bcd4", fill: "#00bcd4" },
    },

    button: {
      zIndex: 1,
      position: "relative",
      transition: "ease-in-out .2s all",

      "& > div": {
        border: "2px solid",
        transition: "ease-in-out .2s all",
      },

      "&:last-child:before": { border: "none" },

      "&:before": {
        cursor: "default",

        zIndex: -1,
        content: '""',
        top: "50%",
        left: "73%",
        right: "-18px",
        marginTop: "-1px",
        position: "absolute",
        borderTopWidth: "2px",
        borderTopStyle: "solid",
      },
    },

    initial: {
      padding: "8px",
      display: "none",
    },

    disabled: {
      padding: "8px",

      "& svg": { fill: "#9e9e9e" },
      "&:before": { borderTopColor: "#9e9e9e" },
      "& > div": { boxShadow: "none", borderColor: "#9e9e9e" },
    },

    active: {
      padding: 0,
      "& > svg": { fill: "#fff" },
      "&:before": { borderTopColor: "#717171" },
    },

    "emotion-1": {
      "& > span svg": { strokeColor: "#d32f2f", fill: "#d32f2f" },
    },
    "emotion-2": {
      "& > span svg": { strokeColor: "#ff9800", fill: "#ff9800" },
    },
    "emotion-3": {
      "& > span svg": { strokeColor: "#c0ca33", fill: "#c0ca33" },
    },
    "emotion-4": {
      "& > span svg": { strokeColor: "#4caf50", fill: "#4caf50" },
    },
    "emotion-5": {
      "& > span svg": { strokeColor: "#00bcd4", fill: "#00bcd4" },
    },
    ".initial-1": { display: "none" },
  }),
);

RatingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

function RatingButton(props) {
  const { classes } = props;

  return (
    <RoundButton
      onClick={props.onClick}
      className={cx(
        classes.button,
        props.active
          ? [classes.active, classes[`emotion-${props.value}`]]
          : props.disabled
          ? classes.disabled
          : `initial-${props.activeIndex}`,
      )}
    >
      <ToggleStar />
    </RoundButton>
  );
}

RatingBar.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

function RatingBar(props) {
  return (
    <FlexBox className={props.classes.root} align="center">
      {_.times(5, i => (
        <RatingButton
          key={i}
          value={i + 1}
          classes={props.classes}
          active={props.input.value === i + 1}
          activeIndex={props.input.value}
          disabled={props.input.value <= i}
          onClick={() => props.input.onChange(i + 1)}
        />
      ))}
    </FlexBox>
  );
}

FormRatingBar.propTypes = { name: PropTypes.string.isRequired };

function FormRatingBar(props) {
  return <Field {...props} component={RatingBar} />;
}

export default enhancer(FormRatingBar);
