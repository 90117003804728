import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import PriceWrapper from "../../ui-core/PriceWrapper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import collect from "./assets/collect.png";

const valueSelector = formValueSelector("OrderCreateWizardDefault");

const enhancer = compose(
  connect(state => ({
    codCharge: valueSelector(state, "codCharge"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepCollectDetails.propTypes = {
  currencyCode: PropTypes.string,
  codCharge: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepCollectDetails(props) {
  return (
    <OrderCreateWizardStepDetails
      label={props.getLocalisationMessage("collect", "Collect")}
      onClick={props.onClick}
      avatar={
        <Avatar
          src={collect}
          style={{
            backgroundColor: "transparent",
          }}
        />
      }
      primaryText={
        <div>
          {props.getLocalisationMessage("collect", "Collect")} -{" "}
          <PriceWrapper price={props.codCharge} code={props.currencyCode} />
        </div>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepCollectDetails);
