import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({ form: "CancelOrderDialog" }),
);

CancelOrderDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function CancelOrderDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <DialogContent>
        <PageLoading isLoading={props.submitting} />
        <DialogContentText>
          {props.getLocalisationMessage(
            "are_you_sure_you_want_to_cancel_this_order",
            "Are you sure you want to cancel this order?",
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>

          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={props.handleSubmit}
          >
            {props.getLocalisationMessage("submit", "Submit")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CancelOrderDialog);
