import { Observable } from "rxjs";
import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ORDER_LIST_URL = `${API_ROOT_URL}/customer/orders`;

const ORDER_URL = `${API_ROOT_URL}/customer/order`;
const ORDER_ITEM_URL = `${API_ROOT_URL}/customer/order/%s`;
const ORDER_CHARGE_URL = `${API_ROOT_URL}/admin/order/%s/charge_item`;
const ORDER_NOTES_URL = `${API_ROOT_URL}/customer/order/%s/notes`;
const ORDER_HISTORY_URL = `${API_ROOT_URL}/customer/order/%s/history_items`;

const ORDER_ITEM_NOTE_URL = `${API_ROOT_URL}/customer/order/%s/note`;
const ORDER_ITEM_STATUS_URL = `${API_ROOT_URL}/customer/order/%s/status`;
// const ORDER_ITEM_STATUS_UPDATE_URL = `${API_ROOT_URL}/customer/order/%s/status_update`;
const ORDER_ITEM_PAYMENT_DETAILS_URL = `${API_ROOT_URL}/customer/order/%s/payment_details`;

const ORDER_GET_FEEDBACK_URL = `${API_ROOT_URL}/customer/order/%s/customer_feedback`;
const ORDER_SAVE_FEEDBACK_URL = `${API_ROOT_URL}/customer/order/%s/save_customer_feedback`;

const ORDER_INTERNATIONAL_SEND_INVOICE_TO_EMAIL_URL = `${API_ROOT_URL}/customer/order/international/%s/invoice`;

const DYNAMIC_FIELDS = `${API_ROOT_URL}/customer/order/csv/dynamic_fields`;
const ORDER_CANCEL_URL = `${API_ROOT_URL}/customer/order/cancel`;

export const getOrderList = (request: DataListFilter) =>
  api.getStream(ORDER_LIST_URL, {
    params: request.getDefinedValues(),
    timeout: 999999,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getOrderListWithOutStream = params =>
  api.get(ORDER_LIST_URL, {
    params,
    timeout: 999999,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const createOrder = values =>
  api.post(ORDER_URL, {
    body: values,
    headers: {
      "x-app-type": "customer-web",
    },
  });
export const getOrder = id =>
  api.getStream(sprintf(ORDER_ITEM_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });
export const createOrderNote = (id, values) =>
  api.post(sprintf(ORDER_ITEM_NOTE_URL, id), {
    body: values,
    headers: {
      "x-app-type": "customer-web",
    },
  });
export const updateOrderStatus = (id, status) =>
  api.put(sprintf(ORDER_ITEM_STATUS_URL, id), {
    params: { status },
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const cancelledOrderUpdateStatus = barcode =>
  api.put(ORDER_CANCEL_URL, {
    params: { barcode },
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getDynamicFields = () => api.getStream(DYNAMIC_FIELDS);

type CustomerFeedbackRequest = {
  id: number,
  note: string,
  score: number,
  order_id: number,
  rating_type: string,
  driver: { id: number },
  supplier: { id: number },
};

export const saveCustomerFeedback = (id, body: CustomerFeedbackRequest) =>
  api.post(sprintf(ORDER_SAVE_FEEDBACK_URL, id), {
    body,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getCustomerFeedback = id =>
  api.getStream(sprintf(ORDER_GET_FEEDBACK_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getOrderActivities = (id, number) =>
  Observable.combineLatest(
    api
      .getStream(sprintf(ORDER_NOTES_URL, id), {
        headers: {
          "x-app-type": "customer-web",
        },
      })
      .catch(() => Observable.of({})),
    api
      .getStream(sprintf(ORDER_HISTORY_URL, number))
      .catch(() => Observable.of({})),
    (notes, history) => ({
      pending: Boolean(notes.pending || history.pending),
      progress: (notes.progress + history.progress) / 2,
      payload: {
        notes: notes.payload,
        history: history.payload,
      },
    }),
  );

export const updateOrderPaymentDetails = (id, paymentType, payer) =>
  api.put(sprintf(ORDER_ITEM_PAYMENT_DETAILS_URL, id), {
    params: { payer, payment_type: paymentType },
    headers: {
      "x-app-type": "customer-web",
    },
  });

export type UpdateOrderChargeRequest = {
  charge_type: string,
  id: number,
  quantity: number,
  charge: number,
  date: string,
  paid: boolean,
  payer: string,
  paid_to_customer: boolean,
  paid_to_wing: boolean,
};

export const updateOrderCharge = (orderId, body: UpdateOrderChargeRequest) =>
  api.put(sprintf(ORDER_CHARGE_URL, orderId), {
    body,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const sendInternationalOrderInvoiceToEmail = (orderId, emails) =>
  api.getStream(
    sprintf(ORDER_INTERNATIONAL_SEND_INVOICE_TO_EMAIL_URL, orderId),
    {
      headers: {
        "x-app-type": "customer-web",
      },
      params: { emails },
    },
  );
