import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { Collapse } from "react-collapse";
import { withTheme } from "@material-ui/core/styles";
import CustomerOrderDetailsDialogTabs, {
  MAP_TAB,
  BRIEF_TAB,
  HISTORY_TAB,
  SIGNATURE_TAB,
} from "./CustomerOrderDetailsDialogTabs";
import CustomerOrderDetailsDialogHeader from "./CustomerOrderDetailsDialogHeader";
import ModalPaper from "../../ui-core/ModalPaper";
import { renderIf } from "../../../helpers/HOCUtils";
import FlexBox, { JUSTIFY_CENTER } from "../../ui-core/FlexBox";
import CustomButton, { CONTAINED, SECONDARY } from "../../ui-core/CustomButton";
import { DeleteForever } from "@material-ui/icons";

const enhancer = compose(
  withTheme,
  useSheet({
    paper: { fontFamily: "Blogger Sans" },
    background: {
      zIndex: -10,
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
    },

    topBackground: {
      zIndex: 1,
      flex: "1 1 0%",
      borderRadius: 0,
      transition: "background 0.5s ease",
    },

    bottomBackground: { backgroundColor: "#ebebeb", flex: "1 1 0%" },

    loading: {
      top: 0,
      width: "100%",
      height: "300px",
      textAlign: "center",
      lineHeight: "300px",
      position: "absolute",
      transition: "opacity 1s ease",
    },
    visibleLoader: { opacity: 1 },
    invisibleLoader: { opacity: 0, top: "2000px" },

    collapse: {
      overflow: "hidden",
      marginLeft: "-3px",
      paddingLeft: "3px",
      marginBottom: "-3px",
      paddingBottom: "3px",
      marginRight: "-3px",
      paddingRight: "3px",
    },

    container: { transition: "opacity 1s ease" },
    visibleContainer: { opacity: 1 },
    invisibleContainer: { opacity: 0 },
  }),
  renderIf("open"),
);

CustomerOrderDetailsDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([BRIEF_TAB, HISTORY_TAB, SIGNATURE_TAB, MAP_TAB]),
  onEditCostsClick: PropTypes.func,
  onCancelOrderClick: PropTypes.func,
  children: PropTypes.node,
  isOrderClosed: PropTypes.bool,
  messages: PropTypes.instanceOf(Map),
  statusMessages: PropTypes.instanceOf(Map),
  isCustom: PropTypes.bool,
  enableCancelOrder: PropTypes.bool,
  theme: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  getReverseUrl: PropTypes.func,
};

function CustomerOrderDetailsDialog(props) {
  const { classes, isCustom, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <div
        className={cx(classes.loading, {
          [classes.invisibleLoader]: !props.isLoading,
          [classes.visibleLoader]: props.isLoading,
        })}
      >
        Loading...
      </div>

      <div
        className={cx(classes.container, {
          [classes.visibleContainer]: !props.isLoading,
          [classes.invisibleContainer]: props.isLoading,
        })}
      >
        <div className={classes.background}>
          <Paper
            className={classes.topBackground}
            style={{ backgroundColor: props.theme.palette.primary1Color }}
          />
          <div className={classes.bottomBackground} />
        </div>

        <CustomerOrderDetailsDialogHeader
          statusMessages={props.statusMessages}
          messages={props.messages}
          order={props.order}
          getReverseUrl={props.getReverseUrl}
        />

        <CustomerOrderDetailsDialogTabs
          messages={props.messages}
          tab={props.tab}
          order={props.order}
          onTabChange={props.onTabChange}
          onEditCostsClick={props.onEditCostsClick}
          onCancelOrderClick={props.onCancelOrderClick}
          isOrderClosed={props.isOrderClosed}
          isCustom={isCustom}
          enableCancelOrder={props.enableCancelOrder}
        />

        {props.order.get("id") > 0 && (
          <Collapse isOpened={!props.isLoading}>{props.children}</Collapse>
        )}
        {props.isOrderClosed && (
          <FlexBox
            direction="column"
            flex={true}
            style={{ marginBottom: "1rem" }}
          >
            <FlexBox justify={JUSTIFY_CENTER} flex={true}>
              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                onClick={props.onCancelOrderClick}
                endIcon={<DeleteForever />}
              >
                {getLocalisationMessage("cancel_order", "Cancel Order")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        )}
      </div>
    </ModalPaper>
  );
}

export default enhancer(CustomerOrderDetailsDialog);
