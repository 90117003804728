import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { PanTo } from "react-google-map-components";
import OrderNeighborhoodField from "./OrderNeighborhoodField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FormPhoneCodeWithoutCountryField from "../form/FormPhoneCodeWithoutCountryField";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText, parsePhone, formatPhone } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  isBlankString,
  isValidCoordinates,
  validatePhoneNumber,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AddressTypes from "../../constants/AddressType";
import {
  getCachedCity,
  getCityPredictions,
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/shared/CountryV2Api";
import { getLocationDetails } from "../../api/customer/CustomerApiV2";
import OrderCityField from "../../components/orders-core/OrderCityField";

const validateLocation = (location, country, getLocalisationMessage) =>
  !isValidCoordinates(location)
    ? getLocalisationMessage(
        "type_address_above_to_search",
        "Type address above to search",
      )
    : location.country &&
      country.code &&
      location.country !== country.code &&
      getLocalisationMessage(
        "please_select_address_with_selected_country",
        "Please select address with selected country",
      );

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    map: { height: "256px", marginTop: "12px", width: "100%" },
    actions: { marginTop: "12px" },
  }),
  withContext(
    {
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    fp.always({
      getCachedCity,
      getCityPredictions,
      getCachedPostcode,
      getPostcodePredictions,
    }),
  ),
  reduxForm({
    form: "OrderAddressForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      apartment:
        !values.apartment &&
        props.getLocalisationMessage(
          "enter_apartment_address",
          "Enter apartment address",
        ),
      building:
        !values.building &&
        props.getLocalisationMessage(
          "enter_building_address",
          "Enter building address",
        ),
      location: validateLocation(
        values.location,
        values.country,
        props.getLocalisationMessage,
      ),
      phone: validatePhoneNumber(values.phone),
      name:
        isBlankString(values.name) &&
        props.getLocalisationMessage("enter_name", "Enter name"),
      addressType:
        isBlankString(values.addressType) &&
        props.getLocalisationMessage(
          "select_address_type",
          "Select Address Type",
        ),
      city:
        isBlankString(values.city) &&
        props.getLocalisationMessage(
          "please_select_city",
          "Please select city",
        ),
      neighborhood:
        isBlankString(values.neighborhood) &&
        props.getLocalisationMessage(
          "region_fields_required",
          "Region fields required",
        ),
    }),
  }),
  formValues({
    location: "location",
    phoneCode: "phoneCode",
    country: "country",
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const locationDetailsResponseStream = propsStream
          .filter(props => props.location)
          .distinctUntilChanged(isEqualData)
          .map(
            props =>
              new DataListFilter({
                lat: props.location.lat,
                lon: props.location.lng,
              }),
          )
          .switchMap(filter =>
            filter
              ? getLocationDetails(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            locationDetailsResponseStream,
            (props, locationDetailsResponse) => ({
              ...props,
              locationDetails: locationDetailsResponse.get("payload"),
              locationDetailsFetching: locationDetailsResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const locationDetailsStream = propsStream
          .filter(fp.get("locationDetails"))
          .distinctUntilChanged(isEqualData)
          .map(fp.pick(["change", "locationDetails"]));

        locationDetailsStream.subscribe(props =>
          props.change("locationDetails", props.locationDetails),
        );

        locationDetailsStream
          .filter(props => props.locationDetails.get("city"))
          .subscribe(props =>
            props.change("city", props.locationDetails.get("city").toJS()),
          );

        locationDetailsStream
          .filter(props => props.locationDetails.get("neighborhood"))
          .subscribe(props =>
            props.change(
              "neighborhood",
              props.locationDetails.get("neighborhood").toJS(),
            ),
          );

        return propsStream;
      },
    ),
  ),
);

OrderAddressForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  reset: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  location: PropTypes.object,
  locationDetails: PropTypes.object,
  locationDetailsFetching: PropTypes.bool,
  phoneCode: PropTypes.instanceOf(Map),
  country: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderAddressForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox
      gutter={8}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox>
        <GoogleMapWrapper
          className={classes.map}
          onClick={event =>
            props.change("location", {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            })
          }
        >
          {isValidCoordinates(props.location) && (
            <div>
              <PanTo latLng={props.location} />
              <BrandMarker position={props.location} />
            </div>
          )}
        </GoogleMapWrapper>
      </FlexBox>

      <FlexBox>
        <FormTextField
          name="name"
          fullWidth={true}
          label={getLocalisationMessage("name", "Name")}
        />
      </FlexBox>

      <FlexBox>
        <FlexBox flex={true}>
          <FormCountryAutoComplete
            readOnly={true}
            fullWidth={true}
            openOnFocus={false}
            name="country"
            label={getLocalisationMessage("country_star", "Country *")}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FormGeoAutoComplete
          name="location"
          countryCode={props.country && props.country.code}
          label={getLocalisationMessage("location", "Location")}
          fullWidth={true}
        />
      </FlexBox>

      <FlexBox>
        <FlexBox flex={6} style={{ paddingRight: "5px" }}>
          <FormPhoneCodeWithoutCountryField
            name="phoneCode"
            fullWidth={true}
            label={getLocalisationMessage("phone_code", "Phone Code")}
          />
        </FlexBox>

        <FlexBox flex={true} style={{ paddingLeft: "5px" }}>
          <FormTextField
            name="phone"
            focus={true}
            fullWidth={true}
            label={getLocalisationMessage("phone_number", "Phone number")}
            parse={parsePhone}
            format={value =>
              formatPhone(
                props.phoneCode && props.phoneCode.get("code")
                  ? props.phoneCode.get("code")
                  : null,
                value,
              )
            }
          />
        </FlexBox>
      </FlexBox>

      {props.locationDetails && !props.locationDetailsFetching && (
        <FlexBox>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <OrderCityField
                name="city"
                fullWidth={true}
                label={getLocalisationMessage("city", "City")}
                readOnly={true}
                location={props.locationDetails}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <OrderNeighborhoodField
                name="neighborhood"
                fullWidth={true}
                label={getLocalisationMessage("region", "Region")}
                readOnly={true}
                location={props.locationDetails}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}

      <FlexBox>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormSelectField
              name="addressType"
              label={getLocalisationMessage(
                "address_type_star",
                "Address Type *",
              )}
              fullWidth={true}
              options={AddressTypes}
              formatOption={x => getLocalisationMessage(x, formatText(x))}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              name="street"
              fullWidth={true}
              label={getLocalisationMessage("street", "Street")}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormTextField
              name="building"
              fullWidth={true}
              label={getLocalisationMessage(
                "building_villa_star",
                "Building / Villa *",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              name="apartment"
              fullWidth={true}
              label={getLocalisationMessage(
                "office_apartment",
                "Office/Apartment #",
              )}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FormTextField
          name="landmark"
          fullWidth={true}
          label={getLocalisationMessage("nearest_landmark", "Nearest Landmark")}
        />
      </FlexBox>

      <FlexBox justify="flex-end" className={classes.actions}>
        <Button onClick={props.reset}>
          {getLocalisationMessage("reset", "Reset")}
        </Button>
        <Button type="submit">
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderAddressForm);
