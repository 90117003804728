import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Step,
  Stepper,
  CardContent,
  StepLabel,
  Button,
  StepContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  facebookSignUp,
  facebookAccountFetch,
} from "../../actions/CustomerAuthActions";
import { isEqualData } from "../../helpers/DataUtils";
import { parsePhone, formatPhone } from "../../helpers/FormatUtils";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  validateEmailLocalisation,
  validatePhoneNumberForLocalisation,
} from "../../helpers/ValidateUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { phoneCountryCodeSet } from "../../constants/PhoneCountryCodes";
import SignInLink from "../../components/auth/SignInLink";
import NavigateFromSignIn from "../../components/auth/NavigateFromSignIn";
import FormTextField from "../../components/form/FormTextField";
import FormCountyPhoneCodeField from "../../components/form/FormCountyPhoneCodeField";
import FacebookButton from "../../components/ui-core/FacebookButton";
import CenteredCard from "../../components/deprecated/CenteredCard";
import { accent1, accent4 } from "../../../shared/theme/main-theme";
import {
  isSigningUp,
  isTokenValid,
  isTokenPersisting,
  getFacebookAccount,
  cleanFacebookSignIn,
  isFacebookSigningIn,
} from "../../../shared/reducers/AuthReducer";

const enhancer = compose(
  useSheet({
    recoverPassword: {
      display: "block",
      fontSize: "12px",
      marginTop: "6px",
      lineHeight: "14px",
      position: "relative",
      marginBottom: "18px",
    },
    signUp: {
      width: "100%",
      backgroundColor: accent1,
      "&:hover": { backgroundColor: accent4 },
    },
    signIn: {
      display: "block",
      textAlign: "center",
      marginBottom: "16px",
      textTransform: "uppercase",
    },
    facebook: { width: "100%" },
    dialogButton: { verticalAlign: "middle" },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
  }),
  connect(
    state => ({
      isSigningUp: isSigningUp(state),
      isTokenValid: isTokenValid(state),
      isTokenPersisting: isTokenPersisting(state),
      facebookAccount: getFacebookAccount(state),
      isFacebookSigningIn: isFacebookSigningIn(state),
      isRTL: getIsRTL(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { facebookSignUp, facebookAccountFetch, cleanFacebookSignIn },
  ),
  withHandlers({
    onSubmit: props => values =>
      props
        .facebookSignUp({
          phone: values.phone,
          last_name: props.facebookAccount.get("last_name"),
          first_name: props.facebookAccount.get("first_name"),
          email: props.facebookAccount.get("email") || values.email,
        })
        .catch(ResponseError.throw),
  }),
  reduxForm({
    form: "CustomerFacebookSignUp",
    validate: (values, props) => ({
      email: validateEmailLocalisation(
        (props.facebookAccount && props.facebookAccount.get("email")) ||
          values.email,
        props.getLocalisationMessage,
      ),
      phone: validatePhoneNumberForLocalisation(
        values.phone,
        props.getLocalisationMessage,
      ),
      country:
        !phoneCountryCodeSet.has(values.country) &&
        props.getLocalisationMessage(
          "please_select_valid_country",
          "Please select valid country",
        ),
    }),
  }),
  formValues({ phone: "phone", country: "country" }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream.takeLast(1).do(props => props.cleanFacebookSignIn()),
      propsStream
        .filter(props => phoneCountryCodeSet.has(props.country))
        .distinctUntilKeyChanged("country", isEqualData)
        .do(props => {
          const nextPhone = props.country.get("code");

          if (props.phone !== nextPhone) {
            props.untouch("phone");
            props.change("phone", nextPhone);
          }
        }),
    );

    return propsStream.merge(sideEffectsStream);
  }),
);

CustomerFacebookSignUp.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  country: PropTypes.instanceOf(Map),
  isTokenValid: PropTypes.bool,
  isTokenPersisting: PropTypes.bool,
  facebookAccountFetch: PropTypes.func,
  isFacebookSigningIn: PropTypes.bool,
  facebookAccount: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
};

function CustomerFacebookSignUp(props) {
  const { classes, facebookAccount, getLocalisationMessage, isRTL } = props;

  if (props.isTokenValid) {
    return <NavigateFromSignIn />;
  }

  const isLoading =
    props.isFacebookSigningIn || props.isTokenValid || props.isTokenPersisting;

  const step = !facebookAccount ? 0 : 1;
  const hasEmail = Boolean(facebookAccount && facebookAccount.get("email"));

  return (
    <CenteredCard withLogo={true} isLoading={isLoading}>
      <Helmet>
        <title>
          {getLocalisationMessage("facebook_sign_up", "Facebook Sign Up")}
        </title>
      </Helmet>
      <CardContent>
        <Stepper activeStep={step} orientation="vertical">
          <Step>
            <StepLabel>
              {getLocalisationMessage(
                "verify_facebook_account",
                "Verify Facebook Account",
              )}
            </StepLabel>
            <StepContent>
              <div>
                <p>
                  {getLocalisationMessage(
                    "before_you_can_proceed_we_need_you_to_verify_your_facebook_account",
                    "Before you can proceed we need you to verify your Facebook\n" +
                      "                  account",
                  )}
                </p>
                <FacebookButton
                  type="button"
                  label={getLocalisationMessage(
                    "connect_to_facebook",
                    "Connect to Facebook",
                  )}
                  className={classes.facebook}
                  onClick={() => props.facebookAccountFetch()}
                />
              </div>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Complete Sign In</StepLabel>
            <StepContent>
              <form
                noValidate={true}
                autoComplete="off"
                onSubmit={props.handleSubmit}
              >
                {!hasEmail && (
                  <FormTextField
                    name="email"
                    fullWidth={true}
                    label={getLocalisationMessage(
                      "email_address",
                      "Email Address",
                    )}
                  />
                )}

                <FormCountyPhoneCodeField
                  className={
                    isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                  }
                  name="country"
                  fullWidth={true}
                  maxSearchResults={5}
                />

                {Boolean(props.country && props.country.get("code")) && (
                  <FormTextField
                    ignoreRtl={isRTL}
                    name="phone"
                    focus={true}
                    fullWidth={true}
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone number",
                    )}
                    parse={parsePhone}
                    format={value =>
                      formatPhone(props.country.get("code"), value)
                    }
                  />
                )}

                <Button type="submit" className={classes.signUp}>
                  {getLocalisationMessage("sign_up", "Sign Up")}
                </Button>
              </form>
            </StepContent>
          </Step>
        </Stepper>
      </CardContent>
      <SignInLink className={classes.signIn} />
    </CenteredCard>
  );
}

export default enhancer(CustomerFacebookSignUp);
