import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import ClearOrderDialog from "./ClearOrderDialog";
import DeleteOrderDialog from "./DeleteOrderDialog";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, { DataListColumn } from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText, formatNumber } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  clearOrderUploadList,
  deleteOrderUploadList,
} from "../../api/customer/CustomerOrderUploadApi";
import DataListFilter from "../../helpers/DataListFilter";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const {
        handler: onRequestRefresh,
        stream: onRequestRefreshStream,
      } = createEventHandler();
      const responseStream = propsStream
        .switchMap(props =>
          props
            .getOrderUploadList(props.filter)
            .repeatWhen(() => onRequestRefreshStream)
            .catch(error => Observable.of({ error })),
        )
        .map(
          fp.flow(
            response => fromJS(response),
            response =>
              fromJS({
                isLoading: response.get("pending", false),
                total: response.getIn(["payload", "data", "total"], 0),
                list: response.getIn(["payload", "data", "list"], List()),
              }),
          ),
        )
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        list: response.get("list"),
        total: response.get("total"),
        isLoading: response.get("pending"),
      }));
    }),
  ),
);

CustomerOrderUploadList.propTypes = {
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  onOrderUploadClick: PropTypes.func.isRequired,
  onClearUploadClick: PropTypes.func.isRequired,
  onRemoveUploadClick: PropTypes.func.isRequired,
  onOrderCreateUploadClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRequestRefresh: PropTypes.func,
  removeId: PropTypes.number,
  clearId: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  showSuccessMessage: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
};

function CustomerOrderUploadList(props) {
  const { getLocalisationMessage, removeId, clearId } = props;
  return (
    <FlexBox flex={true} direction="column" element={<Paper />}>
      <DeleteOrderDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset(["remove"]))}
        onSubmit={() => deleteOrderUploadList(removeId)}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset(["remove"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
          props.setLocationQuery(fp.unset(["remove"]));
        }}
      />

      <ClearOrderDialog
        open={clearId}
        onRequestClose={() => props.setLocationQuery(fp.unset(["clear"]))}
        onSubmit={() => clearOrderUploadList()}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_cleared",
              "Successfully Cleared",
            ),
          );
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset(["clear"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
          props.setLocationQuery(fp.unset(["clear"]));
        }}
      />

      <DataList
        overscanRowCount={10}
        totalCount={props.total}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        filter={props.filter}
        rowCount={props.list.size}
        isLoading={props.isLoading}
        rowGetter={row => props.list.get(row.index)}
        cardActionIcons={
          <div>
            <Button onClick={props.onOrderCreateUploadClick}>
              {getLocalisationMessage("upload_file", "Upload File")}
            </Button>
            <Button onClick={props.onClearUploadClick}>
              {getLocalisationMessage("clear", "Clear")}
            </Button>
          </div>
        }
      >
        <DataListColumn
          width={120}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          cellRenderer={row => (
            <LinkButton
              onClick={() => {
                props.setLocationQueryFilter(props.filter.setPage(0));

                props.onOrderUploadClick(row.cellData.get("id"));
              }}
            >
              {row.cellData.get("id")}
            </LinkButton>
          )}
        />
        <DataListColumn
          width={120}
          label={getLocalisationMessage("uploaded_by", "Uploaded By")}
          dataKey="id"
          cellRenderer={row =>
            row.cellData.get("uploaded_by")
              ? row.cellData.get("uploaded_by")
              : getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("type", "Type")}
          dataKey="domestic"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("domestic")
              ? getLocalisationMessage("domestic", "Domestic")
              : getLocalisationMessage("international", "International")
          }
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("process_date", "Process Date")}
          dataKey="completed_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("completed_date")} />
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("of_orders", "# of orders")}
          dataKey="total"
          justifyContent="end"
          cellRenderer={row => formatNumber(row.cellData.get("total"))}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          justifyContent="center"
          cellRenderer={row =>
            formatText(
              getLocalisationMessage(
                row.cellData.get("status"),
                row.cellData.get("status"),
              ),
            )
          }
        />
        <DataListColumn
          label={getLocalisationMessage("action", "Action")}
          width={200}
          dataKey="action"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <Button
                onClick={() =>
                  props.onRemoveUploadClick(row.cellData.get("id"))
                }
              >
                {getLocalisationMessage("remove", "Remove")}
              </Button>
            </div>
          )}
        />
      </DataList>
    </FlexBox>
  );
}

export default enhancer(CustomerOrderUploadList);
