import React, { useState } from "react";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { marketplaceDefaultOrderCreationEnabled } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getDraftOrder,
  getOrderUploadList,
  updateDraftOrderV2,
  uploadDomesticOrdersV2,
} from "../../api/customer/CustomerOrderUploadApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import OrderUploadDialogV2 from "../../components/order-upload/OrderUploadDialogV2";
import CustomerOrderUploadList from "../../components/order-upload/CustomerOrderUploadList";
import OrderUploadEditDialogV2, {
  valuesToDraft,
} from "../../components/order-upload/OrderUploadEditDialogV2";
import {
  getCachedPostServices,
  getPostServicesPredictions,
} from "../../api/shared/OrderApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import { getPaymentMethods } from "../../api/v2/admin/AdminCustomerApi";
import { getCachedJM, getJMPredictions } from "../../api/shared/CountryV2Api";
import CustomerOrderUploadDetails from "../../components/order-upload/CustomerOrderUploadDetails";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(filterStream, (props, filter) => ({
      ...props,
      filter,
    }));
  }),
);

CustomerUploadOrdersV2Container.propTypes = {
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter),
};

function CustomerUploadOrdersV2Container(props) {
  const { location } = props;

  const batchId = fp.toFinite(location.query.id);
  const [draftId, setDraftId] = useState(false);
  const removeId = fp.toFinite(location.query.remove);
  const clear = Boolean(location.query.clear);

  const openUploadDialog =
    !batchId && !draftId && Boolean(location.query.upload);
  const openPublishDialog = batchId > 0 && Boolean(location.query.publish);

  return (
    <CustomerAppLayout
      slug="new_upload_orders"
      title={props.getLocalisationMessage("upload_orders", "Upload Orders")}
    >
      <OrderUploadDialogV2
        open={openUploadDialog}
        onRequestClose={() => props.setLocationQuery(fp.unset("upload"))}
        onSubmit={fp.flow(
          values => uploadDomesticOrdersV2(values.file),
          request => request.toPromise().catch(ResponseError.throw),
        )}
        onSubmitSuccess={fp.flow(fp.get("payload.data"), id =>
          props.setLocationQuery(fp.set("id", id)),
        )}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderUploadEditDialogV2
        draftId={draftId}
        getDraftOrder={getDraftOrder}
        onRequestClose={() => setDraftId(false)}
        onSubmit={draft =>
          updateDraftOrderV2({
            ...valuesToDraft(draft),
            id: draftId,
            payment_type: { code: draft.paymentType },
            transportation_type: { code: draft.transportationType },
            recipient_jurisdiction: {
              id: draft.recipientJurisdiction.id,
              content: draft.recipientJurisdiction.name,
            },
            sender_jurisdiction: {
              id: draft.senderJurisdiction.id,
              content: draft.senderJurisdiction.name,
            },
            recipient_postcode: {
              id: draft.recipientPostcode.id,
              content: draft.recipientPostcode.name,
            },
            sender_postcode: {
              id: draft.senderPostcode.id,
              content: draft.senderPostcode.name,
            },
            service_type: {
              id: toJS(draft.serviceType).id,
              content: toJS(draft.serviceType).name,
            },
          }).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => setDraftId(false)}
        onSubmitFail={props.showErrorMessage}
        getCachedPostServices={getCachedPostServices}
        getPostServicesPredictions={getPostServicesPredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictions={getPostcodePredictions}
        getPaymentMethods={getPaymentMethods}
        getCachedJM={getCachedJM}
        getJMPredictions={getJMPredictions}
      />

      {!batchId && (
        <CustomerOrderUploadList
          removeId={removeId}
          filter={props.filter}
          clearId={clear}
          getOrderUploadList={getOrderUploadList}
          onClearUploadClick={() =>
            props.setLocationQuery(fp.set("clear", true))
          }
          onRemoveUploadClick={id =>
            props.setLocationQuery(fp.set("remove", id))
          }
          onOrderUploadClick={id => props.setLocationQuery(fp.set("id", id))}
          onOrderCreateUploadClick={() =>
            props.setLocationQuery(fp.set("upload", true))
          }
        />
      )}
      {!draftId && batchId > 0 && (
        <CustomerOrderUploadDetails
          isCustomerApp={true}
          filter={props.filter}
          batchId={batchId}
          openPublishDialog={openPublishDialog}
          id={batchId}
          onPublishClick={() => props.setLocationQuery(fp.set("publish", true))}
          onBackClick={() => props.setLocationQuery({})}
          onDraftSelect={id => setDraftId(id)}
        />
      )}
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerUploadOrdersV2Container);
