import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Button, Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import CustomerOrderChargeEditDialogWrapper from "./CustomerOrderChargeEditDialogWrapper";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { mapOrderNotesAndHistoryToActivities } from "../../helpers/OrderHelper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import NoteTypeCategory from "../../constants/NoteTypeCategory";
import { CANCELLED, COMPLETED } from "../../constants/OrderStatusCodes";
import SupplierPrivacyTypes from "../../constants/SupplierPrivacyTypes";
import { getDriverLocation } from "../../api/shared/DriverApi";
import {
  createOrderNote,
  getOrder,
  getOrderActivities,
  updateOrderStatus,
} from "../../api/customer/CustomerOrderApi";
import OrderNoteDialog from "../../components/orders-core/OrderNoteDialog";
import CancelOrderDialog from "../../components/orders-core/CancelOrderDialog";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import OrderDetailsDialog from "../../components/order-details-dialog/OrderDetailsDialog";
import OrderDetailsDialogMap from "../../components/order-details-dialog/OrderDetailsDialogMap";
import {
  BRIEF_TAB,
  HISTORY_TAB,
  MAP_TAB,
} from "../../components/order-details-dialog/OrderDetailsDialogTabs";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import OrderDetailsDialogBriefV2 from "../../components/order-details-dialog/v2/OrderDetailsDialogBriefV2";

const enhancer = compose(
  renderIf((props) => props.orderId > 0),
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    container: {
      minHeight: "300px",
      maxHeight: "480px",
      overflowY: "auto",
      margin: "0 6px 6px",
    },
    buttonRight: { float: "right" },
  }),
  mapPropsStream((propsStream) => {
    const { handler: onShowAddNote, stream: onShowAddNoteStream } =
      createEventHandler();
    const { handler: onShowSubDialog, stream: onShowSubDialogStream } =
      createEventHandler();
    const { handler: onShowChargeEdit, stream: onShowChargeEditStream } =
      createEventHandler();
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();
    const { handler: onShowCancelDialog, stream: onShowCancelDialogStream } =
      createEventHandler();

    const showAddNoteStream = onShowAddNoteStream.startWith(false);

    const orderResponseStream = propsStream
      .pluck("orderId")
      .distinctUntilChanged()
      .switchMap((id) =>
        getOrder(id)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const getOrderActivitiesResponseStream = orderResponseStream
      .withLatestFrom(propsStream)
      .filter((props) => props.tab === HISTORY_TAB)
      .switchMap(([orderResponse, props]) =>
        getOrderActivities(
          props.orderId,
          orderResponse.getIn(["payload", "order_number"]),
        )
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", mapOrderNotesAndHistoryToActivities),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        showAddNoteStream,
        orderResponseStream,
        getOrderActivitiesResponseStream,
        onShowSubDialogStream.startWith(null),
        onShowChargeEditStream.startWith(false),
        onShowCancelDialogStream.startWith(false),
        (
          props,
          showAddNote,
          orderResponse,
          orderActivities,
          uploadType,
          showChargeEdit,
          showCancelDialog,
        ) => ({
          ...props,
          uploadType,
          showAddNote,
          onShowAddNote,
          showChargeEdit,
          onShowSubDialog,
          showCancelDialog,
          onRequestRefresh,
          onShowChargeEdit,
          onShowCancelDialog,
          order: orderResponse.get("payload"),
          isLoading: orderResponse.get("pending"),
          activities: orderActivities.get("payload"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

CustomerOrderDetailsDialogWrapper.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  activities: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  tab: PropTypes.oneOf([BRIEF_TAB, HISTORY_TAB, MAP_TAB]),
  showAddNote: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onShowAddNote: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  orderId: PropTypes.number,
  onTabChange: PropTypes.func,
  onEditClick: PropTypes.func,
  onShowCancelDialog: PropTypes.func,
  showCancelDialog: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  onShowChargeEdit: PropTypes.func,
  showChargeEdit: PropTypes.bool,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  location: PropTypes.object,
};

CustomerOrderDetailsDialogWrapper.defaultProps = {
  tab: BRIEF_TAB,
};

function CustomerOrderDetailsDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  const isOrderClosed = Boolean(
    props.order.get("status") === COMPLETED ||
      props.order.get("status") === CANCELLED,
  );

  return (
    <OrderDetailsDialog
      open={true}
      onRequestClose={props.onRequestClose}
      tab={props.tab}
      order={props.order}
      isLoading={props.isLoading}
      onTabChange={props.onTabChange}
      onEditClick={props.onEditClick}
      withoutCourierTab={true}
      withoutSignaturesTab={true}
      withoutCustomerTab={true}
      getReverseUrl={(id) => updateQuery(props.location, fp.set("view", id))}
      onEditCostsClick={() => props.onShowChargeEdit(true)}
      isOrderClosed={isOrderClosed}
      onCancelOrderClick={() => props.onShowCancelDialog(true)}
    >
      <CustomerOrderChargeEditDialogWrapper
        open={props.showChargeEdit}
        orderId={props.orderId}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.onShowChargeEdit(false);
        }}
      />

      <CancelOrderDialog
        onRequestClose={() => props.onShowCancelDialog(false)}
        open={!isOrderClosed && props.showCancelDialog}
        onSubmit={() =>
          updateOrderStatus(props.order.get("id"), CANCELLED).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.showSuccessMessage(
            getLocalisationMessage(
              "order_successfully_cancelled",
              "Order Successfully Cancelled",
            ),
          );
          props.onShowCancelDialog(false);
        }}
        onSubmitFail={(error) => {
          props.onRequestRefresh();
          props.showErrorMessage(error);
          props.onShowCancelDialog(false);
        }}
      />

      {Boolean(props.tab === BRIEF_TAB) && (
        <OrderDetailsDialogBriefV2 order={props.order} />
      )}

      {Boolean(props.tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <Button
              className={classes.buttonRight}
              onClick={() => props.onShowAddNote(true)}
            >
              {getLocalisationMessage("add_note", "Add Note")}
            </Button>

            <OrderActivitiesTimeline
              showDriver={true}
              showSupplier={true}
              showWarehouse={true}
              activities={props.activities}
            />

            <OrderNoteDialog
              open={props.showAddNote}
              privacyTypes={SupplierPrivacyTypes}
              noteTypeCategory={NoteTypeCategory}
              withRequiresAttention={true}
              withScheduledForCancellation={true}
              onRequestClose={() => props.onShowAddNote(false)}
              initialValues={{
                requiresAttention: Boolean(
                  props.order.get("requires_attention"),
                ),
                scheduledForCancellation: Boolean(
                  props.order.get("scheduled_for_cancellation"),
                ),
              }}
              onSubmit={(values) =>
                createOrderNote(props.orderId, { value: values.content }).catch(
                  ResponseError.throw,
                )
              }
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.onShowAddNote(false);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_note_created",
                    "Successfully Note Created",
                  ),
                );
              }}
              onSubmitFail={() => props.showErrorMessage()}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === MAP_TAB) && (
        <OrderDetailsDialogMap
          order={props.order}
          getDriverLocation={getDriverLocation}
        />
      )}
    </OrderDetailsDialog>
  );
}

export default enhancer(CustomerOrderDetailsDialogWrapper);
