import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { Motorcycle, DirectionsCar, LocalShipping } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import Text, {
  MUTED,
  SMALL,
  DANGER,
  DELETED,
  ALIGN_RIGHT,
} from "../../ui-core/Text";
import PriceWrapper from "../../ui-core/PriceWrapper";
import { formatDistanceForKM } from "../../../helpers/FormatUtils";
import { getMarketplaceDefaultUnitOfDistance } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { URGENT } from "../../../constants/CourierTypes";
import { BIKE, SEDAN } from "../../../constants/VehicleTypes";

const enhancer = compose(
  withTheme,
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      unitOfDistance: getMarketplaceDefaultUnitOfDistance(state),
    };
  }),
  useSheet({
    vehicleIcon: {
      width: "18px",
      height: "18px",
      color: props => props.theme.palette.primary1Color,
      marginLeft: "6px",
      verticalAlign: "sub",
    },
  }),
);

VehicleIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
};

function VehicleIcon(props) {
  switch (props.type) {
    case BIKE:
      return <Motorcycle className={props.className} />;
    case SEDAN:
      return <DirectionsCar className={props.className} />;
    default:
      return <LocalShipping className={props.className} />;
  }
}

OrderCreateWizardStepHowItem.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  unitOfDistance: PropTypes.string,
};

function OrderCreateWizardStepHowItem(props) {
  const { item, unitOfDistance, getLocalisationMessage } = props;

  if (!item) {
    return null;
  }

  const isUrgent = item.getIn(["courier_type", "type"]) === URGENT;
  const vehicleType = item.getIn(["vehicle_type", "type"]);
  const oldTotal = item.getIn(["price", "old_total"]);
  const currencyCode = item.getIn(["price", "currency", "code"]);

  return (
    <OrderCreateWizardStepDetails
      label={props.label}
      onClick={props.onClick}
      avatar={<Avatar src={item.getIn(["courier_type", "icon"])} />}
      primaryText={
        <div>
          {item.get("name") && item.get("name").length <= 20 ? (
            item.get("name")
          ) : !item.get("name") ? (
            item.get("name")
          ) : (
            <div>
              <div>{item.get("name").slice(0, 30)}</div>
              <div>{item.get("name").slice(30, 60)}</div>
              <div>{item.get("name").slice(60, item.get("name").length)}</div>
            </div>
          )}

          {Boolean(vehicleType && isUrgent) && (
            <VehicleIcon
              type={vehicleType}
              className={props.classes.vehicleIcon}
            />
          )}
        </div>
      }
      rightPrimaryText={
        <Text type={[SMALL, MUTED, ALIGN_RIGHT]}>
          {item.get("name") && item.get("name").length <= 20 ? (
            <div>
              {`${getLocalisationMessage(
                "base_price_1",
                "Base price",
              )}: ${formatDistanceForKM(
                item.getIn(["price", "distance_included"]),
                unitOfDistance,
              )}: `}
              <PriceWrapper
                type={[MUTED]}
                price={item.getIn(["price", "base"])}
                code={currencyCode}
              />
              {` ${getLocalisationMessage(
                "extra_1",
                "Extra",
              )}: ${formatDistanceForKM(
                item.getIn(["price", "distance"]) -
                  item.getIn(["price", "distance_included"]),
                unitOfDistance,
              )}`}
            </div>
          ) : (
            <div>
              {`${getLocalisationMessage(
                "base_price_1",
                "Base price",
              )}: ${formatDistanceForKM(
                item.getIn(["price", "distance_included"]),
                unitOfDistance,
              )}: `}
              <PriceWrapper
                type={[MUTED]}
                price={item.getIn(["price", "base"])}
                code={currencyCode}
              />
              <br />
              {` ${getLocalisationMessage(
                "extra_1",
                "Extra",
              )}: ${formatDistanceForKM(
                item.getIn(["price", "distance"]) -
                  item.getIn(["price", "distance_included"]),
                unitOfDistance,
              )}`}
            </div>
          )}
        </Text>
      }
      secondaryText={item.get("description")}
      rightSecondaryText={
        <div>
          <Text type={[MUTED, ALIGN_RIGHT]}>
            {getLocalisationMessage("starting_from", "Starting from")}{" "}
          </Text>
          {oldTotal > 0 && (
            <PriceWrapper
              type={[SMALL, DELETED, MUTED]}
              price={oldTotal}
              code={currencyCode}
            />
          )}{" "}
          <PriceWrapper
            type={DANGER}
            price={item.getIn(["price", "total"])}
            code={currencyCode}
          />
        </div>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepHowItem);
