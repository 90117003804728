import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { CardContent, Button } from "@material-ui/core";
import { connect } from "react-redux";
import {
  confirmVerificationCode,
  requestVerificationCode,
} from "../../actions/AuthActions";
import { skipVerification } from "../../actions/ProfileActions";
import { fetchUser, updateUser } from "../../actions/CustomerProfileActions";
import { parsePhone, formatPhone } from "../../helpers/FormatUtils";
import { createSkipVerificationUrl } from "../../helpers/CookieHelper";
import { validatePhoneNumber } from "../../helpers/ValidateUtils";
import {
  getUser,
  getUserId,
  getUserName,
  getUserPhone,
  isUserFetching,
  isUserUpdating,
  isUserVerified,
} from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import LinkButton from "../../components/ui-core/LinkButton";
import TextField from "../../components/deprecated/TextField";
import CenteredCard from "../../components/deprecated/CenteredCard";
import { accent1 } from "../../../shared/theme/main-theme";
import {
  isTokenValid,
  isCodeConfirming,
  isCodeRequesting,
} from "../../../shared/reducers/AuthReducer";

const enhancer = compose(
  useSheet({
    link: { display: "block", marginTop: "12px", textAlign: "center" },
    button: { width: "100%", backgroundColor: accent1 },
  }),
  connect(
    state => ({
      isAuthorized: isTokenValid(state),
      isVerificationCodeConfirming: isCodeConfirming(state),
      isVerificationCodeRequesting: isCodeRequesting(state),
      user: getUser(state),
      name: getUserName(state),
      userId: getUserId(state),
      phoneNumber: getUserPhone(state),
      isVerified: isUserVerified(state),
      isUserLoading: isUserFetching(state),
      isUserUpdating: isUserUpdating(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      fetchUser,
      updateUser,
      skipVerification,
      confirmVerificationCode,
      requestVerificationCode,
    },
  ),
);

class CustomerVerify extends React.Component {
  static propTypes = {
    sheet: PropTypes.object,
    confirmVerificationCode: PropTypes.func,
    requestVerificationCode: PropTypes.func,
    isVerificationCodeRequesting: PropTypes.bool,
    name: PropTypes.string,
    user: PropTypes.instanceOf(Map),
    phoneNumber: PropTypes.string,
    fetchUser: PropTypes.func,
    isUserLoading: PropTypes.bool,
    updateUser: PropTypes.func,
    isUserUpdating: PropTypes.bool,
    getLocalisationMessage: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      phone: props.phoneNumber,
      phoneError: null,
      phoneConfirmed: false,
      code: "",
      codeError: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { phoneNumber: p1 } = nextProps;
    const { phoneNumber: p2 } = this.props;

    if (p1 !== p2) {
      this.setState({ phone: p1, phoneError: null, phoneConfirmed: false });
    }
  }

  handlePhoneSubmit = event => {
    const { phoneNumber } = this.props;

    const { phone } = this.state;

    event.preventDefault();

    const phoneError = validatePhoneNumber(phone);

    if (phoneError) {
      this.setState({ phoneError });

      return;
    }

    const cleanPhone = parsePhone(phoneNumber);
    const cleanNextPhone = parsePhone(phone);

    const updatePhone =
      cleanPhone !== cleanNextPhone
        ? this.props.updateUser({ phone: cleanNextPhone })
        : Promise.resolve();

    updatePhone
      .then(() => this.props.requestVerificationCode())
      .then(() => {
        this.setState({ phoneError: null, phoneConfirmed: true });
      });
  };

  renderPhoneNumber() {
    const {
      sheet: {
        classes: { link, button },
      },
    } = this.props;

    const { phone, phoneError } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.handlePhoneSubmit}>
        <TextField
          value={phone}
          errorText={phoneError}
          onChange={x => this.setState({ phone: x })}
          fullWidth={true}
          label={this.props.getLocalisationMessage(
            "phone_number",
            "Phone Number",
          )}
          parse={parsePhone}
          format={formatPhone}
          disabled={this.props.isUserUpdating}
        />
        <Button
          type="submit"
          label={this.props.getLocalisationMessage("confirm", "Confirm")}
          className={button}
        />
        <a className={link} href={createSkipVerificationUrl()}>
          {this.props.getLocalisationMessage("skip", "Skip")}
        </a>
      </form>
    );
  }

  handleCodeSubmit = event => {
    const { code } = this.state;

    event.preventDefault();

    if (!code) {
      this.setState({
        codeError: this.props.getLocalisationMessage(
          "please_enter_verification_code",
          "Please enter verification code",
        ),
      });

      return;
    }

    this.setState({ codeError: null });

    this.props
      .confirmVerificationCode(code)
      .then(() => this.props.fetchUser())
      .catch(e => this.setState({ codeError: _.get(e, "response.value") }));
  };

  handleChangeNumberClick = event => {
    event.preventDefault();

    this.setState({ code: "", codeError: null, phoneConfirmed: false });
  };

  renderVerification() {
    const {
      sheet: {
        classes: { link, button },
      },
    } = this.props;

    const { code, codeError } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.handleCodeSubmit}>
        <TextField
          value={code}
          errorText={codeError}
          onChange={x => this.setState({ code: x })}
          fullWidth={true}
          label={this.props.getLocalisationMessage(
            "verification_code",
            "Verification Code",
          )}
        />
        <Button type="submit" className={button}>
          {this.props.getLocalisationMessage("verify", "Verify")}
        </Button>
        <LinkButton className={link} onClick={this.handleChangeNumberClick}>
          {this.props.getLocalisationMessage("change_number", "Change Number")}
        </LinkButton>
      </form>
    );
  }

  render() {
    const { phoneConfirmed } = this.state;

    const isLoading =
      !this.props.user ||
      this.props.isUserLoading ||
      this.props.isUserUpdating ||
      this.props.isVerificationCodeRequesting;
    const content = !phoneConfirmed
      ? this.renderPhoneNumber()
      : this.renderVerification();

    return (
      <CenteredCard withLogo={true} isLoading={isLoading}>
        <CardContent>
          <br />
          <p>
            {this.props.getLocalisationMessage("dear", "Dear")}{" "}
            <strong>{this.props.name}</strong>
          </p>
          <p>
            {this.props.getLocalisationMessage(
              "phone_not_verified",
              "Your phone number is not verified yet. We don't guarantee that you\n" +
                "            will receive SMS from us notifying about your orders. Please verify\n" +
                "            the number below.",
            )}
          </p>
          {content}
        </CardContent>
      </CenteredCard>
    );
  }
}

export default enhancer(CustomerVerify);
