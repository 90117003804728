import React, { useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  CardContent,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  ArrowBack,
  ArrowRight,
  Check,
  PhoneAndroid,
  Replay,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { grey, green } from "@material-ui/core/colors";
import SignInLink from "./SignInLink";
import FormTextField from "../form/FormTextField";
import CenteredCard from "../deprecated/CenteredCard";
import { validatePasswordForLocalisation } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { makeStyles } from "@material-ui/core/styles";
import {
  getCodeRegister,
  verifyRegisterCode,
} from "../../actions/CustomerAuthActions";
import _ from "lodash";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox from "../ui-core/FlexBox";
import FormPhoneField from "../form/FormPhoneField";
import { createPhoneEmptyValidator } from "../../helpers/FormUtils";
import ResendTimer from "./ResendTimer";
import { formatPhone2 } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  reduxForm({
    form: "SignUpForm",
    validate: (values, { getLocalisationMessage }) => ({
      username:
        (!values.username || formatPhone2(values.username).length < 13) &&
        getLocalisationMessage("this_field_is_required"),
      firstName:
        !values.firstName && getLocalisationMessage("this_field_is_required"),
      lastName:
        !values.lastName && getLocalisationMessage("this_field_is_required"),
      password: validatePasswordForLocalisation(
        values.password,
        getLocalisationMessage,
      ),
    }),
  }),
  formValues(
    "username",
    "code",
    "registration_key",
    "password",
    "firstName",
    "lastName",
  ),
);
const useStyles = makeStyles({
  btn: { width: "100%" },
  recoverPassword: {
    display: "block",
    fontSize: "12px",
    marginTop: "6px",
    lineHeight: "14px",
    position: "relative",
    marginBottom: "18px",
  },
  signIn: {
    display: "block",
    textAlign: "center",
    marginBottom: "16px",
    textTransform: "uppercase",
  },
  passwordField: { position: "relative" },
  facebook: { width: "100%" },
  dialogButton: { verticalAlign: "middle" },
});
const SignUpForm = ({
  getLocalisationMessage,
  handleSubmit,
  isLoading,
  username,
  firstName,
  lastName,
  code,
  password,
  // eslint-disable-next-line camelcase
  registration_key,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  change,
}) => {
  const classes = useStyles();
  const [isLoadingPhoneCode, setIsLoadingPhoneCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [enableToResend, setEnableToResend] = useState(false);
  const [step, setStep] = useState(1);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <CenteredCard withLogo={true}>
      <Helmet>
        <title>{getLocalisationMessage("sign_up", "Sign Up")}</title>
      </Helmet>
      <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
        <CardContent>
          {/* eslint-disable-next-line camelcase */}
          {step === 1 && (
            <FlexBox direction="column" style={{}}>
              <FormTextField
                name="firstName"
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "first_name",
                  "First Name",
                )} *`}
                margin="normal"
              />

              <FormTextField
                name="lastName"
                fullWidth={true}
                label={`${getLocalisationMessage("last_name", "Last Name")} *`}
                margin="normal"
              />

              <FormPhoneField
                disableDropdown={true}
                disableAreaCodes={true}
                country="uz"
                name="username"
                variant={OUTLINED}
                size="small"
                style={{
                  marginTop: 13,
                  marginBottom: 13,
                }}
                fullWidth={true}
                label={`${getLocalisationMessage("phone_number")}*`}
                validate={createPhoneEmptyValidator(
                  getLocalisationMessage(
                    "invalid_phone_number",
                    "Invalid phone number",
                  ),
                )}
              />
            </FlexBox>
          )}

          {/* eslint-disable-next-line camelcase */}
          {step === 3 && (
            <FormTextField
              style={{ marginTop: 13 }}
              name="password"
              type={showPassword ? "text" : "password"}
              fullWidth={true}
              label={`${getLocalisationMessage("password", "Password")} *`}
              withOutInputProps={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {/* eslint-disable-next-line camelcase */}
          {step === 2 && (
            <FormTextField
              name="code"
              fullWidth={true}
              label={getLocalisationMessage("code")}
              margin="normal"
            />
          )}

          {/* eslint-disable-next-line camelcase */}
          {step === 2 &&
            (!enableToResend ? (
              <div />
            ) : (
              <FlexBox
                flex={true}
                justify="center"
                style={{ marginBottom: 13 }}
              >
                <ResendTimer
                  count={120}
                  onTimerIsEnd={() => setEnableToResend(false)}
                />
              </FlexBox>
            ))}

          {/* eslint-disable-next-line camelcase */}
          {step === 3 && (
            <FlexBox
              direction="column"
              style={{ marginTop: "24px", marginBottom: 24 }}
            >
              <FlexBox style={{ gap: "8px" }}>
                <Check
                  style={{
                    color:
                      _.size(password && password) >= 8
                        ? green[500]
                        : grey[500],
                  }}
                />
                <Typography
                  style={{
                    color:
                      _.size(password && password) >= 8
                        ? green[500]
                        : grey[500],
                  }}
                >
                  {getLocalisationMessage(
                    "password_must_be_8_or_more_characters_in_length",
                    "Password must be 8 or more characters in length.",
                  )}
                </Typography>
              </FlexBox>
              <FlexBox style={{ gap: "8px" }}>
                <Check
                  style={{
                    color: /[a-z]/.test(password || "")
                      ? green[500]
                      : grey[500],
                  }}
                />
                <Typography
                  style={{
                    color: /[a-z]/.test(password || "")
                      ? green[500]
                      : grey[500],
                  }}
                >
                  {getLocalisationMessage(
                    "password_must_contain_1_or_more_lowercase_characters",
                    "Password must contain 1 or more lowercase characters.",
                  )}
                </Typography>
              </FlexBox>
              <FlexBox style={{ gap: "8px" }}>
                <Check
                  style={{
                    color: /[A-Z]/.test(password && password)
                      ? green[500]
                      : grey[500],
                  }}
                />
                <Typography
                  style={{
                    color: /[A-Z]/.test(password && password)
                      ? green[500]
                      : grey[500],
                  }}
                >
                  {getLocalisationMessage(
                    "password_must_contain_1_or_more_uppercase_characters",
                    "Password must contain 1 or more uppercase characters.",
                  )}
                </Typography>
              </FlexBox>
              <FlexBox style={{ gap: "8px" }}>
                <Check
                  style={{
                    color: /\d/.test(password && password)
                      ? green[500]
                      : grey[500],
                  }}
                />
                <Typography
                  style={{
                    color: /\d/.test(password && password)
                      ? green[500]
                      : grey[500],
                  }}
                >
                  {getLocalisationMessage(
                    "password_must_contain_1_or_more_digit_characters",
                    "Password must contain 1 or more digit characters.",
                  )}
                </Typography>
              </FlexBox>
            </FlexBox>
          )}

          {/* eslint-disable-next-line camelcase */}
          {!registration_key && (
            <FlexBox style={{ gap: 10 }}>
              {step !== 1 && (
                <CustomButton
                  fullWidth={true}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={() => {
                    setIsCodeSent(false);
                    setStep(1);
                  }}
                  startIcon={<ArrowBack />}
                >
                  {getLocalisationMessage(isCodeSent ? "go_back" : "Go Back")}
                </CustomButton>
              )}

              {isCodeSent && !enableToResend && (
                <CustomButton
                  fullWidth={true}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={() => {
                    getCodeRegister(username)
                      .then(() => {
                        setIsCodeSent(true);
                        setEnableToResend(true);
                        showSuccessMessage1(
                          getLocalisationMessage("successfully"),
                        );
                      })
                      .catch(error => showErrorMessage1(error));
                  }}
                  endIcon={isCodeSent ? <Replay /> : <PhoneAndroid />}
                >
                  {getLocalisationMessage(isCodeSent ? "retry" : "send_code")}
                </CustomButton>
              )}

              {!isCodeSent && (
                <CustomButton
                  fullWidth={true}
                  variant={OUTLINED}
                  color={SECONDARY}
                  disabled={
                    !(
                      formatPhone2(username) &&
                      formatPhone2(username).length >= 13 &&
                      firstName &&
                      lastName
                    )
                  }
                  onClick={() => {
                    getCodeRegister(username)
                      .then(() => {
                        setStep(2);
                        setIsCodeSent(true);
                        setEnableToResend(true);
                        showSuccessMessage1(
                          getLocalisationMessage("successfully"),
                        );
                      })
                      .catch(error => showErrorMessage1(error));
                  }}
                  endIcon={<PhoneAndroid />}
                >
                  {getLocalisationMessage("send_code")}
                </CustomButton>
              )}
              {isCodeSent && enableToResend && (
                <CustomButton
                  fullWidth={true}
                  variant={OUTLINED}
                  color={SECONDARY}
                  disabled={!code}
                  onClick={() => {
                    setIsLoadingPhoneCode(true);
                    verifyRegisterCode(code, username)
                      .then(res => {
                        change("registration_key", _.get(res, "data"));
                        setStep(3);
                        showSuccessMessage1(
                          getLocalisationMessage("successfully"),
                        );
                      })
                      .catch(error => showErrorMessage1(error))
                      .finally(() => setIsLoadingPhoneCode(false));
                  }}
                  endIcon={
                    isLoadingPhoneCode ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <ArrowRight />
                    )
                  }
                >
                  {getLocalisationMessage("verify_code", "Verify Code")}
                </CustomButton>
              )}
            </FlexBox>
          )}

          {/* eslint-disable-next-line camelcase */}
          {registration_key && (
            <FlexBox style={{ gap: 10 }}>
              {step !== 1 && (
                <CustomButton
                  fullWidth={true}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={() => {
                    change("registration_key", null);
                    setStep(2);
                    setEnableToResend(false);
                    setIsCodeSent(true);
                  }}
                  startIcon={<ArrowBack />}
                >
                  {getLocalisationMessage(isCodeSent ? "go_back" : "Go Back")}
                </CustomButton>
              )}

              <CustomButton
                className={classes.btn}
                variant={OUTLINED}
                color={SECONDARY}
                type="submit"
                disabled={isLoading}
                endIcon={
                  isLoading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <ArrowRight />
                  )
                }
              >
                {getLocalisationMessage("sign_up", "Sign Up")}
              </CustomButton>
            </FlexBox>
          )}
        </CardContent>
      </form>
      {!isLoading && (
        <SignInLink className={classes.signIn}>
          {getLocalisationMessage("sign_in", "Sign In")}
        </SignInLink>
      )}
    </CenteredCard>
  );
};
SignUpForm.propTypes = {
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  change: PropTypes.func,
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  code: PropTypes.string,
  password: PropTypes.string,
  registration_key: PropTypes.string,
};

export default enhancer(SignUpForm);
