import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import OrderCreateWizardStepCourierItem from "./OrderCreateWizardStepCourierItem";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardDefault");

const enhancer = compose(
  connect(state => ({
    courier: valueSelector(state, "courier"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepHowDetails.propTypes = {
  courier: PropTypes.instanceOf(Map),
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepHowDetails(props) {
  return (
    <OrderCreateWizardStepCourierItem
      label={props.getLocalisationMessage("who", "Who")}
      onClick={props.onClick}
      item={props.courier}
    />
  );
}

export default enhancer(OrderCreateWizardStepHowDetails);
