import { Observable } from "rxjs";
import React from "react";
import { startOfToday } from "date-fns";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import reduxActions from "redux-form/es/actions";
import { getProperGeoAddressString } from "../../helpers/GeoUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { trackOrderCreate } from "../../helpers/EventTracker";
import ResponseError from "../../helpers/ResponseError";
import {
  getUserName,
  getUserEmail,
  getUserPhone,
} from "../../reducers/ProfileReducer";
import {
  getMarketplaceActualWeight,
  getMarketplaceReverseSetting,
} from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { REGULAR_ORDER } from "../../constants/OrderTypes";
import { RESIDENTIAL } from "../../constants/AddressType";
import { SENDER } from "../../constants/OrderPayerTypes";
import { ORDER_CREATE_SUCCESS_URL } from "../../constants/CustomerPathConstants";
import { DO_NOT_DELIVER } from "../../constants/OrderRecipientNotAvailableActionTypes";
import { getCustomerDefaultAddressforCustomer } from "../../api/admin/AdminCustomerApi";
import { getCountries } from "../../api/shared/CountryApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCachedPostcode,
  getCountryPredictions,
  getPostcodePredictions,
} from "../../api/shared/CountryV2Api";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getMostUsedAddresses } from "../../api/customer/CustomerApiV2";
import { createOrder } from "../../api/customer/CustomerOrderApiV2";
import { validatePromo } from "../../api/customer/CustomerPromoApiV2";
import { getTimeSlots } from "../../api/customer/CustomerTimeSlotApiV2";
import PageLoading from "../../components/ui-core/PageLoading";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import OrderCreateWizard from "../../components/order-create-wizard-new/OrderCreateWizard";
import { base64ToObject } from "../../../shared/helpers/CryptoUtils";
import { CM_KG } from "../../../client/constants/UnitsOfMeasureTypes";
import { WING_AE_ID } from "../../../server/constants/MarketplaceId";

const AE = "AE";

const parseInitialData = fp.flow(base64ToObject, fp.toPlainObject, data => ({
  initialMenuId: data.menuId,
  initialWeight: data.weight,
  initialPackageId: data.initialPackageId,
  initialCourierId: data.initialCourierId,
  initialPickupLocation: data.pickupPoint,
  initialDropoffLocation: data.dropoffPoint,
  initialDropoffCountry: data.toCountry,
  initialDropoffCity: data.toCity,
  initialPickupCity: data.fromCity,
}));

const findCountry = (countries, id, code) => {
  let country;

  if (id > 0) {
    country = countries.find(x => x.get("id") === id);
  }

  if (!country && code) {
    country = countries.find(x => x.get("code") === code);
  }

  return country || null;
};

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      userName: getUserName(state),
      userPhone: getUserPhone(state),
      userEmail: getUserEmail(state),
      isReverseLogisticEnabled: getMarketplaceReverseSetting(state),
      defaultActualWeight: getMarketplaceActualWeight(state),
      i18n: getMessages(state),
    }),
    dispatch => ({
      destroy: () => dispatch(reduxActions.destroy("OrderCreateWizardNew")),
      showErrorMessage: (...args) => dispatch(showErrorMessage(...args)),
    }),
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const initialQueryValuesStream = propsStream
          .take(1)
          .map(fp.flow(fp.get("location.query.q"), parseInitialData));

        const asyncDataStream = Observable.combineLatest(
          getCountries()
            .takeLast(1)
            .catch(() => Observable.of(Map())),
          getMarketplace()
            .takeLast(1)
            .catch(() => Observable.of({}))
            .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, fromJS)),
          getMostUsedAddresses()
            .takeLast(1)
            .catch(() => Observable.of({}))
            .map(
              fp.flow(
                response => fromJS(response),
                response =>
                  fromJS({
                    pending: response.get("pending", false),
                    senderLocations: response.getIn(
                      ["payload", "data", "sender_locations"],
                      List(),
                    ),
                    recipientLocations: response.getIn(
                      ["payload", "data", "recipient_locations"],
                      List(),
                    ),
                  }),
              ),
            ),
          (countries, marketplace, mostUsedAddresses) =>
            fromJS({
              pending: false,
              countries,
              marketplace,
              mostUsedAddresses,
            }),
        ).startWith(Map({ pending: true }));

        const customerDefaultAddressStream = propsStream
          .first()
          .switchMap(() =>
            getCustomerDefaultAddressforCustomer().catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  data: response.getIn(["payload", "data"]),
                }),
            ),
          );

        const sideEffectsStream = propsStream
          .filter(fp.get("location.query.destroy"))
          .do(props => props.destroy())
          .startWith(null);

        return propsStream.combineLatest(
          asyncDataStream,
          customerDefaultAddressStream,
          initialQueryValuesStream,
          sideEffectsStream,
          (props, asyncData, defaultAddress, initialQueryValues) => ({
            ...props,
            initialQueryValues,
            defaultAddress: defaultAddress.get("data"),
            countries: asyncData.get("countries"),
            marketplace: asyncData.get("marketplace"),
            isLoading: asyncData.get("pending"),
            senderLocations: asyncData.getIn([
              "mostUsedAddresses",
              "senderLocations",
            ]),
            recipientLocations: asyncData.getIn([
              "mostUsedAddresses",
              "recipientLocations",
            ]),
          }),
        );
      },
      propsStream => {
        const initialValuesStream = propsStream
          .filter(props => !props.isLoading)
          .map(
            fp.pick([
              "userName",
              "userPhone",
              "userEmail",
              "defaultAddress",
              "defaultActualWeight",
              "countries",
              "marketplace",
              "initialQueryValues",
            ]),
          )
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const defaultCountry = findCountry(
              props.countries,
              !props.defaultAddress
                ? props.marketplace.getIn(["country", "id"])
                : props.defaultAddress.getIn(["country", "id"]),
              AE,
            );

            return {
              ...props.initialQueryValues,

              pickupCountry: defaultCountry.toJS(),
              dropoffCountry: defaultCountry,

              pickupContactName:
                props.defaultAddress && props.defaultAddress.get("name")
                  ? props.defaultAddress.get("name")
                  : props.userName,

              pickupContactPhoneCode:
                props.defaultAddress && props.defaultAddress.get("phone_code")
                  ? fromJS({ code: props.defaultAddress.get("phone_code") })
                  : null,

              pickupContactPhone:
                props.defaultAddress && props.defaultAddress.get("phone")
                  ? props.defaultAddress.get("phone")
                  : props.userPhone,

              pickupLocation: props.defaultAddress
                ? {
                    lat: props.defaultAddress.get("lat"),
                    lng: props.defaultAddress.get("lon"),
                    country: null,
                    address: props.defaultAddress.get("address"),
                    geo_address: getProperGeoAddressString(
                      props.defaultAddress.get("address"),
                    ),
                  }
                : null,

              pickupContactEmail: props.userEmail,

              dropOffTimeslotTime: startOfToday(),
              pickupTimeslotTime: startOfToday(),

              payer: SENDER,
              recipientNotAvailable: DO_NOT_DELIVER,
              pickupAddressType:
                props.defaultAddress && props.defaultAddress.get("address_type")
                  ? props.defaultAddress.get("address_type")
                  : RESIDENTIAL,
              dropoffAddressType: RESIDENTIAL,

              pickupBuilding:
                props.defaultAddress && props.defaultAddress.get("building")
                  ? props.defaultAddress.get("building")
                  : null,

              pickupApartment:
                props.defaultAddress && props.defaultAddress.get("apartment")
                  ? props.defaultAddress.get("apartment")
                  : null,

              pickupStreet:
                props.defaultAddress && props.defaultAddress.get("street")
                  ? props.defaultAddress.get("street")
                  : null,

              pickupNearestLandmark:
                props.defaultAddress && props.defaultAddress.get("landmark")
                  ? props.defaultAddress.get("landmark")
                  : null,

              pickupNeighborhood:
                props.defaultAddress && props.defaultAddress.get("neighborhood")
                  ? props.defaultAddress.get("neighborhood")
                  : null,

              pickupCity:
                props.defaultAddress && props.defaultAddress.get("city")
                  ? props.defaultAddress.get("city")
                  : null,

              unit: CM_KG,
              weight: props.defaultActualWeight,
              pieceCount: 1,
              logistic_type: REGULAR_ORDER,
            };
          })
          .distinctUntilChanged(isEqualData)
          .startWith(null);

        return propsStream
          .combineLatest(initialValuesStream, (props, initialValues) => ({
            ...props,
            initialValues,
            isLoading: props.isLoading || !initialValues,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

CustomerOrderCreateContainer.propTypes = {
  isLoading: PropTypes.bool,
  setLocation: PropTypes.func,
  destroy: PropTypes.func,
  initialValues: PropTypes.object,
  showErrorMessage: PropTypes.func,
  isReverseLogisticEnabled: PropTypes.bool,
  countries: PropTypes.instanceOf(Map),
  marketplace: PropTypes.instanceOf(Map),
  senderLocations: PropTypes.instanceOf(List),
  recipientLocations: PropTypes.instanceOf(List),
  i18n: PropTypes.instanceOf(Map),
};

function CustomerOrderCreateContainer(props) {
  return (
    <CustomerAppLayout
      slug="create_order"
      title={props.i18n.get("create_order", "Create Order")}
    >
      <PageLoading isLoading={props.isLoading} />

      {!props.isLoading && (
        <OrderCreateWizard
          getTimeSlots={getTimeSlots}
          isReverseLogisticEnabled={props.isReverseLogisticEnabled}
          getCachedCity={getCachedCity}
          getCachedCountry={getCachedCountry}
          getCityPredictions={getCityPredictions}
          getCachedPostcode={getCachedPostcode}
          getCountryPredictions={getCountryPredictions}
          getPostcodePredictions={getPostcodePredictions}
          validatePromo={validatePromo}
          countries={props.countries}
          marketplace={props.marketplace}
          initialValues={props.initialValues}
          senderLocations={props.senderLocations}
          recipientLocations={props.recipientLocations}
          allowInternationalCOD={
            props.marketplace.get("marketplace_id") === WING_AE_ID
          }
          onSubmit={values => createOrder(values).catch(ResponseError.throw)}
          onSubmitSuccess={response => {
            props.setLocation(ORDER_CREATE_SUCCESS_URL + response.data.id);

            return trackOrderCreate("Customer", response.data).then(() =>
              props.destroy(),
            );
          }}
          onSubmitFail={error => props.showErrorMessage(error)}
        />
      )}
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerOrderCreateContainer);
