import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const NEIGHBORHOOD_ITEM_URL = `${API_ROOT_URL}/customer/neighborhood/%s`;
const NEIGHBORHOOD_CHOOSE_URL = `${API_ROOT_URL}/customer/neighborhood/choose`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(NEIGHBORHOOD_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(NEIGHBORHOOD_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getCachedPostcode = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getPostcodePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
