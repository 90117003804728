import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  fade,
  List as Lists,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  FontDownload,
  NotificationsActive,
  ViewList,
} from "@material-ui/icons";
import { blue, red } from "@material-ui/core/colors";
import { withTheme } from "@material-ui/core/styles";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ORDER_LIST_URL } from "../../constants/CustomerPathConstants";
import {
  sendInternationalInvoiceEmail,
  sendOrderAWBEmail,
} from "../../api/customer/CustomerApi";
import { getOrder } from "../../api/customer/CustomerOrderApiV2";
import TextWithLink from "../../components/router/TextWithLink";
import CustomerSendEmailDialogWrapper from "../../wrappers/customer/CustomerSendEmailDialogWrapper";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import PayfortDialog from "../../components/orders-core/PayfortDialog";
import StripePaymentDialog from "../../components/orders-core/StripePaymentDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_INTERNATIONAL_INVOICE_URL,
  CREATE_ORDER_AIRWAYBILL_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

export const createOrderHref = (id) => `${ORDER_LIST_URL}?view_v2=${id}`;

const SEND_INTERNATIONAL_INVOICE_EMAIL_DIALOG_HASH = "#SIIEDH";
const SEND_ORDER_AWB_EMAIL_DIALOG_HASH = "#SOAEDH";
const PAY_FOR_ORDER_DIALOG_HASH = "#PFODH";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
  }),
  withTheme,
  useSheet({
    wrapper: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      height: "100%",
      position: "absolute",
    },
    root: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "500px",
      marginRight: "-50%",
      width: "100%",
      position: "absolute",
      zIndex: 2,
    },
    map: { flex: "1 1 0%", zIndex: 1, position: "relative" },
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      position: "absolute",
      backgroundColor: fade("#9c0008", 0.7),
    },
    thankyou: {
      width: "100%",
      color: "white",
      textAlign: "center",
      marginBottom: "20px",
      lineHeight: "1.3",
      fontSize: "20px",
    },
    button: { padding: "12px 0" },
    orderList: { fontSize: "18px", marginBottom: "15px" },
    orderItem: { textAlign: "right", width: "100%", fontWeight: "bold" },
    textLink: {
      width: 470,
      textAlign: "center",
      fontSize: 18,
      background: (props) => props.theme.palette.primary1Color,
      color: (props) => props.theme.palette.appBarTextColor,
      borderRadius: 3,
      transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
      padding: 18,
      textDecoration: "none",
      textDecorationColor: (props) => props.theme.palette.appBarTextColor,
      "&:hover": {
        color: (props) => props.theme.palette.appBarTextColor,
        textDecoration: "none",
        opacity: 0.7,
      },
    },
    avatar1: {
      color: red[500],
      backgroundColor: "white",
      size: 45,
      marginLeft: "-10px",
    },
    avatar2: {
      color: blue[500],
      backgroundColor: "white",
      size: 45,
      marginLeft: "-10px",
    },
  }),
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
          createEventHandler();

        const orderIdStream = propsStream
          .map(fp.flow(fp.get("params.id"), fp.toInteger))
          .distinctUntilChanged();

        const orderResponseStream = orderIdStream
          .switchMap((id) =>
            getOrder(id)
              .catch(() => Observable.of({ notFound: true }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(fp.identity, fromJS, (response) =>
              fromJS({
                notFound: response.get("notFound", false),
                pending: response.get("pending", false),
                payload: response.getIn(["payload", "data"], Map()),
              }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(orderResponseStream, (props, orderResponse) => ({
            ...props,
            onRequestRefresh,
            order: orderResponse.get("payload"),
            isFetching: orderResponse.get("pending"),
            isNotFound: orderResponse.get("notFound"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      (propsStream) => {
        const chargeItemsStream = propsStream
          .distinctUntilKeyChanged("order")
          .map(
            fp.flow(
              fp.get("order"),
              (order) => order.get("charge_items"),
              (chargeItems) =>
                List.isList(chargeItems) ? chargeItems : List(),
              (chargeItems) =>
                chargeItems
                  .toMap()
                  .mapEntries(([, item]) => [item.get("charge_type"), item]),
            ),
          );

        const sideEffectsStream = Observable.merge(
          propsStream
            .map(
              fp.update("order", (item: Map) => ({
                id: item.get("id"),
                paymentType: item.get("payment_type"),
                paymentStatus: item.get("paid"),
              })),
            )
            .distinctUntilKeyChanged("order", isEqualData)
            .filter((props) => !props.order.paymentStatus && 2 === 3)
            .do((props) =>
              props.replaceLocationHash(PAY_FOR_ORDER_DIALOG_HASH),
            ),
        )
          .mapTo(null)
          .startWith(null)
          .catch((error) => {
            console.error(error);

            return Observable.of(null);
          })
          .distinctUntilChanged();

        return propsStream.combineLatest(
          chargeItemsStream,
          sideEffectsStream,
          (props, chargeItems) => ({
            ...props,
            chargeItems,
          }),
        );
      },
    ),
  ),
);

CustomerOrderCreateSuccess.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationQuery: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  chargeItems: PropTypes.instanceOf(Map),

  isFetching: PropTypes.bool,
  isNotFound: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerOrderCreateSuccess(props) {
  const { order, classes, location, getLocalisationMessage } = props;

  return (
    <CustomerAppLayout
      title={getLocalisationMessage("congratulations", "Congratulations!")}
    >
      <PageLoading isLoading={props.isFetching} />

      {props.isNotFound && (
        <Card>
          <CardContent>
            <FlexBox align="center" direction="column">
              <div>
                {getLocalisationMessage(
                  "you_are_not_allowed_to_view_this_order",
                  "You are NOT allowed to view this order!",
                )}
              </div>
              <div>
                {getLocalisationMessage(
                  "please_contact_support_team_for_more_information",
                  "Please contact support team for more information.",
                )}
              </div>
            </FlexBox>
          </CardContent>
        </Card>
      )}

      {Boolean(!props.isFetching && !props.isNotFound) && (
        <div className={classes.wrapper}>
          {location.hash === SEND_INTERNATIONAL_INVOICE_EMAIL_DIALOG_HASH && (
            <CustomerSendEmailDialogWrapper
              open={!order.get("domestic")}
              onRequestClose={() => props.replaceLocationHash(null)}
              onSubmit={fp.flow(toSnakeCase, (values) =>
                sendInternationalInvoiceEmail(
                  order.get("id"),
                  values.emails,
                ).catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.replaceLocationHash(null);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "the_invoice_is_sent_to_email",
                    "The Invoice is sent to email",
                  ),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          )}

          {location.hash === SEND_ORDER_AWB_EMAIL_DIALOG_HASH && (
            <CustomerSendEmailDialogWrapper
              open={true}
              onRequestClose={() => props.replaceLocationHash(null)}
              onSubmit={fp.flow(toSnakeCase, (values) =>
                sendOrderAWBEmail(order.get("id"), values.emails).catch(
                  ResponseError.throw,
                ),
              )}
              onSubmitSuccess={() => {
                props.replaceLocationHash(null);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "the_awb_is_sent_to_email",
                    "The AWB is sent to email",
                  ),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          )}

          {Boolean(
            order.size > 0 &&
              !props.chargeItems.getIn(["service", "paid"]) &&
              !props.chargeItems.getIn(["service", "refund"]) &&
              2 === 3 &&
              location.hash === PAY_FOR_ORDER_DIALOG_HASH,
          ) && (
            <PayfortDialog
              open={true}
              orderId={order.get("id")}
              onRequestClose={() => props.replaceLocationHash(null)}
            />
          )}
          {Boolean(
            order.size > 0 &&
              !props.chargeItems.getIn(["service", "paid"]) &&
              !props.chargeItems.getIn(["service", "refund"]) &&
              2 === 3 &&
              location.hash === PAY_FOR_ORDER_DIALOG_HASH,
          ) && (
            <StripePaymentDialog
              open={true}
              orderId={order.get("id")}
              onRequestClose={() => props.replaceLocationHash(null)}
            />
          )}

          <div className={classes.overlay}>
            <div className={classes.root}>
              <FlexBox>
                <h5 className={classes.thankyou}>
                  {getLocalisationMessage(
                    "your_order_placed_successfully",
                    "Your Order Placed Successfully",
                  )}
                </h5>
              </FlexBox>

              <Card>
                <CardContent>
                  <FlexBox direction="column">
                    <Lists>
                      <ListItem disabled={true}>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar1}>
                            <NotificationsActive />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={getLocalisationMessage(
                            "download_and_print_airway_bill",
                            "In order for us to collect your parcel, please download\n" +
                              "                        and print the airway bill",
                          )}
                        />
                      </ListItem>
                      <ListItem disabled={true}>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar2}>
                            <FontDownload />
                          </Avatar>
                        </ListItemAvatar>
                        <Button
                          disabled={Boolean(!order.get("id"))}
                          target="_blank"
                          component="a"
                          href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
                            ids: order.get("id"),
                          })}
                          style={{ marginLeft: "-15px" }}
                        >
                          {getLocalisationMessage(
                            "download_airway_bill",
                            "Download AirwayBill",
                          )}
                        </Button>
                      </ListItem>
                      {!order.get("domestic") && (
                        <ListItem disabled={true}>
                          <ListItemAvatar>
                            <Avatar className={classes.avatar2}>
                              <ViewList />
                            </Avatar>
                          </ListItemAvatar>
                          <Button
                            disabled={Boolean(!order.get("id"))}
                            target="_blank"
                            component="a"
                            href={updateQuery(
                              CREATE_INTERNATIONAL_INVOICE_URL,
                              {
                                orderId: order.get("id"),
                              },
                            )}
                            style={{ marginLeft: "-15px" }}
                          >
                            {getLocalisationMessage(
                              "download_invoice",
                              "Download Invoice",
                            )}
                          </Button>
                        </ListItem>
                      )}
                    </Lists>
                  </FlexBox>

                  {false && (
                    <FlexBox justify="flex-end">
                      <Button
                        disabled={Boolean(!order.get("id"))}
                        buttonStyle={{ lineHeight: "45px", height: "45px" }}
                        overlayStyle={{ height: "45px" }}
                        onClick={() =>
                          props.replaceLocationHash(
                            SEND_ORDER_AWB_EMAIL_DIALOG_HASH,
                          )
                        }
                      >
                        {getLocalisationMessage(
                          "send_awb_to_email",
                          "Send AWB to Email",
                        )}
                      </Button>
                      {!order.get("domestic") && (
                        <Button
                          disabled={Boolean(!order.get("id"))}
                          buttonStyle={{ lineHeight: "45px", height: "45px" }}
                          overlayStyle={{ height: "45px" }}
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            props.replaceLocationHash(
                              SEND_INTERNATIONAL_INVOICE_EMAIL_DIALOG_HASH,
                            )
                          }
                        >
                          {getLocalisationMessage(
                            "send_invoice_to_email",
                            "Send Invoice to Email",
                          )}
                        </Button>
                      )}
                    </FlexBox>
                  )}

                  <hr />
                  <FlexBox className={classes.orderList}>
                    <FlexBox flex={true}>
                      {getLocalisationMessage("order_id", "Order ID")}:
                    </FlexBox>
                    <FlexBox flex={true}>
                      <div className={classes.orderItem}>
                        #{order.get("order_number")}
                      </div>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox className={classes.orderList}>
                    <FlexBox flex={true}>
                      {getLocalisationMessage("due_amount", "Due Amount")}:
                    </FlexBox>
                    <FlexBox flex={true}>
                      <div className={classes.orderItem}>
                        <PriceWrapper price={order.get("total_charge")} />
                      </div>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox align="center" direction="column">
                    <TextWithLink
                      className={classes.textLink}
                      to={createOrderHref(
                        order.get("id"),
                        order.get("version"),
                      )}
                    >
                      {fp.upperCase(
                        getLocalisationMessage(
                          "track_the_order",
                          "Track the Order",
                        ),
                      )}
                    </TextWithLink>
                  </FlexBox>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      )}
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerOrderCreateSuccess);
