import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { componentFromStream, compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");
const distinctBy = ({ countryId, input: { value } }) => ({ countryId, value });

const enhancer = compose(
  getContext({
    getPublicCachedCity: PropTypes.func,
    getPublicCityPredictions: PropTypes.func,
  }),
);

const CityAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ getPublicCachedCity, input: { value } }) =>
        value && value.id && !value.name && getPublicCachedCity
          ? getPublicCachedCity(value.id)
              .map(item => ({ id: value.id, name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .distinctUntilChanged(null, distinctBy)
      .switchMap(({ getPublicCityPredictions, countryId, input: { value } }) =>
        getPublicCityPredictions
          ? getPublicCityPredictions(
              baseFilter
                .setValue("country_id", countryId)
                .setSearch(value && value.name),
            )
              .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
              .catch(() => Observable.of(List()))
          : Observable.of(List()),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream.combineLatest(
      valueStream,
      optionsStream,
      (
        {
          readOnly,
          getPublicCachedCity,
          getPublicCityPredictions,
          countryId,
          ...props
        },
        value,
        options,
      ) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
          readOnly={
            !getPublicCachedCity || !getPublicCityPredictions || readOnly
          }
          disabled={!countryId}
        />
      ),
    );
  }),
);

PublicFormCityV2AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
  countryId: PropTypes.number,
};

PublicFormCityV2AutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function PublicFormCityV2AutoComplete(props) {
  return <Field {...props} component={CityAutoComplete} />;
}
