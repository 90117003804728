import React from "react";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import {
  getMarketplace,
  getMarketplaceLogo,
  isMarketplaceSubscriptionStatusActive,
} from "../../reducers/MarketplaceReducer";
import {
  getCurrentLanguage,
  getIsRTL,
  getMessages,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { responsive } from "../../../shared/theme/jss-responsive";
import {
  getAppMarketplace,
  getAppName,
} from "../../../shared/reducers/AppReducer";
import logoShipoxSrc from "../../assets/wing-white-logo.svg";
import FlexBox from "../ui-core/FlexBox";
import { getUser } from "../../reducers/ProfileReducer";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Link } from "react-router";
import { ORDER_LIST_URL } from "../../constants/CustomerPathConstants";

const styles = {
  title: { flex: "none" },
};

const enhancer = compose(
  connect(
    state => ({
      marketplaceLogo: getMarketplaceLogo(state),
      marketplaceId: getAppMarketplace(state),
      app: getAppName(state),
      isRTL: getIsRTL(state),
      i18n: getMessages(state),
      currentLanguage: getCurrentLanguage(state),
      user: getUser(state),
      marketplace: getMarketplace(state),
      appName: getAppName(state),
      isStatusActive: isMarketplaceSubscriptionStatusActive(state),
      getTokenUser: getUser(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    title: {
      minHeight: "100%",
      color: "#D0D4EB",
      position: "relative",
      fontSize: "22px",
      "& img": {
        height: "24px",
        maxWidth: "260px",
        marginRight: props => (props.isRTL ? "0" : "10px"),
        marginLeft: props => (!props.isRTL ? "0" : "10px"),
        marginTop: "-4px",
      },
      [responsive("$xs")]: { paddingLeft: 0, backgroundImage: "none" },
    },
  }),
  withState("state", "setState", {}),
);

AppLayoutAppBar.propTypes = {
  sheet: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
  marketplaceLogo: PropTypes.string,
  classes: PropTypes.object,
  titleStyle: PropTypes.object,
  theme: PropTypes.object,
  open: PropTypes.bool,
};

function AppLayoutAppBar(props) {
  const {
    sheet: { classes },
    // state,
  } = props;
  const logo = props.marketplaceLogo || logoShipoxSrc;

  return (
    <FlexBox
      justify={props.open ? "space-between" : "center"}
      alignItems="center"
    >
      {props.open && (
        <FlexBox
          align="center"
          className={classes.title}
          flex={true}
          style={{ ...styles.title, ...props.titleStyle }}
        >
          <Link to={ORDER_LIST_URL}>
            <img src={logo} alt="uzpost" />
          </Link>
        </FlexBox>
      )}

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span
        onClick={() => {
          props.onToggle();
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid rgba(228,228,231,0.99)",
          width: props.open ? 40 : 50,
          borderRadius: 13,
          height: 40,
          background: "#fff",
        }}
      >
        {props.open ? (
          <ArrowBackIos
            style={{ width: 16, height: 16, marginLeft: 8 }}
            color={props.theme.palette.appBarTextColor}
          />
        ) : (
          <ArrowForwardIos
            style={{ width: 16, height: 16 }}
            color={props.theme.palette.appBarTextColor}
          />
        )}
      </span>
    </FlexBox>
  );
}

export default enhancer(AppLayoutAppBar);
