import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Breadcrumb from "./Breadcrumb";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ARRIVED,
  ACCEPTED,
  CANCELLED,
  COMPLETED,
  PICKED_UP,
  IN_TRANSIT,
  ON_HIS_WAY,
  UNASSIGNED,
  TO_BE_RETURNED,
  DELIVERY_FAILED,
  DRIVER_REJECTED,
  OUT_FOR_DELIVERY,
  RETURNED_TO_ORIGIN,
  ASSIGNED_TO_COURIER,
  IN_SORTING_FACILITY,
} from "../../constants/OrderStatusCodes";

const getItems = (status, getLocalisationMessage) => {
  const statuses = [];

  statuses.push({
    label: getLocalisationMessage("new", "New"),
    active: status === UNASSIGNED,
  });

  statuses.push({
    label: getLocalisationMessage("assigned_to_courier", "Assigned To Courier"),
    active: status === ASSIGNED_TO_COURIER,
  });

  statuses.push({
    label: getLocalisationMessage("assigned_to_driver", "Assigned To Driver"),
    active: status === ACCEPTED || status === DRIVER_REJECTED,
  });

  statuses.push({
    label: getLocalisationMessage("driver_on_pickup", "Driver On Pickup"),
    active: status === ON_HIS_WAY,
  });

  statuses.push({
    label: getLocalisationMessage("parcel_pickep_up", "Parcel Picked Up"),
    active: status === PICKED_UP,
  });

  statuses.push({
    label: getLocalisationMessage("arrived", "Arrived"),
    active: status === ARRIVED,
  });

  if (status === TO_BE_RETURNED) {
    statuses.push({
      active: true,
      label: getLocalisationMessage("to_be_returned", "To Be Returned"),
    });
  } else if (status === IN_TRANSIT) {
    statuses.push({
      active: true,
      label: getLocalisationMessage("in_transit", "In Transit"),
    });
  } else {
    statuses.push({
      label: getLocalisationMessage("in_transit", "In Transit"),
    });
  }

  statuses.push({
    label: getLocalisationMessage(
      "parcel_in_sorting_facility",
      "Parcel in Sorting Facility",
    ),
    active: status === IN_SORTING_FACILITY,
  });

  statuses.push({
    label: getLocalisationMessage(
      "parcel_out_for_delivery",
      "Parcel out For Delivery",
    ),
    active: status === OUT_FOR_DELIVERY,
  });

  if (status === COMPLETED) {
    statuses.push({
      active: true,
      label: getLocalisationMessage("parcel_delivered", "Parcel Delivered"),
    });
  } else if (status === DELIVERY_FAILED) {
    statuses.push({
      active: true,
      label: getLocalisationMessage("delivery_failed", "Delivery Failed"),
    });
  } else if (status === CANCELLED) {
    statuses.push({
      active: true,
      label: getLocalisationMessage("delivery_cancelled", "Delivery Cancelled"),
    });
  } else if (status === RETURNED_TO_ORIGIN) {
    statuses.push({
      active: true,
      label: getLocalisationMessage("driver_cancelled", "Driver Cancelled"),
    });
  } else {
    statuses.push({
      label: getLocalisationMessage("parcel_delivered", "Parcel Delivered"),
    });
  }

  return statuses;
};

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderStatusBreadcrumb.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderStatusBreadcrumb(props) {
  const { status, getLocalisationMessage, ...restProps } = props;

  return (
    <Breadcrumb
      {...restProps}
      items={getItems(status, getLocalisationMessage)}
    />
  );
}

export default enhancer(OrderStatusBreadcrumb);
