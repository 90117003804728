import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import Cookies from "js-cookie";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { updatePassword } from "../../api/shared/AuthApi";
import { getUser2, updateUser } from "../../api/shared/ProfileApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import ProfileForm from "../../components/deprecated/ProfileForm";
import ProfilePasswordForm from "../../components/deprecated/ProfilePasswordForm";
import { fetchUser } from "../../actions/ProfileActions";
import { fetchUserAccount } from "../../actions/UserAccountActions";
import { fetchMarketplace } from "../../actions/MarketplaceActions";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      fetchUser,
      fetchUserAccount,
      fetchMarketplace,
    },
  ),
);

CustomerProfile.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  fetchUser: PropTypes.func,
  fetchUserAccount: PropTypes.func,
  fetchMarketplace: PropTypes.func,
  app: PropTypes.string,
};

function CustomerProfile({
  i18n,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  // eslint-disable-next-line no-shadow
  fetchUser,
  // eslint-disable-next-line no-shadow
  fetchUserAccount,
  // eslint-disable-next-line no-shadow
  fetchMarketplace,
  app,
}) {
  const [user, setUser] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getUser2()
      .then(res => {
        setIsLoading(false);
        setUser(res);
      })
      .catch(error => {
        setIsLoading(false);
        showErrorMessage(error);
      });
  }, [refresh]);

  return (
    <CustomerAppLayout title={i18n.get("profile", "Profile")}>
      <PageLoading isLoading={isLoading} />
      <FlexBox container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <ProfileForm
              customer={true}
              disableCustomerName={true}
              onSubmit={values =>
                updateUser(toSnakeCase(values))
                  .catch(ResponseError.throw)
                  .then(res => res)
              }
              onSubmitSuccess={response => {
                showSuccessMessage1(
                  i18n.get("successfully_updated", "Successfully Updated"),
                );
                if ("values" in response) {
                  Cookies.set("language", response.values.languageKey);
                }
                fetchUser(app);
                fetchUserAccount();
                fetchMarketplace();
                setRefresh(true);
              }}
              onSubmitFail={showErrorMessage1}
              initialValues={toCamelCase(user)}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <ProfilePasswordForm
              onSubmit={({ password, currentPassword }) =>
                updatePassword(
                  toSnakeCase({ password, currentPassword }),
                ).catch(ResponseError.throw)
              }
              onSubmitSuccess={() => {
                showSuccessMessage1(
                  i18n.get("successfully_changed", "Successfully Changed"),
                );
              }}
              onSubmitFail={showErrorMessage1}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerProfile);
