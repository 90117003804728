import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ORDER_ITEM_URL } from "../../constants/CustomerPathConstants";
import { getAttachmentUrl } from "../../api/shared/FileApi";
import {
  getCODWithdraw,
  getCODWithdrawOrders,
} from "../../api/customer/CustomerFinanceApi";
import PageLoading from "../../components/ui-core/PageLoading";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CODWithdrawDetails from "../../components/finance-core/CODWithdrawDetails";
import CODWithdrawOrderList from "../../components/finance-core/CODWithdrawOrderList";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({ setLocationQueryFilter: PropTypes.func.isRequired }),
  useSheet({
    root: { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    orders: {
      flex: "1 1 0%",
      marginTop: "12px",
      display: "flex",
      "& > div": { flex: "1 1 0%", display: "flex" },
    },
    appBarRightActionButton: {
      color: "#fff",
      top: "6px",
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const idStream = propsStream
          .map(fp.flow(fp.get("params.id"), fp.toFinite))
          .distinctUntilChanged();
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), DataListFilter.create))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(idStream, filterStream, (props, id, filter) => ({
            ...props,
            id,
            filter,
          }));
      },
      propsStream => {
        const itemResponseStream = propsStream
          .pluck("id")
          .distinctUntilChanged()
          .switchMap(id => getCODWithdraw(id).catch(() => Observable.of({})))
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], Map()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const ordersResponseStream = propsStream
          .map(fp.pick(["id", "filter"]))
          .distinctUntilChanged(isEqualData)
          .switchMap(props =>
            getCODWithdrawOrders(props.id, props.filter).catch(() =>
              Observable.of({}),
            ),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(
            itemResponseStream,
            ordersResponseStream,
            (props, itemResponse, ordersResponse) => ({
              ...props,
              item: itemResponse.get("payload"),
              isLoading: itemResponse.get("pending"),
              orders: ordersResponse.get("list"),
              ordersTotal: ordersResponse.get("total"),
              ordersLoading: ordersResponse.get("pending"),
            }),
          );
      },
    ),
  ),
);

CustomerCODWithdrawItemContainer.propTypes = {
  classes: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  ordersTotal: PropTypes.number,
  ordersLoading: PropTypes.bool,
  orders: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerCODWithdrawItemContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <CustomerAppLayout
      title={getLocalisationMessage("withdraw_requests", "Withdraw Requests")}
      appBarRightAction={
        props.item.get("cod_statement_id") && (
          <Button
            className={classes.appBarRightActionButton}
            onClick={() => {
              Promise.resolve(
                getAttachmentUrl(props.item.get("cod_statement_id")),
              ).then(response => {
                window.location.href = response.data;
              });
            }}
          >
            {getLocalisationMessage("cod_statement", "COD Statement")}
          </Button>
        )
      }
    >
      <PageLoading isLoading={props.isLoading} />

      <div className={classes.root}>
        {!props.isLoading && <CODWithdrawDetails item={props.item} />}

        <Card className={classes.orders}>
          <CODWithdrawOrderList
            list={props.orders}
            totalCount={props.ordersTotal}
            isLoading={props.ordersLoading}
            filter={props.filter}
            createOrderHref={id => ORDER_ITEM_URL + id}
            onFilterChange={filter => props.setLocationQueryFilter(filter)}
          />
        </Card>
      </div>
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerCODWithdrawItemContainer);
