import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";

const getValues = formValueSelector("OrderCreateWizardDefault");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizardDefault",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    values: getValues(
      state,
      "acceptedTerms",
      "pickupCountry",
      "dropoffCountry",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepTermsDialog.propTypes = {
  values: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepTermsDialog(props) {
  const { values, getLocalisationMessage } = props;

  const open =
    !values.acceptedTerms &&
    fp.get("id", values.pickupCountry) !== fp.get("id", values.dropoffCountry);

  return (
    <Dialog open={open} modal={true}>
      <DialogTitle>
        {getLocalisationMessage("terms_and_conditions", "Terms and Conditions")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getLocalisationMessage(
            "duties_and_taxes",
            "Shipments are subject to duties and taxes as determined by customs in the\n" +
              "      destination country.",
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            onClick={() => {
              props.change("acceptedTerms", true);
              props.handleSubmit();
            }}
          >
            {getLocalisationMessage("accept", "Accept")}
          </Button>
          <Button onClick={props.onDismiss}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderCreateWizardStepTermsDialog);
