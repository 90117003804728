import { Observable } from "rxjs";
import React from "react";
import { Set, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocationOn } from "@material-ui/icons";
import Timeline from "../timeline/Timeline";
import TimelineEvent from "../timeline/TimelineEvent";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import {
  mapOrderNotesAndHistoryToActivities,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  IN_SORTING_FACILITY,
} from "../../constants/OrderStatusCodes";
import { getOrderActivities } from "../../api/customer/CustomerOrderApi";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const statusesWithWarehouse = Set.of(
  IN_TRANSIT,
  IN_SORTING_FACILITY,
  OUT_FOR_DELIVERY,
);

const GOOGLE_MAPS_URL = "https://www.google.com/maps/preview";
const enhancer = compose(
  connect((state) => {
    const userRoles = (getUser(state) && getUser(state).get("roles")) || [];
    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const getOrderActivitiesResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap((props) =>
        getOrderActivities(props.orderId, props.orderNumber)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", mapOrderNotesAndHistoryToActivities),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      getOrderActivitiesResponseStream,
      (props, activitiesResponse) => ({
        ...props,
        onRequestRefresh,
        activities: activitiesResponse.get("payload"),
        activitiesFetching: activitiesResponse.get("pending"),
      }),
    );
  }),
);

CustomerOrderActivitiesTimeline.propTypes = {
  showDriver: PropTypes.bool,
  showSupplier: PropTypes.bool,
  showWarehouse: PropTypes.bool,
  driverItemUrl: PropTypes.func,
  driverItemLocationUrl: PropTypes.func,
  showCallLogUrl: PropTypes.bool,
  isAdminViewer: PropTypes.bool,
  activities: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
  orderNumber: PropTypes.string,
  orderId: PropTypes.number,
};

CustomerOrderActivitiesTimeline.defaultProps = {
  showDriver: false,
  showSupplier: false,
  showWarehouse: false,
  showCallLogUrl: true,
};

function CustomerOrderActivitiesTimeline(props) {
  if (!props.activities) {
    return null;
  }
  const { getLocalisationMessage } = props;
  return (
    <Timeline>
      {props.activities
        .map((item, key) => {
          const note = item.get("note");
          const status = item.get("status");
          const driverId = item.getIn(["driver", "id"]);
          const userName =
            item.getIn(["user", "name"]) || item.getIn(["user", "description"]);

          const lat = item.get("lat");
          const lon = item.get("lon");

          if (note) {
            return (
              <TimelineEvent
                key={key}
                date={item.get("date")}
                title={
                  <span>
                    {formatText(note.get("category"))}{" "}
                    {getLocalisationMessage(
                      "order_history_note_from",
                      "Note from",
                    )}{" "}
                    <strong>{userName}</strong>
                  </span>
                }
              >
                <div>
                  {note.get("reason") && (
                    <p>
                      {getLocalisationMessage(
                        "order_history_with_reason",
                        "With Reason",
                      )}
                      <strong>{note.get("reason")}</strong>
                    </p>
                  )}

                  <p>{note.get("content")}</p>
                </div>
              </TimelineEvent>
            );
          } else if (status) {
            const userId = item.getIn(["user", "id"]);

            const statusCode = status.get("code");
            const driverName = item.getIn(["driver", "name"]);
            const supplierName = item.getIn(["supplier", "name"]);
            const warehouseId = item.getIn(["warehouse", "id"]);
            const warehouseName = item.getIn(["warehouse", "name"]);

            return (
              <TimelineEvent
                key={key}
                date={item.get("date")}
                title={
                  <span>
                    <em>
                      {formatOrderStatusCodeForLocalisation(
                        statusCode,
                        getLocalisationMessage,
                      )}
                    </em>
                    {Boolean(props.showDriver && driverId) && (
                      <span>
                        {" "}
                        {getLocalisationMessage(
                          "order_history_with",
                          "with",
                        )}{" "}
                        <strong>
                          {props.driverItemUrl && !props.isAdminViewer ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={props.driverItemUrl(driverId)}
                            >
                              {driverName}
                            </a>
                          ) : (
                            driverName
                          )}
                        </strong>
                      </span>
                    )}
                    {Boolean(
                      props.showWarehouse &&
                        warehouseId &&
                        statusesWithWarehouse.has(statusCode),
                    ) && (
                      <span>
                        {" "}
                        {statusCode === IN_TRANSIT &&
                          getLocalisationMessage(
                            "order_history_to_warehouse",
                            "to",
                          )}
                        {statusCode === IN_SORTING_FACILITY &&
                          getLocalisationMessage(
                            "order_history_in_warehouse",
                            "in",
                          )}
                        {statusCode === OUT_FOR_DELIVERY &&
                          getLocalisationMessage(
                            "order_history_from_warehouse",
                            "from",
                          )}{" "}
                        <strong>{warehouseName}</strong>
                      </span>
                    )}
                    {Boolean(props.showWarehouse && userId) && (
                      <span>
                        {" "}
                        {getLocalisationMessage("order_history_by", "by")}{" "}
                        <strong>
                          {lat && lon ? (
                            <span>
                              {userName}

                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={updateQuery(GOOGLE_MAPS_URL, {
                                  q: [lat, lon].join(","),
                                })}
                              >
                                <LocationOn
                                  style={{
                                    color: "cornflowerblue",
                                    marginTop: "-12px",
                                  }}
                                />
                              </a>
                            </span>
                          ) : (
                            userName
                          )}
                        </strong>
                      </span>
                    )}
                    {Boolean(supplierName) && (
                      <span>
                        {" "}
                        (<strong>{supplierName}</strong>)
                      </span>
                    )}
                  </span>
                }
              />
            );
          }

          return null;
        })
        .toArray()}
    </Timeline>
  );
}

export default enhancer(CustomerOrderActivitiesTimeline);
