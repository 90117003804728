import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {} from "react-router";

import { Select, MenuItem, Button, Divider } from "@material-ui/core";

import { connect } from "react-redux";
import PageBreadcrumb from "./PageBreadcrumb";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_ORDER_AIRWAYBILL_URL,
  CREATE_INTERNATIONAL_INVOICE_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

export const TAB_DETAILS = "details";
export const TAB_ACTIVITIES = "activities";
export const TAB_MAP = "map";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRTL: getIsRTL(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    separator: { marginLeft: "6px", marginRight: "6px" },
    dropDownMenu: { marginRight: 0, alignSelf: "stretch" },
  }),
);

OrderDetailsBreadcrumb.propTypes = {
  classes: PropTypes.object,
  tab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  orderListUrl: PropTypes.string.isRequired,
  order: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
  NOTE_DIALOG_HASH: PropTypes.string,
  replaceLocationHash: PropTypes.func,
};

function OrderDetailsBreadcrumb(props) {
  const { getLocalisationMessage } = props;
  return (
    <PageBreadcrumb
      title={`${getLocalisationMessage(
        "order_num",
        "Order #",
      )}${props.order.get("order_number")}`}
      fallbackBackUrl={props.orderListUrl}
    >
      <Divider
        variant="inset"
        orientation="vertical"
        className={props.classes.separator}
      />
      <Select
        fullWidth={true}
        className={props.classes.dropDownMenu}
        value={props.tab}
        onChange={event => props.onTabChange(event.target.value)}
      >
        <MenuItem value={TAB_DETAILS}>
          {getLocalisationMessage("details", "Details")}
        </MenuItem>
        <MenuItem value={TAB_ACTIVITIES}>
          {getLocalisationMessage("activities", "Activities")}
        </MenuItem>
        <MenuItem value={TAB_MAP}>
          {getLocalisationMessage("map", "Map")}
        </MenuItem>
      </Select>
      {/* <DropDownMenu
        maxHeight={200}
        autoWidth={true}
        value={props.tab}
        className={props.classes.dropDownMenu}
        onChange={(event, index, value) => props.onTabChange(value)}
        iconStyle={{
          left: !isRTL ? "auto" : "16px",
          right: isRTL ? "auto" : "16px",
        }}
        labelStyle={{
          paddingLeft: !isRTL ? "24px" : "56px",
          paddingRight: isRTL ? "24px" : "56px",
          textAlign: isRTL ? "right" : "left",
        }}
      >
        <MenuItem
          value={TAB_DETAILS}
          primaryText={getLocalisationMessage("details", "Details")}
        />
        <MenuItem
          value={TAB_ACTIVITIES}
          primaryText={getLocalisationMessage("activities", "Activities")}
        />
        <MenuItem
          value={TAB_MAP}
          primaryText={getLocalisationMessage("map", "Map")}
        />
      </DropDownMenu> */}
      <div>
        <Button
          target="_blank"
          component="a"
          href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
            ids: props.order.get("id"),
          })}
        >
          {" "}
          {getLocalisationMessage("download_awb", "Download AWB")}{" "}
        </Button>

        {!props.order.get("domestic") && (
          <Button
            target="_blank"
            component="a"
            href={updateQuery(CREATE_INTERNATIONAL_INVOICE_URL, {
              orderId: props.order.get("id"),
            })}
          >
            {" "}
            {getLocalisationMessage(
              "download_invoice",
              "Download Invoice",
            )}{" "}
          </Button>
        )}
        <Button
          onClick={() => props.replaceLocationHash(props.NOTE_DIALOG_HASH)}
        >
          {" "}
          {getLocalisationMessage("add_note", "Add Note")}{" "}
        </Button>
      </div>
    </PageBreadcrumb>
  );
}

export default enhancer(OrderDetailsBreadcrumb);
