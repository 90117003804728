import React from "react";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pipeStreams } from "../../helpers/StreamUtils";
import { PUBLIC_ORDER_TRACK_URL } from "../../constants/CustomerPathConstants";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderTrackMap from "../../components/order-track/OrderTrackMap";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { getAppMarketplace } from "../../../shared/reducers/AppReducer";
import { GOODBASKET_ID } from "../../../server/constants/MarketplaceId";

const enhancer = compose(
  connect(state => ({
    marketplaceId: getAppMarketplace(state),
  })),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const idStream = propsStream.map(
        fp.flow(fp.get("location.query.id"), fp.trim),
      );

      return propsStream.combineLatest(idStream, (props, id) => ({
        ...props,
        id,
      }));
    }),
  ),
);

CustomerOrderTrackingMapContainer.propTypes = {
  id: PropTypes.string,
  marketplaceId: PropTypes.number,
};

function CustomerOrderTrackingMapContainer(props) {
  const { marketplaceId } = props;

  return (
    <FlexBox flex={true} align="center" container={8} direction="column">
      <Redirect
        when={marketplaceId !== GOODBASKET_ID}
        to={updateQuery(PUBLIC_ORDER_TRACK_URL, fp.set("id", props.id))}
      />
      <OrderTrackMap orderId={props.id} />
    </FlexBox>
  );
}

export default enhancer(CustomerOrderTrackingMapContainer);
