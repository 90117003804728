import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PUBLIC_ORDER_URL = `${API_V2_ROOT_URL}/public/order/%s`;

const MAX_TIMEOUT = 30 * 1000;

export const getPublicOrder = (id: number) =>
  api.getStream(sprintf(PUBLIC_ORDER_URL, id), { timeout: MAX_TIMEOUT });
