import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { Button, Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { isCancelOrder } from "../../helpers/CancelOrderHelper";
import {
  getMarketplaceId,
  isCustomMarketplace,
  isEnableCancelOrderForCustomer,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { CUSTOMER_FEEDBACK } from "../../constants/RaitingType";
import {
  COMPLETED,
  ISSUED_TO_RECIPIENT,
} from "../../constants/OrderStatusCodes";
import { getDriverLocation } from "../../api/shared/DriverApi";
import {
  cancelledOrderUpdateStatus,
  createOrderNote,
  getCustomerFeedback,
  saveCustomerFeedback,
} from "../../api/customer/CustomerOrderApi";
import {
  getOrder,
  getOrderWithoutStream,
} from "../../api/customer/CustomerOrderApiV2";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderNoteDialog from "../../components/orders-core/OrderNoteDialog";
import CancelOrderDialog from "../../components/orders-core/CancelOrderDialog";
import OrderSupplierLandlineDialog from "../../components/orders-core/OrderSupplierLandlineDialog";
import CustomerOrderActivitiesTimeline from "../../components/orders-core/CustomerOrderActivitiesTimeline";
import Notification from "../../components/notifications/Notification";
import FeedbackDialog from "../../components/feedback-dialog/FeedbackDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import OrderDetailsDialogMap from "../../components/order-details-dialog/v2/OrderDetailsDialogMap";
import {
  BRIEF_TAB,
  HISTORY_TAB,
  MAP_TAB,
  SIGNATURE_TAB,
} from "../../components/order-details-dialog/customer/CustomerOrderDetailsDialogTabs";
import CustomerOrderDialogDetailsSignature from "../../components/order-details-dialog/customer/CustomerOrderDialogDetailsSignature";
import OrderDetailsDialogBriefV2 from "../../components/order-details-dialog/v2/OrderDetailsDialogBriefV2";
import CustomerOrderDetailsDialog from "../../components/order-details-dialog/customer/CustomerOrderDetailsDialog";
import PageLoading from "../../components/ui-core/PageLoading";

const NOTE_DIALOG_HASH = "#NDH";
const FEEDBACK_DIALOG_HASH = "#FDH";
const SUPPLIER_LANDLINE_HASH = "#SLH";
const SERVICE_CHARGE_EDIT_HASH = "#SCEH";
const ORDER_CANCEL_DIALOG_HASH = "#OCDH";
const PAY_FOR_ORDER_DIALOG_HASH = "#PFODH";

const enhancer = compose(
  withTheme,
  renderIf(props => props.orderId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      mapProvider: getMapProvider(state),
      isCustom: isCustomMarketplace(state),
      marketplaceId: getMarketplaceId(state),
      enableCancelOrder: isEnableCancelOrderForCustomer(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
  }),
  useSheet({
    container: {
      minHeight: "300px",
      maxHeight: "480px",
      overflowY: "auto",
      margin: "0 6px 6px",
    },
    buttonRight: { float: "right" },
    callToSupplier: {
      color: props => props.theme.palette.primary1Color,
      fontWeight: "bold",
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const orderResponseStream = propsStream
          .distinctUntilKeyChanged("orderId")
          .switchMap(props =>
            getOrder(props.orderId)
              .catch(() => Observable.of({ notFound: true }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(fp.identity, fromJS, response =>
              fromJS({
                notFound: response.get("notFound", false),
                pending: response.get("pending", false),
                payload: response.getIn(["payload", "data"], Map()),
                orderNumber: response.getIn(
                  ["payload", "data", "order_number"],
                  null,
                ),
              }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(orderResponseStream, (props, orderResponse) => ({
            ...props,
            onRequestRefresh,
            order: orderResponse.get("payload"),
            orderNumber: orderResponse.get("orderNumber"),
            isFetching: orderResponse.get("pending"),
            isNotFound: orderResponse.get("notFound"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const chargeItemsStream = propsStream
          .distinctUntilKeyChanged("order")
          .map(
            fp.flow(
              fp.get("order"),
              order => order.get("charge_items"),
              chargeItems => (List.isList(chargeItems) ? chargeItems : List()),
              chargeItems =>
                chargeItems
                  .toMap()
                  .mapEntries(([, item]) => [item.get("charge_type"), item]),
            ),
          );

        const sideEffectsStream = Observable.merge(
          propsStream
            .map(
              fp.update("order", (item: Map) => ({
                id: item.get("id"),
                paymentType: item.get("payment_type"),
                paymentStatus: item.get("paid"),
              })),
            )
            .distinctUntilKeyChanged("order", isEqualData)
            .filter(props => !props.order.paymentStatus && 2 === 3)
            .do(props => props.replaceLocationHash(PAY_FOR_ORDER_DIALOG_HASH)),
          propsStream
            .map(
              fp.update("order", (item: Map) => ({
                id: item.get("id"),
                status: item.get("status"),
              })),
            )
            .distinctUntilKeyChanged("order", isEqualData)
            .filter(
              props =>
                props.order.status === COMPLETED ||
                props.order.status === ISSUED_TO_RECIPIENT,
            )
            .switchMap(
              props =>
                getCustomerFeedback(props.order.id)
                  .takeLast(1)
                  .catch(() => Observable.of({})),
              (props, response) => ({ props, response }),
            )
            .filter(fp.flow(fp.get("response.payload.data.id"), fp.isNil))
            .do(({ props }) => props.replaceLocationHash(FEEDBACK_DIALOG_HASH)),
        )
          .mapTo(null)
          .startWith(null)
          .catch(error => {
            console.error(error);

            return Observable.of(null);
          })
          .distinctUntilChanged();

        return propsStream.combineLatest(
          chargeItemsStream,
          sideEffectsStream,
          (props, chargeItems) => ({
            ...props,
            chargeItems,
          }),
        );
      },
    ),
  ),
);

CustomerOrderDetailsDialogWrapper.propTypes = {
  classes: PropTypes.object,
  tab: PropTypes.oneOf([BRIEF_TAB, HISTORY_TAB, SIGNATURE_TAB, MAP_TAB]),
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  replaceLocationQuery: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  getLocalisationMessage: PropTypes.func.isRequired,
  isCustom: PropTypes.bool,
  enableCancelOrder: PropTypes.bool,
  orderId: PropTypes.number,
  onTabChange: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  refreshOrderList: PropTypes.func.isRequired,
};

CustomerOrderDetailsDialogWrapper.defaultProps = {
  tab: BRIEF_TAB,
};

function CustomerOrderDetailsDialogWrapper(props) {
  const {
    classes,
    location,
    getLocalisationMessage,
    location: {
      query: { successMessage, errorMessage },
    },
    isCustom,
    orderId,
  } = props;

  const [order, setOrder] = useState(Map());
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (orderId) {
      setIsLoading(true);
      getOrderWithoutStream(orderId)
        .then(res => {
          if (res && res.data) {
            setIsLoading(false);
            const immutableOrder = fromJS(res.data);
            setOrder(immutableOrder);
          }
        })
        .catch(() => setIsLoading(false));
    }
  }, [orderId, refresh]);

  const isOrderClosed = isCancelOrder(order);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  return (
    <CustomerOrderDetailsDialog
      open={true}
      getLocalisationMessage={getLocalisationMessage}
      onRequestClose={props.onRequestClose}
      tab={props.tab}
      order={order}
      isLoading={isLoading}
      onTabChange={props.onTabChange}
      onEditCostsClick={() =>
        props.replaceLocationHash(SERVICE_CHARGE_EDIT_HASH)
      }
      isCustom={isCustom}
      isOrderClosed={isOrderClosed}
      onCancelOrderClick={() =>
        props.replaceLocationHash(ORDER_CANCEL_DIALOG_HASH)
      }
      getReverseUrl={id => updateQuery(props.location, fp.set("view_v2", id))}
      enableCancelOrder={props.enableCancelOrder}
      deliveryPhotoId={order.get("actual_recipient_id_card_id")}
      deliverySignatureId={order.get("actual_recipient_signature_id")}
    >
      <PageLoading isLoading={isLoading} />
      <FeedbackDialog
        open={location.hash === FEEDBACK_DIALOG_HASH}
        onSubmit={values =>
          saveCustomerFeedback(
            order.get("id"),
            toSnakeCase({
              ...values,
              driver: order.get("driver"),
              supplier: order.get("supplier"),
              order_id: order.get("id"),
              rating_type: CUSTOMER_FEEDBACK,
            }),
          )
        }
        onSubmitSuccess={() => {
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <Notification
        type="success"
        uid="CustomerOrderItemSuccess"
        open={Boolean(successMessage)}
        action={
          <Button
            label={getLocalisationMessage("dismiss", "Dismiss")}
            onClick={() =>
              props.replaceLocationQuery(fp.unset("successMessage"))
            }
          />
        }
      >
        {successMessage}
      </Notification>

      <Notification
        type="error"
        uid="CustomerOrderItemError"
        open={Boolean(errorMessage)}
        action={
          <Button
            label={getLocalisationMessage("dismiss", "Dismiss")}
            onClick={() => props.replaceLocationQuery(fp.unset("errorMessage"))}
          />
        }
      >
        {errorMessage}
      </Notification>

      <CancelOrderDialog
        isCancelLoading={isCancelLoading}
        onRequestClose={() => props.replaceLocationHash(null)}
        open={isOrderClosed && location.hash === ORDER_CANCEL_DIALOG_HASH}
        onSubmit={() => {
          setIsCancelLoading(true);
          cancelledOrderUpdateStatus(order.get("barcode"))
            .then(res => {
              if (res && res.status === "success") {
                setRefresh(prev => !prev);
                props.replaceLocationHash(null);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "order_successfully_cancelled",
                    "Order Successfully Cancelled",
                  ),
                );

                setIsCancelLoading(false);
                props.refreshOrderList();
              }
            })
            .catch(error => {
              props.showErrorMessage(error);
              setIsCancelLoading(false);
            });
        }}
        onSubmitSuccess={() => {}}
        onSubmitFail={error => {
          setRefresh(prev => !prev);
          props.showErrorMessage(error);
        }}
      />

      {Boolean(props.tab === BRIEF_TAB) && (
        <OrderDetailsDialogBriefV2
          order={order}
          isCustom={isCustom}
          isCustomer={true}
          isOrderClosed={isOrderClosed}
          replaceLocationHash={props.replaceLocationHash}
        />
      )}

      {Boolean(props.tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            {order.getIn(["supplier", "phone"]) && (
              <Button
                className={classes.buttonRight}
                onClick={() =>
                  props.replaceLocationHash(SUPPLIER_LANDLINE_HASH)
                }
              >
                {`${getLocalisationMessage("call_to", "Call to")}`}
              </Button>
            )}
            <Button
              className={classes.buttonRight}
              onClick={() => props.replaceLocationHash(NOTE_DIALOG_HASH)}
            >
              {getLocalisationMessage("add_note", "Add Note")}
            </Button>

            <CustomerOrderActivitiesTimeline
              showCallLogUrl={false}
              orderNumber={order.get("order_number")}
              orderId={props.orderId}
            />

            {order.getIn(["supplier", "phone"]) && (
              <OrderSupplierLandlineDialog
                supplier={order.get("supplier")}
                open={location.hash === SUPPLIER_LANDLINE_HASH}
                onRequestClose={() => props.replaceLocationHash(null)}
              />
            )}

            <OrderNoteDialog
              open={location.hash === NOTE_DIALOG_HASH}
              onSubmit={values =>
                createOrderNote(order.get("id"), {
                  value: values.content,
                }).catch(ResponseError.throw)
              }
              onSubmitSuccess={() => {
                setRefresh(prev => !prev);
                props.replaceLocationHash(null);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_note_created",
                    "Successfully Note Created",
                  ),
                );
              }}
              onSubmitFail={() => props.showErrorMessage()}
              onRequestClose={() => props.replaceLocationHash(null)}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === SIGNATURE_TAB) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <CustomerOrderDialogDetailsSignature
                deliveryPhotoId={order.get("actual_sender_id_card_id")}
                deliverySignatureId={order.get("actual_sender_signature_id")}
                order={order}
                header={getLocalisationMessage(
                  "proof_of_pickup",
                  "Proof of Pickup",
                )}
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <CustomerOrderDialogDetailsSignature
                deliveryPhotoId={order.get("actual_recipient_id_card_id")}
                deliverySignatureId={order.get("actual_recipient_signature_id")}
                order={order}
                header={getLocalisationMessage(
                  "proof_of_delivery",
                  "Proof of Delivery",
                )}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}

      {Boolean(props.tab === MAP_TAB) && (
        <OrderDetailsDialogMap
          order={order}
          getDriverLocation={getDriverLocation}
        />
      )}
    </CustomerOrderDetailsDialog>
  );
}

export default enhancer(CustomerOrderDetailsDialogWrapper);
